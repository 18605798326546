import * as React from "react";
import { ToastBar, Toaster as ReactHotToast } from "react-hot-toast";
import { SafeArea } from "~/design-system/SafeArea";

const Toaster = () => {
  return (
    <ReactHotToast>
      {(t) => (
        <SafeArea top>
          <ToastBar toast={t} />
        </SafeArea>
      )}
    </ReactHotToast>
  );
};

export { Toaster };
