import React from "react";
import { useNavigate } from "react-router";
import type { UserPropsAuth0 } from "~/config/auth0/use-auth0";
import { getAuth0Id, useAuth0 } from "~/config/auth0/use-auth0";
import { useNetwork } from "~/config/capacitor/network/hook";
import { useUseRedirectAfterLogin_DataQuery } from "./useRedirectAfterLogin.graphql";

const useRedirectAfterLogin = () => {
  const navigate = useNavigate();

  // ? QUESTION: what is "sub" here ?
  const { getAccessTokenSilently, isAuthenticated, user } =
    useAuth0<UserPropsAuth0>();

  const { isOffline } = useNetwork();

  React.useEffect(() => {
    if (isAuthenticated && !isOffline) {
      console.info(`🟢 Online : Refreshing token for `, user);
      // Refresh token, ignoring cache if we're online
      // This ensures we have a valid token after regaining connection
      void getAccessTokenSilently({
        ignoreCache: !isOffline, // Only use cached token when offline
      });
    } else {
      console.info(`🔴 Offline : Not refreshing token for `, user);
    }
  }, [
    isOffline, // Re-run when network status changes
    isAuthenticated, // Re-run when auth status changes
    getAccessTokenSilently, // Re-run if auth method changes
  ]);

  // This log is necessary to debug logout glitches on the app
  console.info("Clovis code info log: useRedirectAfterLogin user:", user);

  const [{ data }] = useUseRedirectAfterLogin_DataQuery({
    pause: !user,
    variables: {
      auth0_id: getAuth0Id(user),
    },
  });

  const clearStorage = React.useCallback(() => {
    window.localStorage.removeItem("redirectAfterLogin");
  }, []);

  const redirectToPath = React.useCallback(
    (path: string) => {
      // Clear local storage (we want to redirect once)
      clearStorage();
      // Apply redirect
      navigate(path);
    },
    [navigate, clearStorage]
  );

  const redirect = React.useCallback(() => {
    const redirectAfterLogin =
      window.localStorage.getItem("redirectAfterLogin");
    const hasBeenRedirectedToProject = window.localStorage.getItem(
      "redirectedToProject"
    );

    // If a user has no org + is member of only one project => redirect to the project page
    const userShouldBeRedirectedToProject =
      data?.users[0].projects.length === 1 &&
      !data?.users[0].active_org?.org_id;
    if (!hasBeenRedirectedToProject && userShouldBeRedirectedToProject) {
      const projectId = data?.users[0].projects[0].project.id;
      window.localStorage.setItem("redirectedToProject", "true");
      redirectToPath(`/project/${projectId}`);
    }

    if (redirectAfterLogin) {
      redirectToPath(redirectAfterLogin);
    }
  }, [data?.users, redirectToPath]);

  const saveCurrentURL = React.useCallback(() => {
    // We save the current URL only if it is not already set and if the user is not authenticated
    const redirectAfterLogin =
      window.localStorage.getItem("redirectAfterLogin");
    const pathname = window.location.pathname;
    if (!redirectAfterLogin && !user && pathname !== "/") {
      window.localStorage.setItem("redirectAfterLogin", pathname);
    }
  }, [user]);

  const setAfterLoginRedirectPath = React.useCallback(
    (path: string) => {
      window.localStorage.setItem("redirectAfterLogin", path);
    },
    [user]
  );

  return {
    currentUser: data?.users[0],
    redirect,
    saveCurrentURL,
    setAfterLoginRedirectPath,
  };
};

export { useRedirectAfterLogin };
