import type { SetState } from "zustand";
import type { ToolValue } from "./UserStyles.constants";
import { COLORS, Tool } from "./UserStyles.constants";

type UserStyleState = {
  colorGlobal: string;
  setColorGlobal: (color: string) => void;
  thicknessGlobal: number;
  setThicknessGlobal: (thickness: number) => void;
  fontSizeGlobal: number;
  setFontSizeGlobal: (fontSize: number) => void;
  toolGlobal: ToolValue;
  setToolGlobal: (tool: ToolValue) => void;
};

const styleUserPreferencesStore = (set: SetState<UserStyleState>) => ({
  colorGlobal: COLORS[0],
  fontSizeGlobal: 16,
  setColorGlobal: (colorGlobal: string) => set({ colorGlobal }),
  setFontSizeGlobal: (fontSizeGlobal: number) => set({ fontSizeGlobal }),
  setThicknessGlobal: (thicknessGlobal: number) => set({ thicknessGlobal }),
  setToolGlobal: (toolGlobal: ToolValue) => set({ toolGlobal }),
  thicknessGlobal: 2,
  toolGlobal: Tool.FreeHand,
});

export type { UserStyleState };
export { styleUserPreferencesStore };
