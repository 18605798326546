/* This is the Clovis rights screen to explain our users what are the rights of the app 
  it is public because we link to it thru our public help center docs */
import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import React from "react";
import { getCountryFromBrowser } from "~/config/i18next/helpers";
import { Box } from "~/design-system/Box";
import { SpinLoader } from "~/design-system/Spinner/SpinLoader";
import { ScrollableContent } from "~/screens/App/components/Layout";
import { createComponentHook } from "~/types";
import type { LanguageType } from "./components/LanguageDropdown/LanguageDropdown";
import { PermissionTable } from "./components/PermissionTable/PermissionTable";
import { RightsHeader } from "./components/RightsHeader/RightsHeader";

type IntercomSavedType = Record<"user", Record<"locale", string>> | null;

const useRights = createComponentHook(() => {
  const [language, setLanguage] = React.useState<LanguageType>("en");
  const { languageCode } = getCountryFromBrowser();
  const languagesList = Object.values(Language);

  React.useEffect(() => {
    const lang = (
      JSON.parse(
        localStorage.getItem("intercom.intercom-state") ?? "{}"
      ) as IntercomSavedType
    )?.user?.locale;

    /* Because this screen is not inside the app, 
      we need to get the language of the browser, or previsouly connected user*/
    if (lang) {
      setLanguage(
        !languagesList.includes(lang as Language)
          ? "en"
          : (lang as LanguageType)
      );
    } else {
      setLanguage(
        !languagesList.includes(languageCode as Language)
          ? "en"
          : (languageCode as LanguageType)
      );
    }
  }, []);

  return {
    actions: { setLanguage },
    state: { language },
  };
});

function RightsScreen() {
  const { actions, state } = useRights();

  return (
    <ScrollableContent overflowVisible fullHeight>
      <React.Suspense fallback={<SpinLoader />}>
        <Box css={{ height: "100vh" }} padding="medium">
          <RightsHeader
            language={state.language}
            setLanguage={actions.setLanguage}
          />
          <PermissionTable language={state.language} />
        </Box>
      </React.Suspense>
    </ScrollableContent>
  );
}

export { RightsScreen };
