import type { GetState, SetState } from "zustand";

type FolderInfo = {
  folderId: string;
  projectId: string;
};

type UserTaskImportSaveFolderState = {
  saveFolder: FolderInfo[];
  setSaveFolder: (folderId: FolderInfo) => void;
  getFolderIdByProjectId: (projectId: string) => string | undefined;
};

const taskImportSaveFolderUserPreferencesStore = (
  set: SetState<UserTaskImportSaveFolderState>,
  get: GetState<UserTaskImportSaveFolderState>
) => ({
  getFolderIdByProjectId: (projectId: string) => {
    const currentState = get();
    const folder = currentState.saveFolder.find(
      (folder) => folder.projectId === projectId
    );
    return folder ? folder.folderId : undefined;
  },
  saveFolder: [],
  setSaveFolder: (newFolder: FolderInfo) =>
    set((state) => {
      const folderExists = state.saveFolder.find(
        (folder) => folder.projectId === newFolder.projectId
      );

      const updatedFolders = folderExists
        ? state.saveFolder.map((folder) =>
            folder.projectId === newFolder.projectId
              ? { ...folder, folderId: newFolder.folderId }
              : folder
          )
        : [...state.saveFolder, newFolder];

      return { saveFolder: updatedFolders };
    }),
});

export type { UserTaskImportSaveFolderState };
export { taskImportSaveFolderUserPreferencesStore };
