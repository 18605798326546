import * as React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "~/design-system/Badge";
import { Stack } from "~/design-system/Stack";
import { OrgBadgeButton } from "~/screens/App/components/Badges/OrgBadgeButton";
import { createComponentHook } from "~/types";
import type { UserProfileContainer_DataQuery } from "../../UserProfileContainer.graphql";
import { InfoItem } from "./components/InfoItem";
import { InfoItemWithBadges } from "./components/InfoItemWithBadges";

type InfoListProps = {
  user: NonNullable<UserProfileContainer_DataQuery["users_by_pk"]>;
  handleClose: () => void;
};

type InfosItems = {
  description?: string;
  title: string;
  badges?: React.ReactNode[];
}[];

const useInfoList = createComponentHook((props: InfoListProps) => {
  const { t } = useTranslation();

  const infos: InfosItems = [
    {
      description:
        props.user.contact?.phone ??
        t("components.UserProfileDrawer.InfoList.noPhone", "no phone"),
      title: t("components.UserProfileDrawer.InfoList.phone", "Phone"),
    },
    {
      badges: props.user.active_org
        ? [
            <OrgBadgeButton
              key={props.user.active_org.org.id}
              size="large"
              orgId={props.user.active_org.org.id}
              onCloseDrawer={props.handleClose}
              avatar={props.user.active_org?.org.avatar}
            >
              {props.user.active_org?.org.name}
            </OrgBadgeButton>,
          ]
        : (props.user?.metadatas?.placeholder_company_name as
            | string
            | undefined)
        ? [
            <Badge
              size="large"
              key={"props.user.metadatas?.placeholder_company_name"}
            >
              {props.user.metadatas?.placeholder_company_name}
            </Badge>,
          ]
        : undefined,
      description: props.user.active_org
        ? undefined
        : t("components.UserProfileDrawer.InfoList.noOrg", "user has no org"),
      title: t(
        "components.UserProfileDrawer.InfoList.organization",
        "Organization"
      ),
    },
    {
      description:
        props.user.contact?.email ??
        t("components.UserProfileDrawer.InfoList.noEmail", "no email"),
      title: t("components.UserProfileDrawer.InfoList.email", "Email"),
    },
    {
      description: props.user.connections[0]
        ? t(
            "components.UserProfileDrawer.InfoList.lastConnectionDate",
            "{{date, relative}}",
            { date: new Date(props.user.connections[0]?.created_at) }
          )
        : t("components.UserProfileDrawer.InfoList.neverConnected", "never"),
      title: t(
        "components.UserProfileDrawer.InfoList.lastConnection",
        "Last connection"
      ),
    },
  ];

  return {
    state: { infos },
  };
});

function InfoList(props: InfoListProps) {
  const { state } = useInfoList(props);
  return (
    <Stack space="small">
      {state.infos.map((info, index) => {
        if (info.badges) {
          return (
            <InfoItemWithBadges
              key={index}
              title={info.title}
              badges={info.badges}
            />
          );
        } else {
          return (
            <InfoItem
              key={index}
              title={info.title}
              description={info.description ?? ""}
            />
          );
        }
      })}
    </Stack>
  );
}

export { InfoList };
