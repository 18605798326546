import * as React from "react";
import { useIntercomUnreadMessagesCount } from "~/config/intercom/IntercomProvider";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";

const useIntercomNotificationsBadge = createComponentHook(() => {
  const result = useIntercomUnreadMessagesCount();

  const unreadMessagesCount = result?.unreadMessagesCount;

  return {
    state: {
      strongNotificationsCount: unreadMessagesCount,
    },
  };
});

const IntercomNotificationsBadge = () => {
  const { state } = useIntercomNotificationsBadge();

  return (
    <DisplayNotification
      strongNotification={state.strongNotificationsCount}
      weakNotif={false}
    />
  );
};

export { IntercomNotificationsBadge };
