import type * as Types from '../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateOrgMutationVariables = Types.Exact<{
  input: Types.CreateOrgInput;
}>;


export type CreateOrgMutation = { createOrg?: { __typename: 'CreateOrgErrors' } | { __typename: 'CreateOrgSuccess', org_id: string } | null | undefined };


export const CreateOrgDocument = gql`
    mutation createOrg($input: CreateOrgInput!) {
  createOrg(input: $input) {
    __typename
    ... on CreateOrgSuccess {
      org_id
    }
  }
}
    `;

export function useCreateOrgMutation() {
  return Urql.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument);
};