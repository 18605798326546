import { InformationCircleIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "react-polymorphic-box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { Icon } from "~/design-system/Icon";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

/* This buttons are both used in Project / Orgs Invitations and User edition form */
function ActionsButtonsMobile(props: {
  handleCancel?: () => unknown;
  handleWarningVerification?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => unknown | undefined;
  loading?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Stack>
      {props.loading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <Text
            italic
            tone={"info"}
            size={"small"}
            css={{ alignItems: "center", display: "flex", marginRight: "2rem" }}
          >
            <Icon label="info" css={{ marginRight: "0.25rem" }}>
              <InformationCircleIcon />
            </Icon>
            {t(
              "screens.ProjectProfileScreen.ProjectInvitationForm.ActionsButtonsMobile.justWaitFewSeconds",
              "Just wait few seconds, we are validating these informations"
            )}
          </Text>
        </Box>
      )}
      <Columns space="small">
        <Column width="1-2">
          <Button
            type="button"
            variant="solid"
            onClick={() => {
              props.handleCancel?.();
            }}
            css={{ width: "100%" }}
            dataIntercomTarget="cancel"
          >
            {t(
              "screens.ProjectProfileScreen.ProjectInvitationForm.ActionsButtonsMobile.cancelButton",
              "Cancel"
            )}
          </Button>
        </Column>
        <Column width="1-2">
          {props.handleWarningVerification ? (
            <Button
              loading={props.loading}
              variant={props.loading ? "soft" : "solid"}
              dataIntercomTarget="Dialog-Submit-Button-Verification"
              tone={props.loading ? "neutral" : "brandAccent"}
              css={{ width: "100%" }}
              onClick={(event) => {
                event.stopPropagation();
                props.handleWarningVerification?.(event);
              }}
            >
              {t(
                "screens.ProjectProfileScreen.ActionsButtonsMobile.submitButtonVerification",
                "Submit"
              )}
            </Button>
          ) : (
            <Button
              loading={props.loading}
              variant={props.loading ? "soft" : "solid"}
              type="submit"
              tone={props.loading ? "neutral" : "brandAccent"}
              css={{ width: "100%" }}
              dataIntercomTarget="submit"
            >
              {t(
                "screens.ProjectProfileScreen.ProjectInvitationForm.ActionsButtonsMobile.submitButton",
                "Submit"
              )}
            </Button>
          )}
        </Column>
      </Columns>
    </Stack>
  );
}

export { ActionsButtonsMobile };
