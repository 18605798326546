/* React helmet helps to edit the html header of the html page wrapper react */
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BaseFavicon } from "./base-favicon";

const TITLE_TEMPLATE = "Clovis | %s";
const DEFAULT_TITLE = "App";

interface AppHelmetProps {
  children: React.ReactNode;
}

function AppHelmet(props: AppHelmetProps) {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={TITLE_TEMPLATE}
        title={DEFAULT_TITLE}
        // used to refresh the tab even when not in focus
        defer={false}
      />
      <BaseFavicon />
      {props.children}
    </HelmetProvider>
  );
}

export { AppHelmet };
