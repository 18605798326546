import { TrashIcon } from "@heroicons/react/outline";
import type * as Uppy from "@uppy/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { uppy } from "~/config/uppy";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { FileIcon } from "~/design-system/FileIcon";
import { ProgressBar } from "~/design-system/ProgressBar";
import { Text } from "~/design-system/Text";
import { createComponentHook } from "~/types";

type FileUploadPreviewProps = {
  file: Uppy.State["files"][string];
};

const useFileUploadPreview = createComponentHook(
  (props: FileUploadPreviewProps) => {
    const { t } = useTranslation();

    const handleCancelClick: React.MouseEventHandler<
      HTMLButtonElement
    > = () => {
      uppy.removeFile(props.file.id);
    };

    return {
      actions: { handleCancelClick },
      t,
    };
  }
);

const FileUploadPreview = (props: FileUploadPreviewProps) => {
  const { actions, t } = useFileUploadPreview(props);

  return (
    <Columns space="small" alignY="center">
      <Column width="content">
        <FileIcon size="large" format={props.file.extension} />
      </Column>
      <Column>
        <Box width="full" paddingLeft="xsmall" paddingRight="xsmall">
          <Text truncate>{props.file.name}</Text>
          <ProgressBar
            uploadPercentage={props.file.progress?.percentage ?? 0}
            text={
              props.file.progress
                ? t(
                    "components.FilesUploader.FileUploadPreview.progress",
                    "{{bytesUploaded, filesize}} of {{bytesTotal, filesize}}",
                    {
                      bytesTotal: props.file.progress?.bytesTotal,
                      bytesUploaded: props.file.progress?.bytesUploaded,
                      formatParams: {
                        bytesUploaded: {
                          extension: false,
                        },
                      },
                    }
                  )
                : undefined
            }
          />
        </Box>
      </Column>
      <Column width="content">
        <Button
          circular
          variant="transparent"
          tone="critical"
          onClick={actions.handleCancelClick}
          iconLabel="cancel file upload"
          dataIntercomTarget="files-uploader-preview-cancel"
        >
          <TrashIcon />
        </Button>
      </Column>
    </Columns>
  );
};

export { FileUploadPreview };
