import type { OrgRoleId as OrgRole } from "@clovis/server/src/app/domain/org/role";
import { ProjectRoleId as Role } from "@clovis/server/src/app/domain/project/role";
import {
  PermissionSchema,
  ProjectPermission as Permission,
} from "@clovis/server/src/app/domain/utils/permissions";
import * as React from "react";
import type { Rules } from "react-abac";
import { AbacProvider } from "react-abac";
import type { UserContextType } from "~/config/user/UserProvider";
import { useUser } from "~/config/user/UserProvider";
import {
  isAllowedToUpdateMember,
  isAllowedToUpdateOrgProjectSummary,
  isAllowedToUpdateRole,
} from "~/config/users-rights-permissions/project";
import type { Folder } from "./Docs/screens/ProjectDocumentsScreen/components/FilesFolders/FilesFolders";
import type {
  AddNewFileVersionParams,
  DeleteFileParams,
  MoveFileParams,
  RequestUsersApprovalsFilesParams,
  UpdateAssignationsFilesParams,
  UpdateLabelsFilesParams,
  UpdatePermissionsFileParams,
  UpdateSubTaskParams,
  UpdateTaskParams,
} from "./ProjectAutorize.types";

const rules: Rules<Role, Permission, UserContextType["user"]> = {
  ...PermissionSchema.ProjectRules(),
};

const canStandardUserMoveOrDeleteFolder = (
  Folder: Folder,
  user: UserContextType["user"] | undefined
) => {
  const creatorId: string =
    typeof Folder?.folder?.creator?.id === "string"
      ? Folder?.folder?.creator?.id
      : "";

  const folderOrgCreatorId =
    typeof Folder?.folder?.creator?.active_org?.org?.id === "string"
      ? Folder?.folder?.creator?.active_org?.org?.id
      : "";

  return (
    folderOrgCreatorId === user?.active_org?.org?.id || creatorId === user?.id
  );
};

const canStandardUserMoveOrDeleteFile = (
  params: DeleteFileParams | MoveFileParams,
  user: UserContextType["user"] | undefined
) => {
  return (
    params?.lastVersionCreator?.id === user?.id ||
    params?.lastVersionCreator?.active_org?.org?.id ===
      user?.active_org?.org?.id
  );
};

const canLimitedUserMoveOrDeleteFile = (
  params: DeleteFileParams | MoveFileParams,
  user: UserContextType["user"] | undefined
) => {
  return params?.lastVersionCreator?.id === user?.id;
};

// extra owner's permissions
rules[Role.Owner] = {
  ...rules[Role.Owner],
  DeleteProject: (isOrgProject) => {
    if (isOrgProject) {
      return false;
    }
    return true;
  },

  UpdateOrgProjectSummary: (_, user) =>
    /* this permission is also based on org role */
    isAllowedToUpdateOrgProjectSummary(
      Role.Owner,
      user?.active_org?.role.name as OrgRole
    ),
  UpdateProjectArchive: (isOrgProject) => {
    if (isOrgProject) {
      return false;
    }
    return true;
  },
  // this line is because exists a bug when owner user try ti change permissions over organization's members, so we add a redundance about permissions
  // the permission file is packages/server/src/app/domain/utils/permissions/permissions.ts,
  UpdateProjectRole: (memberRoleName: Role) => true,
  UpdateProjectUserInfos: (
    { memberId, memberRoleName }: { memberRoleName: Role; memberId: string },
    user
  ) =>
    isAllowedToUpdateMember(Role.Owner, memberRoleName) ||
    memberId === user?.id,
};

// extra administrator's permissions
rules[Role.Administrator] = {
  ...rules[Role.Administrator],
  DeleteProject: (isOrgProject) => {
    if (isOrgProject) {
      return false;
    }
    return true;
  },
  UpdateOrgProjectSummary: (_, user) =>
    /* this permission is also based on org role */
    isAllowedToUpdateOrgProjectSummary(
      Role.Administrator,
      user?.active_org?.role.name as OrgRole
    ),
  UpdateProjectArchive: (isOrgProject) => {
    if (isOrgProject) {
      return false;
    }
    return true;
  },
  UpdateProjectRole: (memberRoleName: Role) =>
    isAllowedToUpdateRole(Role.Administrator, memberRoleName),
  UpdateProjectUserInfos: (
    { memberId, memberRoleName }: { memberRoleName: Role; memberId: string },
    user
  ) =>
    isAllowedToUpdateMember(Role.Administrator, memberRoleName) ||
    memberId === user?.id,
  UpdateUserTeams: (memberRoleName: Role) =>
    isAllowedToUpdateRole(Role.Administrator, memberRoleName),
};

// extra standard's permissions
rules[Role.Standard] = {
  ...rules[Role.Standard],
  DeleteFile: (DeleteFile: DeleteFileParams, user) => {
    return canStandardUserMoveOrDeleteFile(DeleteFile, user);
  },
  DeleteFolder: (Folder: Folder, user) => {
    return canStandardUserMoveOrDeleteFolder(Folder, user);
  },
  MoveFile: (MoveFileParams: MoveFileParams, user) => {
    return canStandardUserMoveOrDeleteFile(MoveFileParams, user);
  },
  MoveFolder: (Folder: Folder, user) => {
    return canStandardUserMoveOrDeleteFolder(Folder, user);
  },
  UpdateOrgProjectSummary: (_, user) =>
    /* this permission is also based on org role */
    isAllowedToUpdateOrgProjectSummary(
      Role.Standard,
      user?.active_org?.role.name as OrgRole
    ),
  UpdateProjectArchive: (isOrgProject) => {
    if (isOrgProject) {
      return false;
    }
    return true;
  },
  UpdateProjectRole: (memberRoleName: Role) =>
    isAllowedToUpdateRole(Role.Standard, memberRoleName),
  UpdateProjectUserInfos: (
    {
      memberId,
      memberInviterId,
      memberRoleName,
    }: { memberInviterId: string; memberRoleName: Role; memberId: string },
    user
  ) =>
    isAllowedToUpdateMember(
      Role.Standard,
      memberRoleName,
      user?.id,
      memberInviterId
    ) || memberId === user?.id,
  UpdateSubtask: (updateSubTaskParams: UpdateSubTaskParams, user) => {
    if (updateSubTaskParams?.creatorId === user?.id) return true;
    if (
      updateSubTaskParams?.taskAssignations?.users?.some(
        (u) => u.user?.id === user?.id
      )
    )
      return true;
    if (
      updateSubTaskParams?.taskAssignations?.orgs?.some(
        (org) => org.org?.id === user?.active_org?.org.id
      )
    ) {
      return true;
    }
    if (
      updateSubTaskParams?.taskAssignations?.teams?.some((team) =>
        user?.teams.some((t) => t.team_id === team.team?.id)
      )
    ) {
      return true;
    }
    return false;
  },
  UpdateTask: (updateTaskParams: UpdateTaskParams, user) => {
    if (
      updateTaskParams?.taskAssignations?.orgs?.some(
        (org) => org.org?.id === user?.active_org?.org.id
      )
    ) {
      return true;
    }
    return user?.id === updateTaskParams?.creatorId || false;
  },
  UpdateUserTeams: (memberRoleName: Role) =>
    isAllowedToUpdateRole(Role.Standard, memberRoleName),
  // UpdateValidationTask: (
  //   UpdateValidationTaskParams: UpdateValidationTaskParams,
  //   user
  // ) => {
  //   if (UpdateValidationTaskParams?.creatorId === user?.id) return true;
  //   if (
  //     UpdateValidationTaskParams?.taskAssignations?.users?.some(
  //       (u) => u.user?.id === user?.id
  //     )
  //   )
  //     return true;

  //   if (
  //     UpdateValidationTaskParams?.taskAssignations?.orgs?.some(
  //       (org) => org.org?.id === user?.active_org?.org.id
  //     )
  //   ) {
  //     return true;
  //   }
  //   if (
  //     UpdateValidationTaskParams?.taskAssignations?.teams?.some((team) =>
  //       user?.teams.some((t) => t.team_id === team.team?.id)
  //     )
  //   ) {
  //     return true;
  //   }
  //   return false;
  // },
};

// extra limited's permissions for file and task the minimun condition is the limited user is the creator of this
rules[Role.Limited] = {
  ...rules[Role.Limited],
  /**
   * `AddNewFileVersion`: This function checks if the user adding a new file version is the creator of the last version
   * of the file to the limited role.
   */
  AddNewFileVersion: (AddNewFileVersion: AddNewFileVersionParams, user) =>
    AddNewFileVersion?.lastVersionCreator?.id === user?.id,

  DeleteFile: (DeleteFileParams: DeleteFileParams, user) => {
    return canLimitedUserMoveOrDeleteFile(DeleteFileParams, user);
  },

  MoveFile: (MoveFileParams: MoveFileParams, user) => {
    return canLimitedUserMoveOrDeleteFile(MoveFileParams, user);
  },
  /**
   * `RequestUsersApprovalsFiles`: This function checks if the user requesting approval files is the creator of the
   * last version of the file.
   */
  RequestUsersApprovalsFiles: (
    RequestUsersApprovalsFiles: RequestUsersApprovalsFilesParams,
    user
  ) => {
    return RequestUsersApprovalsFiles?.lastVersionCreator?.id === user?.id;
  },
  UpdateAssignationsFiles: (
    UpdateAssignationsFiles: UpdateAssignationsFilesParams,
    user
  ) => UpdateAssignationsFiles?.lastVersionCreator?.id === user?.id,

  UpdateLabelsFiles: (UpdateLabelsFiles: UpdateLabelsFilesParams, user) =>
    UpdateLabelsFiles?.lastVersionCreator?.id === user?.id,

  UpdatePermissionsFile: (
    UpdatePermissionsFile: UpdatePermissionsFileParams,
    user
  ) => UpdatePermissionsFile?.lastVersionCreator?.id === user?.id,
  /**
   * This code defines three functions for updating subtasks, tasks, and validation tasks. These functions are
   * designed to be used by limited users with specific permissions.
   *
   * 1. `UpdateSubtask`: This function takes `updateSubTaskParams` and a `user` as arguments. It checks if the user
   *    is the creator of the subtask or if the user is assigned to the subtask.
   *
   * 2. `UpdateTask`: This function takes `updateTaskParams` and a `user` as arguments. It checks if the user is the
   *    creator of the task.
   *
   * 3. `UpdateValidationTask`: This function is similar to `UpdateSubtask`. It takes `UpdateValidationTaskParams` and
   *    a `user` as arguments. It checks if the user is the creator of the validation task or if the user is assigned
   *    to the task.
   *
   * These functions ensure that only users with appropriate permissions can update subtasks, tasks, and validation tasks.
   */
  UpdateSubtask: (updateSubTaskParams: UpdateSubTaskParams, user) => {
    if (updateSubTaskParams?.creatorId === user?.id) return true;
    if (
      updateSubTaskParams?.taskAssignations?.users?.some(
        (u) => u.user?.id === user?.id
      )
    )
      return true;
    return false;
  },

  UpdateTask: (updateTaskParams: UpdateTaskParams, user) =>
    user?.id === updateTaskParams?.creatorId || false,

  // UpdateValidationTask: (
  //   UpdateValidationTaskParams: UpdateValidationTaskParams,
  //   user
  // ) => {
  //   if (!user) return false;
  //   if (UpdateValidationTaskParams?.creatorId === user?.id) return true;
  //   if (
  //     UpdateValidationTaskParams?.taskAssignations?.users?.some(
  //       (u) => u.user?.id === user?.id
  //     )
  //   )
  //     return true;
  //   if (
  //     user.teams.some((t) =>
  //       UpdateValidationTaskParams?.taskAssignations?.teams?.some(
  //         (team) => t.team_id === team.team?.id
  //       )
  //     )
  //   )
  //     return true;

  //   if (
  //     UpdateValidationTaskParams?.taskAssignations?.orgs?.some(
  //       (orgs) => orgs.org?.id === user.active_org?.org.id
  //     )
  //   )
  //     return true; //  (user.active_org?.org.id))
  //   return false;
  // },
};

function ProjectAbacProvider(props: {
  children: React.ReactNode;
  projectId: string;
  userProjectRole: Role;
}) {
  const { user } = useUser();
  return (
    <AbacProvider
      key={props.projectId}
      roles={[props.userProjectRole]}
      rules={rules}
      user={user}
    >
      {props.children}
    </AbacProvider>
  );
}

export { Permission, ProjectAbacProvider, Role, rules };
