import * as React from "react";
import { Box } from "~/design-system/Box";
import { Drawer, DrawerContent } from "~/design-system/Drawer";
import { createComponentHook } from "~/types";
import { ProjectTeamContent } from "./components/ProjectTeamContent";
import { useProjectTeamDrawer_TeamQuery } from "./ProjectTeamDrawer.graphql";

interface ProjectTeamDrawerProps {
  teamId: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const useProjectTeamDrawer = createComponentHook(
  (props: ProjectTeamDrawerProps) => {
    const [open, setOpen] = React.useState(false);
    const [{ data }] = useProjectTeamDrawer_TeamQuery({
      variables: {
        teamId: props.teamId,
      },
    });

    return {
      actions: {
        setOpen,
      },
      state: {
        open,
        team: data?.teams_by_pk,
      },
    };
  }
);

function ProjectTeamDrawer(props: ProjectTeamDrawerProps) {
  const { actions, state } = useProjectTeamDrawer(props);
  return (
    <Drawer open={state.open} setOpen={actions.setOpen}>
      <DrawerContent position="right" onClose={props.onClose}>
        <Box width="screen" maxWidth="xsmall">
          {state.team && (
            <ProjectTeamContent
              team={state.team}
              handleClose={() => {
                props.onClose?.();
                actions.setOpen(false);
              }}
            />
          )}
        </Box>
      </DrawerContent>
      {props.children}
    </Drawer>
  );
}

export { ProjectTeamDrawer };
