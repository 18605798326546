import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import {
  isMemberInvitedToOrgNotification,
  isOrgNotification,
  isOrgRoleUpdatedNotification,
} from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterOrgManageMembersBadge(
  notification: UserBadgeNotification,
  orgId: string
) {
  return (
    isOrgNotification(notification) &&
    (isMemberInvitedToOrgNotification(notification) ||
      isOrgRoleUpdatedNotification(notification)) &&
    notification.payload.org.id === orgId
  );
}

const useOrgManageMembersBadge = createComponentHook(
  (props: OrgManageMembersBadgeProps) => {
    const notifications = useUserNotificationsContext((state) =>
      state.notifications.filter((n) =>
        filterOrgManageMembersBadge(n, props.orgId)
      )
    );
    const { strongNotificationsCount, weakNotif } =
      getDisplayNotificationProps(notifications);

    return {
      state: {
        strongNotificationsCount,
        weakNotif,
      },
    };
  }
);

type OrgManageMembersBadgeProps = { orgId: string };

const OrgManageMembersBadge = (props: OrgManageMembersBadgeProps) => {
  const { state } = useOrgManageMembersBadge(props);

  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { filterOrgManageMembersBadge, OrgManageMembersBadge };
