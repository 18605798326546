import { ChevronUpIcon } from "@heroicons/react/outline";
import * as React from "react";
import { Button } from "~/design-system/Button";
import { Icon } from "~/design-system/Icon";

export function TopArrow(props: { onClick: () => void }) {
  return (
    <Button
      onClick={props.onClick}
      css={{
        "&:hover": {
          background:
            "linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, .8), rgba(255, 255, 255, .8), transparent)",
        },
        background:
          "linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, .8), rgba(255, 255, 255, .8), transparent)",
        border: "none",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: "$space$4",
        borderTopRightRadius: "$space$4",
        boxShadow: "none",
        height: "$buttonHeight",
        left: 0,
        position: "absolute",
        top: "calc(0 + $space$xxsmall)",
        width: "100%",
      }}
      dataIntercomTarget="button-group-top-arrow"
    >
      <Icon label="up" color="neutral">
        <ChevronUpIcon />
      </Icon>
    </Button>
  );
}
