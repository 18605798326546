import * as Checkbox from "@radix-ui/react-checkbox";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const checkboxStyle = {
  ...tw`text-sky-500`,

  "&": {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "0.25rem",
    boxShadow: "inset 0 0 0 2px gainsboro",
    display: "flex",
    justifyContent: "center",
    padding: 0,
  },

  "&:focus": {
    ...tw`ring-2 ring-offset-2 ring-sky-500`,
    outline: "none",
  },

  compoundVariants: [
    {
      css: { boxShadow: "inset 0 0 0 1px rgb(34, 197, 94)" },
      size: "small",
      tone: "positive",
    },
    {
      css: { boxShadow: "inset 0 0 0 1px rgb(239, 68, 68)" },
      size: "small",
      tone: "critical",
    },
    {
      css: { boxShadow: "inset 0 0 0 1px rgb(34, 197, 94)" },
      size: "xsmall",
      tone: "positive",
    },
    {
      css: { boxShadow: "inset 0 0 0 1px rgb(239, 68, 68)" },
      size: "xsmall",
      tone: "critical",
    },
  ],

  defaultVariants: {
    color: "blue",
    size: "medium",
  },

  variants: {
    color: {
      blue: {},
      white: {
        "&:focus": {
          ...tw`ring-white ring-offset-0`,
        },
        boxShadow: "inset 0 0 0 1px white",
      },
    },

    rounded: {
      false: {},
      true: {
        "&": {
          backgroundColor: "white",
          borderRadius: "$full",
          display: "flex",
          justifyContent: "center",
          outline: "none",
          overflow: "hidden",
        },

        "&:disabled > *": {
          ...tw`cursor-not-allowed opacity-50`,
        },
      },
    },
    size: {
      large: { ...tw`w-7 h-7` },
      medium: { ...tw`w-6 h-6` },
      small: { ...tw`w-5 h-5`, boxShadow: "inset 0 0 0 1px gainsboro" },
      xsmall: { ...tw`w-4 h-4`, boxShadow: "inset 0 0 0 1px gainsboro" },
    },
    tone: {
      critical: {
        "&:focus": {
          ...tw`ring-critical-500`,
        },
        boxShadow: "inset 0 0 0 2px rgb(239, 68, 68)",
      },
      positive: {
        "&:focus": {
          ...tw`ring-positive-500`,
        },
        boxShadow: "inset 0 0 0 2px rgb(34, 197, 94)",
      },
    },
  },
};

const StyledCheckbox = styled(Checkbox.Root, checkboxStyle);

export { checkboxStyle, StyledCheckbox };
