import React from "react";
import Favicon from "react-favicon";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import {
  isStrongNotification,
  isWeakNotification,
} from "~/config/user/user-notifications/utils/filters";
import { createComponentHook } from "~/types";

const CLOVIS_FAVICON_URL = "/favicon.png";
const CLOVIS_FAVICON_WEAK_NOTIF_URL = "/favicon-weak-notif.png";
const ALERT_FILL_COLOR = "red";

const useUserNotificationsFavicon = createComponentHook(() => {
  const notifications = useUserNotificationsContext(
    (state) => state.notifications
  );
  const strongNotificationsCount =
    notifications.filter(isStrongNotification).length;
  const weakNotif =
    strongNotificationsCount === 0
      ? notifications.some(isWeakNotification)
      : false;

  return {
    state: {
      strongNotificationsCount,
      weakNotifications: weakNotif,
    },
  };
});

function UserNotificationsFavicon() {
  const { state } = useUserNotificationsFavicon();
  if (state.strongNotificationsCount > 0) {
    return (
      <Favicon
        alertFillColor={ALERT_FILL_COLOR}
        alertCount={state.strongNotificationsCount}
        url={CLOVIS_FAVICON_URL}
        iconSize={32}
      />
    );
  }
  if (state.weakNotifications) {
    return <Favicon url={CLOVIS_FAVICON_WEAK_NOTIF_URL} iconSize={32} />;
  }
  return <Favicon url={CLOVIS_FAVICON_URL} />;
}

export { CLOVIS_FAVICON_URL, UserNotificationsFavicon };
