import type * as Stitches from "@stitches/react";
import * as React from "react";
import { styled } from "~/config/stitches";
import { Box } from "../Box/Box";
import { Icon } from "../Icon/Icon";

const CircledIconWrapper = styled(Box, {
  defaultVariants: {
    size: "standard",
    tone: "positive",
  },

  variants: {
    size: {
      standard: { height: "$12", width: "$12" },
    },
    tone: {
      brandAccent: { backgroundColor: "$green100" },
      caution: { backgroundColor: "$orange100" },
      critical: { backgroundColor: "$red100" },
    },
  },
});

type CircledIconProps = {
  size?: Stitches.VariantProps<typeof CircledIconWrapper>["size"];
  icon: React.ReactNode;
  iconLabel: string;
  tone?: "brandAccent" | "caution" | "critical";
};

const CircledIcon = (props: CircledIconProps) => {
  return (
    <Box display="flex" justifyContent="evenly">
      <CircledIconWrapper
        display="flex"
        justifyContent="center"
        alignItems="center"
        size={props.size}
        tone={props.tone}
        css={{
          borderRadius: "50%",
        }}
      >
        <Icon label={props.iconLabel} color={props.tone} size="medium">
          {props.icon}
        </Icon>
      </CircledIconWrapper>
    </Box>
  );
};

export type { CircledIconProps };
export { CircledIcon };
