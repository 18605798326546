import type { validationSchema } from "@clovis/server/src/app/domain/task/create-task/create-task.validation";
import * as React from "react";
import type z from "zod";
import type { FileBadgeProps } from "./components/FileBadge/FileBadge";
import { FileBadge } from "./components/FileBadge/FileBadge";

type TaskFilesBadgesProps = {
  fileVersions?: z.infer<typeof validationSchema>["file_versions"];
  onRemove: FileBadgeProps["onRemove"];
};
function TaskFilesBadges(props: TaskFilesBadgesProps) {
  if (!props.fileVersions) {
    return null;
  }

  return (
    <>
      {props.fileVersions.map(({ file_version_id }) => (
        <FileBadge
          key={file_version_id}
          fileVersionId={file_version_id}
          onRemove={props.onRemove}
        />
      ))}
    </>
  );
}

export { TaskFilesBadges };
