import { ImpactStyle } from "@capacitor/haptics";
import * as React from "react";
import tw from "twin.macro";
import { hapticsImpact } from "~/config/capacitor/haptics/hook";
import { styled } from "~/config/stitches";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Text } from "~/design-system/Text";

const MobileButtonWrapper = styled("button", {
  ...tw`focus:(outline-none)
  shadow-md`,
  borderRadius: "2rem",
  height: "3.5rem",
  position: "relative",
  width: "3.5rem",
});

type MobileButtonProps = {
  imageURL?: string | null;
  imageAlt?: string | null;
  activeProjectId?: string;
  textToDisplay?: string;
  notif?: React.ReactNode;
};

const ButtonTitle = styled(Text, {
  backgroundColor: "$slate900",
  borderRadius: "3px",
  color: "$white",
  left: "50%",
  opacity: "0.97",
  padding: "2px",
  position: "absolute",
  textAlign: "center",
  textTransform: "uppercase",
  top: "-18px",
  transform: "translateX(-50%)",
});

const MobileButton = React.forwardRef<HTMLButtonElement, MobileButtonProps>(
  function MobileButton(props, ref) {
    const { imageAlt, imageURL, textToDisplay, ...radixButtonProps } = props;

    const handleHaptic = async () => {
      await hapticsImpact(ImpactStyle.Medium);
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        onClick={handleHaptic}
      >
        <ButtonTitle size="xxsmall" truncate="noEllipsis">
          {textToDisplay}
        </ButtonTitle>
        <MobileButtonWrapper ref={ref} {...radixButtonProps}>
          <Avatar
            size="xlarge"
            alt={imageAlt ?? textToDisplay}
            src={imageURL ?? undefined}
            withBorder
            css={{
              "& > div": {
                border: "$slate900 $space$xxsmall solid",
                borderRadius: "$full",
              },
              marginRight: "0.2rem",
            }}
          />
          {props.notif}
        </MobileButtonWrapper>
      </Box>
    );
  }
);
export { MobileButton };
