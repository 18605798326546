import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import nl from "./nl.json";
import pt from "./pt.json";

const resources = {
  [Language.EN]: { translation: en },
  [Language.FR]: { translation: fr },
  [Language.ES]: { translation: es },
  [Language.DE]: { translation: de },
  [Language.NL]: { translation: nl },
  [Language.PT]: { translation: pt },
} as const;

export { resources };
