/* We cenytralized here the app breakpoints of the whole app, for both tailwind, sitches, and storybook */

// Be careful updating the breakpoints here, be sure to symetrise them with packages/client/tailwind.config.js
const BREAKPOINTS_NUM = {
  desktop: 1250,
  mobile: 0,
  tablet: 650,
} as const;

const BREAKPOINTS_NAMES = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
  TABLET: "tablet",
} as const;

const BreakpointsPx = {
  DESKTOP: `${BREAKPOINTS_NUM.desktop}px`,
  MOBILE: `${BREAKPOINTS_NUM.mobile}px`,
  TABLET: `${BREAKPOINTS_NUM.tablet}px`,
} as const;

type BreakpointNames = "desktop" | "mobile" | "tablet";

const breakpointsNamesArray: BreakpointNames[] = [
  BREAKPOINTS_NAMES.DESKTOP,
  BREAKPOINTS_NAMES.MOBILE,
  BREAKPOINTS_NAMES.TABLET,
];

const enum Tones {
  caution = "caution",
  critical = "critical",
  info = "info",
  neutral = "neutral",
  positive = "positive",
  promote = "promote",
}

export type { BreakpointNames };
export {
  BREAKPOINTS_NAMES,
  BREAKPOINTS_NUM,
  breakpointsNamesArray,
  BreakpointsPx,
  Tones,
};
