import { Capacitor } from "@capacitor/core";
import * as React from "react";
import { useUpdateUserAppMetadataInfosMutation } from "./UpdateAppMetadataInfos.graphql";

type UpdateAppMetadataInfosProps = {
  children: React.ReactNode;
};

const useUpdateAppMetadataInfos = () => {
  const isNativePlatform = Capacitor.isNativePlatform();
  const [, updateUserAppMetadata] = useUpdateUserAppMetadataInfosMutation();

  // TODO: implement the "appDesktop" when desktop app is ready
  React.useEffect(() => {
    void updateUserAppMetadata({
      appDesktop: false,
      appMobile: isNativePlatform,
    });
  }, [updateUserAppMetadata, isNativePlatform]);
};

function UpdateAppMetadataInfos(props: UpdateAppMetadataInfosProps) {
  useUpdateAppMetadataInfos();

  return <>{props.children}</>;
}

export { UpdateAppMetadataInfos };
