import i18n from "i18next";
import { Language, resources } from "./languages/allowed-languages";

// Use createInstance to avoid conflict between i18n server & client instances
const i18nInstance = i18n.createInstance();

void i18nInstance.init({
  fallbackLng: Language.EN,
  interpolation: {
    escapeValue: false,
  },
  load: "currentOnly",
  resources,
  supportedLngs: Object.values(Language),
});

export { i18nInstance as i18n, Language };
