import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Text } from "~/design-system/Text";

const StyledItem = styled("li", {
  ...tw`w-full`,
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    backgroundColor: "$gray300",
  },
  alignItems: "center",
  borderRadius: "$space$2",
  color: "$gray500",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "$14",
  padding: "$xxsmall",
  textAlign: "center",
  userSelect: "none",
});

type StyledItemProps = React.ComponentProps<typeof StyledItem>;

type IconAndTextStyledItemProps = StyledItemProps & {
  name: string;
  icon: React.ReactNode;
};

const IconAndTextStyledItem = React.forwardRef<
  HTMLLIElement,
  IconAndTextStyledItemProps
>(function (props: IconAndTextStyledItemProps, ref) {
  const { icon, name, ...styledItemProps } = props;
  return (
    <StyledItem {...styledItemProps} ref={ref}>
      {icon}
      <Text
        css={{
          fontSize: "x-small",
          lineHeight: "1em",
          paddingTop: "$xsmall",
          textTransform: "uppercase",
        }}
      >
        {name}
      </Text>
    </StyledItem>
  );
});

export { IconAndTextStyledItem };
export type { IconAndTextStyledItemProps };
