import { Slot } from "nano-slots";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { DesktopNavigationButtonGroup } from "./components/DesktopNavigationButtons/DesktopNavigationButtonGroup";

type SideBarProps = {
  css?: CSS;
};

function SideBar(props: SideBarProps) {
  return (
    <Box
      css={props.css}
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      alignItems="center"
    >
      <Box width="full">
        <Slot name="Layout.Navigation" />
      </Box>
      <DesktopNavigationButtonGroup />
      {/* HIDE FOR NOW
      <SupportButtonGroup />
      */}
    </Box>
  );
}

export { SideBar };
