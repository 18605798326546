import { Capacitor } from "@capacitor/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { buildNativeSchemeUrl } from "~/config/capacitor/router/router";
import { env } from "~/config/env";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Card } from "~/design-system/Card";
import { Dialog, DialogClose, DialogContent } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "~/screens/App/components/CloseButton";
import { createComponentHook } from "~/types";

function isOnAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function isOnIos() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

const userRedirectToMobileAppDialog = createComponentHook(() => {
  const { t } = useTranslation();
  const platform = isOnAndroid() ? "android" : isOnIos() ? "ios" : "unknown";
  const downloadUrl =
    platform === "android"
      ? env.VITE_ANDROID_APP_STORE_URL
      : env.VITE_IOS_APP_STORE_URL;
  const isOnMobileApp = Capacitor.isNativePlatform();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // If the user is either on android or ios, but in a browser navigation
    // rather than the mobile application
    if (platform !== "unknown" && isOnMobileApp === false) {
      setOpen(true);
    }
  }, [platform, isOnMobileApp]);

  const handleOpenNativeAppClicked = () => {
    // Redirect the browser to the installed native app via APP_ID:// url
    window.location.replace(buildNativeSchemeUrl(window.location.pathname));
  };

  return {
    actions: {
      handleOpenNativeAppClicked,
      platform,
      setOpen,
    },
    state: {
      downloadUrl,
      open,
    },
    t,
  };
});

function DialogCloseButton() {
  return (
    <DialogClose asChild>
      <CloseButton dataIntercomTarget="redirect-mobile-app-close" />
    </DialogClose>
  );
}

function RedirectToMobileAppDialogTopBar() {
  const { t } = useTranslation();

  return (
    <Box
      width="full"
      display="flex"
      justifyContent="spaceBetween"
      alignItems="center"
    >
      <Heading level="4">
        {t(
          "screens.RedirectToMobileAppDialogTopBar.title",
          "Use the native app"
        )}
      </Heading>
      <DialogCloseButton />
    </Box>
  );
}

function RedirectToMobileAppDialog() {
  const { actions, state, t } = userRedirectToMobileAppDialog();
  return (
    <Dialog open={state.open} setOpen={actions.setOpen}>
      <DialogContent
        css={{ maxWidth: "$md" }}
        size="regular"
        stopClickPropagation
      >
        <Card padding="gutter">
          <Stack space="gutter">
            <RedirectToMobileAppDialogTopBar />
            <Text size="standard" weight="medium" tone="secondary">
              {t(
                "screens.App.components.RedirectToMobileAppDialog.message",
                "Hi there, to experience the best possible experience with Clovis, we recommand you to use the native app on your mobile platforms."
              )}
            </Text>
            <Button
              as={"a"}
              href={state.downloadUrl}
              target="_blank"
              tone="brandAccent"
              width="full"
              dataIntercomTarget="redirect-mobile-app-download"
            >
              {t(
                "screens.App.components.RedirectToMobileAppDialog.download",
                "DOWNLOAD APP"
              )}
            </Button>
            <Button
              variant="ghost"
              tone="neutral"
              width="full"
              onClick={actions.handleOpenNativeAppClicked}
              dataIntercomTarget="redirect-mobile-app-open"
            >
              {t(
                "screens.App.components.RedirectToMobileAppDialog.open",
                "OPEN THE APP"
              )}
            </Button>
          </Stack>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export { RedirectToMobileAppDialog };
