/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type { i18n } from "i18next";
import { z } from "zod";

export const createCustomErrorMap = (i18n: i18n) =>
  ((error, _ctx) => {
    const t = i18n.t.bind(i18n);
    let message;
    switch (error.code) {
      case z.ZodIssueCode.custom:
        message = t("zod.custom", "Invalid input");
        break;
      case z.ZodIssueCode.invalid_type:
        if (error.received === "undefined") {
          message = t("zod.invalid_type.undefined", "Required");
        } else {
          message = t(
            "zod.invalid_type.defined",
            "Expected {{expected}}, received {{received}}",
            { expected: error.expected, received: error.received }
          );
        }
        break;
      case z.ZodIssueCode.unrecognized_keys:
        message = t(
          "zod.unrecognized_keys",
          "Unrecognized key(s) in object: {{keys}}",
          { keys: error.keys.map((k) => `'${k}'`).join(", ") }
        );
        break;
      case z.ZodIssueCode.invalid_union:
        message = t("zod.invalid_union", "Invalid input");
        break;
      case z.ZodIssueCode.invalid_enum_value:
        message = t(
          "zod.invalid_enum_value",
          "Invalid enum value. Expected {{expected}}, received {{received}}",
          {
            expected: error.options
              .map((val) => (typeof val === "string" ? `'${val}'` : val))
              .join(" | "),
            received:
              typeof _ctx.data === "string"
                ? `'${_ctx.data}'`
                : (_ctx.data as string),
          }
        );
        break;
      case z.ZodIssueCode.invalid_arguments:
        message = t("zod.invalid_arguments", "Invalid function arguments");
        break;
      case z.ZodIssueCode.invalid_return_type:
        message = t("zod.invalid_return_type", "Invalid function return type");
        break;
      case z.ZodIssueCode.invalid_date:
        message = t("zod.invalid_date", "Invalid date");
        break;
      case z.ZodIssueCode.invalid_string:
        if (error.validation !== "regex") {
          message = t(
            "zod.invalid_string.validation",
            "Invalid {{validation}}",
            {
              validation: error.validation,
            }
          );
        } else {
          message = t("zod.invalid_string.regex", "Invalid");
        }
        break;
      case z.ZodIssueCode.too_small:
        if (error.type === "array")
          message = t(
            "zod.too_small.array.default",
            "Should have {{quantifier}} {{minimum}} items",
            {
              minimum: error.minimum,
              quantifier: error.inclusive
                ? t("zod.too_small.array.inclusive", "at least")
                : t("zod.too_small.array.not_inclusive", "more than"),
            }
          );
        else if (error.type === "string") {
          if (error.minimum === 1) {
            message = t("zod.too_small.string.required", "Required");
          } else {
            message = t(
              "zod.too_small.string.default",
              "Should be {{quantifier}} {{minimum}} characters",
              {
                minimum: error.minimum,
                quantifier: error.inclusive
                  ? t("zod.too_small.string.inclusive", "at least")
                  : t("zod.too_small.string.not_inclusive", "over"),
              }
            );
          }
        } else if (error.type === "number")
          message = t(
            "zod.too_small.number.default",
            "Value should be greater than {{quantifier}}{{minimum}}",
            {
              minimum: error.minimum,
              quantifier: error.inclusive
                ? t("zod.too_small.number.inclusive", "or equal to ")
                : "",
            }
          );
        else message = t("zod.too_small.another", "Invalid input");
        break;
      case z.ZodIssueCode.too_big:
        if (error.type === "array")
          message = t(
            "zod.too_big.array.default",
            "Should have {{quantifier}} {{maximum}} items",
            {
              maximum: error.maximum,
              quantifier: error.inclusive
                ? t("zod.too_big.array.inclusive", "at most")
                : t("zod.too_big.array.not_inclusive", "less than"),
            }
          );
        else if (error.type === "string")
          message = t(
            "zod.too_big.string.default",
            "Should be {{quantifier}} {{maximum}} characters long",
            {
              maximum: error.maximum,
              quantifier: error.inclusive
                ? t("zod.too_big.string.inclusive", "at most")
                : t("zod.too_big.string.not_inclusive", "under"),
            }
          );
        else if (error.type === "number")
          message = t(
            "zod.too_big.number.default",
            "Value should be less than {{quantifier}}{{maximum}}",
            {
              maximum: error.maximum,
              quantifier: error.inclusive
                ? t("zod.too_big.number.inclusive", "or equal to ")
                : "",
            }
          );
        else message = t("zod.too_big.another", "Invalid input");
        break;
      case z.ZodIssueCode.invalid_intersection_types:
        message = t(
          "zod.invalid_intersection_types",
          "Intersections only support objects"
        );
        break;
      default:
        message = t("zod.default", "Invalid input.");
    }

    return { message };
  }) as z.ZodErrorMap;
