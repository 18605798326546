import { ChevronDownIcon } from "@heroicons/react/outline";
import * as React from "react";
import { Button } from "~/design-system/Button";
import { Icon } from "~/design-system/Icon";

export function BottomArrow(props: { onClick: () => void }) {
  return (
    <Button
      onClick={props.onClick}
      css={{
        "&:hover": {
          background:
            "linear-gradient(transparent, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8), rgb(255, 255, 255))",
        },
        background:
          "linear-gradient(transparent, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8), rgb(255, 255, 255))",
        border: "none",
        borderBottomLeftRadius: "$space$4",
        borderBottomRightRadius: "$space$4",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        bottom: "0",
        boxShadow: "none",
        height: "$buttonHeight",
        left: 0,
        position: "absolute",
        width: "100%",
      }}
      dataIntercomTarget="button-group-bottom-arrow"
    >
      <Icon label="down" color="neutral">
        <ChevronDownIcon />
      </Icon>
    </Button>
  );
}
