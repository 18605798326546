import { makeOperation } from "@urql/core";
import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import type { JwtPayload } from "jwt-decode";
import jwtDecode from "jwt-decode";
// TODO: use normalized cache once hasura includes remote schema merging (See: https://github.com/hasura/graphql-engine/issues/5801)
// import { cacheExchange } from "@urql/exchange-graphcache";
// import type { IntrospectionQuery } from "graphql";
import * as React from "react";
// import { SubscriptionClient } from "subscriptions-transport-ws";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider,
  // subscriptionExchange,
} from "urql";
import { useAuth0 } from "~/config/auth0/use-auth0";
import { env } from "~/config/env";
import { FullPageSpinner } from "~/screens/App/components/FullPageSpinner/FullPageSpinner";

interface UrqlConfigProps {
  children: React.ReactNode;
}

function useClient() {
  const { getAccessTokenSilently } = useAuth0();

  // TODO: Fix hasura subscription high cpu usage before re-enabling websocket
  // See:
  // https://github.com/hasura/graphql-engine/issues/6542
  // https://github.com/hasura/graphql-engine/issues/6042
  //
  // const subscriptionClient = new SubscriptionClient(
  //   env.VITE_HASURA_SUBSCRIPTION_URL,
  //   {
  //     async connectionParams() {
  //       const token = await getAccessTokenSilently();
  //       return {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //     },
  //     // See: https://github.com/hasura/graphql-engine/issues/4509#issuecomment-882503283
  //     inactivityTimeout: 30000,
  //     lazy: true,
  //     minTimeout: 2000,
  //     reconnect: true,
  //   }
  // );

  const client = createClient({
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange,
      // cacheExchange({
      //
      //   schema: (schema as unknown) as IntrospectionQuery,
      // }),
      authExchange<{ token: string }>({
        addAuthToOperation: ({ authState, operation }) => {
          // the token isn't in the auth state, return the operation without changes
          if (!authState?.token) {
            return operation;
          }

          // fetchOptions can be a function (See Client API) but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === "function"
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions ?? {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
              },
            },
          });
        },
        didAuthError: ({ error }) => {
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === "FORBIDDEN"
          );
        },
        getAuth: async () => {
          const token = await getAccessTokenSilently();

          return { token };
        },
        willAuthError: ({ authState }) => {
          const authToken = authState?.token;
          if (!authToken) {
            return true;
          }
          // Check token formating and expirancy
          try {
            const decodedToken = jwtDecode<JwtPayload>(authToken);
            if (decodedToken) {
              const expiry = decodedToken.exp ?? 0;
              // Check if the token is still valid for at least 10 seconds
              const now = (Date.now() + 10000) / 1000;
              const isExpired = Boolean(expiry < now);
              // Token is already expired, so we need to refresh it before performing next request
              if (isExpired) {
                return true;
              }
            }
          } catch (e) {
            // JWT decode failed, so the token is invalid
            return true;
          }
          // No error, so we can continue with the request and shouldn't have auth error
          return false;
        },
      }),
      fetchExchange,
      // subscriptionExchange({
      //   forwardSubscription(operation) {
      //     return subscriptionClient.request(operation);
      //   },
      // }),
    ],
    requestPolicy: "cache-and-network",
    url: env.VITE_HASURA_URL,
  });

  const clientRef = React.useRef(client);

  return clientRef.current;
}

export function UrqlConfig(props: UrqlConfigProps) {
  const client = useClient();

  if (client === null) {
    return <FullPageSpinner />;
  }

  return <Provider value={client}>{props.children}</Provider>;
}
