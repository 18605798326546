import type * as Types from '../../../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateOrgProjectSummaryForm_Project_CategoriesFragment = { id: string, color: string, name: string };

export type GetCreateOrgProjectSummaryCategoriesOptionsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetCreateOrgProjectSummaryCategoriesOptionsQuery = { project_categories: Array<{ id: string, color: string, name: string }> };

export type CreateOrgProjectSummaryMutationVariables = Types.Exact<{
  input: Types.CreateOrgProjectSummaryInput;
}>;


export type CreateOrgProjectSummaryMutation = { createOrgProjectSummary?: { __typename: 'CreateOrgProjectSummaryErrors' } | { __typename: 'CreateOrgProjectSummarySuccess', org_project_summary_id: string } | null | undefined };

export const CreateOrgProjectSummaryForm_Project_CategoriesFragmentDoc = gql`
    fragment CreateOrgProjectSummaryForm_project_categories on project_categories {
  id
  color
  name
}
    `;
export const GetCreateOrgProjectSummaryCategoriesOptionsDocument = gql`
    query getCreateOrgProjectSummaryCategoriesOptions($orgId: uuid!) {
  project_categories(where: {org_id: {_eq: $orgId}}) {
    ...CreateOrgProjectSummaryForm_project_categories
  }
}
    ${CreateOrgProjectSummaryForm_Project_CategoriesFragmentDoc}`;

export function useGetCreateOrgProjectSummaryCategoriesOptionsQuery(options: Omit<Urql.UseQueryArgs<GetCreateOrgProjectSummaryCategoriesOptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetCreateOrgProjectSummaryCategoriesOptionsQuery>({ query: GetCreateOrgProjectSummaryCategoriesOptionsDocument, ...options });
};
export const CreateOrgProjectSummaryDocument = gql`
    mutation createOrgProjectSummary($input: CreateOrgProjectSummaryInput!) {
  createOrgProjectSummary(input: $input) {
    __typename
    ... on CreateOrgProjectSummarySuccess {
      org_project_summary_id
    }
  }
}
    `;

export function useCreateOrgProjectSummaryMutation() {
  return Urql.useMutation<CreateOrgProjectSummaryMutation, CreateOrgProjectSummaryMutationVariables>(CreateOrgProjectSummaryDocument);
};