import * as React from "react";

export function ClovisIcon() {
  return (
    <svg
      fill="currentColor"
      stroke="none"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <path
        d="M92.577,45.6c-1.506-3.82-3.754-7.235-6.614-10.079c0.13-3.946-0.524-7.944-2.024-11.75
      C77.989,8.667,60.861,1.22,45.757,7.169c-3.904,1.539-7.338,3.841-10.162,6.695c-3.912-0.115-7.877,0.546-11.662,2.037
      C8.828,21.852,1.38,38.98,7.33,54.083c1.539,3.906,3.84,7.342,6.693,10.166c-0.136,3.994,0.548,7.986,2.039,11.771
      c4.56,11.574,15.68,18.651,27.428,18.651c3.581,0,7.223-0.66,10.753-2.05c3.934-1.548,7.396-3.887,10.241-6.793
      c0.236,0.005,0.475,0.011,0.712,0.011c3.661,0,7.306-0.689,10.778-2.057C91.079,77.832,98.527,60.704,92.577,45.6 M73.086,76.445
      c-0.917,0.361-1.852,0.655-2.795,0.886c1.54-3.409,2.446-7.138,2.592-11.02c0.08-2.175-1.618-4.005-3.794-4.087
      c-2.174-0.077-4.005,1.618-4.089,3.794c-0.156,4.23-1.545,8.216-3.869,11.546c-0.266-0.051-0.53-0.107-0.795-0.17
      c-4.163-0.965-7.91-3.11-10.83-6.203c-1.497-1.583-3.991-1.654-5.574-0.157c-1.583,1.493-1.654,3.99-0.158,5.572
      c2.992,3.169,6.614,5.607,10.635,7.185c-0.968,0.572-1.987,1.074-3.054,1.493c-11.059,4.357-23.6-1.095-27.957-12.152
      c-0.396-1.009-0.712-2.03-0.952-3.061c3.626,1.658,7.624,2.598,11.792,2.667c0.023,0,0.044,0,0.067,0
      c2.147,0,3.906-1.723,3.94-3.877c0.037-2.179-1.699-3.973-3.875-4.007c-4.433-0.075-8.602-1.487-12.056-3.916
      c0.855-4.217,2.976-8.175,6.244-11.298c1.574-1.505,1.63-4.002,0.125-5.574c-1.504-1.574-4-1.631-5.573-0.125
      c-3.047,2.913-5.406,6.416-6.963,10.291c-0.565-0.963-1.062-1.976-1.48-3.038c-4.355-11.058,1.098-23.599,12.156-27.955
      c0.964-0.38,1.944-0.684,2.933-0.918c-1.635,3.585-2.565,7.531-2.65,11.643c-0.045,2.178,1.684,3.98,3.861,4.024
      c2.177,0.042,3.978-1.684,4.023-3.86c0.09-4.402,1.502-8.543,3.92-11.978c4.106,0.812,7.973,2.815,11.062,5.901
      c1.541,1.539,4.038,1.538,5.577-0.002c1.538-1.541,1.538-4.038-0.001-5.576c-2.88-2.876-6.253-5.062-9.889-6.515
      c0.948-0.553,1.944-1.04,2.988-1.451c11.06-4.357,23.6,1.097,27.956,12.155c0.39,0.987,0.701,1.992,0.937,3.007
      c-3.785-1.753-7.898-2.689-12.109-2.725c-0.012-0.001-0.021-0.001-0.036-0.001c-2.161,0-3.923,1.743-3.941,3.909
      c-0.017,2.178,1.732,3.958,3.909,3.977c4.539,0.04,8.801,1.474,12.313,3.973c-0.803,4.01-2.739,7.792-5.711,10.833
      c-1.521,1.559-1.489,4.055,0.067,5.577c0.767,0.747,1.761,1.12,2.754,1.12c1.024,0,2.049-0.396,2.82-1.188
      c2.734-2.799,4.825-6.058,6.234-9.559c0.53,0.921,1.002,1.892,1.399,2.906C89.596,59.549,84.144,72.091,73.086,76.445"
      />
    </svg>
  );
}
