import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuth0 } from "~/config/auth0/use-auth0";
import { i18n } from "~/config/i18next";
import {
  getAvailableLanguage,
  getCountryFromBrowser,
} from "~/config/i18next/helpers";
import { createComponentHook } from "~/types";
import { NotFound } from "./components/NotFound/NotFound";
import { Auth0ContinueLoginScreen } from "./screens/Auth0ContinueLoginScreen/Auth0ContinueLoginScreen";

const useAnonymousApp = createComponentHook(() => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const { languageCode } = getCountryFromBrowser();
  const browserLanguage = getAvailableLanguage(languageCode);

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  React.useEffect(() => {
    if (i18n.language !== browserLanguage) {
      void i18n.changeLanguage(browserLanguage);
    }
  }, [browserLanguage]);

  return {
    state: {
      isAuthenticated,
      isLoading,
    },
  };
});

function AnonymousApp() {
  const { state } = useAnonymousApp();
  if (state.isLoading === false && state.isAuthenticated === false) {
    return (
      <Routes>
        <Route
          path="register/userinfos"
          element={<Auth0ContinueLoginScreen />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
  return null;
}

export { AnonymousApp };
