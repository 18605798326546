// TODO: find a way to automatically keep it in sync and generate this file

// Those enums allow us to safely use our seeded roles data in our code
// it must be kept in sync with "/hasura/seeds/default/xxx_project_roles.sql"
enum ProjectRoleId {
  Owner = "owner",
  Administrator = "administrator",
  Standard = "standard",
  Limited = "limited",
  Readonly = "readonly",
  Disabled = "disabled",
}

const ProjectRoleDefault = ProjectRoleId.Standard;

// This constant is here to avoid trip to database to know which role
// have the most privileges. If you change the order in this list, you should
// also change the value of "weight" in "project_roles" tables accordingly
const ProjectRolesHierarchy = [
  ProjectRoleId.Owner,
  ProjectRoleId.Administrator,
  ProjectRoleId.Standard,
  ProjectRoleId.Limited,
  ProjectRoleId.Readonly,
  ProjectRoleId.Disabled,
];

// This constant is simply a convenient way to lookup which roles you can
// access from your current role with O(1)
// eg: ProjectRolesHierarchyMap['standard'] === ['standard', 'limited', 'disabled']
const ProjectRolesHierarchyMap: Record<ProjectRoleId, ProjectRoleId[]> =
  ProjectRolesHierarchy.reduce(
    (acc, role) => ({
      ...acc,
      [role]: ProjectRolesHierarchy.slice(
        ProjectRolesHierarchy.findIndex((c) => c === role)
      ),
    }),
    {} as Record<ProjectRoleId, ProjectRoleId[]>
  );

function getHigherRole(roles: ProjectRoleId[]) {
  if (!roles.length) {
    return;
  }

  let higherRole = roles[0];
  let higherRoleIndex = 6;
  for (const role of roles) {
    const index = ProjectRolesHierarchy.indexOf(role);

    if (index !== -1 && index < higherRoleIndex) {
      higherRoleIndex = index;
      higherRole = role;
    }
  }

  return higherRole;
}

function getLowestRole(roles: ProjectRoleId[]) {
  if (!roles.length) {
    return;
  }

  let lowestRole = roles[0];
  let lowestRoleIndex = 0;
  for (const role of roles) {
    const index = ProjectRolesHierarchy.indexOf(role);

    if (index !== -1 && index > lowestRoleIndex) {
      lowestRoleIndex = index;
      lowestRole = role;
    }
  }

  return lowestRole;
}

export {
  getHigherRole,
  getLowestRole,
  ProjectRoleDefault,
  ProjectRoleId,
  ProjectRolesHierarchy,
  ProjectRolesHierarchyMap,
};
