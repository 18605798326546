import * as Toggle from "@radix-ui/react-toggle";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

export const StyledToggle = styled(Toggle.Root, {
  ...tw`
  bg-gray-200 
  relative 
  inline-flex flex-shrink-0 
  h-6 w-11 
  border-2 border-transparent 
  rounded-full 
  cursor-pointer 
  transition-colors ease-in-out duration-200 
  focus:(outline-none ring-2 ring-offset-2 ring-sky-500)
  `,
});
