import type * as Uppy from "@uppy/core";
import * as React from "react";
import { Box } from "~/design-system/Box";
import { Drawer, DrawerContent } from "~/design-system/Drawer";
import { FilesUploaderDrawerContent } from "./components/FilesUploaderDrawerContent/FilesUploaderDrawerContent";

interface FilesUploaderDrawerProps {
  files: Array<Uppy.State["files"][string]>;
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function FilesUploaderDrawer(props: FilesUploaderDrawerProps) {
  return (
    <Drawer open={props.open} setOpen={props.setOpen}>
      {props.children}
      <DrawerContent position="right">
        <Box width="screen" maxWidth="xsmall">
          <FilesUploaderDrawerContent files={props.files} />
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

export { FilesUploaderDrawer };
export type { FilesUploaderDrawerProps };
