import { UserAddIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Card } from "~/design-system/Card";
import { CircledIcon } from "~/design-system/CircledIcon";
import { DialogContent } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { DialogCloseButton } from "~/screens/App/components/FileViewerDialog/components/FileViewer/components/Modes/components/ApprovalMode/components/AddApprovalDialog/components/AddApprovalDialogContent/AddApprovalDialogContent";
import { UpdateTeamMembersForm } from "./components/UpdateTeamMembersForm";

type UpdateTeamMembersDialogContentProps = {
  teamId: string;
  onSuccess?: () => void;
  teamName: string;
};

function UpdateTeamMembersDialogContent(
  props: UpdateTeamMembersDialogContentProps
) {
  const { t } = useTranslation();
  return (
    <DialogContent
      css={{ width: "fit-content" }}
      size="regular"
      stopClickPropagation
    >
      <Card
        width="modal"
        css={{
          maxWidth: "calc(100vw - 40px)",
          padding: "$4",
        }}
      >
        <Stack space="small">
          <Stack>
            <DialogCloseButton />
            <CircledIcon
              tone="brandAccent"
              icon={<UserAddIcon />}
              iconLabel="edit team members icon"
            />
          </Stack>
          <Box>
            <Stack space="xsmall" css={{ paddingBottom: "$4" }}>
              <Heading level="3" weight="weak" css={{ textAlign: "center" }}>
                {t(
                  "screens.Projects.components.UpdateTeamMembersDialog.formTitle",
                  "Edit team members"
                )}
              </Heading>
              <Text
                size="standard"
                tone="neutral"
                css={{ display: "flex" }}
                align="center"
              >
                {t(
                  "screens.Projects.components.UpdateTeamMembersDialog.formSubTitle",
                  "Select the users you want to be members of the {{teamName}} team.",
                  {
                    teamName: props.teamName,
                  }
                )}
              </Text>
            </Stack>
            <UpdateTeamMembersForm
              teamId={props.teamId}
              onSuccess={props.onSuccess}
            />
          </Box>
        </Stack>
      </Card>
    </DialogContent>
  );
}

export { UpdateTeamMembersDialogContent };
