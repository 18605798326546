import { XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { styled } from "~/config/stitches";
import { Icon } from "../../Icon";
import type { ImagePreviewProps } from "../ImagePreview";

const RemoveButtonWrapper = styled("button", {
  "&:hover": {
    opacity: "0.8",
  },
  backgroundColor: "$black",
  borderRadius: "50%",
  opacity: "0.6",
  padding: "$xxsmall",
  position: "absolute",
  right: "3%",
  top: "3%",
});

type RemoveButtonProps = Pick<ImagePreviewProps, "size"> & {
  onClick?: (event: React.MouseEvent) => unknown;
};

function RemoveButton(props: RemoveButtonProps) {
  return (
    <RemoveButtonWrapper onClick={props.onClick} type="button">
      <Icon size="xxsmall" label="remove" css={{ color: "$white" }}>
        <XIcon />
      </Icon>
    </RemoveButtonWrapper>
  );
}

export { RemoveButton };
