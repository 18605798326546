import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Image } from "../../Image";

export const BaseImgAvatar = styled(Image, {
  "& img": tw`inline-flex`,

  defaultVariants: {
    shape: "circular",
    size: "medium",
  },

  variants: {
    shape: {
      circular: {
        "& *": tw`rounded-full`,
      },
      rounded: {
        "& *": tw`rounded-md`,
      },
    },
    size: {
      large: {
        height: "3.125rem",
        maxHeight: "3.125rem",
        maxWidth: "3.125rem",
        width: "3.125rem",
      },
      medium: {
        height: "2.625rem",
        maxHeight: "2.625rem",
        maxWidth: "2.625rem",
        width: "2.625rem",
      },
      small: {
        height: "2rem",
        maxHeight: "2rem",
        maxWidth: "2rem",
        width: "2rem",
      },
      xlarge: {
        height: "3.5rem",
        maxHeight: "3.5rem",
        maxWidth: "3.5rem",
        width: "3.5rem",
      },
      xsmall: {
        height: "1.5rem",
        maxHeight: "1.5rem",
        maxWidth: "1.5rem",
        width: "1.5rem",
      },
      xxsmall: {
        height: "1rem",
        maxHeight: "1rem",
        maxWidth: "1rem",
        width: "1rem",
      },
    },
    withPlaceholder: {
      true: {
        "& *": tw`overflow-hidden bg-gray-100`,
      },
    },
  },
});
