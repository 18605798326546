import tw from "twin.macro";
import { styled } from "~/config/stitches";

const SelectFieldInput = styled("select", {
  ...tw`
    mt-1 block w-full
    pl-3 pr-10 py-2
    text-base
  border-gray-300
    focus:(outline-none ring-slate-400 border-slate-400)
    rounded-md
    shadow-sm
  `,

  variants: {
    tone: {
      critical: tw`
        border-red-300 text-red-900 placeholder-red-300
        focus:(outline-none ring-red-500 border-red-500)
      `,
      neutral: tw``,
      positive: tw``,
    },
  },
});

export { SelectFieldInput };
