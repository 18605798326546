import * as React from "react";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import type { CreateOrgProjectSummaryFormProps } from "./components/CreateOrgProjectSummaryForm/CreateOrgProjectSummaryForm";
import { CreateOrgProjectSummaryForm } from "./components/CreateOrgProjectSummaryForm/CreateOrgProjectSummaryForm";
import { DrawerHeader } from "./components/DrawerHeader";

function CreateOrgProjectSummaryDrawerContent(
  props: CreateOrgProjectSummaryFormProps
) {
  return (
    <DrawerContent position="right">
      <Stack>
        <DrawerHeader />
        <Box padding="gutter">
          <CreateOrgProjectSummaryForm {...props} />
        </Box>
      </Stack>
    </DrawerContent>
  );
}

export { CreateOrgProjectSummaryDrawerContent };
