import * as React from "react";
import { Box } from "../Box";

type FormProps = Pick<React.ComponentProps<"form">, "children" | "onSubmit">;

const Form = React.forwardRef(function Form(
  props: FormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  return <Box ref={ref} as="form" width="full" noValidate {...props} />;
});

export type { FormProps };
export { Form };
