import * as React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { useArtificialProgressHook } from "~/config/app/hook";
import { styled } from "~/config/stitches";
import { createComponentHook } from "~/types";
import { BaseBar } from "./components/BaseBar";
import { MainBar } from "./components/MainBar";
import { TextBar } from "./components/TextBar";

type UploadProgressBarProps = {
  files: Array<{ name: string }>;
  totalProgress: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const UploadProgressBarWrapper = styled("button", {
  ...tw`w-full relative z-50`,
  "&:focus": {
    ...tw`outline-none`,
  },
});

const useUploadProgressBar = createComponentHook(
  (props: UploadProgressBarProps) => {
    const { t } = useTranslation();
    const { artificialProgress } = useArtificialProgressHook({
      initialProgress: props.totalProgress,
    });

    const uploadText =
      props.files.length > 1
        ? t(
            "components.FilesUploader.UploadProgressBar.uploadingMultipleFiles",
            "Uploading {{totalFiles}} files",
            { totalFiles: props.files.length }
          )
        : t(
            "components.FilesUploader.UploadProgressBar.uploadingOneFile",
            "Uploading {{fileName}}",
            { fileName: props.files[0].name }
          );

    const uploadProgress = t(
      "components.FilesUploader.UploadProgressBar.progress",
      "{{totalProgress}}%",
      { totalProgress: artificialProgress }
    );

    return {
      state: {
        artificialProgress,
        uploadProgress,
        uploadText,
      },
      t,
    };
  }
);

function UploadProgressBar(props: UploadProgressBarProps) {
  const { state } = useUploadProgressBar(props);

  return (
    <UploadProgressBarWrapper onClick={props.onClick}>
      <BaseBar />
      <MainBar
        style={{
          minWidth: "10%",
          transitionDuration: "0.3s",
          transitionProperty: "width",
          width: `${5 + state.artificialProgress * 0.95}%`,
        }}
      />
      <TextBar
        uploadText={state.uploadText}
        uploadPercentage={state.uploadProgress}
      />
    </UploadProgressBarWrapper>
  );
}

export { UploadProgressBar };
