import * as React from "react";
import type { FieldProps } from "../Field";
import { Field } from "../Field";
import type { PhoneFieldInputProps } from "./components/PhoneFieldInput";
import { PhoneFieldInput } from "./components/PhoneFieldInput";

type PhoneFieldProps = Omit<FieldProps & PhoneFieldInputProps, "children">;

const PhoneField = React.forwardRef<HTMLInputElement, PhoneFieldProps>(
  function PhoneField(props, ref) {
    const fieldProps: Omit<FieldProps, "children"> = {
      cornerHint: props.cornerHint,
      dataIntercomTarget: props.dataIntercomTarget,
      error: props.error,
      label: props.label,
      message: props.message,
      name: props.name,
      required: props.required,
      rootProps: props.rootProps,
      secondaryLabel: props.secondaryLabel,
      tone: props.tone,
    };
    const phoneFieldInputProps: PhoneFieldInputProps = {
      autoComplete: props.autoComplete,
      autoFocus: props.autoFocus,
      /* defaultCountryCode is only used if there is no value */
      defaultCountryCode: props.defaultCountryCode,
      disabled: props.disabled,
      error: props.error,
      name: props.name,
      onBlur: props.onBlur,
      onChange: props.onChange,
      onFocus: props.onFocus,
      placeholder: props.placeholder,
      readOnly: props.readOnly,
      required: props.required,
      tone: props.tone,
      value: props.value,
    };
    return (
      <Field {...fieldProps}>
        <PhoneFieldInput ref={ref} {...phoneFieldInputProps} />
      </Field>
    );
  }
);

export type { PhoneFieldProps };
export { PhoneField };
