import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

const useAllNotificationsBadge = createComponentHook(() => {
  const notifications = useUserNotificationsContext(
    (state) => state.notifications
  );
  const { strongNotificationsCount, weakNotif } =
    getDisplayNotificationProps(notifications);

  return {
    state: {
      strongNotificationsCount,
      weakNotif,
    },
  };
});

const AllNotificationsBadge = () => {
  const { state } = useAllNotificationsBadge();

  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { AllNotificationsBadge };
