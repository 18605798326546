import { parsePhoneNumber } from "libphonenumber-js";
import * as React from "react";
import { useAbac } from "react-abac";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { CountryCode } from "~/config/i18next/helpers";
import type { CompanyInfosApiSearchResult } from "~/config/papers/types";
import { Column, Columns } from "~/design-system/Columns";
import { PhoneField } from "~/design-system/PhoneField";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import type { BasicMultiSelectFieldInputOption } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { CompanyNameAutocompleteSelectFieldInput } from "~/screens/App/components/CompanyNameAutocompleteSelectFieldInput/CompanyNameAutocompleteSelectFieldInput";
import { ProjectTeamsMultiSelectFieldInput } from "~/screens/App/screens/Project/components/ProjectsInvitationsDialog/components/ProjectInvitationForm/components/ProjectTeamsMultiSelectFieldInput/ProjectTeamsMultiSelectFieldInput";
import { Permission } from "~/screens/App/screens/Project/Project.authorize";
import { createComponentHook } from "~/types";
import type { Member } from "../../../../../../../ProjectMemberList.types";
import { ProjectRightsSelectFieldInput } from "../../../../../../ProjectRightsSelectFieldInput/ProjectRightsSelectFieldInput";
import type { EditUserFormType } from "../EditUserForm";

type UserInputsProps = {
  control: EditUserFormType["control"];
  setValue: EditUserFormType["setValue"];
  getValues: EditUserFormType["getValues"];
  registerInput: EditUserFormType["register"];
  errors: EditUserFormType["formState"]["errors"];
  member: Member;
  projectId: string;
  editionAllowed: boolean;
  userDefinedCountryCode?: CountryCode;
  setUserDefinedCountryCode: React.Dispatch<
    React.SetStateAction<CountryCode | undefined>
  >;
  canEditAllFields: boolean;
  orgSuggestions?: BasicMultiSelectFieldInputOption<CompanyInfosApiSearchResult>[];
};

const useUserInputs = createComponentHook((props: UserInputsProps) => {
  const { t } = useTranslation();

  const { userHasPermissions } = useAbac();
  const changeTeamsDisabled = !userHasPermissions(
    Permission.AddUserInTeam,
    props.member.role.name
  );

  const readonlyField = (_value: string | null | undefined) => {
    // UPDATE : All fields are now editable if props.editionAllowed is true (except email which have now its own logic see emailEditable function below)
    // to allow edition of legacy users with wrong firstname / lastname

    // Previous logic (if rollback needed) :
    // // All fiels editable readonly will always be set to false
    // if (props.canEditAllFields) {
    //   return false;
    // }

    // // Field is readonly if it already have a value or if it's not editable
    // return !!value || !props.editionAllowed;

    return !props.editionAllowed;
  };

  // Custom edition logic for email field
  const emailEditable = !props.member.user.is_active && props.editionAllowed;

  return {
    actions: {
      readonlyField,
    },
    state: {
      changeTeamsDisabled,
      emailEditable,
    },
    t,
  };
});

/* This is only used to change members info of a company, inside project context */
function UserInputs(props: UserInputsProps) {
  const { errors, registerInput } = props;
  const { actions, state, t } = useUserInputs(props);

  return (
    <Columns space="small" collapseBelow="desktop">
      <Column>
        <Stack space="small">
          <TextField
            {...registerInput("first_name")}
            readOnly={actions.readonlyField(props.member.user.first_name)}
            label={t(
              "screens.ProjectMembersScreen.EditUserForm.firstName",
              "First name"
            )}
            required
            error={errors.first_name?.message}
            dataIntercomTarget="project-member-edit-first_name"
          />
          <TextField
            {...registerInput("email")}
            readOnly={!state.emailEditable}
            label={t(
              "screens.ProjectMembersScreen.EditUserForm.email",
              "Email"
            )}
            required
            error={errors.email?.message}
            dataIntercomTarget="project-member-edit-email"
          />
          <CompanyNameAutocompleteSelectFieldInput
            {...registerInput(`companyData`)}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            /* Disabled if the current user have still an official "Clovis" org */
            disabled={!!props.member.user.active_org?.org?.id}
            options={props.orgSuggestions ?? []}
            label={t(
              "ProjectInvitationForm.UserFieldArrayInput.placeholderCompanyName",
              "Company Name"
            )}
            dataIntercomTarget="project-member-edit-company_name"
          />
        </Stack>
      </Column>
      <Column>
        <Stack space="small">
          <TextField
            {...registerInput("last_name")}
            readOnly={actions.readonlyField(props.member.user.last_name)}
            label={t(
              "screens.ProjectMembersScreen.EditUserForm.lastName",
              "Last name"
            )}
            required
            error={errors.last_name?.message}
            dataIntercomTarget="project-member-edit-last_name"
          />
          <Controller
            control={props.control}
            name="phone"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <PhoneField
                readOnly={actions.readonlyField(
                  props.member.user.contact?.phone
                )}
                defaultCountryCode={props.userDefinedCountryCode}
                ref={ref}
                onBlur={onBlur}
                label={t(
                  "screens.ProjectMembersScreen.EditUserForm.phone",
                  "Phone"
                )}
                value={value}
                onChange={(value) => {
                  onChange(value);
                  try {
                    const phoneCountryCode = parsePhoneNumber(value).country;
                    props.setUserDefinedCountryCode(
                      phoneCountryCode as CountryCode
                    );
                  } catch {
                    //
                  }
                }}
                name={name}
                error={errors.phone?.message}
                dataIntercomTarget="project-member-edit-phone"
              />
            )}
          />
        </Stack>
      </Column>
      <Column width="1-2">
        <Stack space="small">
          <ProjectRightsSelectFieldInput
            {...registerInput("role")}
            label={t("screens.ProjectMembersScreen.EditUserForm.role", "Role")}
            required
            error={errors.role?.message}
            defaultValue={props.member.role.name}
            dataIntercomTarget="project-member-edit-role"
          />
          <ProjectTeamsMultiSelectFieldInput
            control={props.control}
            {...registerInput("teamIds")}
            label={t("screens.ProjectMembersScreen.EditUserForm.team", "Team")}
            projectId={props.projectId}
            dataIntercomTarget="project-member-edit-team"
            disabled={state.changeTeamsDisabled}
          />
        </Stack>
      </Column>
    </Columns>
  );
}

export { UserInputs };
