import type { Cacheable, ICache } from "@auth0/auth0-spa-js/dist/typings/cache";
import { SecureCache } from "~/config/capacitor/secure-storage";

// We use a memory map on top of the SecureStoragePlugin
// because auth0 perform a LOT of "get" to his cache to retrieve the token
// and everytime he do so, talking to the platform native API can slow down
// the whole process since it needs to change context.
// So we use the SecureStorage for long and persistent storage, otherwise
// we try to stay in our app context as much as we can.
const memoryMap = new Map<string, any>();

class Auth0Cache extends SecureCache implements ICache {
  async get<T = Cacheable>(key: string): Promise<T | null> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const memResult = memoryMap.get(key);
    if (memResult !== undefined) {
      return memResult as unknown as T | null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const secureStorageResult = await super.get(key);
    // If the value doesnt exist in our inMemory cache but has been retrieved from
    // our persistent long duration storage, we add it into our inmemory cache
    // to speed up next look-up
    memoryMap.set(key, secureStorageResult);
    return secureStorageResult as unknown as T | null;
  }

  async set<T = Cacheable>(key: string, entry: T): Promise<void> {
    memoryMap.set(key, entry);
    return super.set(key, entry);
  }

  async remove(key: string): Promise<void> {
    memoryMap.delete(key);
    return super.remove(key);
  }

  async allKeys(): Promise<string[]> {
    return super.allKeys();
  }
}

const auth0Cache = new Auth0Cache();

export { auth0Cache };
