import tw from "twin.macro";
import { styled } from "~/config/stitches";

export const InitialsWrapper = styled("span", {
  ...tw`font-medium leading-none text-white`,
  "&": { overflow: "hidden", textOverflow: "ellipsis" },

  variants: {
    size: {
      large: tw`text-lg`,
      medium: tw``,
      small: tw`text-sm`,
      xlarge: tw`text-xl`,
      xsmall: tw`text-xs`,
      xxsmall: {
        alignItems: "center",
        display: "flex",
        fontSize: "0.5rem",
        height: "1rem",
        justifyContent: "center",
        width: "1rem",
      },
    },
  },
});
