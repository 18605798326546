import * as React from "react";
import type { PolymorphicComponentProps } from "react-polymorphic-box";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

type HeadingOwnProps = {
  level: "1" | "2" | "3" | "4" | "5" | "6";
  children: React.ReactNode;
  weight?: "medium" | "regular" | "weak";
  css?: CSS;
  truncate?: "noFullWidth" | true;
};

type HeadingProps<E extends React.ElementType = "h1"> =
  PolymorphicComponentProps<E, HeadingOwnProps>;

const sharedStyle = {
  defaultVariants: {
    weight: "regular",
  },

  variants: {
    truncate: {
      noFullWidth: tw`w-auto max-w-full inline-block whitespace-nowrap overflow-ellipsis overflow-hidden`,
      true: tw`w-full inline-block whitespace-nowrap overflow-ellipsis overflow-hidden`,
    },
    weight: {
      medium: tw`font-medium`,
      regular: tw`font-bold`,
      weak: tw`font-normal`,
    },
  },
};

const Heading1 = styled("h1", {
  ...tw`text-3xl`,
  ...sharedStyle,
});

const Heading2 = styled("h2", {
  ...tw`text-2xl`,
  ...sharedStyle,
});

const Heading3 = styled("h3", {
  ...tw`text-xl`,
  ...sharedStyle,
});

const Heading4 = styled("h4", {
  ...tw`text-lg`,
  ...sharedStyle,
});

const Heading5 = styled("h5", {
  ...tw`text-base`,
  ...sharedStyle,
});

const Heading6 = styled("h6", {
  ...tw`text-base`,
  ...tw`text-slate-400`,
  ...sharedStyle,
});

export function Heading<E extends React.ElementType = "h1">(
  props: HeadingProps<E>
) {
  const { level, ...restProps } = props;
  switch (level) {
    case "1":
      return <Heading1 {...restProps} />;
    case "2":
      return <Heading2 {...restProps} />;
    case "3":
      return <Heading3 {...restProps} />;
    case "4":
      return <Heading4 {...restProps} />;
    case "5":
      return <Heading5 {...restProps} />;
    case "6":
      return <Heading6 {...restProps} />;
  }
}
