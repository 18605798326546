import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserProjectRoleQueryVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
  userId: Types.Scalars['uuid'];
}>;


export type UserProjectRoleQuery = { projects_by_pk?: { is_demo: boolean, members: Array<{ role: { name: string } }> } | null | undefined };

export type AddUserViewToProjectMutationVariables = Types.Exact<{
  input: Types.AddUserViewToProjectInput;
}>;


export type AddUserViewToProjectMutation = { addUserViewToProject?: { __typename: 'AddUserViewToProjectErrors' } | { __typename: 'AddUserViewToProjectSuccess' } | null | undefined };


export const UserProjectRoleDocument = gql`
    query userProjectRole($projectId: uuid!, $userId: uuid!) {
  projects_by_pk(id: $projectId) {
    is_demo
    members(where: {user: {id: {_eq: $userId}}}) {
      role {
        name
      }
    }
  }
}
    `;

export function useUserProjectRoleQuery(options: Omit<Urql.UseQueryArgs<UserProjectRoleQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserProjectRoleQuery>({ query: UserProjectRoleDocument, ...options });
};
export const AddUserViewToProjectDocument = gql`
    mutation addUserViewToProject($input: AddUserViewToProjectInput!) {
  addUserViewToProject(input: $input) {
    __typename
  }
}
    `;

export function useAddUserViewToProjectMutation() {
  return Urql.useMutation<AddUserViewToProjectMutation, AddUserViewToProjectMutationVariables>(AddUserViewToProjectDocument);
};