import * as React from "react";
import { Box } from "~/design-system/Box";
import { createComponentHook } from "~/types";
import { Infos } from "./Infos";
import { UpdateGroupForm } from "./UpdateGroupForm/UpdateGroupForm";
import type { GroupsList_ItemFragment } from "./UpdateGroupForm/UpdateGroupForm.graphql";

const useUpdateGroupForm = createComponentHook(() => {
  const [editMode, setEditMode] = React.useState(false);

  const enableForm = () => {
    setEditMode(true);
  };

  const disableForm = () => {
    setEditMode(false);
  };

  return {
    actions: {
      disableForm,
      enableForm,
    },
    state: {
      editMode,
    },
  };
});

type GroupsListItemProps = {
  group: GroupsList_ItemFragment;
  orgId: string;
};

function GroupsListItem(props: GroupsListItemProps) {
  const { actions, state } = useUpdateGroupForm();

  return (
    <Box marginBottom="small">
      {state.editMode ? (
        <UpdateGroupForm
          group={props.group}
          orgId={props.orgId}
          onSuccess={actions.disableForm}
        />
      ) : (
        <Infos group={props.group} onEditClick={actions.enableForm} />
      )}
    </Box>
  );
}

export { GroupsListItem };
