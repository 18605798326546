import tw from "twin.macro";
import { styled } from "~/config/stitches";

const Input = styled("input", {
  variants: {
    tone: {
      critical: tw`
        border-red-300 text-red-900 placeholder-red-300
        focus:(outline-none ring-red-500 border-red-500)
      `,
      neutral: tw``,
      positive: tw``,
    },
    withLeadingIcon: {
      true: tw`pl-10`,
    },
    withTrailingIcon: {
      true: tw`pr-10`,
    },
    withTrailingIconButton: {
      true: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        // For some input like date field, adding a padding isn't enough
        // Because some uncontrolled style (eg: calendar icon on chrome)
        // will not take into account the "padding" to display themself.
        // So, if we want a TrailingIconButton we have to reduce the width
        // of our field accordingly to the size of icon + button + borders
        width: "calc(100% - 57px)",
      },
    },
  },

  ...tw`
  text-base
  shadow-sm
  focus:(ring-slate-400 border-slate-400)
  block
  w-full
  border-gray-300
  rounded-md
  read-only:(bg-gray-200 text-gray-400 cursor-default focus:(ring-0 border-gray-300))`,
});

Input.defaultProps = {
  type: "text",
};

export { Input };
