import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import { isProjectNotification } from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterExcludeProjectBadge(
  notification: UserBadgeNotification,
  projectId: string
) {
  return (
    isProjectNotification(notification) &&
    notification.payload.project.id !== projectId
  );
}

const useAllNotificationsButActualProjectBadge = createComponentHook(
  (props: AllNotificationsButActualProjectBadgeProps) => {
    const notifications = useUserNotificationsContext((state) =>
      state.notifications.filter((n) =>
        filterExcludeProjectBadge(n, props.projectId)
      )
    );
    const { strongNotificationsCount, weakNotif } =
      getDisplayNotificationProps(notifications);

    return {
      state: {
        strongNotificationsCount,
        weakNotif,
      },
    };
  }
);

type AllNotificationsButActualProjectBadgeProps = { projectId: string };

const AllNotificationsButActualProjectBadge = (
  props: AllNotificationsButActualProjectBadgeProps
) => {
  const { state } = useAllNotificationsButActualProjectBadge(props);

  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { AllNotificationsButActualProjectBadge, filterExcludeProjectBadge };
