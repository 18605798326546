import {
  OFFICE_VIEWER_BINARY_EXTENSIONS,
  OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
} from "@clovis/server/src/app/config/pdftron/extensions";

// WOPI API actions : https://docs.microsoft.com/en-us/microsoft-365/cloud-storage-partner-program/online/discovery#wopi-actions
const enum WopiAction {
  View = "view",
  Edit = "edit",
  Create = "editnew",
  Convert = "convert",
}

export {
  OFFICE_VIEWER_BINARY_EXTENSIONS,
  OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
  WopiAction,
};
