import React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { CellContext, CellParent } from "./Cell";

const StyledBody = styled("tbody", {
  ...tw`
  bg-white divide-y divide-gray-200
  `,
});

type BodyProps = React.ComponentProps<typeof StyledBody>;

const Body = (props: BodyProps) => {
  return (
    <CellContext.Provider value={CellParent.BODY}>
      <StyledBody {...props} />
    </CellContext.Provider>
  );
};

export { Body };
