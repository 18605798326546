import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "../../CloseButton";

function DrawerHeader() {
  const { t } = useTranslation();

  return (
    <Stack space="medium">
      <Box display="flex" justifyContent="spaceBetween" alignItems="center">
        <Heading level="3">
          {t("CreateOrgDrawer.title", "Create an organization")}
        </Heading>
        <DrawerClose asChild>
          <CloseButton dataIntercomTarget="create-org-close" />
        </DrawerClose>
      </Box>
      <Text>
        {t(
          "CreateOrgDrawer.message",
          "You can change those information at any time"
        )}
      </Text>
    </Stack>
  );
}

export { DrawerHeader };
