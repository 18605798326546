import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateBrowserTimezoneMutationVariables = Types.Exact<{
  timezone: Types.Scalars['String'];
}>;


export type UpdateBrowserTimezoneMutation = { updateBrowserTimezone?: { __typename: 'UpdateBrowserTimezoneErrors' } | { __typename: 'UpdateBrowserTimezoneSuccess', user_id: string } | null | undefined };

export type UpdateBrowserLanguageMutationVariables = Types.Exact<{
  language: Types.Scalars['String'];
}>;


export type UpdateBrowserLanguageMutation = { updateBrowserLanguage?: { __typename: 'UpdateBrowserLanguageErrors' } | { __typename: 'UpdateBrowserLanguageSuccess', user_id: string } | null | undefined };


export const UpdateBrowserTimezoneDocument = gql`
    mutation updateBrowserTimezone($timezone: String!) {
  updateBrowserTimezone(input: {timezone: $timezone}) {
    __typename
    ... on UpdateBrowserTimezoneSuccess {
      user_id
    }
  }
}
    `;

export function useUpdateBrowserTimezoneMutation() {
  return Urql.useMutation<UpdateBrowserTimezoneMutation, UpdateBrowserTimezoneMutationVariables>(UpdateBrowserTimezoneDocument);
};
export const UpdateBrowserLanguageDocument = gql`
    mutation updateBrowserLanguage($language: String!) {
  updateBrowserLanguage(input: {browser_language: $language}) {
    __typename
    ... on UpdateBrowserLanguageSuccess {
      user_id
    }
  }
}
    `;

export function useUpdateBrowserLanguageMutation() {
  return Urql.useMutation<UpdateBrowserLanguageMutation, UpdateBrowserLanguageMutationVariables>(UpdateBrowserLanguageDocument);
};