import { CheckIcon, TrashIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DialogTrigger } from "~/design-system/Dialog";
import { Form } from "~/design-system/Form";
import { TextFieldInput } from "~/design-system/TextField";
import { SelectColorInput } from "~/screens/App/components/SelectColorField/components/SelectColorInput";
import { createComponentHook } from "~/types";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { validationSchema } from "./update-team.validation";
import type { TeamsList_ItemFragment } from "./UpdateTeamForm.graphql";
import {
  useDeleteTeamMutation,
  useUpdateTeamMutation,
} from "./UpdateTeamForm.graphql";

const useUpdateTeamForm = createComponentHook((props: UpdateTeamFormProps) => {
  const { t } = useTranslation();
  const [, updateTeam] = useUpdateTeamMutation();
  const [, deleteTeam] = useDeleteTeamMutation();

  const form = useForm(validationSchema, {
    defaultValues: {
      color: props.team.color,
      name: props.team.name,
      teamId: props.team.id,
    },
  });

  const handleSubmit = form.handleSubmit(async (input) => {
    const { data, error } = await updateTeam(
      {
        input,
      },
      { additionalTypenames: ["teams"] }
    );
    if (data?.updateTeam?.__typename === "UpdateTeamSuccess") {
      toast.success(
        t(
          "screens.ProjectTeamsDrawer.UpdateTeamForm.updateTeamSuccessToast",
          "Your team has been updated"
        )
      );
      props.onSuccess?.();
    } else {
      captureException(error);
      toast.error(
        t(
          "screens.ProjectTeamsDrawer.UpdateTeamForm.updateTeamErrorsToast",
          "Something went wrong while updating the team."
        )
      );
    }
  });

  const handleRemove = async () => {
    const { data, error } = await deleteTeam(
      {
        input: {
          teamId: props.team.id,
        },
      },
      { additionalTypenames: ["teams"] }
    );
    if (data?.deleteTeam?.__typename === "DeleteTeamSuccess") {
      toast.success(
        t(
          "screens.ProjectTeamsDrawer.UpdateTeamForm.deleteTeamSuccessToast",
          "Your team has been deleted"
        )
      );
    } else {
      captureException(error);
      toast.error(
        t(
          "screens.ProjectTeamsDrawer.UpdateTeamForm.deleteTeamErrorsToast",
          "Something went wrong while deleting the team."
        )
      );
    }
  };

  return {
    actions: {
      handleRemove,
      handleSubmit,
      registerInput: form.register,
    },
    state: {
      control: form.control,
      errors: form.formState.errors,
    },
    t,
  };
});

type UpdateTeamFormProps = {
  team: TeamsList_ItemFragment;
  onSuccess?: () => void;
};

function UpdateTeamForm(props: UpdateTeamFormProps) {
  const { actions, state, t } = useUpdateTeamForm(props);

  return (
    <Form onSubmit={actions.handleSubmit}>
      <Columns space="small">
        <Column>
          <Columns space="small">
            <Column>
              <TextFieldInput
                {...actions.registerInput("name")}
                placeholder={t(
                  "screens.ProjectTeamsDrawer.UpdateTeamForm.namePlaceholder",
                  "Name"
                )}
                required
                error={state.errors.name?.message}
              />
            </Column>
            <Column width="content">
              <SelectColorInput
                {...actions.registerInput("color")}
                control={state.control}
              />
            </Column>
          </Columns>
        </Column>
        <Column width="content">
          <Columns space="small">
            <Column>
              <DeleteConfirmationDialog onConfirm={actions.handleRemove}>
                <DialogTrigger asChild>
                  <Button
                    type="button"
                    variant="transparent"
                    circular
                    dataIntercomTarget="project-teams-update-team-delete"
                  >
                    <TrashIcon />
                  </Button>
                </DialogTrigger>
              </DeleteConfirmationDialog>
            </Column>
            <Column>
              <Button
                tone="brandAccent"
                variant="solid"
                circular
                dataIntercomTarget="project-teams-update-team-validate"
              >
                <CheckIcon />
              </Button>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Form>
  );
}

export { UpdateTeamForm };
