import { FolderIcon as FolderHeroicon } from "@heroicons/react/outline";
import type * as Stitches from "@stitches/react";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { Icon } from "../Icon/Icon";
import { FolderIconAutomated } from "./components/FolderIconAutomated";
import { FolderIconChevron } from "./components/FolderIconChevron";
import { FolderIconLock } from "./components/FolderIconLock";
import { FolderIconSize } from "./FolderIconConstant";

const FolderIconWrapper = styled("span", {
  ...{ position: "relative" },
  defaultVariants: {
    size: FolderIconSize.XLARGE,
  },
  variants: {
    size: {
      large: { height: "2rem", width: "2rem" },
      medium: { height: "1.5rem", width: "1.5rem" },
      small: { height: "1.25rem", width: "1.25rem" },
      xlarge: { height: "2.5rem", width: "2.5rem" },
    },
  },
});

type FolderIconProps = {
  locked?: boolean;
  automated?: boolean;
  chevron?: boolean;
  size: Stitches.VariantProps<typeof FolderIconWrapper>["size"];
  iconProps?: {
    css?: CSS;
  };
  children?: React.ReactNode;
};

const FolderIcon = (props: FolderIconProps) => {
  return (
    <FolderIconWrapper
      size={props.size}
      css={props.iconProps?.css}
      className="folderIcon"
    >
      <Icon label={`folder`} size={props.size} color="info">
        <FolderHeroicon />
      </Icon>
      {props.chevron && <FolderIconChevron size={props.size} />}
      {props.locked && <FolderIconLock size={props.size} color="info" />}
      {props.automated && (
        <FolderIconAutomated size={props.size} color="info" />
      )}
      {props.children}
    </FolderIconWrapper>
  );
};

FolderIcon.defaultProps = {
  size: FolderIconSize.XLARGE,
};

export { FolderIcon, FolderIconSize };
export type { FolderIconProps };
