import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { BoxProps } from "~/design-system/Box";
import { Box } from "~/design-system/Box";
import { useBreakpoint } from "~/design-system/hooks";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import { Auth0ContinueLoginForm } from "./components/Auth0ContinueLoginForm/Auth0ContinueLoginForm";

/* This screen is displayed to fill the user Name / first name, language, phone after first login 
  See the storybok story: https://www.chromatic.com/component?appId=6024f27d191e130023ff4976&csfId=screens-app-user-auth0continueloginscreen&buildNumber=3304&k=668b811a18437a0ce39a8ae7-1200px-interactive-true&h=8&b=-1 */
const useAuth0ContinueLoginScreen = createComponentHook(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint();

  // Doesn't come with a state from auth0 redirect
  React.useEffect(() => {
    const locationSearchParams = new URLSearchParams(location.search);
    if (!locationSearchParams.get("state")) {
      navigate("/");
    }
    // Only trigger this effect once at component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      breakpoint,
    },
  };
});

function Auth0ContinueLoginScreen() {
  const { state } = useAuth0ContinueLoginScreen();

  const boxProps: BoxProps = {
    padding: "large",
    ...(state.breakpoint === "mobile"
      ? {
          width: "full",
        }
      : {
          background: "card",
          borderRadius: "standard",
          boxShadow: "medium",
          height: "full",
          width: "modal",
        }),
  };

  return (
    <Box display="flex" alignItems="center" css={{ height: "100vh" }}>
      <Stack space="large" align="center">
        <Box {...boxProps}>
          <Auth0ContinueLoginForm />
        </Box>
      </Stack>
    </Box>
  );
}

export { Auth0ContinueLoginScreen };
