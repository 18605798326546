import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Image } from "~/design-system/Image";
import { SafeArea } from "~/design-system/SafeArea";
import type { UserOrg } from "../../NavigationDrawer";
import type { OriginalInviterFragment } from "../../NavigationDrawer.graphql";
import {
  DrawerMenuSectionHeader,
  IntercomDrawerHelpButton,
} from "../DrawerMenuSectionHeader";
import { DashboardButton, HeaderButtons } from "./components/HeaderButtons";
import { HeaderInvitedBy } from "./components/HeaderInvitedBy";
import { HeaderOrg } from "./components/HeaderOrg";

type NavigationDrawerHeaderProps = {
  activeOrg?: UserOrg;
  originalInviter?: OriginalInviterFragment;
  keyboardOpen: boolean;
};

const NavigationDrawerHeader = (props: NavigationDrawerHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display="grid"
      background={props.activeOrg?.background ? "image" : "transparent"}
      css={{
        gridTemplate: "1fr auto",
      }}
    >
      <SafeArea top />
      {/* Show another header for users who have been invited 
         We show them by who they have been invited to show the brand of their inviter */}
      {
        // Data to replace props.originalInviter & test under dev:
        // const fakeOriginalInviter = {
        //   avatar: "http://localhost:9000/clovis-public-assets/user_avatar.jpg",
        //   contact: {email: 'gustave@clovis.pro', phone: '+33102030405',},
        //   first_name: "gustave",
        //   full_name: "gustave eiffel",
        //   id: "b091eda9-e766-46b5-914f-d34940d2267e",
        //   last_name: "eiffel",
        //   active_org: {
        //     org : {
        //       avatar: "http://localhost:9000/clovis-public-assets/org_avatar.jpg",
        //       name: "clovis",
        //       phone: "+33 6.37.38.06.47",
        //       id: "fc9df192-6313-4347-9317-8ee7b37952ba"
        //     }
        //   }
        // }
      }
      <AnimatePresence>
        {!props.keyboardOpen && (
          <motion.div
            key="drawer-header-shortcuts"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box paddingX="small" paddingTop="small">
              <Box
                display="flex"
                justifyContent="spaceBetween"
                alignItems="center"
              >
                <DrawerMenuSectionHeader
                  data-intercom-target="drawer-header-shortcuts"
                  title={t(
                    "screens.NavigationDrawer.NavigationDrawerHeader.menuProjects",
                    "Projects' Menu"
                  )}
                />
                <IntercomDrawerHelpButton />
              </Box>
              <Box paddingY="small">
                {props.activeOrg ? <HeaderButtons /> : <DashboardButton />}
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!props.keyboardOpen && (
          <motion.div
            key="drawer-header-org"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {!props.activeOrg && props.originalInviter ? (
              <>
                <Box paddingX="small" paddingTop="small">
                  <DrawerMenuSectionHeader
                    data-intercom-target="drawer-header-invited-by"
                    title={t(
                      "screens.NavigationDrawer.NavigationDrawerHeader.clovis",
                      "Clovis - Your shared platform"
                    )}
                  />
                  <HeaderInvitedBy originalInviter={props.originalInviter} />
                </Box>
              </>
            ) : (
              <>
                <Box paddingX="small">
                  <DrawerMenuSectionHeader
                    data-intercom-target="drawer-header-org"
                    title={t(
                      "screens.NavigationDrawer.NavigationDrawerHeader.myCompany",
                      "My company"
                    )}
                  />
                </Box>
                <HeaderOrg activeOrg={props.activeOrg} />
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {props.activeOrg?.background && (
        <Box css={{ overflow: "hidden" }}>
          <Image
            src={props.activeOrg?.background}
            css={{
              overflow: "hidden",
            }}
          />
          <Box
            css={{
              background: "linear-gradient(rgb(15 23 42 / 53%), rgb(15 23 42))",
              inset: 0,
              position: "absolute",
              zIndex: 2,
            }}
          ></Box>
        </Box>
      )}
    </Box>
  );
};

export type { NavigationDrawerHeaderProps };
export { NavigationDrawerHeader };
