import tw from "twin.macro";
import { styled } from "~/config/stitches";

export const BaseSpanAvatar = styled("span", {
  ...tw``,

  defaultVariants: {
    shape: "circular",
    size: "medium",
  },

  variants: {
    shape: {
      circular: tw`rounded-full`,
      rounded: tw`rounded-md`,
    },
    size: {
      large: tw`h-12 w-12`,
      medium: {
        height: "2.625rem",
        maxHeight: "2.625rem",
        maxWidth: "2.625rem",
        width: "2.625rem",
      },
      small: tw`h-8 w-8`,
      xlarge: tw`h-14 w-14`,
      xsmall: tw`h-6 w-6`,
      xxsmall: tw`h-4 w-4`,
    },
    withInitials: {
      false: tw`inline-flex`,
      true: tw`inline-flex items-center justify-center bg-gray-500`,
    },
    withPlaceholder: {
      true: tw`overflow-hidden bg-gray-100`,
    },
  },
});
