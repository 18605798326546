import { UsersIcon, XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useAbac } from "react-abac";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerClose, DrawerTrigger } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import { Permission } from "../../../../Project.authorize";
import { ProjectTeamsDrawer } from "../../../ProjectTeamsDrawer/ProjectTeamsDrawer";

function DrawerCloseButton(props: { handleClose: () => void }) {
  return (
    <DrawerClose onClick={props.handleClose}>
      <Icon color="neutral" size="medium" label="dismiss">
        <XIcon />
      </Icon>
    </DrawerClose>
  );
}

const useHeader = createComponentHook(() => {
  const { t } = useTranslation();
  const { userHasPermissions } = useAbac();

  return {
    actions: {
      userHasPermissions,
    },
    t,
  };
});

function Header(props: {
  name: string;
  projectId: string;
  handleClose: () => void;
}) {
  const { actions, t } = useHeader();
  return (
    <Box padding="gutter">
      <Stack space="small">
        <Box display="flex" justifyContent="spaceBetween" alignItems="center">
          <Heading level="4">
            {t(
              "ProjectTeamDrawer.ProjectTeamContent.projectTeam",
              "Project team"
            )}
          </Heading>
          <DrawerCloseButton handleClose={props.handleClose} />
        </Box>
        <Inline space="small">
          <Icon label="teams icon">
            <UsersIcon />
          </Icon>
          <Heading level="3">{props.name}</Heading>
        </Inline>
        <ProjectTeamsDrawer projectId={props.projectId}>
          <DrawerTrigger asChild>
            <Button
              link
              disabled={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                !actions.userHasPermissions(Permission.UpdateProjectTeams)
              }
              dataIntercomTarget="project-teams-edit"
            >
              {t(
                "ProjectTeamDrawer.ProjectTeamContent.editTeams",
                "edit project's teams"
              )}
            </Button>
          </DrawerTrigger>
        </ProjectTeamsDrawer>
      </Stack>
    </Box>
  );
}

export { Header };
