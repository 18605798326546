import type {
  Auth0ContextInterface,
  LogoutUrlOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-react";
import { useAuth0 as webUseAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import React, { useState } from "react";
import {
  buildNativeAuthorizeUrlFactory,
  buildNativeLogoutUrlFactory,
  canUseNative,
  getNativeAppRedirectUri,
} from "./utils";

// Be careful when tweeking this code, there is many things who rely on it on mobile side
// If you do any changes make sure to test the following path in both Android AND iOS
// - Uninstall app,, install and login from scratch
// - Logout
// - Put the app in background, then go back in it, multiples times
// - Clean the phone processes, then go back in the app
// - Reboot the phone, then go back in the app
// - Close the browser window of the "login page" instead of login in
// - Login
// - Put the app in background, then go back in it, multiples times
// - Clean the phone processes, then go back in the app
// - Reboot the phone, then go back in the app
// All of those cases manage memory and resuming in a slightly different manner
// so you want to make sure you don't have weird behaviors in any of those scenarios
function useNativeUseAuth0() {
  const platform = Capacitor.getPlatform();
  const defaultUseAuth0 = webUseAuth0();
  // We need to define our own isLoading to set it true when the login
  // page is presented in another browser.
  // Without that, we'll get race conditions between our "app authentication"
  // synchronous logic and our "async external Browser login" logic
  const [isLoading, setIsLoading] = useState(defaultUseAuth0.isLoading);

  const nativeUseAuth0: Auth0ContextInterface = {
    ...defaultUseAuth0,
    buildAuthorizeUrl: buildNativeAuthorizeUrlFactory(
      defaultUseAuth0.buildAuthorizeUrl
    ),
    buildLogoutUrl: buildNativeLogoutUrlFactory(defaultUseAuth0.buildLogoutUrl),
    isLoading,
  };

  // Still, we want to keep OUR isLoading in sync with the one of auth0 sdk
  // this will for instance avoid to consider the user logged out when
  // auth0 silently get back a refresh token
  React.useEffect(() => {
    setIsLoading(defaultUseAuth0.isLoading);
  }, [defaultUseAuth0.isLoading]);

  const handleRedirectCallback = React.useCallback(
    async (url?: string | undefined) => {
      const result = await defaultUseAuth0.handleRedirectCallback(url);
      setIsLoading(false);
      // We need to close the browser, on ios only, on android it'll be done automatically
      if (platform === "ios") {
        await Browser.close();
      }
      return result;
    },
    [defaultUseAuth0.handleRedirectCallback, setIsLoading]
  );

  const openLoginPage = React.useCallback(
    async (opts: RedirectLoginOptions) => {
      const url = await nativeUseAuth0.buildAuthorizeUrl(opts);

      // If the login page is closed without any login, this will catch it,
      // set the isLogin to false which will re-trigger the "login prompt" logic
      // on iOS this won't work since clicking the "OK" button on safari page doesn't
      // trigger any event on capacitor. So the app will be in infinite loading state forever
      // it is less impactful than on android since closing and re-opening the app will fix the bug
      // where on android, the app state will be preserved very long
      if (platform === "android") {
        const listener = await Browser.addListener("browserFinished", () => {
          void listener.remove().finally(() => {
            setIsLoading(false);
          });
        });
      }
      // Once the "external login page" show up, we set the isLoading to true
      setIsLoading(true);
      try {
        // Closing all previously opened browsers on ios
        try {
          await Browser.close();
          // eslint-disable-next-line no-empty
        } catch (_) {}
        await Browser.open({ url });
      } catch (e) {
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );

  const openLogoutPage = React.useCallback(
    async (opts: LogoutUrlOptions) => {
      const url = nativeUseAuth0.buildLogoutUrl({
        ...opts,
        returnTo: `${getNativeAppRedirectUri("/logout")}`,
      });

      if (platform === "ios") {
        // We want to catch when the logout page is loaded
        const listener = await Browser.addListener("browserPageLoaded", () => {
          // Remove the listener as we want a one shot event
          void listener.remove().finally(() => {
            // Close the current external "logout" page
            void Browser.close().finally(() => {
              // Logout the user locally in the app using auth0 SDK
              setIsLoading(false);
            });
          });
        });
      }

      if (platform === "android") {
        // On Android we need to listen on the "browserFinished" event because the
        // browserPageLoaded doesn't fire as it should, it means that we don't have
        // to close the browser manually as android does it for us
        const listener = await Browser.addListener("browserFinished", () => {
          void listener.remove().finally(() => {
            setIsLoading(false);
          });
        });
      }

      setIsLoading(true);
      try {
        // Closing all previously opened browsers on ios
        try {
          await Browser.close();
          // eslint-disable-next-line no-empty
        } catch (_) {}
        await Browser.open({ url });
      } catch (e) {
        // Do nothing
      } finally {
        defaultUseAuth0.logout({ localOnly: true });
        setIsLoading(false);
      }
    },
    [setIsLoading, defaultUseAuth0.isAuthenticated]
  );

  return {
    ...nativeUseAuth0,
    handleRedirectCallback,
    isAuthenticated: isLoading ? false : defaultUseAuth0.isAuthenticated,
    isLoading,
    loginWithRedirect: openLoginPage,
    logout: openLogoutPage,
  };
}

const useAuth0 = <
  TUser extends User = User
>(): Auth0ContextInterface<TUser> => {
  if (canUseNative() === false) {
    return webUseAuth0();
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useNativeUseAuth0() as Auth0ContextInterface<TUser>;
};

type UserPropsAuth0 = {
  impersonation?: {
    "impersonator-email": string;
    /* The impersonator Clovis id */
    "impersonator-id": string;
    "usurped-user-email": string;
    /* The usurped user Auth0 id */
    "usurped-user-sub": string;
  };
  /* Account linking exist when the user is connected with microsoft sso and have an existing account auth0 */
  account_linking?: {
    "primary-account-email": string;
    /* The primary account Auth0 id (connection Auth0) */
    "primary-account-sub": string;
  };
  "https://hasura.io/jwt/claims": {
    "x-hasura-allowed-roles": string[];
    "x-hasura-default-role": string;
    "x-hasura-user-email": string;
    "x-hasura-user-id": string;
  };
  iss: string;
  /* the sub is the user identifier, meaning "subject", often in the structure of "auth0|12345678..." with is the Auth0 id */
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  scope: string;
  azp: string;
};

function getAuth0Id(user: UserPropsAuth0 | undefined) {
  return (
    user?.impersonation?.["usurped-user-sub"] ??
    user?.account_linking?.["primary-account-sub"] ??
    user?.sub
  );
}

export { canUseNative, getAuth0Id, getNativeAppRedirectUri, useAuth0 };
export type { UserPropsAuth0 };
