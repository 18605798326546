import * as React from "react";
import { Link } from "react-router-dom";
import { Box } from "~/design-system/Box";
import { Column, Columns } from "~/design-system/Columns";

type SettingsDrawerContentItemProps = {
  avatar: React.ReactNode;
  title: React.ReactNode;
  notif: React.ReactNode;
  toRoute?: string;
  href?: string;
  onClick: () => void;
  actionId?: string;
};

const SettingsDrawerContentItem = (props: SettingsDrawerContentItemProps) => {
  const item = (
    <Box
      display="flex"
      alignItems="center"
      padding="small"
      cursor="pointer"
      css={{
        "&:focus, &:hover": {
          backgroundColor: "$blueGray700",
          outline: "none",
        },
        fontSize: "0.875rem",
        height: "$space$xxlarge",
        lineHeight: "1.25rem",
        paddingRight: "1.25rem",
      }}
    >
      <Columns space="small" alignY="center">
        <Column width="content">
          <Box
            display="flex"
            justifyContent="center"
            css={{ width: "$space$xlarge" }}
          >
            {props.avatar}
          </Box>
        </Column>
        <Column>{props.title}</Column>
        <Column width="content" align="right">
          {props.notif}
        </Column>
      </Columns>
    </Box>
  );

  if (props.toRoute) {
    return (
      <Link
        onClick={props.onClick}
        to={props.toRoute}
        data-intercom-target={props.actionId}
      >
        {item}
      </Link>
    );
  }

  if (props.href) {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        data-intercom-target={props.actionId}
      >
        {item}
      </a>
    );
  }

  return (
    <Box onClick={props.onClick} data-intercom-target={props.actionId}>
      {item}
    </Box>
  );
};

export { SettingsDrawerContentItem };
