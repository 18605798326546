import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Button } from "~/design-system/Button";
import { DialogTrigger } from "~/design-system/Dialog";
import { DrawerTrigger } from "~/design-system/Drawer";
import { EditUserDialog } from "~/screens/App/screens/Project/Info/screens/ProjectMembersScreen/components/ProjectMembersList/components/ProjectMembersListItemActions/components/EditUserDialog/EditUserDialog";
import { ProjectTeamDrawer } from "../../screens/Project/components/ProjectTeamDrawer/ProjectTeamDrawer";
import type { Member } from "../../screens/Project/Info/screens/ProjectMembersScreen/components/ProjectMembersList/ProjectMemberList.types";
import { TeamBadge } from "./TeamBadge";

type TeamBadgeButtonProps = Pick<
  BadgeProps,
  "children" | "color" | "onRemove" | "size" | "truncate"
> & {
  teamId: string;
  onCloseDrawer?: () => void;
};

const TeamBadgeButton = (props: TeamBadgeButtonProps) => {
  const { onCloseDrawer, teamId, ...rest } = props;

  return (
    <ProjectTeamDrawer teamId={teamId} onClose={onCloseDrawer}>
      <DrawerTrigger asChild>
        <Button
          variant="text"
          css={{
            display: "inline-flex",
            maxWidth: "100%",
            minHeight: "fit-content",
          }}
          dataIntercomTarget="badges-team-open"
        >
          <TeamBadge clickable {...rest} />
        </Button>
      </DrawerTrigger>
    </ProjectTeamDrawer>
  );
};

const EditUserTeamBadgeButton = (
  props: TeamBadgeButtonProps & { member: Member }
) => {
  const { member, ...rest } = props;

  return (
    <EditUserDialog member={member}>
      <DialogTrigger asChild>
        <Button
          variant="text"
          css={{
            display: "inline-flex",
            maxWidth: "100%",
            minHeight: "fit-content",
          }}
          dataIntercomTarget="badges-team-edit-user"
        >
          <TeamBadge clickable {...rest} />
        </Button>
      </DialogTrigger>
    </EditUserDialog>
  );
};

export { EditUserTeamBadgeButton, TeamBadgeButton };
