import type { INotification } from "@clovis/server/src/app/domain/notification/types";
import { NotificationNamespace } from "@clovis/server/src/app/domain/notification/types";

interface IOrg {
  id: string;
}

interface IOrgNotificationPayload {
  org: IOrg;
}

const IOrgNotificationTypePrefix = `${NotificationNamespace.Org}` as const;
// All Chat notifications must extend from this interface rien qu
interface IOrgNotification extends INotification {
  type: `${typeof IOrgNotificationTypePrefix}/${string}`;
  readonly payload: IOrgNotificationPayload;
}

export type { IOrgNotification, IOrgNotificationPayload };
export { IOrgNotificationTypePrefix };
