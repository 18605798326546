import * as React from "react";
import create from "zustand";
import createContext from "zustand/context";
import type { Tasks_Bool_Exp } from "~/schema.graphql";
import type { TaskFiltersFormType } from "~/screens/App/screens/Project/Tasks/components/TaskFiltersDialog/components/TaskFiltersForm/TaskFiltersForm";

type TaskFilterData = {
  graphqlFilters: Tasks_Bool_Exp | undefined;
  formFilters: TaskFiltersFormType | undefined;
};

type TaskFilterState = {
  projectFilters: Record<string, TaskFilterData | undefined>;
  setProjectFilter: (
    projectId: string,
    filter: TaskFilterState["projectFilters"][string]
  ) => void;
  tasksFilteredCount?: number;
  setTasksFilteredCount: (count: number) => void;
  tasksCount?: number;
  setTasksCount: (count: number) => void;
};

const { Provider, useStore: useTaskFilterContext } =
  createContext<TaskFilterState>();

type TaskFilterProviderProps = {
  projectFilters?: TaskFilterState["projectFilters"];
  children: React.ReactNode;
};

function TaskFilterProvider(props: TaskFilterProviderProps) {
  return (
    <Provider
      createStore={() =>
        create<TaskFilterState>((set) => ({
          projectFilters: props.projectFilters ?? {},
          setProjectFilter: (projectId, filter) =>
            set((state) => ({
              ...state,
              projectFilters: { ...state.projectFilters, [projectId]: filter },
              tasksFilteredCount: undefined,
            })),
          setTasksCount: (count) =>
            set((state) => ({
              ...state,
              tasksCount: count,
            })),
          setTasksFilteredCount: (count) =>
            set((state) => ({
              ...state,
              tasksFilteredCount: count,
            })),
        }))
      }
    >
      {props.children}
    </Provider>
  );
}

export type { TaskFilterData, TaskFilterProviderProps };
export { TaskFilterProvider, useTaskFilterContext };
