import type { Color } from "@clovis/server/src/app/config/colors/colors";
import { colorToHexValues } from "@clovis/server/src/app/config/colors/colors";
import { PencilIcon } from "@heroicons/react/outline";
import * as React from "react";
import { AllowedTo } from "react-abac";
import { Dot } from "~/design-system/Badge";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { Text } from "~/design-system/Text";
import { Permission } from "~/screens/App/screens/Org/Org.authorize";
import type { GroupsList_ItemFragment } from "./UpdateGroupForm/UpdateGroupForm.graphql";

type InfosProps = {
  group: GroupsList_ItemFragment;
  onEditClick: () => void;
};

function Infos(props: InfosProps) {
  const color = colorToHexValues.get(props.group.color as Color)!.base;

  return (
    <Columns space="small">
      <Column>
        <Box display="flex" alignItems="center" height="full">
          <Dot size="large" css={{ color }} />
          <Text css={{ color }}>{props.group.name}</Text>
        </Box>
      </Column>
      <Column width="content">
        <AllowedTo perform={Permission.UpdateProjectGroup}>
          <Button
            type="button"
            variant="transparent"
            circular
            onClick={props.onEditClick}
            dataIntercomTarget="org-project-categories-edit"
          >
            <PencilIcon />
          </Button>
        </AllowedTo>
      </Column>
    </Columns>
  );
}

export { Infos };
