import type * as Stitches from "@stitches/react";
import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

type DotWrapperProps = Stitches.ComponentProps<typeof DotWrapper>;

type DotProps = Pick<DotWrapperProps, "shape" | "size"> & {
  css?: CSS;
};

const DotWrapper = styled("svg", {
  ...tw`mr-1.5`,

  compoundVariants: [
    {
      css: tw`-ml-0.5`,
      shape: "circular",
      size: "medium",
    },
    {
      css: tw`-ml-1`,
      shape: "circular",
      size: "large",
    },
    {
      css: tw`mr-1.5`,
      shape: "rounded",
      size: "medium",
    },
    {
      css: tw`-ml-0.5 mr-1.5`,
      shape: "rounded",
      size: "large",
    },
  ],

  defaultVariants: {
    size: "medium",
  },

  variants: {
    shape: {
      circular: tw``,
      rightRounded: tw``,
      rounded: tw``,
    },
    size: {
      large: tw`h-3 w-3`,
      medium: tw`h-2 w-2`,
    },
  },
});

function Dot(props: DotProps) {
  return (
    <DotWrapper {...props} fill="currentColor" viewBox="0 0 8 8">
      <circle cx={4} cy={4} r={3} />
    </DotWrapper>
  );
}

export { Dot };
