import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/design-system/Button";
import { Inline } from "~/design-system/Inline";
import { createComponentHook } from "~/types";

const useActions = createComponentHook(() => {
  const { t } = useTranslation();

  const handleCallClick = () => {
    console.info("handleCallClick");
  };
  const handleMessageClick = () => {
    console.info("handleMessageClick");
  };

  return {
    actions: {
      handleCallClick,
      handleMessageClick,
    },
    t,
  };
});

type ActionsProps = {
  userPhone?: string | null;
};

export function Actions(props: ActionsProps) {
  const { actions, t } = useActions();

  return (
    <Inline space="small">
      {/* HIDE FOR NOW
       <Button
        tone="brandAccent"
        variant="solid"
        onClick={actions.handleMessageClick}
      >
        {t("components.UserProfileDrawer.Actions.message", "Message")}
      </Button> */}
      {props.userPhone && (
        <Button
          variant="ghost"
          as="a"
          href={`tel:${props.userPhone}`}
          dataIntercomTarget="user-profile-call"
        >
          {t("components.UserProfileDrawer.Actions.call", "Call")}
        </Button>
      )}
    </Inline>
  );
}
