import * as React from "react";
import { createComponentHook } from "~/types";
import { useNavigationDrawerStore } from "../../utils/useNavigationDrawerStore";
import { ProjectListContentByGroup } from "./components/ProjectListContentByGroup";
import { ProjectListHeaderByGroup } from "./components/ProjectListHeaderByGroup";
import type { ProjectNavigationDrawer } from "./ProjectListMenu";

type ProjectListByGroupProps = {
  projectListByGroup: ProjectListByGroup[];
  activeProjectId: string | undefined;
};

type ProjectListByGroup = {
  projects: ProjectNavigationDrawer[];
  color: string;
  name: string;
  avatar?: string | null | undefined;
};

const useProjectListByGroup = createComponentHook(() => {
  const { groupIndexContext, setGroupIndexContext } =
    useNavigationDrawerStore();

  const [indexContent, setIndexContent] = React.useState<number | null>(
    groupIndexContext ?? null
  );

  const handleIndexContent = (index: number | null) => {
    setIndexContent(index);
    setGroupIndexContext(index);
  };
  return {
    actions: { handleIndexContent, setIndexContent },
    state: { indexContent },
  };
});

function ProjectListByGroupMenu(props: ProjectListByGroupProps) {
  const { actions, state } = useProjectListByGroup();
  return (
    <>
      {state.indexContent === null && (
        <ProjectListHeaderByGroup
          projectListByGroup={props.projectListByGroup}
          onClick={actions.handleIndexContent}
        />
      )}

      {props.projectListByGroup.map((projectList, index) =>
        state.indexContent === index ? (
          <ProjectListContentByGroup
            projectListByGroup={projectList}
            key={index}
            activeProjectId={props.activeProjectId}
            goBack={() => actions.handleIndexContent(null)}
          />
        ) : null
      )}
    </>
  );
}
export type { ProjectListByGroup };
export { ProjectListByGroupMenu };
