import type { Prisma } from "@clovis/server/src/app/config/prisma";

// eslint-disable-next-line @typescript-eslint/ban-types

enum NotificationNamespace {
  Org = "ORG",
  Project = "PROJECT",
  Chat = "CHAT",
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface INotification<T extends Prisma.InputJsonValue = {}> {
  readonly type: `${NotificationNamespace}/${string}`;
  readonly creator_id: string;
  readonly recipient_id: string;
  readonly event_id: string | null;
  readonly is_strong: boolean;
  readonly read_at?: Date;
  readonly payload?: T;
}

export type { INotification };
export { NotificationNamespace };
