import * as React from "react";
import { useAbac } from "react-abac";
import { useTranslation } from "react-i18next";
import { getUserProjectRolesOptions } from "~/config/users-rights-permissions/project";
import type { SelectFieldProps } from "~/design-system/SelectField";
import { SelectField } from "~/design-system/SelectField";
import type { Role } from "~/screens/App/screens/Project/Project.authorize";
import { Permission } from "~/screens/App/screens/Project/Project.authorize";
import { createComponentHook } from "~/types";

type ProjectRightsSelectFieldInputProps = Omit<SelectFieldProps, "children">;

const useProjectRightsSelectFieldInput = createComponentHook(() => {
  const { t } = useTranslation();
  const userRightsOptions = getUserProjectRolesOptions(t);
  const { userHasPermissions } = useAbac();
  const isDisabled = (value: Role) => {
    return !userHasPermissions(Permission.UpdateProjectRole, value);
  };

  return {
    actions: {
      isDisabled,
    },
    state: {
      userRightsOptions,
    },
    t,
  };
});

const ProjectRightsSelectFieldInput = React.forwardRef<
  HTMLSelectElement,
  ProjectRightsSelectFieldInputProps
>(function ProjectRightsSelectFieldInput(props, ref) {
  const { actions, state } = useProjectRightsSelectFieldInput();
  return (
    <SelectField {...props} ref={ref}>
      {state.userRightsOptions.map(({ name, value }) => (
        <option key={value} value={value} disabled={actions.isDisabled(value)}>
          {name}
        </option>
      ))}
    </SelectField>
  );
});

export { ProjectRightsSelectFieldInput };
