import type { WithAuthenticationRequiredOptions } from "@auth0/auth0-react";
import { withAuthenticationRequired as webAuthenticationRequired } from "@auth0/auth0-react";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import * as React from "react";
import { useLocation } from "react-router";
import { DefaultOnRedirecting } from "./DefaultOnRedirecting";
import { canUseNative, useAuth0 } from "./use-auth0";

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

/** A callback function listening for IDP's callback */
let resolveCallback: ((url: string) => void) | null = null;

void App.addListener("appUrlOpen", ({ url }) => {
  if (url.includes("/capacitor")) {
    return resolveCallback?.(url);
  }
});

const nativeAuthenticationRequired = <P extends object>(
  Component: React.ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): React.FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      handleRedirectCallback,
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      logout,
      user,
    } = useAuth0();
    const {
      returnTo = defaultReturnTo,
      loginOptions = {},
      claimCheck = (): boolean => true,
    } = options;
    // We need to have a separate logic during redirection to avoid
    // weird login showing up multiples times bug on android
    const [isRedirecting, setIsRedirecting] = React.useState(false);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const login_hint = urlParams.get("login_hint") ?? undefined;
    const screen_hint = urlParams.get("screen_hint") ?? undefined;

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = isAuthenticated && claimCheck(user);

    const handleLoginWithRedirect = () => {
      const opts = {
        ...loginOptions,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
        },
        login_hint,
        screen_hint,
      };
      // On mobile, our custom useAuth0 will make sure "loginWithRedirect" open an external browser
      void loginWithRedirect(opts);
    };

    const handleRetryClicked = React.useCallback(() => {
      void Browser.removeAllListeners().finally(() => {
        void Browser.close().catch(() => undefined);
        void handleLoginWithRedirect();
      });
    }, []);

    React.useEffect(() => {
      if (isLoading || isRedirecting || routeIsAuthenticated) {
        return;
      } else {
        handleLoginWithRedirect();
      }
    }, [isLoading, isRedirecting, routeIsAuthenticated]);

    const handleAppUrlOpen = React.useCallback(
      (url: string) => {
        // The redirect for logout callback will be handled inside logout function
        if (url.includes("/callback/logout")) {
          return;
        }
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          // We need to logout the user manually if there is an error in the login process to avoid infinite login loop
          if (url.includes("error")) {
            logout();
          } else {
            setIsRedirecting(true);
            void handleRedirectCallback(url).finally(() =>
              setIsRedirecting(false)
            );
          }
        } else {
          // close methode is not implemented on android but it doesn't matter
          void Browser.close().catch(() => undefined);
        }
      },
      [setIsRedirecting, logout]
    );

    React.useEffect(() => {
      resolveCallback = handleAppUrlOpen;
      return () => {
        resolveCallback = null;
      };
    }, [handleAppUrlOpen]);

    return routeIsAuthenticated ? (
      <Component {...props} />
    ) : (
      <DefaultOnRedirecting onRetryClick={handleRetryClicked} />
    );
  };
};

const withAuthenticationRequired = <P extends object>(
  Component: React.ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): React.FC<P> => {
  if (canUseNative() === false) {
    return webAuthenticationRequired(Component, options);
  }
  return nativeAuthenticationRequired(Component, options);
};

export { withAuthenticationRequired };
