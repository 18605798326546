import * as DialogPrimitive from "@radix-ui/react-dialog";
import type * as Stitches from "@stitches/react";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { useUser } from "~/config/user/UserProvider";
import { SafeArea } from "../SafeArea";

const StyledOverlay = styled(motion.div, {
  backgroundColor: "rgba(0, 0, 0, .15)",
  bottom: 0,
  display: "flex",
  left: 0,
  position: "fixed",
  right: 0,
  top: 0,
  zIndex: 9990,
});

const StyledContent = styled(motion.div, {
  ...{
    "&:focus": {
      outline: "none",
    },
    zIndex: 9990,
  },

  defaultVariants: {
    size: "regular",
  },

  variants: {
    size: {
      full: {
        width: "100%",
      },
      regular: {
        margin: "auto",
        minWidth: 200,
        position: "relative",
        width: "100%",
      },
    },
    width: {
      large: {
        maxWidth: "$screen-lg",
      },
      medium: {
        maxWidth: "$screen-md",
      },
      small: {
        maxWidth: "$screen-sm",
      },
    },
  },
});

const StyledContentContainer = styled("div", {
  display: "flex",
  height: "100vh",
  position: "fixed",
  top: 0,
  width: "100vw",
  zIndex: 9990,
});

const OpenContext = React.createContext(false);

type UncontrolledDialogProps = {
  children: React.ReactNode;
  open?: never;
  setOpen?: never;
};

type ControlledDialogProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DialogRoot = (props: ControlledDialogProps) => (
  <OpenContext.Provider value={props.open}>
    <DialogPrimitive.Root open={props.open} onOpenChange={props.setOpen}>
      {props.children}
    </DialogPrimitive.Root>
  </OpenContext.Provider>
);

type DialogProps = ControlledDialogProps | UncontrolledDialogProps;

// Some rules about how to use popup for a good UX: https://www.nngroup.com/articles/popups/
const Dialog = (props: DialogProps) => {
  const [open, setOpen] = React.useState(false);

  if (props.open !== undefined) {
    return <DialogRoot {...props} />;
  }

  return <DialogRoot open={open} setOpen={setOpen} {...props} />;
};

const DialogTrigger = DialogPrimitive.Trigger;
const DialogClose = DialogPrimitive.Close;

type DialogContentProps = Stitches.VariantProps<typeof StyledContent> & {
  children: React.ReactNode;
  css?: CSS;
  // You want to use this option when your DialogContent is in the
  // subtree of another element with an onClick listener on it, or to disable overlay blur close
  stopClickPropagation?: boolean;
  /* stopFocusPropagation is needed to avoid "too much recursion" errors because of "grabbing focus loop"
   it happens for instance when adding a task while in annotation mode  */
  stopFocusPropagation?: boolean;
  preventCloseOnClickOutside?: boolean;
  onPointerDownOutside?: () => void;
  /* by default is set to true, but not needed for some 
  specific dialog which got their own safe area logic */
  safeAreaTop?: boolean;
  safeAreaBottom?: boolean;
};

const stopClickPropagationHandler = (event: React.MouseEvent<unknown>) => {
  event.stopPropagation();
};

const stopFocusPropagationHandler = (event: React.FocusEvent<unknown>) => {
  event.stopPropagation();
};

/* To help our team to create intercom tooltips and guides, we need to disable the close on overlay click
we tried using use-keyboard-shortcut but it doesn' work well as a hook and many render  */
const isClovisTeamIntercomUser = (full_name: string) => {
  return full_name.includes("clovis-intercom");
};

const DialogContent = (props: DialogContentProps) => {
  const open = React.useContext(OpenContext);
  const { safeAreaBottom = true, safeAreaTop = true } = props;
  const { user } = useUser();

  const initialCloseOnOverlayClick =
    props.preventCloseOnClickOutside ||
    (user.full_name && isClovisTeamIntercomUser(user.full_name));

  return (
    <AnimatePresence>
      {open && (
        <DialogPrimitive.Portal forceMount>
          <DialogPrimitive.Overlay forceMount asChild>
            <StyledOverlay
              onClick={
                initialCloseOnOverlayClick || props.stopClickPropagation
                  ? stopClickPropagationHandler
                  : undefined
              }
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
            >
              {/* We need to put the Content part into the Overlay part to fix scroll issue (See https://github.com/radix-ui/primitives/issues/1159) */}
              <StyledContentContainer>
                <DialogPrimitive.Content
                  asChild
                  forceMount
                  onPointerDownOutside={(e) => {
                    props.onPointerDownOutside?.();
                    initialCloseOnOverlayClick && e.preventDefault();
                  }}
                  onClick={
                    props.stopClickPropagation
                      ? stopClickPropagationHandler
                      : undefined
                  }
                  onFocus={
                    props.stopFocusPropagation
                      ? stopFocusPropagationHandler
                      : undefined
                  }
                >
                  <StyledContent
                    size={props.size}
                    width={props.width}
                    css={props.css}
                    initial={{ opacity: 0, transform: "scale(.90)" }}
                    animate={{ opacity: 1, transform: "scale(1)" }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0, transform: "scale(.90)" }}
                  >
                    {safeAreaTop && props.size === "full" && (
                      <SafeArea top css={{ backgroundColor: "white" }} />
                    )}
                    {props.children}
                    {safeAreaBottom && props.size === "full" && (
                      <SafeArea bottom css={{ backgroundColor: "white" }} />
                    )}
                  </StyledContent>
                </DialogPrimitive.Content>
              </StyledContentContainer>
            </StyledOverlay>
          </DialogPrimitive.Overlay>
        </DialogPrimitive.Portal>
      )}
    </AnimatePresence>
  );
};

export type { ControlledDialogProps, DialogContentProps, DialogProps };
export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  isClovisTeamIntercomUser,
  OpenContext,
};
