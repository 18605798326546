import tw from "twin.macro";
import { styled } from "~/config/stitches";

const FieldMessage = styled("p", {
  variants: {
    tone: {
      critical: tw`mt-2 text-sm text-critical-600`,
      neutral: tw`mt-2 text-sm text-neutral-600`,
      positive: tw`mt-2 text-sm text-positive-600`,
    },
  },
});

export { FieldMessage };
