import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { AvatarPlaceholder } from "./components/AvatarPlaceholder";
import { BaseImgAvatar } from "./components/BaseImgAvatar";
import { BaseSpanAvatar } from "./components/BaseSpanAvatar";
import { BoringAvatar } from "./components/BoringAvatar";
import { InitialsWrapper } from "./components/InitialsWrapper";
import { Notification } from "./components/Notification";
import { Spinner } from "./components/Spinner";

type AvatarProps = {
  src?: string;
  alt?: string;
  withPlaceholder?: boolean;
  transparent?: boolean;
  withText?: boolean;
  bottomNotification?: boolean;
  topNotification?: boolean;
  notification?: React.ReactNode;
  ring?:
    | "caution"
    | "critical"
    | "info"
    | "lightWhite"
    | "neutral"
    | "positive"
    | "white";
  size?: "large" | "medium" | "small" | "xlarge" | "xsmall" | "xxsmall";
  shape?: "circular" | "rounded";
  notificationTone?: "critical" | "neutral" | "positive";
  notificationAlign?: "left" | "right";
  children?: React.ReactNode;
  zIndex?: number;
  noInitials?: boolean;
  css?: CSS;
  numberNotification?: number;
  withBorder?: boolean;
  isLoading?: boolean;
};

const AvatarWrapper = styled("span", {
  ...tw`inline-flex relative`,

  variants: {
    ring: {
      caution: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-caution-500`,
      },
      critical: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-critical-500`,
      },
      info: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-info-500`,
      },
      lightWhite: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-white/70`,
      },
      neutral: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-neutral-500`,
      },
      positive: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-positive-500`,
      },
      white: {
        "& > span:first-child, & > div > svg, & > div > div": tw`ring-2 ring-white`,
      },
    },
    transparent: {
      false: tw``,
      true: tw`opacity-50`,
    },
  },
});

const Avatar = (props: AvatarProps) => {
  const {
    bottomNotification,
    css,
    noInitials,
    notificationAlign,
    notificationTone,
    numberNotification,
    ring,
    topNotification,
    transparent,
    withText,
    zIndex,
    ...restProps
  } = props;
  const hasNotif = Boolean(topNotification) || Boolean(bottomNotification);
  const useSpanAvatar = Boolean(props.withPlaceholder) || Boolean(withText);
  const words = props.children?.toString().split(" ");
  let initials;
  if (words?.length === 1) {
    initials = words[0].slice(0, 2).toUpperCase();
  } else if (words?.length) {
    initials = words
      .map((w) => w.slice(0, 1))
      .join("")
      .slice(0, 2)
      .toUpperCase();
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <AvatarWrapper
      css={{ zIndex: zIndex, ...css } as CSS}
      // When the avatar is loading the loader will take the space normaly allocated to the ring
      ring={props.isLoading ? undefined : ring}
      transparent={transparent}
    >
      {useSpanAvatar ? (
        <BaseSpanAvatar {...restProps} withInitials={Boolean(withText)}>
          {props.withPlaceholder && <AvatarPlaceholder />}
          {withText && (
            <InitialsWrapper size={props.size}>
              {noInitials ? props.children : initials}
            </InitialsWrapper>
          )}
        </BaseSpanAvatar>
      ) : !props.src ? (
        <BoringAvatar
          shape={props.shape}
          alt={props.alt}
          size={props.size}
          withBorder={props.withBorder}
        >
          {props.children}
        </BoringAvatar>
      ) : (
        <BaseImgAvatar
          src={props.src}
          alt={props.alt}
          size={props.size}
          {...restProps}
        />
      )}
      {hasNotif && (
        <Notification
          notificationAlign={notificationAlign}
          size={props.size}
          notificationTone={notificationTone}
          bottomNotification={bottomNotification}
          topNotification={topNotification}
          shape={props.shape}
          numberNotification={numberNotification ? true : false}
        >
          {numberNotification}
        </Notification>
      )}
      {props.notification}
      {props.isLoading && <Spinner size={props.size} ring={props.ring} />}
    </AvatarWrapper>
  );
};

export { Avatar };
export type { AvatarProps };
