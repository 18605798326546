import type { FallbackRender } from "@sentry/react";
import * as React from "react";
import { ErrorPage } from "../Error/Error";

type ItemWithExpirancy = {
  value: string;
  expiry: number;
};

function setWithExpiry(key: string, value: string, ttl: number) {
  const item: ItemWithExpirancy = {
    expiry: new Date().getTime() + ttl,
    value: value,
  };

  window.localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString) as ItemWithExpirancy;
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    window.localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

// We only search parts of the message as the full message differ from browser to browser
// this one should be common between Chrome/Firefox/Safari
const MODULE_MISSMATCHES_MESSAGES = [
  "dynamically imported",
  "load module",
  "Loading chunk",
  "is not a valid JavaScript MIME type",
];

const ErrorFallback: FallbackRender = (props) => {
  // Handle failed lazy loading of a JS/CSS chunk
  // it'll reload the app if there is some file which cannot be found
  // because of new version being push with a different hash on the server
  // See: https://mitchgavan.com/code-splitting-react-safely/

  const errorObject = props.error as Error;

  if (errorObject?.message) {
    const isModuleMissmatchesError = MODULE_MISSMATCHES_MESSAGES.some(
      (m) => errorObject.message.indexOf(m) > -1
    );
    if (isModuleMissmatchesError) {
      // We use localstorage with expiry custom function to avoid infinite reloading
      // if the file is just really not present on the server
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();

        return <div>Clovis is reloaded for update</div>;
      }
    }
  }

  return <ErrorPage errorObject={errorObject} />;
};

export { ErrorFallback };
