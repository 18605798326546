import * as React from "react";
import type { FieldProps } from "../Field";
import { Field } from "../Field";
import { Tones } from "../tokens";
import { SelectFieldInput } from "./components/SelectFieldInput";

type SelectFieldProps = FieldProps & React.HTMLAttributes<HTMLSelectElement>;

const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
  function SelectField(props: SelectFieldProps, ref) {
    const {
      cornerHint,
      dataIntercomTarget,
      error,
      label,
      message,
      name,
      required,
      rootProps,
      secondaryLabel,
      ...rest
    } = props;

    const tone = props.error ? Tones.critical : props.tone;

    const fieldProps: Omit<FieldProps, "children"> = {
      cornerHint,
      dataIntercomTarget,
      error,
      label,
      message,
      name,
      required,
      rootProps,
      secondaryLabel,
      tone,
    };

    const selectProps = {
      ...rest,
      id: name,
      name,
      required,
      tone,
    };

    return (
      <Field {...fieldProps}>
        <SelectFieldInput ref={ref} {...selectProps} />
      </Field>
    );
  }
);

export type { SelectFieldProps };
export { SelectField };
