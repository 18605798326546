import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { captureException } from "~/config/sentry";

class SecureCache {
  async get<T = any>(key: string): Promise<T | null> {
    try {
      const cacheResult = await SecureStoragePlugin.get({ key });
      return JSON.parse(cacheResult.value) as T;
    } catch (e) {
      console.warn(e, { extra: { key } });
      return null;
    }
  }

  async set<T = any>(key: string, entry: T): Promise<void> {
    try {
      await SecureStoragePlugin.set({ key, value: JSON.stringify(entry) });
    } catch (e) {
      captureException(e, { extra: { entry, key } });
    }
  }

  async remove(key: string): Promise<void> {
    try {
      await SecureStoragePlugin.remove({ key });
    } catch (e) {
      captureException(e, { extra: { key } });
    }
  }

  async allKeys(): Promise<string[]> {
    try {
      const allKeys = await SecureStoragePlugin.keys();
      return allKeys.value;
    } catch (e) {
      captureException(e);
      return [];
    }
  }
}

export { SecureCache };
