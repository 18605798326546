import XIcon from "@heroicons/react/outline/XIcon";
import * as React from "react";
import type { ButtonProps } from "~/design-system/Button";
import { Button } from "~/design-system/Button";

const CloseButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    props: Pick<ButtonProps, "autoFocus" | "dataIntercomTarget" | "onClick">,
    ref
  ) => {
    return (
      <Button
        circular={true}
        variant="ghost"
        iconLabel="dismiss"
        {...props}
        ref={ref}
      >
        <XIcon />
      </Button>
    );
  }
);

export { CloseButton };
