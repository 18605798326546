import tw from "twin.macro";
import { styled } from "~/config/stitches";

const TextArea = styled("textarea", {
  ...tw`
  text-base
  shadow-sm
  focus:(ring-slate-400 border-slate-400)
  block
  w-full
  border-gray-300
  rounded-md`,

  variants: {
    readOnly: {
      true: tw`bg-gray-200 text-gray-400 
            cursor-default focus:(ring-0 border-gray-300)`,
    },
    tone: {
      critical: tw`
        border-red-300 text-red-900 placeholder-red-300
        focus:(outline-none ring-red-500 border-red-500)
      `,
      neutral: tw``,
      positive: tw``,
    },
    withLeadingIcon: {
      true: tw`pl-10`,
    },
    withTrailingIcon: {
      true: tw`pr-10`,
    },
  },
});

export { TextArea };
