import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import { isOrgNotification } from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterOrgBadge(notification: UserBadgeNotification, orgId: string) {
  return (
    isOrgNotification(notification) && notification.payload.org.id === orgId
  );
}

const useOrgBadge = createComponentHook((props: OrgBadgeProps) => {
  const notifications = useUserNotificationsContext((state) =>
    state.notifications.filter((n) => filterOrgBadge(n, props.orgId))
  );
  const { strongNotificationsCount, weakNotif } =
    getDisplayNotificationProps(notifications);

  return {
    state: {
      strongNotificationsCount,
      weakNotif,
    },
  };
});

type OrgBadgeProps = { orgId: string };

const OrgBadge = (props: OrgBadgeProps) => {
  const { state } = useOrgBadge(props);

  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { filterOrgBadge, OrgBadge, useOrgBadge };
