import type * as Types from '../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrgProjectSummaryIdQueryVariables = Types.Exact<{
  orgProjectSummaryId: Types.Scalars['uuid'];
}>;


export type GetOrgProjectSummaryIdQuery = { org_project_summary_by_pk?: { id: string, org_id: string, project: { id: string, name: string } } | null | undefined };


export const GetOrgProjectSummaryIdDocument = gql`
    query getOrgProjectSummaryID($orgProjectSummaryId: uuid!) {
  org_project_summary_by_pk(id: $orgProjectSummaryId) {
    id
    org_id
    project {
      id
      name
    }
  }
}
    `;

export function useGetOrgProjectSummaryIdQuery(options: Omit<Urql.UseQueryArgs<GetOrgProjectSummaryIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetOrgProjectSummaryIdQuery>({ query: GetOrgProjectSummaryIdDocument, ...options });
};