import { Slot } from "nano-slots";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { Column, Columns } from "~/design-system/Columns";
import { SafeArea } from "~/design-system/SafeArea";

type AppBarProps = {
  css?: CSS;
};

function AppBar(props: AppBarProps) {
  return (
    <Box css={props.css}>
      <SafeArea top>
        <Columns space="small" alignY="center">
          <Column width="1-5" align="left">
            <Slot name="Layout.Actions" />
          </Column>
          <Column width="3-5" align="center">
            <Slot name="Layout.Tabs" />
          </Column>
          <Column width="1-5" align="right">
            <Slot name="Layout.ExtraActions" />
          </Column>
        </Columns>
      </SafeArea>
    </Box>
  );
}

export { AppBar };
