import type { ProjectRoleId } from "@clovis/server/src/app/domain/project/role";
import { validationSchema } from "@clovis/server/src/app/domain/project/update-project-user-infos/update-project-user-infos.validation";
import { isEqual, uniqBy } from "lodash";
import * as React from "react";
import { useAbac } from "react-abac";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import type { z } from "zod";
import type { CountryCode } from "~/config/i18next/helpers";
import type { CompanyInfosApiSearchResult } from "~/config/papers/types";
import type { UseFormReturn } from "~/config/react-hook-form";
import { useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import { useUser } from "~/config/user/UserProvider";
import { Form } from "~/design-system/Form";
import { useBreakpoint } from "~/design-system/hooks";
import { Stack } from "~/design-system/Stack";
import type {
  UpdateProjectRoleInput,
  UpdateProjectUserInfosInput,
  UpdateUserTeamsInput,
} from "~/schema.graphql";
import { BasicMultiSelectFieldInputOptionType } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { ActionsButtons } from "~/screens/App/screens/Project/components/ProjectsInvitationsDialog/components/ProjectInvitationForm/components/ActionsButtons";
import { ActionsButtonsMobile } from "~/screens/App/screens/Project/components/ProjectsInvitationsDialog/components/ProjectInvitationForm/components/ActionsButtonsMobile";
import { Permission } from "~/screens/App/screens/Project/Project.authorize";
import { createComponentHook } from "~/types";
import type { Member } from "../../../../../../ProjectMemberList.types";
import { AvatarField } from "./components/AvatarField";
import { UserInputs } from "./components/UserInputs";
import {
  useEditUserForm_DataQuery,
  useUpdateProjectRoleMutation,
  useUpdateProjectUserInfosMutation,
  useUpdateUserTeamsMutation,
} from "./EditUserForm.graphql";

type EditUserFormType = UseFormReturn<z.infer<typeof validationSchema>>;

type EditUserFormProps = {
  handleSuccess?: () => unknown;
  handleCancel?: () => unknown;
  member: Member;
};

const useEditUserForm = createComponentHook((props: EditUserFormProps) => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const { projectId } = useParams() as { projectId: string };
  const { user } = useUser();
  const { userHasPermissions } = useAbac();
  const isAllowedToEditMember = userHasPermissions(
    Permission.UpdateProjectUserInfos,
    {
      memberId: props.member.user.id,
      memberInviterId: props.member.inviter?.id,
      memberRoleName: props.member.role.name,
    }
  );
  const canEditAllFields =
    isAllowedToEditMember &&
    !props.member.user.is_active &&
    props.member.inviter?.id === user.id;
  const currentUserTeamsIds = props.member.user.teams.map((t) => t.team_id);
  const currentUserTeamIdsKey = currentUserTeamsIds.sort().join();
  const [userDefinedCountryCode, setUserDefinedCountryCode] =
    React.useState<CountryCode>();

  const form = useForm(validationSchema, {
    defaultValues: {
      companyData: {
        name:
          props.member.user.active_org?.org.name ??
          props.member.user.company_name!,
        // note that company data can have more infos than just the name, like city, etc...
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      email: props.member.user.contact?.email!,
      first_name: props.member.user.first_name,
      last_name: props.member.user.last_name,
      phone: props.member.user.contact?.phone ?? undefined,
      projectId: projectId,
      /* Member role name type is string when querying, but ProjectRoleId in server */
      role: props.member.role.name as ProjectRoleId,
      teamIds: currentUserTeamsIds,
      userId: props.member.user.id,
    },
  });

  const [, updateProjectRole] = useUpdateProjectRoleMutation();
  const [, updateUserTeams] = useUpdateUserTeamsMutation();
  const [, updateProjectUserInfos] = useUpdateProjectUserInfosMutation();

  const handleProjectRoleSubmit = async (input: UpdateProjectRoleInput) => {
    const { data, error } = await updateProjectRole(
      {
        input,
      },
      { additionalTypenames: ["projects_to_users"] }
    );

    if (data?.updateProjectRole?.__typename === "UpdateProjectRoleSuccess") {
      toast.success(
        t(
          "screens.ProjectMembersScreen.EditUserForm.updateProjectRoleSuccess",
          "The role has been updated"
        )
      );
      return { success: true };
    }
    captureException(error);
    toast.error(
      t(
        "screens.ProjectMembersScreen.EditUserForm.UpdateProjectRoleErrors",
        "You are not allowed to change the role"
      )
    );
    return { success: false };
  };

  const handleUserTeamsSubmit = async (input: UpdateUserTeamsInput) => {
    const { data, error } = await updateUserTeams(
      {
        input,
      },
      { additionalTypenames: ["projects_to_users"] }
    );

    if (data?.updateUserTeams?.__typename === "UpdateUserTeamsSuccess") {
      toast.success(
        t(
          "screens.ProjectMembersScreen.EditUserForm.updateUserTeamsSuccess",
          "The user teams have been updated"
        )
      );
      return { success: true };
    }
    captureException(error);
    toast.error(
      t(
        "screens.ProjectMembersScreen.EditUserForm.UpdateUserTeamsErrors",
        "You are not allowed to change the teams"
      )
    );
    return { success: false };
  };

  const handleProjectUserInfosSubmit = async (
    input: UpdateProjectUserInfosInput
  ) => {
    const { data, error } = await updateProjectUserInfos(
      {
        input,
      },
      { additionalTypenames: ["projects_to_users"] }
    );

    if (
      data?.updateProjectUserInfos?.__typename ===
      "UpdateProjectUserInfosSuccess"
    ) {
      toast.success(
        <span data-intercom-target="participant-updated">
          {t(
            "screens.ProjectMembersScreen.EditUserForm.UpdateProjectUserInfosSuccess",
            "The member has been updated"
          )}
        </span>
      );
      props.handleSuccess?.();
    } else if (
      data?.updateProjectUserInfos?.__typename ===
      "UpdateProjectUserInfosErrors"
    ) {
      captureException(error);
      if (
        data?.updateProjectUserInfos.errors[0]?.__typename ===
        "CannotModifyEmailOfActiveUser"
      ) {
        toast.error(
          t(
            "screens.ProjectMembersScreen.EditUserForm.CannotModifyEmailOfActiveUserError",
            "You can't update the email of an active user"
          )
        );
      } else {
        toast.error(
          t(
            "screens.ProjectMembersScreen.EditUserForm.UpdateProjectUserInfosErrors",
            "Something went wrong while updating the user"
          )
        );
      }
    }
  };

  const handleSubmit = form.handleSubmit(async (input) => {
    if (isAllowedToEditMember) {
      await handleProjectUserInfosSubmit(input);
    } else {
      const requests: Promise<{ success: boolean }>[] = [];

      if (!isEqual(input.teamIds?.sort().join(), currentUserTeamIdsKey)) {
        requests.push(
          handleUserTeamsSubmit({
            projectId: input.projectId,
            teamIds: input.teamIds,
            userId: input.userId,
          })
        );
      }
      if (input.role !== props.member.role.name) {
        requests.push(
          handleProjectRoleSubmit({
            projectId: input.projectId,
            role: input.role,
            userId: input.userId,
          })
        );
      }
      const results = await Promise.all(requests);
      if (results.some((r) => r.success === false)) {
        return;
      }
      return props.handleSuccess?.();
    }
  });

  const [{ data }] = useEditUserForm_DataQuery();

  const orgSuggestions = React.useMemo(() => {
    const orgs = [];

    for (const project of data?.projects ?? []) {
      for (const member of project.members) {
        const temporaryOrgName = member.user.company_name;

        const orgName = member.user.active_org?.org.name ?? temporaryOrgName;
        const avatar = member.user.active_org?.org?.avatar ?? "";
        const id = member.user.active_org?.org?.id ?? "";
        if (orgName) {
          orgs.push({
            avatar,
            id,
            name: orgName,
          });
        }
      }
    }

    const uniqueOrgs = uniqBy(orgs, (o) => o.name.toLowerCase());

    // For each name build the options
    return Array.from(uniqueOrgs).map((org) => ({
      avatar: org.avatar,
      label: org.name,
      type: BasicMultiSelectFieldInputOptionType.ORG,
      value: { name: org.name } as CompanyInfosApiSearchResult,
    }));
  }, [data?.projects]);

  return {
    actions: {
      getValues: form.getValues,
      handleSubmit,
      registerInput: form.register,
      setUserDefinedCountryCode,
      setValue: form.setValue,
    },
    state: {
      breakpoint,
      canEditAllFields,
      control: form.control,
      errors: form.formState.errors,
      isAllowedToEditMember,
      isSubmitting: form.formState.isSubmitting,
      orgSuggestions,
      projectId,
      userDefinedCountryCode,
    },
    t,
  };
});

function EditUserForm(props: EditUserFormProps) {
  const { actions, state } = useEditUserForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="medium">
        <AvatarField
          projectId={state.projectId}
          userId={props.member.user.id}
          avatarUrl={props.member.user.avatar}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userName={props.member.user.full_name!}
          editionAllowed={state.canEditAllFields}
        />
        <UserInputs
          control={state.control}
          registerInput={actions.registerInput}
          errors={state.errors}
          member={props.member}
          editionAllowed={state.isAllowedToEditMember}
          userDefinedCountryCode={state.userDefinedCountryCode}
          setUserDefinedCountryCode={actions.setUserDefinedCountryCode}
          projectId={state.projectId}
          canEditAllFields={state.canEditAllFields}
          setValue={actions.setValue}
          getValues={actions.getValues}
          orgSuggestions={state.orgSuggestions}
        />
        {state.breakpoint === "mobile" ? (
          <ActionsButtonsMobile
            loading={state.isSubmitting}
            handleCancel={props.handleCancel}
          />
        ) : (
          <ActionsButtons
            loading={state.isSubmitting}
            handleCancel={props.handleCancel}
          />
        )}
      </Stack>
    </Form>
  );
}

export { EditUserForm };
export type { EditUserFormType };
