import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import filesize from "filesize";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { dayjs } from "../dayjs";
import { languageToSymbols } from "../filesize/symbols";
import { resources } from "./languages";

// Use createInstance to avoid conflict between i18n server & client instances
const i18nInstance = i18n.createInstance();

/* Think to update this as symetric in the backend, cf packages/server/src/app/config/i18next/index.ts */
void i18nInstance.use(initReactI18next).init({
  fallbackLng: Language.EN,
  interpolation: {
    escapeValue: false,
  },
  load: "currentOnly",
  resources,
  supportedLngs: Object.values(Language),
});

i18nInstance.services.formatter?.add("relative", (value): string => {
  if (value instanceof Date) {
    return dayjs().to(dayjs(value));
  }

  return value as string;
});

i18nInstance.services.formatter?.add(
  "filesize",
  (value, lng, options: { extension?: boolean }): string => {
    if (typeof value === "number") {
      const formatted = filesize(value, {
        locale: lng,
        symbols: languageToSymbols.get(lng as Language),
      });

      return options.extension === false ? formatted.split(" ")[0] : formatted;
    }

    return value as string;
  }
);

i18nInstance.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});
export { i18nInstance as i18n, Language };
