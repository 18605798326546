import * as React from "react";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer } from "~/design-system/Drawer";
import { OrgProjectCategoriesDrawerContent } from "./components/OrgProjectCategoriesContent";

type OrgProjectCategoriesDrawerProps = {
  children?: React.ReactNode;
  controlled?: Pick<ControlledDrawerProps, "open" | "setOpen">;
  orgId: string;
};

function OrgProjectCategoriesDrawer(props: OrgProjectCategoriesDrawerProps) {
  if (props.controlled) {
    return (
      <Drawer open={props.controlled.open} setOpen={props.controlled.setOpen}>
        <OrgProjectCategoriesDrawerContent orgId={props.orgId} />
        {props.children}
      </Drawer>
    );
  }
  return (
    <Drawer>
      <OrgProjectCategoriesDrawerContent orgId={props.orgId} />
      {props.children}
    </Drawer>
  );
}

export type { OrgProjectCategoriesDrawerProps };
export { OrgProjectCategoriesDrawer };
