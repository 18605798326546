/* eslint-disable react/prop-types */
import * as React from "react";
import type { FieldProps } from "../Field";
import { Field } from "../Field";
import type { TextFieldInputProps } from "./components/TextFieldInput/TextFieldInput";
import { TextFieldInput } from "./components/TextFieldInput/TextFieldInput";

type TextFieldProps = Omit<FieldProps & TextFieldInputProps, "children">;

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, ref) {
    const fieldProps: Omit<FieldProps, "children"> = {
      cornerHint: props.cornerHint,
      dataIntercomTarget: props.dataIntercomTarget,
      error: props.error,
      label: props.label,
      message: props.message,
      name: props.name,
      required: props.required,
      rootProps: props.rootProps,
      secondaryLabel: props.secondaryLabel,
      tone: props.tone,
    };
    const textFieldInputProps: TextFieldInputProps = {
      autoComplete: props.autoComplete,
      autoFocus: props.autoFocus,
      cols: props.cols,
      disabled: props.disabled,
      error: props.error,
      leadingIcon: props.leadingIcon,
      maxLength: props.maxLength,
      minLength: props.minLength,
      multiline: props.multiline,
      name: props.name,
      onBlur: props.onBlur,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onKeyPress: props.onKeyPress,
      onPaste: props.onPaste,
      placeholder: props.placeholder,
      readOnly: props.readOnly,
      required: props.required,
      rows: props.rows,
      tone: props.tone,
      trailingIcon: props.trailingIcon,
      type: props.type,
      value: props.value,
    };

    return (
      <Field {...fieldProps}>
        <TextFieldInput ref={ref} {...textFieldInputProps} />
      </Field>
    );
  }
);

export type { TextFieldProps };
export { TextField };
