import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import nl from "./nl.json";

enum Language {
  EN = "en",
  FR = "fr",
  ES = "es",
  DE = "de",
  NL = "nl",
  PT = "pt",
}

const resources = {
  [Language.EN]: { translation: en },
  [Language.FR]: { translation: fr },
  [Language.ES]: { translation: es },
  [Language.DE]: { translation: de },
  [Language.NL]: { translation: nl },
} as const;

export { Language, resources };
