import type * as Stitches from "@stitches/react";
import React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

const Table = styled("table", {
  ...tw`
    w-full divide-y divide-gray-200
  `,

  variants: {
    fixed: {
      true: tw`table-fixed`,
    },
  },
});

const TableWrapper = styled("div", {
  ...tw`
    shadow overflow-hidden border-b border-gray-200 sm:rounded-lg
  `,
});

type RootProps = Stitches.VariantProps<typeof Table> & {
  children: React.ReactNode;
  css?: CSS;
};

const Root = React.forwardRef<HTMLDivElement, RootProps>(function Root(
  props,
  forwardedRef
) {
  const { css, ...rest } = props;
  return (
    <TableWrapper ref={forwardedRef} css={css}>
      <Table {...rest} />
    </TableWrapper>
  );
});

export { Root };
