import { Listbox } from "@headlessui/react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const SelectOptions = styled(Listbox.Options, {
  ...tw`
  absolute z-10 mt-1 w-full bg-white shadow-lg 
  max-h-56 rounded-md py-1 text-base 
  ring-1 ring-black ring-opacity-5 
  overflow-auto 
  focus:outline-none`,
});

export { SelectOptions };
