import type * as Uppy from "@uppy/core";
import * as React from "react";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { FileUploadPreview } from "./components/FileUploadPreview";
import { Toolbar } from "./components/Toolbar";

type FilesUploaderDrawerContentProps = {
  files: Array<Uppy.State["files"][string]>;
};

function FilesUploaderDrawerContent(props: FilesUploaderDrawerContentProps) {
  return (
    <Box background="card" paddingY="medium" paddingX="gutter">
      <Stack space="medium">
        <Toolbar />
        <Box paddingTop="small">
          <Stack space="small">
            {props.files.map((file) => (
              <FileUploadPreview key={file.id} file={file} />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export { FilesUploaderDrawerContent };
