import { SlotsProvider } from "nano-slots";
import * as React from "react";
import { useUser } from "~/config/user/UserProvider";
import { useBreakpoint } from "~/design-system/hooks";
import { createComponentHook } from "~/types";
import * as Desktop from "./components/Desktop/Desktop";
import * as Mobile from "./components/Mobile/Mobile";

interface LayoutProps {
  children: React.ReactNode;
}

const useLayout = createComponentHook(() => {
  const { breakpoint } = useBreakpoint();
  const { user } = useUser();

  return {
    state: { breakpoint, userIsLocked: user.is_locked },
  };
});

function Layout(props: LayoutProps) {
  const { state } = useLayout();

  if (state.userIsLocked) {
    return <>{props.children}</>;
  }

  return (
    <div>
      <SlotsProvider>
        {state.breakpoint === "mobile" ? (
          <Mobile.Layout {...props} />
        ) : (
          <Desktop.Layout {...props} />
        )}
      </SlotsProvider>
    </div>
  );
}

export { Layout };
export type { LayoutProps };
