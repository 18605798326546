import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

type NavProps = { children: React.ReactNode; label: string; css?: CSS };

const StyledNav = styled("nav", {});

const Nav = React.forwardRef<HTMLElement, NavProps>(function Nav(props, ref) {
  return (
    <StyledNav ref={ref} css={props.css} aria-label={props.label}>
      {props.children}
    </StyledNav>
  );
});

export { Nav };
