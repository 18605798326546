import type { UppyFile } from "@uppy/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { CombinedError } from "urql";
import { captureException } from "~/config/sentry";
import type { FileMeta } from "./index";
import { uppy } from "./index";
import {
  useFinalizeFileVersionAnnotationsForUploadMutation,
  useFinalizeUploadedChannelAssetMutation,
  useFinalizeUploadedFileApprovalsAttachmentMutation,
  useFinalizeUploadedFileMutation,
  useFinalizeUploadedFileStructureMutation,
  useFinalizeUploadedFileVersionMutation,
  useFinalizeUploadedOrgAvatarMutation,
  useFinalizeUploadedOrgBackgroundMutation,
  useFinalizeUploadedProjectAvatarMutation,
  useFinalizeUploadedProjectBackgroundMutation,
  useFinalizeUploadedProjectBannerMutation,
  useFinalizeUploadedProjectGroupAvatarMutation,
  useFinalizeUploadedProjectSpreadsheetMutation,
  useFinalizeUploadedTaskAttachmentAnnotationMutation,
  useFinalizeUploadedTaskAttachmentMutation,
  useFinalizeUploadedTaskSpreadsheetMutation,
  useFinalizeUploadedUserAvatarMutation,
} from "./UppyConfig.graphql";

type UppyConfigProps = {
  children: React.ReactNode;
};

const useUppyConfig = () => {
  const { t } = useTranslation();
  const [, finalizeUploadedFile] = useFinalizeUploadedFileMutation();
  const [, finalizeUploadedFileVersion] =
    useFinalizeUploadedFileVersionMutation();
  const [, finalizeFileVersionAnnotationMutation] =
    useFinalizeFileVersionAnnotationsForUploadMutation();
  const [, finalizeUploadedFileStructure] =
    useFinalizeUploadedFileStructureMutation();
  const [, finalizeUploadedTaskAttachment] =
    useFinalizeUploadedTaskAttachmentMutation();
  const [, finalizeUploadedTaskAttachmentAnnotation] =
    useFinalizeUploadedTaskAttachmentAnnotationMutation();
  const [, finalizeUploadedOrgAvatar] = useFinalizeUploadedOrgAvatarMutation();
  const [, finalizeUploadedOrgBackground] =
    useFinalizeUploadedOrgBackgroundMutation();
  const [, finalizeUploadedProjectAvatar] =
    useFinalizeUploadedProjectAvatarMutation();
  const [, FinalizeUploadedProjectGroupAvatar] =
    useFinalizeUploadedProjectGroupAvatarMutation();
  const [, finalizeUploadedProjectBackground] =
    useFinalizeUploadedProjectBackgroundMutation();
  const [, finalizeUploadedProjectBanner] =
    useFinalizeUploadedProjectBannerMutation();
  const [, finalizeUploadedUserAvatar] =
    useFinalizeUploadedUserAvatarMutation();
  const [, finalizeUploadedChannelAsset] =
    useFinalizeUploadedChannelAssetMutation();
  const [, finalizeUploadedTaskSpreadsheet] =
    useFinalizeUploadedTaskSpreadsheetMutation();
  const [, finalizeUploadedProjectSpreadsheet] =
    useFinalizeUploadedProjectSpreadsheetMutation();
  const [, finalizeUploadedFileApprovalsAttachment] =
    useFinalizeUploadedFileApprovalsAttachmentMutation();

  React.useEffect(() => {
    async function handleUploadSuccess(file: UppyFile<FileMeta>) {
      if (file.meta.internalFileType === "file") {
        const { data, error } = await finalizeUploadedFile(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["files", "folders"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "fileVersion") {
        const { data, error } = await finalizeUploadedFileVersion(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["files", "file_versions"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "fileVersionAnnotation") {
        void finalizeFileVersionAnnotationMutation(
          {},
          { additionalTypenames: ["file_versions"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, true);
      }
      // TODO: change for the actual zipStructure upload mutation
      if (file.meta.internalFileType === "zipStructure") {
        const { data, error } = await finalizeUploadedFileStructure(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["files", "folders", "events_workers_status"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "taskAttachment") {
        const { data, error } = await finalizeUploadedTaskAttachment(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["task_attachments"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "taskAttachmentAnnotation") {
        const { data, error } = await finalizeUploadedTaskAttachmentAnnotation(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["task_attachments"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "fileApprovalAttachment") {
        const { data, error } = await finalizeUploadedFileApprovalsAttachment(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["file_approvals_attachment"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "orgAvatar") {
        const { data, error } = await finalizeUploadedOrgAvatar(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["orgs"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "orgBackground") {
        const { data, error } = await finalizeUploadedOrgBackground(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["orgs"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "projectAvatar") {
        const { data, error } = await finalizeUploadedProjectAvatar(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["projects"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "projectGroupAvatar") {
        console.log("projectGroupAvatar uppy", file);
        const { data, error } = await FinalizeUploadedProjectGroupAvatar(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["projects"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "projectBackground") {
        const { data, error } = await finalizeUploadedProjectBackground(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["projects"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "projectBanner") {
        const { data, error } = await finalizeUploadedProjectBanner(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["projects"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "userAvatar") {
        const { data, error } = await finalizeUploadedUserAvatar(
          { input: { key: file.meta.key } },
          { additionalTypenames: ["users"] }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "channelAsset") {
        const { data, error } = await finalizeUploadedChannelAsset({
          input: { key: file.meta.key },
        });
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "importTask") {
        const { data, error } = await finalizeUploadedTaskSpreadsheet(
          {
            input: { key: file.meta.key },
          },
          {
            additionalTypenames: [
              "tasks",
              "task_attachments",
              "project_labels",
            ],
          }
        );
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
      if (file.meta.internalFileType === "importProject") {
        const { data, error } = await finalizeUploadedProjectSpreadsheet({
          input: { key: file.meta.key },
        });
        file.meta.onFinalizeEnd?.(file.meta, data, error);
        return;
      }
    }

    const syncHandleUploadSuccess = (
      file: UppyFile<FileMeta, Record<string, unknown>>
    ) => {
      void handleUploadSuccess(file);
    };

    const syncHandleUploadError = (
      file: UppyFile<FileMeta, Record<string, unknown>>,
      error: Error,
      response?: unknown
    ) => {
      captureException(error, {
        extra: {
          file: JSON.stringify(file),
          response: JSON.stringify(response),
        },
      });
      file.meta.onFinalizeEnd?.(
        file.meta,
        undefined,
        new CombinedError({
          networkError: error,
          response,
        })
      );
    };

    uppy.on<"upload-error", FileMeta>("upload-error", syncHandleUploadError);

    uppy.on<"upload-success", FileMeta>(
      "upload-success",
      syncHandleUploadSuccess
    );

    return () => {
      uppy.off<"upload-success", FileMeta>(
        "upload-success",
        syncHandleUploadSuccess
      );
      uppy.off<"upload-error", FileMeta>("upload-error", syncHandleUploadError);
    };
  }, [
    finalizeUploadedFile,
    finalizeUploadedFileVersion,
    finalizeUploadedTaskAttachment,
    finalizeUploadedOrgAvatar,
    finalizeUploadedOrgBackground,
    t,
    finalizeUploadedProjectAvatar,
    FinalizeUploadedProjectGroupAvatar,
    finalizeUploadedProjectBackground,
    finalizeUploadedProjectBanner,
    finalizeUploadedUserAvatar,
    finalizeUploadedChannelAsset,
    finalizeUploadedFileApprovalsAttachment,
  ]);
};

function UppyConfig(props: UppyConfigProps) {
  useUppyConfig();

  return <>{props.children}</>;
}

export { UppyConfig };
