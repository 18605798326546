import * as React from "react";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import { CategoriesList } from "./CategoriesList/CategoriesList";
import { Header } from "./Header";

type OrgProjectCategoriesDrawerContentProps = {
  orgId: string;
};

function OrgProjectCategoriesDrawerContent(
  props: OrgProjectCategoriesDrawerContentProps
) {
  return (
    <DrawerContent position="right">
      <Box width="screen" maxWidth="xsmall">
        <Header />
        <CategoriesList orgId={props.orgId} />
      </Box>
    </DrawerContent>
  );
}

export type { OrgProjectCategoriesDrawerContentProps };
export { OrgProjectCategoriesDrawerContent };
