import { LockClosedIcon } from "@heroicons/react/solid";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { Icon } from "../../Icon";
import type { IconProps } from "../../Icon/Icon";
import type { FolderIconProps } from "../FolderIcon";
import type { FolderIconSize } from "../FolderIconConstant";
import { folderSizeToIconSize } from "../FolderIconConstant";

const FolderIconLockWrapper = styled("div", {
  backgroundColor: "$white",
  borderRadius: "50% 50% 0 0",
  bottom: 0,
  position: "absolute",
  right: 0,
});

type FolderIconLockProps = Pick<FolderIconProps, "size"> & {
  css?: CSS;
  color: IconProps["color"];
};

const FolderIconLock = (props: FolderIconLockProps) => {
  return (
    <FolderIconLockWrapper>
      <Icon
        label={`lock`}
        size={folderSizeToIconSize.get(
          props.size as (typeof FolderIconSize)[keyof typeof FolderIconSize]
        )}
        color={props.color}
        css={props.css}
      >
        <LockClosedIcon />
      </Icon>
    </FolderIconLockWrapper>
  );
};

export { FolderIconLock };
