import type { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import { TranslateIcon } from "@heroicons/react/outline";
import React from "react";
import { Button } from "~/design-system/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  MenuItem,
} from "~/design-system/DropdownMenu";
import { Text } from "~/design-system/Text";

const LANGUAGES_ = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  nl: "Nederlands",
  pt: "Português",
};

type LanguageType = `${Language}`;

type LanguageDropdownProps = {
  language: LanguageType;
  setLanguage: React.Dispatch<LanguageType>;
};

function LanguageDropdown(props: LanguageDropdownProps) {
  return (
    <DropdownMenu
      label="language options"
      trigger={
        <Button
          size="small"
          variant="ghost"
          leadingIcon={<TranslateIcon />}
          dataIntercomTarget="rights-language-dropdown"
        >
          {LANGUAGES_[props.language]}
        </Button>
      }
    >
      <DropdownMenuContent>
        <MenuItem onClick={() => props.setLanguage("en")}>
          <Text>English</Text>
        </MenuItem>
        <MenuItem onClick={() => props.setLanguage("fr")}>
          <Text>Français</Text>
        </MenuItem>
        <MenuItem onClick={() => props.setLanguage("de")}>
          <Text>Deutsch</Text>
        </MenuItem>
        <MenuItem onClick={() => props.setLanguage("es")}>
          <Text>Español</Text>
        </MenuItem>
        <MenuItem onClick={() => props.setLanguage("nl")}>
          <Text>Netherlands</Text>
        </MenuItem>
        <MenuItem onClick={() => props.setLanguage("pt")}>
          <Text>Português</Text>
        </MenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export type { LanguageDropdownProps, LanguageType };
export { LanguageDropdown };
