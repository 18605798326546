import create from "zustand";
import type { MultiSelectStore } from "~/config/zustand/createMultiSelectStore.store";
import { createMultiSelectStore } from "~/config/zustand/createMultiSelectStore.store";
import type { FileFolder } from "./FilesFolders";

type FilesFoldersStore = MultiSelectStore<FileFolder>;

type FilesFoldersAttachmentStore = {
  numberOfAttachments: number | undefined;
  setNumberOfAttachments: (numberOfAttachments: number | undefined) => void;
  numberOfLinkedFiles: number | undefined;
  setNumberOfLinkedFiles: (numberOfLinkedFiles: number | undefined) => void;
  fileID: string | undefined;
  setFileID: (fileID: string | undefined) => void;
  setNumberOfFiles: ({
    fileID,
    numberOfAttachments,
    numberOfLinkedFiles,
  }: {
    numberOfLinkedFiles: number | undefined;
    numberOfAttachments: number | undefined;
    fileID: string | undefined;
  }) => void;
};

const useFilesFoldersAttachmentStore = create<FilesFoldersAttachmentStore>(
  (set) => ({
    fileID: undefined,
    numberOfAttachments: undefined,
    numberOfLinkedFiles: undefined,
    setFileID: (fileID) => set({ fileID }),
    setNumberOfAttachments: (numberOfAttachments) =>
      set({ numberOfAttachments }),
    setNumberOfFiles: ({ fileID, numberOfAttachments, numberOfLinkedFiles }) =>
      set({ fileID, numberOfAttachments, numberOfLinkedFiles }),
    setNumberOfLinkedFiles: (numberOfLinkedFiles) =>
      set({ numberOfLinkedFiles }),
  })
);

const useFilesFoldersStore = createMultiSelectStore<FileFolder>();

export { useFilesFoldersAttachmentStore, useFilesFoldersStore };
export type { FilesFoldersAttachmentStore, FilesFoldersStore };
