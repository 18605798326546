import "./i18next";
import * as React from "react";
import { initCapacitor } from "./capacitor";
import { AppHelmet } from "./helmet";
import { IntercomProvider } from "./intercom/IntercomProvider";
import { SegmentProvider } from "./segment/SegmentProvider";
import { initSentryReact } from "./sentry";
import { globalStyles } from "./stitches/global-styles";
import { initTwicPics } from "./twicpics";

function compose(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configs: React.JSXElementConstructor<any>[],
  children: JSX.Element
) {
  return configs.reverse().reduce((acc, Config) => {
    return <Config>{acc}</Config>;
  }, children);
}

interface ConfigProps {
  children: JSX.Element;
}

function Config(props: ConfigProps) {
  globalStyles();

  return compose(
    [AppHelmet, SegmentProvider, IntercomProvider],
    props.children
  );
}

async function initConfig() {
  initTwicPics();
  initSentryReact();
  await initCapacitor();
}

export { Config, initConfig };
