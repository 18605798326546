import { ImpactStyle } from "@capacitor/haptics";
import { ClipboardListIcon, MapIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hapticsImpact } from "~/config/capacitor/haptics/hook";
import { Button } from "~/design-system/Button";
import { useDrawerContext } from "~/design-system/Drawer";
import { Inline } from "~/design-system/Inline";

const DashboardButton = () => {
  const { t } = useTranslation();
  const { setOpen } = useDrawerContext();
  const handleClick = async () => {
    setOpen(false);
    await hapticsImpact(ImpactStyle.Light);
  };

  return (
    <Button
      leadingIcon={<MapIcon />}
      as={Link}
      to="/"
      onClick={handleClick}
      width="auto"
      opacity="light"
      dataIntercomTarget="navigation-drawer-home"
    >
      {t("screens.NavigationDrawer.HeaderButtons.home", "Go to Dashboard")}
    </Button>
  );
};

const HeaderButtons = () => {
  const { t } = useTranslation();
  const { setOpen } = useDrawerContext();
  const handleClick = async () => {
    setOpen(false);
    await hapticsImpact(ImpactStyle.Light);
  };

  return (
    <Inline space="small">
      <DashboardButton />
      <Button
        leadingIcon={<ClipboardListIcon />}
        as={Link}
        to="/dashboard/tasks"
        onClick={handleClick}
        width="auto"
        opacity="light"
        dataIntercomTarget="navigation-drawer-all-tasks"
      >
        {t(
          "screens.NavigationDrawer.HeaderButtons.allTasks",
          "Go to all projects tasks planning"
        )}
      </Button>
    </Inline>
  );
};

export { DashboardButton, HeaderButtons };
