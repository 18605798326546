import * as React from "react";
import create from "zustand";
import createContext from "zustand/context";
import { captureMessage } from "~/config/sentry";
import { createComponentHook } from "~/types";
import type { UserBadgeWorkerStatusState } from "./utils/types";
import type { WorkerStatusQuery } from "./WorkerStatus.graphql";
import { useWorkerStatusQuery } from "./WorkerStatus.graphql";

type WorkerStatusProviderProps = {
  userId: string;
  children: React.ReactNode;
};

const { Provider, useStore: useWorkerStatusContext } =
  createContext<UserBadgeWorkerStatusState>();

const userWorkerStatusStore = create<UserBadgeWorkerStatusState>(
  (set, get) => ({
    getWorkerStatus: (workerFilterFunction, elementId) => {
      if (workerFilterFunction) {
        return get().workerStatus.filter((worker_status, index, array) =>
          workerFilterFunction({
            array,
            elementId,
            index,
            worker_status,
          })
        );
      }
      return get().workerStatus;
    },

    hasPending: false,
    setWorkerStatus: (
      workerStatus: UserBadgeWorkerStatusState["workerStatus"]
    ) => {
      set((state) => ({
        ...state,
        hasPending: workerStatus.some((ws) => ws.status === "PENDING"),
        workerStatus,
      }));
    },
    workerStatus: [],
  })
);

const useWorkerStatusProvider = createComponentHook(
  (props: WorkerStatusProviderProps) => {
    const [fetchedWorkerStatus, setFetchedWorkerStatus] = React.useState<
      WorkerStatusQuery["workerStatus"]
    >([]);
    const strigifiedWorkerStatus = JSON.stringify(fetchedWorkerStatus);
    const [{ data, error, fetching }, refetchWorkerStatus] =
      useWorkerStatusQuery({ variables: { userId: props.userId } });

    React.useEffect(() => {
      const refetchInterval = setInterval(() => {
        refetchWorkerStatus();
        // Refetch workerStatus every 10s
      }, 10000);
      return () => {
        clearInterval(refetchInterval);
      };
    }, [fetching]);

    // Handle workerStatus fetch query result
    React.useEffect(() => {
      if (fetching === false) {
        if (error) {
          captureMessage(error.message);
        } else {
          if (data?.workerStatus) {
            setFetchedWorkerStatus(data.workerStatus);
          }
        }
      }
    }, [data, error, fetching]);

    // Dispatch newly fetched workerStatus to the store
    React.useEffect(() => {
      if (fetchedWorkerStatus) {
        const workerStatusStore = userWorkerStatusStore.getState();
        workerStatusStore.setWorkerStatus(
          fetchedWorkerStatus as UserBadgeWorkerStatusState["workerStatus"]
        );
      }
    }, [strigifiedWorkerStatus]);

    return {};
  }
);

function WorkerStatusProvider(props: WorkerStatusProviderProps) {
  useWorkerStatusProvider(props);

  return (
    // cast our state as we checked that user isn't null
    <Provider createStore={() => userWorkerStatusStore}>
      {props.children}
    </Provider>
  );
}

export { useWorkerStatusContext, WorkerStatusProvider };
