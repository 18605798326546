import { Listbox } from "@headlessui/react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const SelectButton = styled(Listbox.Button, {
  ...tw`
  relative w-full 
  bg-white border border-gray-300 
  rounded-md shadow-sm 
  text-left cursor-default 
  focus:outline-none focus:ring-1 focus:ring-slate-400 focus:border-slate-400 
  `,
});
export { SelectButton };
