import * as React from "react";
import { useTranslation } from "react-i18next";
import Undraw from "react-undraw";
import { colors } from "~/config/stitches/colors";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

function NotFound() {
  const { t } = useTranslation();

  return (
    <div>
      <Stack align="center" space="small">
        <Box style={{ maxHeight: "70vh" }} width="full" height="full">
          <Undraw name="void" primaryColor={colors.emerald500} height="100%" />
        </Box>
        <Text align="center">{t("components.NotFound", "Page not found")}</Text>
      </Stack>
    </div>
  );
}

export { NotFound };
