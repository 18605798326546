import * as React from "react";
import { useTranslation } from "react-i18next";
import type { UserBadgeWorkerStatus } from "~/config/user/workerstatus/utils/types";
import { Inline } from "~/design-system/Inline";
import type { ProgressBarProps } from "~/design-system/ProgressBar";
import { ProgressBar } from "~/design-system/ProgressBar";
import { Text } from "~/design-system/Text";

type WorkerStatusListItemSubtitleProps = {
  createdAt: string;
  updatedAt: string;
  text: React.ReactNode;
  percentDone: number;
  status: UserBadgeWorkerStatus["status"];
};

const statusProgressColorMap: Record<
  UserBadgeWorkerStatus["status"],
  Required<ProgressBarProps["color"]>
> = {
  ERROR: "critical",
  PENDING: "info",
  SUCCESS: "positive",
  TIMEOUT: "caution",
};

function WorkerStatusListItemSubtitle(
  props: WorkerStatusListItemSubtitleProps
) {
  const { t } = useTranslation();
  const created = t(
    "WorkerStatusListSubtitle.created",
    "created: {{createdAt, relative}}",
    { createdAt: new Date(props.createdAt) }
  );
  const updated = t(
    "WorkerStatusListSubtitle.updated",
    "updated: {{updatedAt, relative}}",
    { updatedAt: new Date(props.updatedAt) }
  );
  const progressColor = statusProgressColorMap[props.status];
  return (
    <Text>
      <ProgressBar uploadPercentage={props.percentDone} color={progressColor} />
      <Inline space={"xxsmall"}>
        <Text size={"xsmall"}>{created}</Text>
        <Text size="xsmall">{updated}</Text>
      </Inline>
      <Text size="small">{props.text}</Text>
    </Text>
  );
}
export { WorkerStatusListItemSubtitle };
