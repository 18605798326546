import { ProjectRolesHierarchyMap } from "@clovis/server/src/app/domain/project/role";
import { ProjectPermission } from "@clovis/server/src/app/domain/utils/permissions";
import type { TFunction } from "i18next";
import { Role as OrgRole } from "../../screens/App/screens/Org/Org.authorize";
import { Role } from "../../screens/App/screens/Project/Project.authorize";
import type { UserPermissionsOptions } from "./utils";

function getProjectPermissionsOptions(t: TFunction): UserPermissionsOptions {
  return [
    {
      name: t(
        "screens.permissions.description.AddMembersToProjectChannel",
        "Add members to chat"
      ),
      permission: ProjectPermission.AddMembersToProjectChannel,
    },
    {
      name: t(
        "screens.permissions.description.RequestFileTreeExport",
        "Request File Tree Export"
      ),
      permission: ProjectPermission.RequestFileTreeExport,
    },
    {
      name: t(
        "screens.permissions.description.AddNewFileVersion",
        "Add new file version"
      ),
      permission: ProjectPermission.AddNewFileVersion,
    },
    {
      name: t(
        "screens.permissions.description.AddUserInTeam",
        "Add user in team"
      ),
      permission: ProjectPermission.AddUserInTeam,
    },
    {
      name: t(
        "screens.permissions.description.AddUserViewToFolder",
        "Add user view to folder"
      ),
      permission: ProjectPermission.AddUserViewToFolder,
    },
    {
      name: t(
        "screens.permissions.description.AddUserViewToProject",
        "Add user view to project"
      ),
      permission: ProjectPermission.AddUserViewToProject,
    },
    {
      name: t("screens.permissions.description.AnnotateFile", "Annotate file"),
      permission: ProjectPermission.AnnotateFile,
    },
    {
      name: t(
        "screens.permissions.description.CreateChannelForProject",
        "Create chat for project"
      ),
      permission: ProjectPermission.CreateChannelForProject,
    },
    {
      name: t(
        "screens.permissions.description.CreateChannelForTask",
        "Create chat for task"
      ),
      permission: ProjectPermission.CreateChannelForTask,
    },
    {
      name: t(
        "screens.permissions.description.CreateEmptyFile",
        "Create empty file"
      ),
      permission: ProjectPermission.CreateEmptyFile,
    },
    {
      name: t("screens.permissions.description.CreateFolder", "Create folder"),
      permission: ProjectPermission.CreateFolder,
    },

    {
      name: t(
        "screens.permissions.description.CreateProjectDirectMessage",
        "Create direct message"
      ),
      permission: ProjectPermission.CreateProjectDirectMessage,
    },
    {
      name: t(
        "screens.permissions.description.CreateProjectLabel",
        "Create project label"
      ),
      permission: ProjectPermission.CreateProjectLabel,
    },
    {
      name: t("screens.permissions.description.CreateTask", "Create task"),
      permission: ProjectPermission.CreateTask,
    },
    {
      name: t("screens.permissions.description.CreateTeam", "Create team"),
      permission: ProjectPermission.CreateTeam,
    },
    {
      name: t("screens.permissions.description.DeleteFile", "Delete file"),
      permission: ProjectPermission.DeleteFile,
    },
    {
      name: t("screens.permissions.description.DeleteFolder", "Delete folder"),
      permission: ProjectPermission.DeleteFolder,
    },
    {
      name: t(
        "screens.permissions.description.DeleteProject",
        "Delete project"
      ),
      permission: ProjectPermission.DeleteProject,
    },
    {
      name: t(
        "screens.permissions.description.DeleteProjectLabel",
        "Delete project label"
      ),
      permission: ProjectPermission.DeleteProjectLabel,
    },
    {
      name: t("screens.permissions.description.DeleteTask", "Delete task"),
      permission: ProjectPermission.DeleteTask,
    },
    {
      name: t(
        "screens.permissions.description.DeleteTaskAttachment",
        "Delete task attachment"
      ),
      permission: ProjectPermission.DeleteTaskAttachment,
    },
    {
      name: t("screens.permissions.description.DeleteTeam", "Delete team"),
      permission: ProjectPermission.DeleteTeam,
    },
    {
      name: t(
        "screens.permissions.description.DownloadFolder",
        "Download folder"
      ),
      permission: ProjectPermission.DownloadFolder,
    },
    {
      name: t(
        "screens.permissions.description.DuplicateFile",
        "Duplicate file"
      ),
      permission: ProjectPermission.DuplicateFile,
    },
    {
      name: t(
        "screens.permissions.description.DuplicateProject",
        "Duplicate project"
      ),
      permission: ProjectPermission.DuplicateProject,
    },
    {
      name: t(
        "screens.permissions.description.GetPresignedFileUrl",
        "Download file"
      ),
      permission: ProjectPermission.GetPresignedFileUrl,
    },
    {
      name: t("screens.permissions.description.ImportTasks", "Import Tasks"),
      permission: ProjectPermission.ImportTasks,
    },
    {
      name: t("screens.permissions.description.MoveFile", "Move file"),
      permission: ProjectPermission.MoveFile,
    },
    {
      name: t("screens.permissions.description.MoveFolder", "Move folder"),
      permission: ProjectPermission.MoveFolder,
    },
    {
      name: t(
        "screens.permissions.description.OpenFileFolderLabelsDialog",
        "Edit file/folder labels"
      ),
      permission: ProjectPermission.OpenFileFolderLabelsDialog,
    },

    {
      name: t(
        "screens.permissions.description.PersignedTaskAttachmentUrl",
        "Get presigned task attachment url"
      ),
      permission: ProjectPermission.PersignedTaskAttachmentUrl,
    },
    {
      name: t(
        "screens.permissions.description.PrepareFileStructureForUpload",
        "Prepare file structure for upload"
      ),
      permission: ProjectPermission.PrepareFileStructureForUpload,
    },

    {
      name: t(
        "screens.permissions.description.PrepareProjectAssetForUpload",
        "Prepare project asset for upload"
      ),
      permission: ProjectPermission.PrepareProjectAssetForUpload,
    },
    {
      name: t(
        "screens.permissions.description.PrepareProjectAssetForUpload",
        "Prepare project asset for upload"
      ),
      permission: ProjectPermission.PrepareProjectAssetForUpload,
    },
    {
      name: t(
        "screens.permissions.description.PrepareTaskSpreadsheetForUpload",
        "Prepare task spreadsheet for upload"
      ),
      permission: ProjectPermission.PrepareTaskSpreadsheetForUpload,
    },
    {
      name: t(
        "screens.permissions.description.ProjectInvitations",
        "Project invitations"
      ),
      permission: ProjectPermission.ProjectInvitations,
    },

    {
      name: t(
        "screens.permissions.description.RemoveMembersFromProjectChannel",
        "Remove members from project chat"
      ),
      permission: ProjectPermission.RemoveMembersFromProjectChannel,
    },
    {
      name: t(
        "screens.permissions.description.RemoveUserInTeam",
        "Remove user in team"
      ),
      permission: ProjectPermission.RemoveUserInTeam,
    },
    {
      name: t("screens.permissions.description.RenameFile", "Rename file"),
      permission: ProjectPermission.RenameFile,
    },
    {
      name: t("screens.permissions.description.RenameFolder", "Rename folder"),
      permission: ProjectPermission.RenameFolder,
    },
    {
      name: t(
        "screens.permissions.description.RenameProjectChannel",
        "Rename chat"
      ),
      permission: ProjectPermission.RenameProjectChannel,
    },
    {
      name: t(
        "screens.permissions.description.RequestApprovalExport",
        "Request approval export"
      ),
      permission: ProjectPermission.RequestApprovalExport,
    },
    {
      name: t(
        "screens.permissions.description.RequestApprovalReport",
        "Request approval report"
      ),
      permission: ProjectPermission.RequestApprovalReport,
    },
    {
      name: t(
        "screens.permissions.description.RequestTaskExport",
        "Request task export"
      ),
      permission: ProjectPermission.RequestTaskExport,
    },
    {
      name: t(
        "screens.permissions.description.RequestTaskReport",
        "Request task report"
      ),
      permission: ProjectPermission.RequestTaskReport,
    },
    {
      name: t(
        "screens.permissions.description.RequestTasksExport",
        "Request tasks export"
      ),
      permission: ProjectPermission.RequestTasksExport,
    },
    {
      name: t(
        "screens.permissions.description.RequestTasksReport",
        "Request tasks report"
      ),
      permission: ProjectPermission.RequestTasksReport,
    },
    {
      name: t(
        "screens.permissions.description.RequestUsersApprovalsFiles",
        "Request users approvals files"
      ),
      permission: ProjectPermission.RequestUsersApprovalsFiles,
    },
    {
      name: t(
        "screens.permissions.description.RestoreFileVersion",
        "Restore file version"
      ),
      permission: ProjectPermission.RestoreFileVersion,
    },
    {
      name: t("screens.permissions.description.SignFile", "Sign file"),
      permission: ProjectPermission.SignFile,
    },
    {
      name: t(
        "screens.permissions.description.UpdateArchiveTask",
        "Archive task"
      ),
      permission: ProjectPermission.UpdateArchiveTask,
    },
    {
      name: t(
        "screens.permissions.description.UpdateAssignationsFiles",
        "Assing someone on a file"
      ),
      permission: ProjectPermission.UpdateAssignationsFiles,
    },
    {
      name: t(
        "screens.permissions.description.UpdateAssignationsFolders",
        "Assing someone on a folder"
      ),
      permission: ProjectPermission.UpdateAssignationsFolders,
    },
    {
      name: t(
        "screens.permissions.description.UpdateAssignationsTask",
        "Assing someone on a task"
      ),
      permission: ProjectPermission.UpdateAssignationsTask,
    },
    {
      name: t(
        "screens.permissions.description.UpdateDueDateFile",
        "Edit the due date (deadline) on a file"
      ),
      permission: ProjectPermission.UpdateDueDateFile,
    },
    {
      name: t(
        "screens.permissions.description.UpdateFilesApprovalMode",
        "Edit files approval mode"
      ),
      permission: ProjectPermission.UpdateFilesApprovalMode,
    },
    {
      name: t(
        "screens.permissions.description.UpdateLabelsFiles",
        "Edit file labels"
      ),
      permission: ProjectPermission.UpdateLabelsFiles,
    },
    {
      name: t(
        "screens.permissions.description.UpdateLabelsFolders",
        "Edit folder labels"
      ),
      permission: ProjectPermission.UpdateLabelsFolders,
    },
    {
      name: t(
        "screens.permissions.description.UpdateOrgProjectSummary",
        "Edit project summary"
      ),
      permission: ProjectPermission.UpdateOrgProjectSummary,
    },
    {
      name: t(
        "screens.permissions.description.UpdatePermissionsFile",
        "Edit file permissions"
      ),
      permission: ProjectPermission.UpdatePermissionsFile,
    },
    {
      name: t(
        "screens.permissions.description.UpdatePermissionsFolder",
        "Edit folder permissions"
      ),
      permission: ProjectPermission.UpdatePermissionsFolder,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectArchive",
        "Archive project"
      ),
      permission: ProjectPermission.UpdateProjectArchive,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectLabel",
        "Edit project label"
      ),
      permission: ProjectPermission.UpdateProjectLabel,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectProfile",
        "Edit project profile"
      ),
      permission: ProjectPermission.UpdateProjectProfile,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectRole",
        "Edit project role"
      ),
      permission: ProjectPermission.UpdateProjectRole,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectTeam",
        "Edit project team"
      ),
      permission: ProjectPermission.UpdateProjectTeam,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectTeams",
        "Edit project teams"
      ),
      permission: ProjectPermission.UpdateProjectTeams,
    },
    {
      name: t(
        "screens.permissions.description.UpdateProjectUserInfos",
        "Edit project user infos"
      ),
      permission: ProjectPermission.UpdateProjectUserInfos,
    },
    {
      name: t("screens.permissions.description.UpdateTask", "Edit task"),
      permission: ProjectPermission.UpdateTask,
    },
    {
      name: t(
        "screens.permissions.description.UpdateTaskFileVersion",
        "Edit task file version"
      ),
      permission: ProjectPermission.UpdateTaskFileVersion,
    },
    {
      name: t(
        "screens.permissions.description.UpdateTaskLabels",
        "Edit task labels"
      ),
      permission: ProjectPermission.UpdateTaskLabels,
    },
    {
      name: t("screens.permissions.description.UpdateTeam", "Edit team"),
      permission: ProjectPermission.UpdateTeam,
    },
    {
      name: t(
        "screens.permissions.description.UpdateUserInTeam",
        "Edit user in team"
      ),
      permission: ProjectPermission.UpdateUserInTeam,
    },
    {
      name: t(
        "screens.permissions.description.UpdateUserTeams",
        "Edit user teams"
      ),
      permission: ProjectPermission.UpdateUserTeams,
    },
    {
      name: t(
        "screens.permissions.description.UpdateValidationTask",
        "Validate task"
      ),
      permission: ProjectPermission.UpdateValidationTask,
    },
    {
      name: t("screens.permissions.description.UploadFile", "Upload file"),
      permission: ProjectPermission.UploadFile,
    },
    {
      name: t(
        "screens.permissions.description.UploadTaskAttachment",
        "Upload task attachment"
      ),
      permission: ProjectPermission.UploadTaskAttachment,
    },
    {
      name: t(
        "screens.permissions.description.UpdateLabelsTasks",
        "Edit tasks labels"
      ),
      permission: ProjectPermission.UpdateLabelsTasks,
    },
    {
      name: t("screens.permissions.description.UpdateSubtask", "Edit subtask"),
      permission: ProjectPermission.UpdateSubtask,
    },
    {
      name: t(
        "screens.permissions.description.OrgProjectAuthorize",
        "Authorize project"
      ),
      permission: ProjectPermission.OrgProjectAuthorize,
    },
  ];
}

type UserRolesOptions = {
  name: string;
  value: Role;
}[];

function getUserProjectRolesOptions(t: TFunction): UserRolesOptions {
  return [
    { name: t("enums.projectRoles.owner", "Owner"), value: Role.Owner },
    { name: t("enums.projectRoles.admin", "Admin"), value: Role.Administrator },
    {
      name: t("enums.projectRoles.standard", "Standard"),
      value: Role.Standard,
    },
    { name: t("enums.projectRoles.limited", "Limited"), value: Role.Limited },
    {
      name: t("enums.projectRoles.readonly", "Readonly"),
      value: Role.Readonly,
    },
    {
      name: t("enums.projectRoles.disabled", "Disabled"),
      value: Role.Disabled,
    },
  ];
}

function getI18nProjectRole(role: Role, t: TFunction) {
  return getUserProjectRolesOptions(t).find((option) => option.value === role)
    ?.name;
}

// Check if the current user is allowed to update a given role
const isAllowedToUpdateRole = (
  currentUserRole: Role,
  roleToChange: Role
): boolean => {
  const rolesAllowedToUpdate = ProjectRolesHierarchyMap[currentUserRole];

  // Check the role hierarchy
  return rolesAllowedToUpdate.includes(roleToChange);
};

// Check if the current user is allowed to update a given member
const isAllowedToUpdateMember = (
  currentUserRole: Role,
  roleToChange: Role,
  currentUserId?: string,
  inviterId?: string
): boolean => {
  if (isAllowedToUpdateRole(currentUserRole, roleToChange)) {
    // Owner & Admin user can update member below their role
    if (
      [Role.Owner, Role.Administrator].includes(currentUserRole) &&
      currentUserRole !== roleToChange
    ) {
      return true;
    }
    // Standard user can update member if he invited him (according to the role hierarchy)
    if (currentUserRole === Role.Standard && currentUserId && inviterId) {
      return currentUserId === inviterId;
    }
  }

  return false;
};

const isAllowedToUpdateOrgProjectSummary = (
  userProjectRole: Role,
  userOrgRole?: OrgRole
): boolean => {
  if (userOrgRole) {
    const isAllowedProjectRole = [
      Role.Owner,
      Role.Administrator,
      Role.Standard,
      Role.Limited,
    ].includes(userProjectRole);
    const isAllowedOrgRole = [
      OrgRole.Owner,
      OrgRole.Administrator,
      OrgRole.Standard,
    ].includes(userOrgRole);
    if (isAllowedProjectRole && isAllowedOrgRole) {
      return true;
    }
  }
  return false;
};

export {
  getI18nProjectRole,
  getProjectPermissionsOptions,
  getUserProjectRolesOptions,
  isAllowedToUpdateMember,
  isAllowedToUpdateOrgProjectSummary,
  isAllowedToUpdateRole,
};
export type { UserRolesOptions };
