import type {
  IProjectNotification,
  IProjectNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import {
  IProjectNotificationTypePrefix,
  ProjectNotificationNamespace,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";

interface ITask {
  id: string;
}

interface ITaskNotificationPayload extends IProjectNotificationPayload {
  task: ITask;
}

const ITaskNotificationTypePrefix =
  `${IProjectNotificationTypePrefix}/${ProjectNotificationNamespace.Task}` as const;
// All Chat notifications must extend from this interface rien qu
interface ITaskNotification extends IProjectNotification {
  type: `${typeof ITaskNotificationTypePrefix}/${string}`;
  readonly payload: ITaskNotificationPayload;
}

export type { ITaskNotification, ITaskNotificationPayload };
export { ITaskNotificationTypePrefix };
