import { z } from "zod";
import { isValidPhoneNumber } from "../../../config/phonenumber";

export const validationSchema = z.object({
  country_code: z.string().nonempty(),
  first_name: z.string().nonempty(),
  language: z.string().nonempty(),
  last_name: z.string().nonempty(),
  phone: z
    .string()
    .nonempty()
    .nullable()
    .optional()
    // Verify the phone number format only if it's provided
    .refine((v) => (v ? isValidPhoneNumber(v) : true)),
});
