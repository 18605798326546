import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import type { BadgeProps } from "..";

type RemoveButtonProps = Pick<BadgeProps, "shape" | "size"> & {
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
  css?: CSS;
};

const RemoveButtonWrapper = styled("button", {
  ...tw`flex-shrink-0 ml-1 inline-flex items-center justify-center focus:outline-none bg-white opacity-60 hover:opacity-80`,

  defaultVariants: {
    shape: "circular",
    size: "medium",
  },

  variants: {
    shape: {
      circular: tw`rounded-full`,
      rightRounded: tw`rounded-sm`,
      rounded: tw`rounded-sm`,
    },
    size: {
      large: tw`h-6 w-6`,
      medium: tw`h-4 w-4`,
    },
  },
});

const RemoveButtonLabel = styled("span", {
  ...tw`sr-only`,
});

const RemoveButtonIconWrapper = styled("svg", {
  defaultVariants: {
    size: "medium",
  },

  variants: {
    size: {
      large: tw`h-3 w-3`,
      medium: tw`h-2 w-2`,
    },
  },
});

export function RemoveButton(props: RemoveButtonProps) {
  const { onRemove, ...rest } = props;
  return (
    <RemoveButtonWrapper
      type="button"
      onClick={onRemove}
      // Even if we shouldn't have to, we must also listen on the touch event for
      // mobile devices.
      // Without this, when a badge is inside a "ReactSelectMultiInput" the "remove" action doesn't
      // work because the "click" event is never fired/reach this element.
      // See: CLVS-1624
      onTouchEnd={onRemove as any as React.TouchEventHandler<HTMLButtonElement>}
      {...rest}
    >
      <RemoveButtonLabel>Remove option</RemoveButtonLabel>
      <RemoveButtonIconWrapper
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
        size={props.size}
      >
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </RemoveButtonIconWrapper>
    </RemoveButtonWrapper>
  );
}
