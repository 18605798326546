import * as React from "react";
import { styled } from "~/config/stitches";
import { checkboxStyle } from "~/design-system/Checkbox";

type IndeterminateCheckboxProps = {
  indeterminate?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>((props, ref) => {
  const { indeterminate = false, ...rest } = props;
  const defaultRef = React.useRef<HTMLInputElement>(null);
  const resolvedRef = ref ?? defaultRef;

  React.useEffect(() => {
    (
      resolvedRef as React.MutableRefObject<HTMLInputElement>
    ).current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const StyledIndeterminateCheckbox = styled(
  IndeterminateCheckbox,
  checkboxStyle
);

export { IndeterminateCheckbox, StyledIndeterminateCheckbox };
