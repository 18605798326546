import { captureException } from "~/config/sentry";
import { streamChatClient } from ".";
import type { Channel, ChannelMember, StreamChatUser } from "./types";
import { ChannelTypeEnum } from "./types";

/* Initialise Stream Chat with current user stream_user_id (it's a different id than userId) */
const setStreamChatUser = async (user: StreamChatUser) => {
  const { token, ...rest } = user;
  try {
    // Connect user only if no user is connected on the client OR if the user is different than the one connected
    if (!streamChatClient.user || streamChatClient.user.id !== user.id) {
      await streamChatClient.connectUser(
        {
          ...rest,
        },
        token
      );
    }
  } catch (err) {
    console.error("Error to set Clovis Stream Chat User");
    captureException(err);
  }
};

const getChannelMembers = (channel: Channel, filteredStreamUserId?: string) => {
  return Object.values(
    channel.state.members as Record<string, ChannelMember>
  ).filter(
    // Remove current user from the list
    (m) => m.user_id !== filteredStreamUserId
  );
};

const getChannelName = (channel: Channel, filteredStreamUserId: string) => {
  // Get channel name or list members name as name
  return (
    channel.data?.name ??
    getChannelMembers(channel, filteredStreamUserId)
      .map((m) => m.user?.name)
      .join(", ")
  );
};

const isChannelModerator = (channel: Channel, filteredStreamUserId: string) => {
  return (
    Object.values(
      channel.state.members as Record<string, ChannelMember>
    ).filter(
      // Filter current user + check if moderator
      (m) => m.user_id === filteredStreamUserId && m.is_moderator === true
    ).length > 0
  );
};

const getChannelLastMessage = (channel: Channel) => {
  return channel.lastMessage();
};

const fetchProjectChannels = async (
  projectId: string,
  streamUserId: string,
  hidden?: boolean
) => {
  /* We are using custom field on getStream to be sure to get the right channels on the front-end
     for projects, orgs, or tasks see https://app.gitbook.com/o/-LlhZY09V4VTR7cy_55t/s/-MSitZZVNivUi5eDfPNe/guidelines/frontend/clovis-chat-getstream/getstream-custom-data-structure */
  return await streamChatClient.queryChannels(
    {
      clovis_entity_type: ChannelTypeEnum.Project,
      clovis_project_id: projectId,
      hidden: hidden ?? false,
      members: { $in: [streamUserId] },
    },
    { last_message_at: -1 },
    { limit: 30, presence: true }
  );
};

const fetchUserChannels = async (streamUserId: string, hidden?: boolean) => {
  return await streamChatClient.queryChannels(
    {
      clovis_entity_type: ChannelTypeEnum.DirectMessage,
      hidden: hidden ?? false,
      members: { $in: [streamUserId] },
    },
    { last_message_at: -1 },
    { limit: 30, presence: true }
  );
};

const fetchProjectDirectMessagesChannels = async (
  streamUserId: string,
  hidden?: boolean
) => {
  return await streamChatClient.queryChannels(
    {
      clovis_entity_type: ChannelTypeEnum.DirectMessage,
      clovis_message_context: "project",
      hidden: hidden ?? false,
      members: { $in: [streamUserId] },
    },
    { last_message_at: -1 },
    { limit: 30, presence: true }
  );
};

export {
  fetchProjectChannels,
  fetchProjectDirectMessagesChannels,
  fetchUserChannels,
  getChannelLastMessage,
  getChannelMembers,
  getChannelName,
  isChannelModerator,
  setStreamChatUser,
};
