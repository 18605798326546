import Avatar from "boring-avatars";
import * as React from "react";
import { styled } from "~/config/stitches";
import { colors } from "~/config/stitches/colors";

const StyledWrapper = styled("div", {
  defaultVariants: {
    shape: "circular",
  },
  variants: {
    shape: {
      circular: {
        "& > svg": {
          borderRadius: "9999px",
        },
      },
      rounded: {
        "& > svg": {
          borderRadius: "0.375rem",
        },
      },
    },
  },
});

const TextSize = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  XLARGE: "xlarge",
  XSMALL: "xsmall",
  XXSMALL: "xxsmall",
} as const;

const BORDER_SIZE_NUMBER = 6;

const textSizeToNumberSize = new Map([
  [TextSize.XXSMALL, 16],
  [TextSize.XSMALL, 24],
  [TextSize.SMALL, 32],
  [TextSize.MEDIUM, 42],
  [TextSize.LARGE, 48],
  [TextSize.XLARGE, 56],
]);

export const BoringAvatar = (props: {
  children: React.ReactNode;
  alt?: string;
  shape?: "circular" | "rounded";
  size?: "large" | "medium" | "small" | "xlarge" | "xsmall" | "xxsmall";
  withBorder?: boolean;
}) => {
  const size =
    (textSizeToNumberSize.get(props.size ?? "medium") ?? 40) -
    (props.withBorder ? BORDER_SIZE_NUMBER : 0);
  return (
    <StyledWrapper shape={props.shape}>
      <Avatar
        size={size}
        square={true}
        name={props.children?.toString() ?? props.alt ?? "avatar"}
        variant="marble"
        colors={[
          colors.amber500,
          colors.sky500,
          colors.rose500,
          colors.emerald500,
        ]}
      />
    </StyledWrapper>
  );
};
