import { validationSchema as schemaUpdateProjectRole } from "@clovis/server/src/app/domain/project/update-project-role/update-project-role.validation";
import { validationSchema as schemaUpdateUserTeams } from "@clovis/server/src/app/domain/project/update-user-teams/update-user-teams.validation";
import { validationSchema as schemaUpdateCompanyMetadata } from "@clovis/server/src/app/domain/project/update-users-company-name/update-users-company-name.validation";
import { validationSchema as schemaUpdateUserProfile } from "@clovis/server/src/app/domain/user/update-user-profile/update-user-profile.validation";
import { z } from "zod";
import { isValidPhoneNumber } from "../../../config/phonenumber";
import { validEmail } from "../../../config/zod/custom-validator";

export const validationSchema = z.object({
  email: validEmail().nonempty(),
  phone: z
    .string()
    .optional()
    // Verify the phone number format only if it's provided
    .refine((v) => (v ? isValidPhoneNumber(v) : true)),
  ...schemaUpdateProjectRole.shape,
  ...schemaUpdateCompanyMetadata.pick({ companyData: true }).shape,
  ...schemaUpdateUserTeams.pick({ teamIds: true }).shape,
  ...schemaUpdateUserProfile.pick({
    first_name: true,
    last_name: true,
  }).shape,
});
