import React from "react";
import { styled } from "~/config/stitches";
import { CellContext, CellParent } from "./Cell";

const StyledHead = styled("thead", {
  backgroundColor: "$gray50",
  boxShadow: "inset 0 1px 0 $colors$gray200, inset 0 -1px 0 $colors$gray200",
});

type HeadProps = React.ComponentProps<typeof StyledHead>;

const Head = (props: HeadProps) => {
  return (
    <CellContext.Provider value={CellParent.HEAD}>
      <StyledHead {...props} />
    </CellContext.Provider>
  );
};

export { Head };
