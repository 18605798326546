import {
  isStrongNotification,
  isWeakNotification,
} from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotifications } from "~/config/user/user-notifications/utils/types";

function getDisplayNotificationProps(notifications: UserBadgeNotifications) {
  const strongNotificationsCount =
    notifications.filter(isStrongNotification).length;
  const weakNotif =
    strongNotificationsCount === 0
      ? notifications.some(isWeakNotification)
      : false;

  return {
    strongNotificationsCount,
    weakNotif,
  };
}

export { getDisplayNotificationProps };
