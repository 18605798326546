import tw from "twin.macro";
import { styled } from "~/config/stitches";

const Label = styled("label", {
  ...tw`
  text-sm font-medium text-gray-700
  flex justify-between
  `,
});

export { Label };
