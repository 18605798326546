import * as React from "react";
import { Avatar } from "~/design-system/Avatar";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type OrgBadgeProps = Pick<
  BadgeProps,
  "children" | "clickable" | "onRemove" | "shadow" | "size"
> & {
  avatar?: string | null;
};

const OrgBadge = (props: OrgBadgeProps) => {
  const { avatar, ...rest } = props;

  return (
    <Badge
      avatar={
        <Avatar
          shape="rounded"
          alt={props.children?.toString()}
          src={avatar ?? undefined}
          size={props.size === "large" ? "xsmall" : "xxsmall"}
        />
      }
      shape="rounded"
      maxWidth="full"
      {...rest}
    >
      <Text size="inherit" truncate>
        {props.children}
      </Text>
    </Badge>
  );
};

export { OrgBadge };
