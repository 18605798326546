import * as React from "react";
import { AbacProvider } from "react-abac";
import { useUser } from "~/config/user/UserProvider";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer } from "~/design-system/Drawer";
import { createComponentHook } from "~/types";
import type { Role } from "../../Project.authorize";
import { rules } from "../../Project.authorize";
import { useUserProjectRoleQuery } from "../../Project.graphql";
import { ProjectTeamsDrawerContent } from "./components/ProjectTeamsDrawerContent";

type ProjectTeamsDrawerProps = {
  children?: React.ReactNode;
  controlled?: Pick<ControlledDrawerProps, "open" | "setOpen">;
  projectId: string;
};

function ProjectTeamsDrawer(props: ProjectTeamsDrawerProps) {
  if (props.controlled) {
    return (
      <Drawer open={props.controlled.open} setOpen={props.controlled.setOpen}>
        <ProjectTeamsDrawerContent projectId={props.projectId} />
        {props.children}
      </Drawer>
    );
  }
  return (
    <Drawer>
      <ProjectTeamsDrawerContent projectId={props.projectId} />
      {props.children}
    </Drawer>
  );
}

const useAbacProjectTeamsDrawer = createComponentHook(
  (props: ProjectTeamsDrawerProps) => {
    const { user } = useUser();
    const [{ data }] = useUserProjectRoleQuery({
      variables: { projectId: props.projectId, userId: user.id },
    });
    const userProjectRoleName = data?.projects_by_pk?.members[0]?.role
      .name as Role;

    return {
      state: {
        user,
        userProjectRoleName,
      },
    };
  }
);

function AbacProjectTeamsDrawer(props: ProjectTeamsDrawerProps) {
  const { state } = useAbacProjectTeamsDrawer(props);
  return (
    <AbacProvider
      key={props.projectId}
      roles={[state.userProjectRoleName]}
      rules={rules}
      user={state.user}
    >
      <ProjectTeamsDrawer {...props} />
    </AbacProvider>
  );
}

export type { ProjectTeamsDrawerProps };
export { AbacProjectTeamsDrawer as ProjectTeamsDrawer };
