import { styled } from "~/config/stitches";
import { hasNotch } from "~/design-system/SafeArea";

const ScrollableContent = styled("div", {
  "&::after": {
    backgroundImage:
      "linear-gradient(to top, rgba(255,255,255,0.98) 0%, rgba(255,255,255,0.90) 40%, rgba(255,255,255,0.40) 80%, rgba(255,255,255,0) 100%)",
    backgroundRepeat: "no-repeat",
    bottom: "0",
    content: "",
    height: "95px",
    left: "-$small",
    overflow: "hidden",
    pointerEvents: "none",
    position: "absolute",
    width: "150%",
    zIndex: 2,
  },
  "&::before": {
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0.98) 0%, rgba(255,255,255,0.835) 45%, rgba(255,255,255,0.40) 80%, rgba(255,255,255,0) 100%)",
    backgroundRepeat: "no-repeat",
    content: "",
    height: "70px",
    left: "-$small",
    overflow: "hidden",
    pointerEvents: "none",
    position: "absolute",
    top: "0",
    width: "150%",
    zIndex: 2,
  },
  maxHeight: "100vh",
  paddingBottom: "calc($sizes$appbar + $space$9) !important",

  paddingTop: hasNotch()
    ? "$sizes$appbar !important"
    : "calc($sizes$appbar + $space$2) !important",
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  "@desktop": {
    "&::after": {
      backgroundImage: "none",
      display: "none",
    },
    "&::before": {
      backgroundImage: "none",
      display: "none",
    },
    maxHeight: "100%",
    paddingBottom: "initial !important",
    paddingTop: "initial !important",
  },
  "@tablet": {
    "&::after": {
      backgroundImage: "none",
      display: "none",
    },
    "&::before": {
      backgroundImage: "none",
      display: "none",
    },
    maxHeight: "100%",
    paddingBottom: "initial !important",
    paddingTop: "initial !important",
  },

  overflowY: "auto",
  variants: {
    fullHeight: {
      true: {
        // Usefull for some screens like tasks list to center the emptyTask placeholder
        height: "100%",
      },
    },
    overflowVisible: {
      true: {
        // The negative margin cancels the padding which allow children with box-shadow to be fully visible in a overflow-scroll context
        margin: "calc(-1 * $xsmall)",
        padding: "$xsmall",
      },
    },
  },
});

export { ScrollableContent };
