import {
  AdjustmentsIcon,
  BellIcon,
  BookOpenIcon,
  ChatAltIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  LightningBoltIcon,
  LogoutIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import * as React from "react";
import { useAbac } from "react-abac";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { env } from "~/config/env";
import type { CSS } from "~/config/stitches";
import { useWorkerStatusContext } from "~/config/user/workerstatus/WorkerStatus";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerContent, useDrawerContext } from "~/design-system/Drawer";
import { useBreakpoint } from "~/design-system/hooks";
import { Icon } from "~/design-system/Icon";
import { hasNotch, SafeArea } from "~/design-system/SafeArea";
import { Spinner } from "~/design-system/Spinner/Spinner";
import { Text } from "~/design-system/Text";
import { DrawerMenuSectionHeader } from "~/screens/App/components/NavigationDrawer/components/DrawerMenuSectionHeader";
import { AllNotificationsBadge } from "~/screens/App/components/NotificationsBadge/AllNotificationsBadge";
import { OrgBadge } from "~/screens/App/components/NotificationsBadge/OrgBadge";
import { OrgManageMembersBadge } from "~/screens/App/components/NotificationsBadge/OrgManageMembersBadge";
import { WorkerStatusDetailsDrawer } from "~/screens/App/screens/components/WorkerStatusDetailsDrawer/WorkerStatusDetailsDrawer";
import {
  OrgAbacProvider,
  Permission,
} from "~/screens/App/screens/Org/Org.authorize";
import { useLogout } from "~/screens/App/utils/useLogout/useLogout";
import { createComponentHook } from "~/types";
import type { UserOrg } from "../../../NavigationDrawer";
import { SettingsDrawerContentItem } from "./SettingsDrawerContentItem";

type SettingsDrawerContentProps = {
  orgs?: UserOrg[];
  activeOrgId?: string;
  css?: CSS;
  closeDrawer?: () => void;
};

const useSettingsDrawerContent = createComponentHook(
  (props: SettingsDrawerContentProps) => {
    const { breakpoint } = useBreakpoint();
    const { t } = useTranslation();
    const { logout } = useLogout();
    const { userHasPermissions } = useAbac();
    const { setOpen } = useDrawerContext();
    const IntercomContext = useIntercom();
    const { hasPending } = useWorkerStatusContext();
    const [workerStatusOpen, setWorkerStatusOpen] = React.useState(false);

    const handleClose = () => {
      props.closeDrawer ? props.closeDrawer() : setOpen(false);
    };

    const openIntercom = () => {
      if (IntercomContext) {
        IntercomContext.show();
        handleClose();
      }
    };

    const openWorkerStatus = () => {
      setWorkerStatusOpen(true);
    };

    const handleLogoutClick = async () => {
      await logout();
    };
    const userOptions = [
      {
        display: true,
        icon: <UserIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.MySettings",
          "My settings"
        ),
        notif: null,
        notifications: undefined,
        toRoute: "/user/profile",
        weakNotif: false,
      },
      {
        display: true,
        icon: <BellIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.MyNotifications",
          "My notifications"
        ),
        notif: <AllNotificationsBadge />,
        toRoute: "/user/notifications",
      },
      {
        display: true,
        icon: hasPending ? <Spinner size="small" /> : <LightningBoltIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.workerStatus",
          "Pending processes"
        ),
        notif: null,
        onClick: openWorkerStatus,
      },
    ];

    const activeOrgOptions = [
      {
        display: true,
        icon: <UsersIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.ManageMembers",
          "Manage members"
        ),
        notif: props.activeOrgId ? (
          <OrgManageMembersBadge orgId={props.activeOrgId} />
        ) : null,
        toRoute: `/org/${props.activeOrgId}/members`,
      },
      {
        display: userHasPermissions(Permission.UpdateOrgProfile),
        icon: <AdjustmentsIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.OrganisationSettings",
          "Organisation settings"
        ),
        notif: null,
        toRoute: `/org/${props.activeOrgId}/edit`,
      },
    ];

    const helpOptions = [
      {
        actionId: "help-and-support-guide-button",
        display: true,
        href: "https://help.clovis.app",
        icon: <BookOpenIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.helpSupportGuide",
          "Help & support guide"
        ),
        notif: null,
      },
      {
        actionId: "chat-with-sales-button",
        display: true,
        icon: <ChatAltIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.chatWithSales",
          "Chat with sales"
        ),
        notif: null,
        onClick: openIntercom,
      },
      // {
      //   actionId: "go-to-youtube-button",
      //   display: true,
      //   href: "https://www.youtube.com/channel/UCYGxRFdancnoAMlMb_LCx8g",
      //   icon: <VideoCameraIcon />,
      //   name: t(
      //     "screens.NavigationDrawer.SettingsDrawerContent.youtubeChannel",
      //     "Youtube Channel"
      //   ),
      //   notif: null,
      // },
      {
        actionId: "go-to-terms-and-privacy-button",
        display: true,
        href: "https://clovis.app/legals",
        icon: <DocumentTextIcon />,
        name: t(
          "screens.NavigationDrawer.SettingsDrawerContent.termsAndPrivacy",
          "Terms & Privacy"
        ),
        notif: null,
      },
    ];

    return {
      actions: {
        handleClose,
        handleLogoutClick,
        setWorkerStatusOpen,
        userHasPermissions,
      },
      state: {
        activeOrgOptions,
        breakpoint,
        helpOptions,
        userOptions,
        workerStatusOpen,
      },
      t,
    };
  }
);

const SettingsDrawerContent = (props: SettingsDrawerContentProps) => {
  const { actions, state, t } = useSettingsDrawerContent(props);

  return (
    <>
      <DrawerContent
        position="left"
        safeAreaTop={false}
        safeAreaBottom={false}
        background={false}
        scrollable={false}
        css={{
          height: "100%",
          maxWidth: "360px",
          width: "calc(100vw - 20px)",
          ...props.css,
        }}
      >
        <Box
          overflowY="auto"
          width="full"
          height="full"
          background="navyDark"
          paddingY={hasNotch() ? undefined : "small"}
        >
          <SafeArea top bottom>
            <>
              <Box paddingX="small" marginBottom="xsmall">
                <DrawerMenuSectionHeader
                  data-intercom-target="drawer-header-org"
                  title={t(
                    "screens.NavigationDrawer.NavigationDrawerHeader.profile",
                    "My profile menu"
                  )}
                />
              </Box>
              {state.userOptions.map(
                (option) =>
                  option.display && (
                    <SettingsDrawerContentItem
                      key={option.name}
                      toRoute={option.toRoute}
                      onClick={option.onClick ?? actions.handleClose}
                      avatar={
                        <Icon label={`${option.name} icon`} color="white">
                          {option.icon}
                        </Icon>
                      }
                      title={
                        <Text
                          truncate={true}
                          className="option-name"
                          tone="white"
                        >
                          {option.name}
                        </Text>
                      }
                      notif={option.notif}
                    />
                  )
              )}

              {props.orgs
                ?.sort((a) => (a.id === props.activeOrgId ? 1 : -1))
                .map((org) => {
                  const isActive = org.id === props.activeOrgId;
                  return (
                    <React.Fragment key={org.id}>
                      <Box
                        paddingX="small"
                        marginBottom="xsmall"
                        marginTop="small"
                      >
                        <DrawerMenuSectionHeader
                          data-intercom-target="drawer-header-org"
                          title={t(
                            "screens.NavigationDrawer.NavigationDrawerHeader.lorg",
                            "My company options"
                          )}
                        />
                      </Box>
                      {actions.userHasPermissions(
                        Permission.UpdateOrgProfile
                      ) && (
                        <SettingsDrawerContentItem
                          key={org.name}
                          onClick={actions.handleClose}
                          toRoute={`/org/${org.id}/edit`}
                          avatar={
                            org.avatar ? (
                              <Avatar
                                alt={`${org.name} avatar`}
                                src={org.avatar}
                                shape="circular"
                                size="large"
                              />
                            ) : (
                              <Avatar size="large" shape="circular">
                                {org.name}
                              </Avatar>
                            )
                          }
                          title={
                            <Text
                              truncate={true}
                              className="option-name"
                              tone={isActive ? "caution" : "white"}
                            >
                              {org.name}
                            </Text>
                          }
                          notif={
                            isActive ? (
                              <Icon
                                color="caution"
                                label="active organisation"
                                css={{
                                  transform: "translateX(2px)",
                                }}
                              >
                                <CheckCircleIcon />
                              </Icon>
                            ) : (
                              <OrgBadge orgId={org.id} />
                            )
                          }
                        />
                      )}
                      {isActive &&
                        state.activeOrgOptions.map(
                          (option) =>
                            option.display && (
                              <SettingsDrawerContentItem
                                key={option.name}
                                onClick={actions.handleClose}
                                toRoute={option.toRoute}
                                avatar={
                                  <Icon
                                    label={`${option.name} icon`}
                                    color="white"
                                  >
                                    {option.icon}
                                  </Icon>
                                }
                                title={
                                  <Text
                                    truncate={true}
                                    className="option-name"
                                    tone="white"
                                  >
                                    {option.name}
                                  </Text>
                                }
                                notif={option.notif}
                              />
                            )
                        )}
                    </React.Fragment>
                  );
                })}
              <Box paddingX="small" marginBottom="xsmall" marginTop="small">
                <DrawerMenuSectionHeader
                  data-intercom-target="drawer-header-org"
                  title={t(
                    "screens.NavigationDrawer.NavigationDrawerHeader.usefullLinks",
                    "Usefull links"
                  )}
                />
              </Box>
              {state.helpOptions.map(
                (option) =>
                  option.display && (
                    <div key={option.name}>
                      <SettingsDrawerContentItem
                        actionId={option.actionId}
                        key={option.name}
                        href={option.href}
                        onClick={option.onClick ?? actions.handleClose}
                        avatar={
                          <Icon label={`${option.name} icon`} color="white">
                            {option.icon}
                          </Icon>
                        }
                        title={
                          <Text truncate={true} tone="white">
                            {option.name}
                          </Text>
                        }
                        notif={option.notif}
                      />
                    </div>
                  )
              )}
              <Box
                width="full"
                display="flex"
                justifyContent="center"
                padding="small"
              >
                <Text tone="neutral">
                  {`Clovis ${env.VITE_RELEASE}`}{" "}
                  {env.VITE_RELEASE_DATE && (
                    <>
                      —{" "}
                      {t(
                        "screens.NavigationDrawer.SettingsDrawerContent.appUpdatedTime",
                        "Updated {{date, relative}}",
                        {
                          date:
                            env.VITE_RELEASE_DATE &&
                            new Date(env.VITE_RELEASE_DATE),
                        }
                      )}
                    </>
                  )}
                </Text>
              </Box>
              <Box
                width="full"
                display="flex"
                justifyContent="center"
                padding="small"
              >
                <Button
                  dataIntercomTarget="profile-menu-logout-button"
                  leadingIcon={<LogoutIcon />}
                  variant="transparent"
                  onClick={actions.handleLogoutClick}
                  css={{
                    "&:hover": {
                      color: "$slate5600",
                    },
                    color: "$slate300",
                  }}
                >
                  {t(
                    "screens.NavigationDrawer.SettingsDrawerContent.logout",
                    "Log out"
                  )}
                </Button>
              </Box>
            </>
          </SafeArea>
        </Box>
      </DrawerContent>
      <WorkerStatusDetailsDrawer
        controlled={{
          open: state.workerStatusOpen,
          setOpen: actions.setWorkerStatusOpen,
        }}
      />
    </>
  );
};

/* 
  Necessary to wrap here because AbacSettingsDrawerContent
  is not children of OrgAbacProvider where else
*/
const AbacSettingsDrawerContent = (props: SettingsDrawerContentProps) => {
  return (
    <OrgAbacProvider>
      <SettingsDrawerContent {...props} />
    </OrgAbacProvider>
  );
};

export { AbacSettingsDrawerContent as SettingsDrawerContent };
