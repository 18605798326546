import * as React from "react";
import { Box } from "../../Box";
import { Text } from "../../Text";

export const LabelGroup = (props: {
  toggleAlign: "justify" | "left" | "right";
  label?: React.ReactNode;
  description?: React.ReactNode;
}) => {
  const leftToggleAlign = props.toggleAlign === "left";
  return (
    <Box
      /* 
        Issue for now, thoses props are not considered:
        display="flex"
        flexDirection="column"
      */
      css={{ display: "flex", flexDirection: "column" }}
      paddingLeft={leftToggleAlign ? "xsmall" : undefined}
      paddingRight={leftToggleAlign ? undefined : "xsmall"}
    >
      {props.label && <Text>{props.label}</Text>}
      {props.description && (
        <Text size="small" className="description">
          {props.description}
        </Text>
      )}
    </Box>
  );
};
