import type * as Types from '../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WorkerStatus_StatusFragment = { created_at: string, updated_at: string, creator_id: string, event_id: string, payload: any, type: string, status: string };

export type WorkerStatusQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type WorkerStatusQuery = { workerStatus: Array<{ created_at: string, updated_at: string, creator_id: string, event_id: string, payload: any, type: string, status: string }> };

export const WorkerStatus_StatusFragmentDoc = gql`
    fragment WorkerStatus_status on events_workers_status {
  created_at
  updated_at
  creator_id
  event_id
  payload
  type
  status
}
    `;
export const WorkerStatusDocument = gql`
    query workerStatus($userId: uuid!) {
  workerStatus: events_workers_status(
    where: {creator_id: {_eq: $userId}}
    order_by: {created_at: desc}
    limit: 100
  ) {
    ...WorkerStatus_status
  }
}
    ${WorkerStatus_StatusFragmentDoc}`;

export function useWorkerStatusQuery(options: Omit<Urql.UseQueryArgs<WorkerStatusQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<WorkerStatusQuery>({ query: WorkerStatusDocument, ...options });
};