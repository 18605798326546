import * as React from "react";
import type { FieldProps } from "~/design-system/Field";
import { Field } from "~/design-system/Field";
import type { SelectColorInputProps } from "./components/SelectColorInput";
import { SelectColorInput } from "./components/SelectColorInput";

type SelectColorFieldProps = Omit<FieldProps, "children"> &
  SelectColorInputProps;

const SelectColorField = React.forwardRef<
  HTMLSelectElement,
  SelectColorFieldProps
>(function SelectColorField(props: SelectColorFieldProps, ref) {
  const fieldProps: Omit<FieldProps, "children"> = {
    cornerHint: props.cornerHint,
    dataIntercomTarget: props.dataIntercomTarget,
    error: props.error,
    label: props.label,
    message: props.message,
    name: props.name,
    required: props.required,
    secondaryLabel: props.secondaryLabel,
    tone: props.tone,
  };

  const { control, ...rest } = props;

  return (
    <Field {...fieldProps}>
      <SelectColorInput control={control} {...rest} ref={ref} />
    </Field>
  );
});

export { SelectColorField };
