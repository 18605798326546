import type { PermissionType } from "@clovis/server/src/app/domain/utils/permissions";
import React from "react";
import { useTranslation } from "react-i18next";
import { getI18nPermission } from "~/config/users-rights-permissions/utils";
import { Text } from "~/design-system/Text";

type ProjectPermissionTableProps = {
  permission: PermissionType;
};

const PermissionTranslation = (props: ProjectPermissionTableProps) => {
  const { t } = useTranslation();
  return <Text>{getI18nPermission(props.permission, t)}</Text>;
};

export { PermissionTranslation };
