import type { Color } from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import { useAbac } from "react-abac";
import { useTranslation } from "react-i18next";
import { getI18nProjectRole } from "~/config/users-rights-permissions/project";
import { Button } from "~/design-system/Button";
import { DialogTrigger } from "~/design-system/Dialog";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { UserBadgeButton } from "~/screens/App/components/Badges";
import { TeamBadgeButton } from "~/screens/App/components/Badges/TeamBadgeButton";
import { EditUserDialog } from "~/screens/App/screens/Project/Info/screens/ProjectMembersScreen/components/ProjectMembersList/components/ProjectMembersListItemActions/components/EditUserDialog/EditUserDialog";
import type { Role } from "~/screens/App/screens/Project/Project.authorize";
import { Permission } from "~/screens/App/screens/Project/Project.authorize";
import { createComponentHook } from "~/types";
import type {
  UserProfileContainer_DataQuery,
  UserProjectInfo_DataQuery,
} from "../../UserProfileContainer.graphql";
import { InfoItem } from "./components/InfoItem";
import { InfoItemWithBadges } from "./components/InfoItemWithBadges";

type ProjectInfoListProps = {
  user: NonNullable<UserProfileContainer_DataQuery["users_by_pk"]>;
  handleClose: () => void;
  userProjectInfos: UserProjectInfo_DataQuery["projects_by_pk"];
};

type InfosItems = {
  description?: string;
  title: string;
  badges?: React.ReactNode[];
  cta?: React.ReactNode;
}[];

const useProjectInfoList = createComponentHook(
  (props: ProjectInfoListProps) => {
    const { t } = useTranslation();
    const { userHasPermissions } = useAbac();
    const editionDisabled =
      !userHasPermissions(
        Permission.UpdateProjectRole,
        props.userProjectInfos?.members[0].role.name
      ) ||
      !userHasPermissions(
        Permission.UpdateUserTeams,
        props.userProjectInfos?.members[0].role.name
      );

    const infos: InfosItems = props.userProjectInfos
      ? [
          ...(props.userProjectInfos.members[0].inviter
            ? [
                {
                  badges: [
                    <>
                      <UserBadgeButton
                        key={props.userProjectInfos.members[0].inviter.id}
                        userId={props.userProjectInfos.members[0].inviter.id}
                        size="large"
                        onCloseDrawer={props.handleClose}
                        avatar={
                          props.userProjectInfos.members[0].inviter.avatar
                        }
                      >
                        {props.userProjectInfos.members[0].inviter.full_name}
                      </UserBadgeButton>
                      <Text tone="neutral" size="small">
                        {t(
                          "components.UserProfileDrawer.ProjectInfoList.invitationDate",
                          "on {{invitationDate, datetime}}",
                          {
                            formatParams: {
                              invitationDate: {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              },
                            },
                            invitationDate: new Date(
                              props.userProjectInfos.members[0].created_at
                            ),
                          }
                        )}
                      </Text>
                    </>,
                  ],
                  title: t(
                    "components.UserProfileDrawer.ProjectInfoList.invitedBy",
                    "Invited in {{projectName}} project by",
                    {
                      projectName: props.userProjectInfos.name,
                    }
                  ),
                },
              ]
            : []),
          {
            cta: (
              <EditUserDialog member={props.userProjectInfos.members[0]}>
                <DialogTrigger asChild>
                  <Button
                    link
                    css={{ height: "auto" }}
                    disabled={editionDisabled}
                    dataIntercomTarget="user-profile-edit"
                  >
                    {t(
                      "components.UserProfileDrawer.ProjectInfoList.edit",
                      "edit"
                    )}
                  </Button>
                </DialogTrigger>
              </EditUserDialog>
            ),
            description: getI18nProjectRole(
              props.userProjectInfos.members[0].role.name as Role,
              t
            ),
            title: t(
              "components.UserProfileDrawer.ProjectInfoList.rightInProject",
              "Right in {{projectName}} project",
              {
                projectName: props.userProjectInfos.name,
              }
            ),
          },
          {
            badges: props.userProjectInfos.members[0].user.teams.length
              ? props.userProjectInfos.members[0].user.teams.map((t) => (
                  <TeamBadgeButton
                    key={t.team_id}
                    teamId={t.team_id}
                    color={t.team.color as Color}
                    size="large"
                    onCloseDrawer={props.handleClose}
                  >
                    {t.team.name}
                  </TeamBadgeButton>
                ))
              : undefined,
            cta: (
              <EditUserDialog member={props.userProjectInfos.members[0]}>
                <DialogTrigger asChild>
                  <Button
                    link
                    css={{ height: "auto" }}
                    disabled={editionDisabled}
                    dataIntercomTarget="user-profile-edit"
                  >
                    {t(
                      "components.UserProfileDrawer.ProjectInfoList.edit",
                      "edit"
                    )}
                  </Button>
                </DialogTrigger>
              </EditUserDialog>
            ),
            description: props.userProjectInfos.members[0].user.teams.length
              ? undefined
              : t(
                  "components.UserProfileDrawer.ProjectInfoList.noTeam",
                  "no team"
                ),
            title: t(
              "components.UserProfileDrawer.ProjectInfoList.teamsInProject",
              "Teams in {{projectName}} project",
              {
                projectName: props.userProjectInfos.name,
              }
            ),
          },
        ]
      : [];

    return {
      state: { infos },
    };
  }
);

function ProjectInfoList(props: ProjectInfoListProps) {
  const { state } = useProjectInfoList(props);
  return (
    <Stack space="small">
      {state.infos.map((info, index) => {
        if (info.badges) {
          return (
            <InfoItemWithBadges
              key={index}
              title={info.title}
              cta={info.cta}
              badges={info.badges}
            />
          );
        } else {
          return (
            <InfoItem
              key={index}
              title={info.title}
              description={info.description ?? ""}
              cta={info.cta}
            />
          );
        }
      })}
    </Stack>
  );
}

export { ProjectInfoList };
