import * as React from "react";
import type { BasicMultiSelectFieldInputProps } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { createComponentHook } from "~/types";
import { useGetProjectTeamsQuery } from "./ProjectTeamsMultiSelectFieldInput.graphql";

const useProjectMultiSelectFieldInput = createComponentHook(
  (props: ProjectTeamsMultiSelectFieldInputProps) => {
    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["projects"] }),
      []
    );
    const [{ data }] = useGetProjectTeamsQuery({
      context: queryContext,
      variables: { projectId: props.projectId },
    });

    return {
      state: {
        teams:
          data?.projects_by_pk?.teams.map((t) => ({
            color: t.color,
            label: t.name,
            type: BasicMultiSelectFieldInputOptionType.TEAM,
            value: t.id,
          })) ?? [],
      },
    };
  }
);

type ProjectTeamsMultiSelectFieldInputProps = Omit<
  BasicMultiSelectFieldInputProps<any>,
  "options"
> & {
  projectId: string;
};

const ProjectTeamsMultiSelectFieldInput = React.forwardRef<
  HTMLInputElement,
  ProjectTeamsMultiSelectFieldInputProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(function ProjectTeamsMultiSelectFieldInput(props, _) {
  const { state } = useProjectMultiSelectFieldInput(props);

  return (
    <BasicMultiSelectFieldInput
      options={state.teams}
      defaultValue={[]}
      {...props}
    />
  );
});

export { ProjectTeamsMultiSelectFieldInput };
