import { UsersIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "~/screens/App/components/CloseButton";

function DrawerCloseButton() {
  return (
    <Box>
      <DrawerClose asChild>
        <CloseButton dataIntercomTarget="project-teams-close" />
      </DrawerClose>
    </Box>
  );
}

function Header() {
  const { t } = useTranslation();

  return (
    <Box padding="gutter">
      <Stack space="medium">
        <Box display="flex" justifyContent="spaceBetween" alignItems="center">
          <Inline space="small">
            <Icon label="teams icon">
              <UsersIcon />
            </Icon>
            <Heading level="3">
              {t("screens.ProjectTeamsDrawer.Header.title", "List of teams")}
            </Heading>
          </Inline>
          <DrawerCloseButton />
        </Box>
        <Text>
          {t(
            "screens.ProjectTeamsDrawer.Header.message",
            "Teams are used to categorize and notify stakeholder groups."
          )}
        </Text>
      </Stack>
    </Box>
  );
}

export { Header };
