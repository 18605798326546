import * as React from "react";
import { useParams } from "react-router-dom";
import {
  useUserNotificationsContext,
  useUserNotificationsHook,
} from "~/config/user/user-notifications/UserNotifications";
import { useUser } from "~/config/user/UserProvider";
import { filterOrgMemberListAvatarBadge } from "~/screens/App/components/NotificationsBadge/OrgMembersListAvatarBadge";
import { useUserProjectRoleQuery } from "~/screens/App/screens/Project/Project.graphql";
import { createComponentHook } from "~/types";
import { UserProfile } from "./UserProfile";
import {
  useUserProfileContainer_DataQuery,
  useUserProjectInfo_DataQuery,
} from "./UserProfileContainer.graphql";

type UserProfileContainerProps = {
  userId: string;
  handleClose: () => void;
};

const useUserProfileContainer = createComponentHook(
  (props: UserProfileContainerProps) => {
    const { projectId } = useParams() as { projectId: string };
    const { user: currentUser } = useUser();
    const [{ data: currentUserData }] = useUserProjectRoleQuery({
      variables: { projectId, userId: currentUser.id },
    });
    const currentUserProjectRole =
      currentUserData?.projects_by_pk?.members[0]?.role;

    const {
      actions: { handleMarkNotificationsAsRead },
    } = useUserNotificationsHook();
    const getNotifications = useUserNotificationsContext(
      (state) => state.getNotifications
    );

    const [{ data }] = useUserProfileContainer_DataQuery({
      variables: { userId: props.userId },
    });
    const [{ data: userProjectInfos }] = useUserProjectInfo_DataQuery({
      pause: projectId === undefined,
      variables: { projectId: projectId, userId: props.userId },
    });
    const { orgId } = useParams() as {
      orgId?: string;
    };

    const loadedUserId = data?.users_by_pk?.id;

    const markOrgNotificationsAsRead = React.useCallback(
      (orgId: string, loadedUserId: string) => {
        const notificationsIds = getNotifications((n) =>
          filterOrgMemberListAvatarBadge(n, orgId, loadedUserId)
        ).map((n) => n.id);
        void handleMarkNotificationsAsRead(notificationsIds);
      },
      [handleMarkNotificationsAsRead, getNotifications]
    );

    React.useLayoutEffect(() => {
      if (loadedUserId) {
        if (orgId) {
          markOrgNotificationsAsRead(orgId, loadedUserId);
        }
      }
    }, [loadedUserId, orgId]);

    return {
      state: {
        currentUser,
        currentUserProjectRole,
        projectId,
        user: data?.users_by_pk,
        userProjectInfos: userProjectInfos?.projects_by_pk,
      },
    };
  }
);

function UserProfileContainer(props: UserProfileContainerProps) {
  const { state } = useUserProfileContainer(props);

  return state.user ? (
    <UserProfile
      user={state.user}
      currentUser={state.currentUser}
      userProjectInfos={state.userProjectInfos}
      currentUserProjectRole={state.currentUserProjectRole?.name}
      handleClose={props.handleClose}
    />
  ) : null;
}

export { UserProfileContainer };
