import { z } from "zod";

// eslint-disable-next-line no-control-regex
const nonAscii = /^[\u0000-\u007f]*$/;

const validEmail = () =>
  z
    .string()
    .email()
    .regex(nonAscii, "no special characters (é, ñ, ...) allowed");

export { validEmail };
