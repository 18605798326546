import * as React from "react";
import { Avatar } from "~/design-system/Avatar";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type ProjectBadgeProps = Pick<BadgeProps, "children" | "onRemove" | "size"> & {
  avatar?: string | null;
  onClick?: () => void;
};

const ProjectBadge = (props: ProjectBadgeProps) => {
  const { avatar, ...rest } = props;

  return (
    <Badge
      avatar={
        <Avatar
          shape="rounded"
          alt={props.children?.toString()}
          src={avatar ?? undefined}
          size={props.size === "large" ? "xsmall" : "xxsmall"}
        />
      }
      onClick={() => props.onClick?.()}
      shape="rounded"
      maxWidth="full"
      {...rest}
    >
      <Text noSelectable size="inherit" truncate>
        {props.children}
      </Text>
    </Badge>
  );
};

export { ProjectBadge };
