import tw from "twin.macro";
import { styled } from "~/config/stitches";

export const StyledToggleSpan = styled("span", {
  ...tw`
  flex
  items-center	
  justify-center	
  translate-x-0 
  pointer-events-none 
  h-5 w-5 
  rounded-full 
  bg-white shadow 
  transform ring-0 transition ease-in-out duration-200
  `,
});
