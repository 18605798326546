import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteFirebaseTokenMutationVariables = Types.Exact<{
  input: Types.DeleteFirebaseTokenInput;
}>;


export type DeleteFirebaseTokenMutation = { deleteFirebaseToken?: { __typename: 'DeleteFirebaseTokenErrors' } | { __typename: 'DeleteFirebaseTokenSuccess' } | null | undefined };


export const DeleteFirebaseTokenDocument = gql`
    mutation deleteFirebaseToken($input: DeleteFirebaseTokenInput!) {
  deleteFirebaseToken(input: $input) {
    __typename
  }
}
    `;

export function useDeleteFirebaseTokenMutation() {
  return Urql.useMutation<DeleteFirebaseTokenMutation, DeleteFirebaseTokenMutationVariables>(DeleteFirebaseTokenDocument);
};