import type { DropdownMenuProps as RadixDropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import * as React from "react";
import { createComponentHook } from "~/types";
import { DefaultTrigger } from "./components/DefaultTrigger";
import { DropdownMenuButton } from "./components/DropdownMenuButton";
import type { DropdownMenuContentProps } from "./components/DropdownMenuContent";
import { MinimalTrigger } from "./components/MinimalTrigger";

type DropdownMenuContentAsDropdownMenuChildrenProps = React.ReactElement<
  Exclude<DropdownMenuContentProps, "dropdownOpen">
>;

type DropdownMenuProps = RadixDropdownMenuProps & {
  children: DropdownMenuContentAsDropdownMenuChildrenProps;
  label?: string;
  trigger?: React.ReactElement;
  /* for submenus */
  triggerItem?: React.ReactElement;
  minimal?: boolean;
  dropDownOpenDispatch?: (open: boolean) => void;
};

const useDropdownMenu = createComponentHook(() => {
  /* used in DropdownMenuContent for scroll arrows */
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  return {
    actions: {
      setDropdownOpen,
    },
    state: {
      dropdownOpen,
    },
  };
});

function DropdownMenu(props: DropdownMenuProps) {
  const { actions, state } = useDropdownMenu();
  const {
    children,
    label,
    minimal,
    trigger,
    triggerItem,
    ...radixDropdownProps
  } = props;
  return (
    // Add default LTR dir for the dropdown to avoid requestAnimationFrame spamming event
    // See: https://github.com/radix-ui/primitives/issues/749
    // TODO: upgrade to new radix version which seems to fix this issue
    // See: https://github.com/radix-ui/primitives/pull/1119
    <RadixDropdownMenu.Root
      dir="ltr"
      {...radixDropdownProps}
      onOpenChange={(open: boolean) => {
        actions.setDropdownOpen(open);
        props.dropDownOpenDispatch?.(open);
      }}
    >
      {trigger ? (
        React.cloneElement(trigger, {
          as: DropdownMenuButton,
        })
      ) : triggerItem ? (
        triggerItem
      ) : minimal ? (
        <MinimalTrigger label={label ?? ""} />
      ) : (
        <DefaultTrigger label={label ?? ""} />
      )}

      {React.cloneElement(children, {
        dropdownOpen: state.dropdownOpen,
        ...(children.props as DropdownMenuContentAsDropdownMenuChildrenProps),
      })}
    </RadixDropdownMenu.Root>
  );
}

export type { DropdownMenuProps };
export { DropdownMenu };
