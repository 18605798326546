import type * as Stitches from "@stitches/react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const BadgeWrapper = styled("span", {
  ...tw`inline-flex items-center py-0.5 font-medium`,

  compoundVariants: [
    {
      css: tw`pl-2.5`,
      leftMargin: true,
      shape: "circular",
      size: "medium",
    },
    {
      css: tw`pr-2.5`,
      rightMargin: true,
      shape: "circular",
      size: "medium",
    },
    {
      css: tw`pl-3`,
      leftMargin: true,
      shape: "circular",
      size: "large",
    },
    {
      css: tw`pr-3`,
      rightMargin: true,
      shape: "circular",
      size: "large",
    },
    {
      css: tw`pl-2`,
      leftMargin: true,
      shape: "rounded",
      size: "medium",
    },
    {
      css: tw`pr-2`,
      rightMargin: true,
      shape: "rounded",
      size: "medium",
    },
    {
      css: tw`pl-2.5 rounded-md`,
      leftMargin: true,
      shape: "rounded",
      size: "large",
    },
    {
      css: tw`pr-2.5 rounded-md`,
      rightMargin: true,
      shape: "rounded",
      size: "large",
    },
    {
      css: tw`pl-1`,
      leftMargin: false,
      size: "large",
    },
    {
      css: tw`pl-0.5`,
      leftMargin: false,
      size: "medium",
    },
    {
      css: tw`pr-1`,
      rightMargin: false,
      size: "large",
    },
    {
      css: tw`pr-0.5`,
      rightMargin: false,
      size: "medium",
    },
    {
      css: tw`pl-3`,
      leftMargin: true,
      shape: "rightRounded",
      size: "large",
    },
    {
      css: tw`pl-2.5`,
      leftMargin: true,
      shape: "rightRounded",
      size: "medium",
    },
    {
      css: tw`pr-2.5 rounded-tr-md rounded-br-md`,
      rightMargin: true,
      shape: "rightRounded",
      size: "large",
    },
    {
      css: tw`pr-2`,
      rightMargin: true,
      shape: "rightRounded",
      size: "medium",
    },
  ],

  defaultVariants: {
    clickable: false,
    leftMargin: true,
    rightMargin: true,
    shape: "circular",
    size: "medium",
  },

  variants: {
    border: {
      dashed: tw`border border-dashed`,
      dotted: tw`border border-dotted`,
      solid: tw`border border-solid`,
    },
    clickable: {
      true: tw`hover:(opacity-75)`,
    },
    leftMargin: {
      false: tw``,
    },
    maxWidth: {
      full: tw`max-w-full`,
    },
    rightMargin: {
      false: tw``,
    },
    shadow: {
      large: tw`shadow-2xl`,
      medium: tw`shadow-lg`,
      none: tw`shadow-none`,
      small: tw`shadow`,
      xsmall: tw`shadow-sm`,
    },
    shape: {
      circular: tw`rounded-full`,
      rightRounded: {
        ...tw`rounded-tr rounded-br`,
        borderBottomLeftRadius: "1rem",
        borderTopLeftRadius: "1rem",
      },
      rounded: tw`rounded`,
    },
    size: {
      large: {
        ...tw`text-sm py-1`,
      },
      medium: tw`text-xs`,
    },
    truncate: {
      true: tw`max-w-full`,
    },
  },
});

type BadgeWrapperProps = Stitches.ComponentProps<typeof BadgeWrapper>;

export { BadgeWrapper };
export type { BadgeWrapperProps };
