import React from "react";
import type { ValueContainerProps } from "react-select";
import { components } from "react-select";
import { Box } from "~/design-system/Box";
import { useBreakpoint } from "~/design-system/hooks";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";

/* We created this custom Value Container to be able to control the "scroll", especially when a lot of elements
   are selected inside it, to make it scroll to the bottom */
export function CustomValueContainer(
  props: ValueContainerProps<BasicMultiSelectFieldInputOption<any>>
) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { breakpoint } = useBreakpoint();
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [props]);

  return (
    <components.ValueContainer {...props}>
      <Box
        css={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          gap: "$2",
          maxHeight: breakpoint !== "mobile" ? "17vh" : "20vh",
          overflowY: "auto",
          paddingRight: "0",
          position: "relative",
          width: "100%",
        }}
        ref={containerRef}
      >
        {props.children}
      </Box>
    </components.ValueContainer>
  );
}
