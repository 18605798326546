import {
  Color,
  colorToHexValues,
  neutralVariant,
} from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import type { AvatarProps } from "../Avatar";
import { Icon } from "../Icon";
import { AvatarWrapper } from "./components/AvatarWrapper";
import type { BadgeWrapperProps } from "./components/BadgeWrapper";
import { BadgeWrapper } from "./components/BadgeWrapper";
import { Dot } from "./components/Dot";
import { RemoveButton } from "./components/RemoveButton";

type BadgeProps = Pick<
  BadgeWrapperProps,
  "border" | "clickable" | "maxWidth" | "shadow" | "shape" | "size" | "truncate"
> & {
  children: React.ReactNode;
  color?: Color;
  variant?: "light" | "plain";
  dot?: boolean;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  avatar?: React.ReactElement<AvatarProps>;
  leadingIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  css?: CSS;
};

function Badge(props: BadgeProps) {
  const defaultColor = Color.Gray;
  const { color = defaultColor, variant = "light" } = props;
  // Ensure color has a valid fallback if not provided or if the provided value doesn't exist in colorToHexValues
  const safeColor = colorToHexValues.has(color) ? color : defaultColor;
  // Safely attempt to get the color variants, providing an empty object as fallback
  const variants = colorToHexValues.get(safeColor) ?? neutralVariant;
  // Safely access the variant color, with a fallback if the variant doesn't exist
  const variantColors = variants[variant];

  return (
    <BadgeWrapper
      clickable={props.clickable}
      truncate={props.truncate}
      css={{
        ...props.css,
        backgroundColor: variantColors.background,
        borderColor: variantColors.text,
        color: variantColors.text,
      }}
      size={props.size}
      shape={props.shape}
      maxWidth={props.maxWidth}
      border={props.border}
      shadow={props.shadow}
      leftMargin={
        !props.dot && (props.avatar || props.leadingIcon) ? false : true
      }
      rightMargin={props.onRemove ? false : true}
      onClick={props.onClick}
    >
      {props.dot && (
        <Dot
          css={{ color: variantColors.text }}
          size={props.size}
          shape={props.shape}
        />
      )}
      {props.leadingIcon && (
        <Icon
          label="badge icon"
          size={props.size === "large" ? "small" : "xxsmall"}
          css={{ margin: "0 0.2rem" }}
        >
          {props.leadingIcon}
        </Icon>
      )}
      {props.avatar && <AvatarWrapper>{props.avatar}</AvatarWrapper>}
      {props.children}
      {props.onRemove && (
        <RemoveButton
          onRemove={props.onRemove}
          shape={props.shape}
          size={props.size}
          css={{
            color:
              variant === "plain"
                ? variantColors.background
                : variantColors.text,
          }}
        />
      )}
    </BadgeWrapper>
  );
}

export type { BadgeProps };
export { Badge };
