import * as React from "react";
import { useTranslation } from "react-i18next";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "../../../CloseButton";

function DrawerCloseButton() {
  return (
    <DrawerClose asChild>
      <CloseButton dataIntercomTarget="project-create-close" />
    </DrawerClose>
  );
}

function DrawerHeader() {
  const { t } = useTranslation();

  return (
    <Stack space="medium">
      <Inline width="full" align="between">
        <Heading level="3">
          {t(
            "screens.Project.CreateProjectDrawer.components.DrawerHeader.title",
            "New Project"
          )}
        </Heading>
        <DrawerCloseButton />
      </Inline>
      <Text>
        {t(
          "screens.Project.CreateProjectDrawer.components.DrawerHeader.message",
          "You can change those information at any time"
        )}
      </Text>
    </Stack>
  );
}

export { DrawerHeader };
