/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import type { ColumnProps } from "./Column";

const ColumnsWrapper = styled("div", {
  ...tw``,

  defaultVariants: {
    width: "full",
  },

  variants: {
    width: {
      auto: tw`w-auto`,
      full: tw`w-full`,
    },
  },
});

const ColumnsContent = styled("div", {
  ...tw`flex flex-row`,

  compoundVariants: [
    {
      align: "center",
      collapseBelow: "desktop",
      css: {
        "@desktop": tw`justify-center`,
        ...tw`items-center`,
      },
    },
    {
      align: "right",
      collapseBelow: "desktop",
      css: {
        "@desktop": tw`justify-end`,
        ...tw`items-end`,
      },
    },
    {
      align: "left",
      collapseBelow: "desktop",
      css: {
        "@desktop": tw`justify-start`,
        ...tw`items-start`,
      },
    },
    {
      align: "center",
      collapseBelow: "tablet",
      css: {
        "@tablet": tw`justify-center`,
        ...tw`items-center`,
      },
    },
    {
      align: "right",
      collapseBelow: "tablet",
      css: {
        "@tablet": tw`justify-end`,
        ...tw`items-end`,
      },
    },
    {
      align: "left",
      collapseBelow: "tablet",
      css: {
        "@tablet": tw`justify-start`,
        ...tw`items-start`,
      },
    },
    {
      collapseBelow: "desktop",
      css: {
        flexDirection: "column-reverse",
        "@desktop": {
          flexDirection: "row-reverse",
        },
      },
      reverse: true,
    },
    {
      collapseBelow: "tablet",
      css: {
        flexDirection: "column-reverse",
        "@tablet": {
          flexDirection: "row-reverse",
        },
      },
      reverse: true,
    },
  ],

  variants: {
    align: {
      center: tw`justify-center`,
      left: tw`justify-start`,
      right: tw`justify-end`,
      evenly: tw`justify-evenly`,
      between: tw`justify-between`,
    },
    collapseBelow: {
      desktop: {
        flexDirection: "column",
        "@desktop": {
          flexDirection: "row",
        },
      },
      tablet: {
        flexDirection: "column",
        "@tablet": {
          flexDirection: "row",
        },
      },
    },
    reverse: {
      false: tw``,
      true: tw`flex-row-reverse`,
    },
    space: {
      gutter: {
        marginLeft: "$-gutter",
      },
      large: {
        marginLeft: "$-large",
      },
      medium: {
        marginLeft: "$-medium",
      },
      small: {
        marginLeft: "$-small",
      },
      xlarge: {
        marginLeft: "$-xlarge",
      },
      xsmall: {
        marginLeft: "$-xsmall",
      },
      xxlarge: {
        marginLeft: "$-xxlarge",
      },
      xxsmall: {
        marginLeft: "$-xxsmall",
      },
    },
  },
});

type ColumnsProps = {
  children: React.ReactElement<ColumnProps>[];
  space?: "gutter" | "large" | "medium" | "small" | "xsmall" | "xxsmall";
  collapseBelow?: "desktop" | "tablet";
  align?: "between" | "center" | "evenly" | "left" | "right";
  alignY?: "bottom" | "center" | "top";
  reverse?: boolean;
  width?: "auto" | "full";
  css?: CSS;
};

function Columns(props: ColumnsProps) {
  return (
    <ColumnsWrapper width={props.width}>
      <ColumnsContent
        space={props.space}
        collapseBelow={props.collapseBelow}
        align={props.align}
        reverse={props.reverse}
        css={props.css}
      >
        {React.Children.map(
          props.children,
          (column: React.ReactElement<ColumnProps>) =>
            React.cloneElement(column, {
              alignY: props.alignY,
              collapseBelow: props.collapseBelow,
              space: props.space,
            })
        )}
      </ColumnsContent>
    </ColumnsWrapper>
  );
}

export type { ColumnsProps };
export { Columns };
