import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/design-system/Button/Button";
import { ClovisIcon } from "~/design-system/Icon/icons/outline/ClovisIcon";
import { SpinLoader } from "~/design-system/Spinner/SpinLoader";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text/Text";

type DefaultOnRedirectingProps = {
  onRetryClick?: () => unknown;
};

function DefaultOnRedirecting(props: DefaultOnRedirectingProps): JSX.Element {
  const [showRetry, setShowRetry] = React.useState(false);
  const { t } = useTranslation();

  // Wait 2s before showing the retry button
  React.useEffect(() => {
    setShowRetry(false);
    const timeout = setTimeout(() => {
      setShowRetry(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [setShowRetry]);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack space={"large"} align={"center"} width={"full"}>
        {/* Creating a div of 0px allow the SpinLoader to stay perfectly vertically centered */}
        <div></div>
        <SpinLoader />
        <div style={{ visibility: showRetry ? "visible" : "hidden" }}>
          <Button
            onClick={props.onRetryClick}
            variant="solid"
            tone="brandAccent"
            leadingIcon={<ClovisIcon />}
            size="large"
            css={{
              boxShadow:
                "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
            dataIntercomTarget="default-on-redirecting"
          >
            <Text weight="strong">
              {t("DefaultOnRedirecting.retry", "Retry Login")}
            </Text>
          </Button>
        </div>
      </Stack>
    </div>
  );
}
export { DefaultOnRedirecting };
