import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import type { IconProps } from "../../../../Icon";
import { Icon } from "../../../../Icon";

const StyledButton = styled("button", {
  ...tw`
  inset-y-0
  -ml-px
  relative
  inline-flex
  items-center
  space-x-2
  px-4
  py-2
  border border-gray-300
  text-sm
  font-medium
  rounded-r-md
  text-gray-700
  bg-gray-50
  hover:bg-gray-100
  focus:outline-none
  focus:ring-1
  focus:ring-slate-400
  focus:border-slate-400`,
  position: "absolute",
  right: 0,
});

export const TrailingIconButton = (props: {
  onClick?: () => void;
  icon: React.ReactNode;
  color?: IconProps["color"];
  css?: CSS;
}) => (
  <StyledButton type="button" onClick={props.onClick} css={props.css}>
    <Icon color={props.color} label="trailing icon">
      {props.icon}
    </Icon>
  </StyledButton>
);
