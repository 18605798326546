import type { ImpactStyle } from "@capacitor/haptics";
import { Haptics } from "@capacitor/haptics";

const hapticsImpact = async (style: ImpactStyle) => {
  try {
    await Haptics.impact({ style });
  } catch (error) {
    console.warn("Your device does not support vibrate API.");
  }
};

export { hapticsImpact };
