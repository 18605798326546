import * as React from "react";
import { IntercomProvider as ReactIntercomProvider } from "react-use-intercom";
import { env } from "~/config/env";

type IntercomProviderProps = {
  children: React.ReactNode;
};

const IntercomUnreadContext = React.createContext<{
  setUnreadMessagesCount: (amount: number) => void;
  unreadMessagesCount: number;
} | null>(null);

const useIntercomUnreadMessagesCount = () => {
  const result = React.useContext(IntercomUnreadContext);

  return result;
};

function IntercomProvider(props: IntercomProviderProps) {
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0);

  const onUnreadCountChange = (amount: number) => {
    setUnreadMessagesCount(amount);
  };

  return (
    <IntercomUnreadContext.Provider
      value={{ setUnreadMessagesCount, unreadMessagesCount }}
    >
      <ReactIntercomProvider
        appId={env.VITE_INTERCOM_APP_ID}
        onUnreadCountChange={onUnreadCountChange}
      >
        {props.children}
      </ReactIntercomProvider>
    </IntercomUnreadContext.Provider>
  );
}

export { IntercomProvider, useIntercomUnreadMessagesCount };
