import MenuIcon from "@heroicons/react/outline/MenuIcon";
import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { Text } from "~/design-system/Text";

const AboveMobileButtonWrapper = styled("button", {
  ...tw`focus:(outline-none)
  bg-slate-900`,
  // temporary disabling outlines until "we make beautiful ones"
  // focus:ring
  "& span:first-of-type": {
    padding: "0.25rem",
  },
  "&:hover": tw`opacity-90`,
  alignItems: "center",
  borderRadius: "8px",
  boxShadow:
    "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
  display: "flex",
  flexDirection: "row",
  height: "$appbar",
  justifyContent: "center",
  padding: 0,
  width: "255px",
});

type AboveMobileButtonProps = {
  imageURL?: string | null;
  imageAlt?: string | null;
  textToDisplay?: string;
  notif?: React.ReactNode;
};

const AboveMobileButton = React.forwardRef<
  HTMLButtonElement,
  AboveMobileButtonProps
>(function AboveMobileButton(props, ref) {
  const { imageAlt, imageURL, textToDisplay, ...radixButtonProps } = props;
  return (
    <AboveMobileButtonWrapper
      ref={ref}
      role="button"
      data-intercom-target="navigation-drawer-button"
      {...radixButtonProps}
    >
      <Avatar
        alt={imageAlt ?? textToDisplay}
        src={imageURL ?? undefined}
        shape="rounded"
        size="large"
      />
      <Text
        truncate={true}
        tone="white"
        align="left"
        css={{ marginLeft: "0.5rem" }}
        weight="strong"
      >
        {textToDisplay}
      </Text>
      {props.notif ? (
        <Box css={{ marginRight: "$2" }}>{props.notif}</Box>
      ) : (
        <Icon
          label="menu"
          color="white"
          size="medium"
          css={{ marginRight: "$4" }}
        >
          <MenuIcon />
        </Icon>
      )}
    </AboveMobileButtonWrapper>
  );
});

export { AboveMobileButton };
