import { Listbox } from "@headlessui/react";
import React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { OptionContent } from "./OptionContent";

type NavOptionProps = {
  to: string;
  icon?: React.ReactNode;
  notifBadge?: React.ReactNode;
  children: React.ReactNode;
  target?: string;
};

const OptionWrapper = styled(Listbox.Option, {
  ...tw`cursor-default select-none relative`,
});

function NavOption(props: NavOptionProps) {
  return (
    <OptionWrapper value={props}>
      {({ active, selected }: { active: boolean; selected: boolean }) => (
        <OptionContent
          icon={props.icon}
          notifBadge={props.notifBadge}
          active={active}
          selected={selected}
          to={props.to}
          target={props.target}
        >
          {props.children}
        </OptionContent>
      )}
    </OptionWrapper>
  );
}

export { NavOption };
export type { NavOptionProps };
