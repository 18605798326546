import { zodResolver } from "@hookform/resolvers/zod";
import type { FieldError, NestedValue, UseFormProps } from "react-hook-form";
import { useForm as useDefaultForm } from "react-hook-form";
import type { z } from "zod";
import type { Maybe, Scalars } from "~/schema.graphql";
import { dayjs } from "../dayjs";
import { errorMap } from "../zod";

type NestedValues<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Schema extends z.ZodEffects<any> | z.ZodObject<any>,
  K extends keyof z.infer<Schema>
> = {
  [k in K]: NestedValue<z.infer<Schema>[K]>;
};

const useForm = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Schema extends z.ZodEffects<any> | z.ZodObject<any>,
  // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
  SchemaKeys extends void | keyof z.infer<Schema> = void
>(
  schema: Schema,
  options?: Omit<
    // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
    UseFormProps<
      SchemaKeys extends keyof z.infer<Schema>
        ? // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
          z.infer<Schema> & NestedValues<Schema, SchemaKeys>
        : z.infer<Schema>
    >,
    "resolver"
  >
) => {
  // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
  return useDefaultForm<
    SchemaKeys extends keyof z.infer<Schema>
      ? // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
        z.infer<Schema> & NestedValues<Schema, SchemaKeys>
      : z.infer<Schema>
  >({
    resolver: zodResolver(schema, { errorMap }),
    ...options,
  });
};

const nullable = (v: string) => (v === "" ? null : v);

const nullableDate = (v: string | null | undefined) => {
  return Boolean(v) === false ? null : dayjs(v).toDate();
};

const nullableFloat = (v: string) =>
  v === "" ? null : isNaN(parseFloat(v)) ? null : parseFloat(v);

/*
 ** Convert a timestamptz string coming from our graphql api into
 ** a value ready to be consumed by an <input type="date" />
 ** ex: ("2021-01-01T00:00:00+02:00" -> "2021-01-01")
 */
const timestamptzToDateValue = (
  timestamptz?: Maybe<Scalars["timestamptz"]>
): Date | undefined => {
  if (timestamptz) {
    const date = dayjs(timestamptz).format("YYYY-MM-DD");
    return date as unknown as Date;
  }

  return undefined;
};

const timestamptzToTimeValue = (
  timestamptz?: Maybe<Scalars["timestamptz"]>
) => {
  if (timestamptz) {
    const time = dayjs(timestamptz).format("HH:mm");
    return time;
  }

  return undefined;
};

/*
 ** Convert a timestamptz string coming from our graphql api into
 ** a value ready to be consumed by an <input type="datetime-local" />
 ** ex: ("2021-01-01T00:00:00+02:00" -> "2021-01-01T00:00")
 */
const timestamptzToDatetimeValue = (
  timestamptz?: Maybe<Scalars["timestamptz"]>
): Date | undefined => {
  if (timestamptz) {
    const date = dayjs(timestamptz).format("YYYY-MM-DDTHH:mm");
    return date as unknown as Date;
  }

  return undefined;
};

export * from "react-hook-form";

export type { FieldError, NestedValues };
export {
  nullable,
  nullableDate,
  nullableFloat,
  timestamptzToDatetimeValue,
  timestamptzToDateValue,
  timestamptzToTimeValue,
  useForm,
};
