import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateProjectCategoryMutationVariables = Types.Exact<{
  input: Types.CreateProjectCategoryInput;
}>;


export type CreateProjectCategoryMutation = { createProjectCategory?: { __typename: 'CreateProjectCategoryErrors' } | { __typename: 'CreateProjectCategorySuccess', project_category_id: string } | null | undefined };


export const CreateProjectCategoryDocument = gql`
    mutation createProjectCategory($input: CreateProjectCategoryInput!) {
  createProjectCategory(input: $input) {
    __typename
    ... on CreateProjectCategorySuccess {
      project_category_id
    }
  }
}
    `;

export function useCreateProjectCategoryMutation() {
  return Urql.useMutation<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>(CreateProjectCategoryDocument);
};