import * as React from "react";
import { Drawer } from "~/design-system/Drawer";
import type { UserOrg } from "../../NavigationDrawer";
import { SettingsDrawerContent } from "./components/SettingsDrawerContent";

type SettingsDrawerProps = {
  children: React.ReactNode;
  closeDrawer?: () => void;
  orgs?: UserOrg[];
  activeOrgId?: string;
};

function SettingsDrawer(props: SettingsDrawerProps) {
  const { children, ...rest } = props;

  return (
    <Drawer>
      <SettingsDrawerContent {...rest} />
      {children}
    </Drawer>
  );
}

export { SettingsDrawer };
