import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const BaseMenuItemStyles = {
  "&": {
    alignItems: "center",
    display: "flex",
    fontSize: "16px",
    fontWeight: "600",
    height: "$buttonHeight",
  },

  "&:focus": {
    outline: "none",
  },
  ...tw`first:rounded-t-2xl last:rounded-b-2xl`,

  compoundVariants: [
    {
      css: tw`hover:(bg-critical-100 first:rounded-t-2xl last:rounded-b-2xl text-critical-500) text-critical-500`,
      disabled: false,
      tone: "critical",
    },
    {
      css: {
        "& svg": tw`text-critical-200`,
        ...tw`text-critical-200`,
      },
      disabled: true,
      tone: "critical",
    },
    {
      css: tw`hover:(bg-neutral-100 first:rounded-t-2xl last:rounded-b-2xl text-neutral-500) text-neutral-500`,
      disabled: false,
      tone: "neutral",
    },
    {
      css: {
        "& svg": tw`text-neutral-200`,
        ...tw`text-neutral-200`,
      },
      disabled: true,
      tone: "neutral",
    },
    {
      css: tw`hover:(bg-caution-100 first:rounded-t-2xl last:rounded-b-2xl text-caution-500) text-caution-500`,
      disabled: false,
      tone: "caution",
    },
    {
      css: {
        "& svg": tw`text-caution-200`,
        ...tw`text-caution-200`,
      },
      disabled: true,
      tone: "caution",
    },
    {
      css: tw`hover:(bg-info-100 first:rounded-t-2xl last:rounded-b-2xl text-info-500) text-info-500`,
      disabled: false,
      tone: "info",
    },
    {
      css: {
        "& svg": tw`text-info-200`,
        ...tw`text-info-200`,
      },
      disabled: true,
      tone: "info",
    },
    {
      css: tw`hover:(bg-positive-100 first:rounded-t-2xl last:rounded-b-2xl text-positive-500) text-positive-500`,
      disabled: false,
      tone: "positive",
    },
    {
      css: {
        "& svg": tw`text-positive-200`,
        ...tw`text-positive-200`,
      },
      disabled: true,
      tone: "positive",
    },
    {
      css: {
        backgroundColor: "$slate900",
        color: "$neutral500",
        cursor: "default",
      },
      disabled: true,
      tone: "slate",
    },
    {
      css: {
        "&:hover": {
          backgroundColor: "$slate800",
        },
        backgroundColor: "$slate900",
        ...tw`hover:(bg-neutral-600 first:rounded-t-2xl last:rounded-b-2xl) text-white `,
      },
      disabled: false,
      tone: "slate",
    },
  ],

  defaultVariants: {
    disabled: false,
    size: "standard",
    tone: "neutral",
  },

  variants: {
    disabled: {
      false: {
        "&:focus": {
          backgroundColor: "dodgerblue",
          color: "white",
        },
        cursor: "pointer",
      },
      true: tw`cursor-default`,
    },
    size: {
      small: {
        "& > div[aria-hidden=true], & svg[aria-hidden=true], & > .fileIcon, & > .folderIcon":
          {
            marginRight: "$small",
          },
        lineHeight: "$small",
        padding: "$medium $small",
      },
      standard: {
        "& > div[aria-hidden=true], & svg[aria-hidden=true], & > .fileIcon, & > .folderIcon":
          {
            marginRight: "$medium",
          },
        lineHeight: "$medium",
        padding: "$gutter $medium",
      },
    },
    tone: {
      caution: tw``,
      critical: tw``,
      info: tw``,
      neutral: tw``,
      positive: tw``,
      slate: tw``,
    },
  },
};

const BaseMenuItem = styled(RadixDropdownMenu.Item, {
  ...BaseMenuItemStyles,
});

const ContextBaseMenuItem = styled(ContextMenuPrimitive.Item, {
  ...BaseMenuItemStyles,
});

export { BaseMenuItem, ContextBaseMenuItem };
