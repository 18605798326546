import { validationSchema } from "@clovis/server/src/app/domain/project_group/create-project_group/create-project_group.validation";
import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import type { FileMeta } from "~/config/uppy";
import { uppy } from "~/config/uppy";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { createComponentHook } from "~/types";
import { SelectColorField } from "../../../../../../../../components/SelectColorField/SelectColorField";
import { AvatarGroupField } from "../AvatarGroupField";
import {
  useCreateProjectGroupMutation,
  usePrepareProjectGroupAssetForUploadMutation,
} from "./CreateGroupForm.graphql";

const useCreateGroupForm = createComponentHook(
  (props: CreateGroupFormProps) => {
    const { t } = useTranslation();
    const [addMode, setAddMode] = React.useState(false);
    const [, createProjectGroup] = useCreateProjectGroupMutation();
    const [groupAvatar, setGroupAvatar] = React.useState<
      ArrayBuffer | File | string | null
    >(null);

    const form = useForm(validationSchema, {
      defaultValues: {
        color: "gray",
        orgId: props.orgId,
      },
    });

    const enableForm = () => {
      setAddMode(true);
    };

    const disableForm = () => {
      form.reset();
      setAddMode(false);
    };

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data, error } = await createProjectGroup(
        {
          input,
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.createProjectGroup?.__typename === "CreateProjectGroupSuccess"
      ) {
        await handleSubmitAvatarGroup(data.createProjectGroup.project_group_id);

        toast.success(
          t(
            "screens.OrgProjectGroupDrawer.CreateGroupForm.createProjectGroupSuccessToast",
            "Your group has been added"
          )
        );
        disableForm();
      } else {
        captureException(error);
        toast.error(
          t(
            "screens.OrgProjectGroupDrawer.CreateGroupForm.createProjectGroupErrorsToast",
            "Something went wrong while creating the group."
          )
        );
      }
    });

    const [, PrepareProjectGroupAssetForUploadDocument] =
      usePrepareProjectGroupAssetForUploadMutation();

    const handleSubmitAvatarGroup = async (projectGroupId: string) => {
      if (groupAvatar && groupAvatar instanceof File) {
        const { data, error } = await PrepareProjectGroupAssetForUploadDocument(
          {
            input: {
              name: groupAvatar.name,
              projectGroupId,
            },
          },
          { additionalTypenames: ["orgs"] }
        );
        if (
          data?.PrepareProjectGroupAssetForUpload?.__typename !==
          "PrepareProjectGroupAssetForUploadSuccess"
        ) {
          return;
        } else {
          captureException(error);
          toast.error(
            t(
              "screens.OrgProjectGroupDrawer.CreateGroupForm.uploadError",
              "The upload didn't succeed"
            )
          );
        }

        const { key, url } = data.PrepareProjectGroupAssetForUpload;

        uppy.addFile<FileMeta>({
          data: groupAvatar,
          meta: {
            internalFileType: "projectGroupAvatar",
            key,
            onFinalizeEnd: (_meta, _data, error) => {
              // Reset the fileInput value to "empty" once the upload is done
              if (groupAvatar) {
                setGroupAvatar(null);
              }
              if (error) {
                toast.error(
                  t(
                    "screens.OrgProjectGroupDrawer.CreateGroupForm.finalizeFilesUploadError",
                    "An error occured while uploading your avatar"
                  )
                );
              } else {
                toast.success(
                  t(
                    "screens.OrgProjectGroupDrawer.CreateGroupForm.finalizeFilesUploadSuccess",
                    "Project avatar has been successfully uploaded"
                  )
                );
              }
            },
            url,
          },
          name: groupAvatar.name,
          size: groupAvatar.size,
          type: groupAvatar.type,
        });
      }
    };

    return {
      actions: {
        disableForm,
        enableForm,
        handleSubmit,
        registerInput: form.register,
        setGroupAvatar,
      },
      state: {
        addMode,
        control: form.control,
        errors: form.formState.errors,
      },
      t,
    };
  }
);

type CreateGroupFormProps = {
  orgId: string;
};

function CreateGroupForm(props: CreateGroupFormProps) {
  const { actions, state, t } = useCreateGroupForm(props);

  if (state.addMode) {
    return (
      <Form>
        <Stack space="small">
          <AvatarGroupField setGroupAvatar={actions.setGroupAvatar} />
          <TextField
            {...actions.registerInput("name")}
            label={t(
              "screens.OrgProjectGroupDrawer.CreateGroupForm.nameLabel",
              "Name"
            )}
            error={state.errors.name?.message}
            required
            dataIntercomTarget="org-project-categories-create-name"
          />
          <SelectColorField
            {...actions.registerInput("color")}
            label={t(
              "screens.OrgProjectGroupDrawer.CreateGroupForm.colorLabel",
              "Color"
            )}
            control={state.control}
            error={state.errors.color?.message}
            required
            dataIntercomTarget="org-project-categories-create-color"
          />
          <Columns space="small">
            <Column>
              <Button
                size="large"
                variant="soft"
                width="full"
                onClick={actions.disableForm}
                type="button"
                dataIntercomTarget="org-project-categories-create-cancel"
              >
                {t(
                  "screens.OrgProjectGroupDrawer.CreateGroupForm.cancelButton",
                  "Cancel"
                )}
              </Button>
            </Column>
            <Column>
              <Button
                size="large"
                tone="brandAccent"
                width="full"
                dataIntercomTarget="org-project-categories-create-submit"
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  await actions.handleSubmit();
                }}
              >
                {t(
                  "screens.OrgProjectGroupDrawer.CreateGroupForm.submitButton",
                  "Create group"
                )}
              </Button>
            </Column>
          </Columns>
        </Stack>
      </Form>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Button
        type="button"
        tone="brandAccent"
        leadingIcon={<PlusSmIcon />}
        onClick={actions.enableForm}
        dataIntercomTarget="org-project-categories-create-submit"
      >
        {t(
          "screens.OrgProjectGroupDrawer.CreateGroupForm.enableButton",
          "Add a project group"
        )}
      </Button>
    </Box>
  );
}

export { CreateGroupForm };
