import * as React from "react";
import type { CheckboxItemProps } from "./CheckboxItem";
import { CheckboxGroupLabel } from "./components/CheckboxGroupLabel";
import { CheckboxGroupMessage } from "./components/CheckboxGroupMessage";
import { CheckboxGroupWrapper } from "./components/CheckboxGroupWrapper";
import { CheckboxItems } from "./components/CheckboxItems";

const BaseCheckboxGroup = (props: BaseCheckboxGroupProps) => {
  const { variant: checkboxGroupVariant = "simple" } = props;
  return (
    <CheckboxGroupWrapper>
      <CheckboxGroupLabel
        label={props.label}
        description={props.description}
        variant={checkboxGroupVariant}
      />
      <CheckboxItems variant={checkboxGroupVariant}>
        {React.Children.map(
          props.children,
          (checkboxItem: React.ReactElement<CheckboxItemProps>) =>
            React.cloneElement(checkboxItem, {
              disabled: props.disabled,
              required: props.required,
              tone: props.tone,
              variant: props.variant,
              // Allow each checkbox in the group to control his own state
              ...checkboxItem.props,
              checkboxGroupName: props.checkboxGroupName,
            })
        )}
      </CheckboxItems>
      <CheckboxGroupMessage message={props.message} tone={props.tone} />
    </CheckboxGroupWrapper>
  );
};

type BaseCheckboxGroupProps = {
  children:
    | React.ReactElement<CheckboxItemProps>
    | React.ReactElement<CheckboxItemProps>[];
  label?: React.ReactNode;
  description?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  checkboxGroupName: string;
  message?: React.ReactNode;
  tone?: "critical" | "positive";
  variant?: "list" | "simple";
};

export type { BaseCheckboxGroupProps as CheckboxGroupProps };
export { BaseCheckboxGroup as CheckboxGroup };
