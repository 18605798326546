import { FolderIcon } from "@heroicons/react/outline";
import * as React from "react";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import type { FolderProps } from "../Breadcrumb";
import { NavSelect } from "./NavSelect/NavSelect";

const TabletAndBelowBreadcrumb = (props: {
  foldersToDisplay: Array<FolderProps>;
}) => {
  const options = props.foldersToDisplay.map((f) => ({
    children: f.name,
    icon: <FolderIcon />,
    notifBadge: (
      <DisplayNotification
        strongNotification={f.strongNotification}
        weakNotif={f.weakNotif}
      />
    ),
    to: f.id,
  }));

  return <NavSelect options={options} />;
};

export { TabletAndBelowBreadcrumb };
