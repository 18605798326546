import { WorkerStatusNamespace } from "@clovis/server/src/app/workers/status/types";
import type { WorkerFilterArgumentsType } from "./types";

function isPendingOnboardingWorkerStatus(params: WorkerFilterArgumentsType) {
  return (
    params.worker_status.type.startsWith(
      `${WorkerStatusNamespace.User}/SELF_ONBOARDING_STATUS`
    ) && params.worker_status.status === "PENDING"
  );
}

function is3dFileConversionWorkerStatus(params: WorkerFilterArgumentsType) {
  return (
    params.worker_status.type.startsWith(
      `${WorkerStatusNamespace.Project}/DOCUMENT/FILE/CONVERT_3D_FILE`
    ) &&
    // @ts-expect-error: Unreachable code error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    params.worker_status?.payload?.fileVersion?.id === params.elementId
  );
}

export { is3dFileConversionWorkerStatus, isPendingOnboardingWorkerStatus };
