import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import { countries, languagesAll } from "countries-list";
import type { TranslationLanguages } from "stream-chat";
// TODO: load the other languages files with a network call instead of bundling them

/* We are using France and French by default because Clovis is French 😎, and most users are */
const DEFAULT_LANGUAGE = Language.FR;
const DEFAULT_COUNTRY_CODE = DEFAULT_LANGUAGE.toUpperCase() as CountryCode;

type CountryCode = keyof typeof countries;
type LanguageCode = keyof typeof languagesAll;

const getChatLanguageFromUserLanguage = (
  userLanguage: Language
): TranslationLanguages => {
  return Object.values(Language).includes(userLanguage)
    ? userLanguage
    : Language.EN;
};

function getCountryFromBrowser(): {
  countryCode: CountryCode;
  languageCode: LanguageCode;
} {
  // Regex found here: https://github.com/gagle/node-bcp47/blob/master/lib/index.js
  let countryCode: CountryCode;
  let languageCode: LanguageCode;

  const navigatorCountryRegex =
    /^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|d{3}))?((?:-(?:[da-z]{5,8}|d[da-z]{3}))*)?((?:-[da-wy-z](?:-[da-z]{2,8})+)*)?(-x(?:-[da-z]{1,8})+)?$|^(x(?:-[da-z]{1,8})+)$/i;

  const parsing = navigatorCountryRegex.exec(navigator.language);

  /* Set FR as default phone for the moment, as we don't want to
    use ip detection libraries or heavy lobraries like https://www.npmjs.com/package/js-user-country */
  // Some browser doesn't necessary set complete bcp47 country code (fr-FR) but just "fr" instead.
  // In that case our parser will fail to retrieve the code, check if the language is already a valid country
  // We check the if our parser gave us something, and if this something is a valid country code
  // in Safari on iOS prior to 10.2, the country code returned is lowercase */
  if (parsing?.[5] && countries[parsing[5]?.toUpperCase() as CountryCode]) {
    countryCode = parsing[5].toUpperCase() as CountryCode;
    languageCode = parsing[3] as LanguageCode;
  } else if (countries[navigator.language.toUpperCase() as CountryCode]) {
    // Else, if the navigator language is a valid country by itself (eg: fr)
    // This can be the case on chrome
    countryCode = navigator.language.toUpperCase() as CountryCode;
  } else {
    countryCode = DEFAULT_COUNTRY_CODE;
  }

  if (parsing?.[3] && languagesAll[parsing[3] as LanguageCode]) {
    languageCode = parsing[3] as LanguageCode;
  } else {
    languageCode = DEFAULT_LANGUAGE;
  }

  return { countryCode, languageCode };
}

const getAvailableLanguage = (language: LanguageCode): LanguageCode => {
  return Object.values(Language).includes(language as Language)
    ? language
    : Language.EN;
};

export type { CountryCode };
export {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE,
  getAvailableLanguage,
  getChatLanguageFromUserLanguage,
  getCountryFromBrowser,
};
