import { SupportIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { useDrawerContext } from "~/design-system/Drawer";
import { Text } from "~/design-system/Text";
import { IntercomNotificationsBadge } from "~/screens/App/components/NotificationsBadge/IntercomNotificationsBadge";

const IntercomDrawerHelpButton = () => {
  const { t } = useTranslation();
  const { setOpen } = useDrawerContext();
  const IntercomContext = useIntercom();

  return (
    <Box
      height="full"
      display="flex"
      css={{
        position: "relative",
      }}
      marginLeft={"xsmall"}
      width="auto"
    >
      <Button
        size="small"
        leadingIcon={<SupportIcon />}
        dataIntercomTarget="intercom-drawer-help-button"
        variant="transparent"
        css={{
          "&:hover": {
            color: "$slate500",
          },
          borderRadius: "1rem",
          color: "$slate300",
          minHeight: "auto",
          paddingBottom: "2px",
          paddingTop: "2px",
        }}
        onClick={() => {
          setOpen(false);
          IntercomContext.show();
        }}
      >
        {t("screens.HelpButton.HelpButton", "Ask Help")}
      </Button>
      <Box position="absolute" css={{ right: "$-1", top: "$-2" }}>
        <IntercomNotificationsBadge />
      </Box>
    </Box>
  );
};

const DrawerMenuSectionHeader = (props: {
  title: string;
  "data-intercom-target"?: string;
}) => {
  return (
    <Text
      data-intercom-target={props["data-intercom-target"]}
      tone="neutralLight"
      uppercase
    >
      {props.title}
    </Text>
  );
};

export { DrawerMenuSectionHeader, IntercomDrawerHelpButton };
