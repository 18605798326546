import * as React from "react";
import ReactDOM from "react-dom";
import { Config, initConfig } from "./config";
import { App } from "./screens/App/App";

void main();

async function main() {
  await initConfig();

  console.info("🍀 Welcome on Clovis");

  ReactDOM.render(
    <React.StrictMode>
      <Config>
        <App />
      </Config>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
