import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Text } from "../../Text";

const TextWrapper = styled("div", {
  ...tw`
  w-full
  absolute
  flex items-center
  h-10
  px-3.5 pb-0.5
  `,
});

const WeightText = styled(Text, {
  ...tw`whitespace-nowrap`,
});

type TextBarProps = {
  uploadText: string;
  uploadPercentage: string;
};

const TextBar = (props: TextBarProps) => {
  return (
    <TextWrapper data-intercom-target="upload-text-bar">
      <Text weight="medium" tone="white" truncate="true" align="left">
        {props.uploadText}
      </Text>
      <WeightText weight="medium" tone="white" style={{ paddingLeft: "1rem" }}>
        {props.uploadPercentage}
      </WeightText>
    </TextWrapper>
  );
};

export { TextBar };
