import type { PermissionType } from "@clovis/server/src/app/domain/utils/permissions";
import type { SectionType } from "@clovis/server/src/app/domain/utils/permissions/permissions";
import {
  getOrgData,
  getProjectData,
} from "@clovis/server/src/app/domain/utils/permissions/permissions";
import { CheckIcon } from "@heroicons/react/solid";
import { sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import type { CellProps, Column } from "react-table";
import type { CSS } from "~/config/stitches";
import { Badge } from "~/design-system/Badge";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { Placeholder } from "~/design-system/Placeholder";
import { Stack } from "~/design-system/Stack";
import { CELL_HEIGHT } from "~/design-system/Table";
import { Text } from "~/design-system/Text";
import { VirtualTable } from "~/screens/App/components/VirtualTable";
import type { LanguageType } from "~/screens/App/screens/AnonRightsScreen/components/LanguageDropdown/LanguageDropdown";
import { PermissionTranslation } from "~/screens/App/screens/AnonRightsScreen/components/PermissionTable/components/PermissionTranslation";
import {
  sectionBadgesColors,
  SectionTranslation,
} from "~/screens/App/screens/AnonRightsScreen/components/PermissionTable/components/SectionTranslation";
import { createComponentHook } from "~/types";

type ProjectPermissionTableProps = {
  type: "org" | "project";
  title: string;
  css?: CSS;
  language: LanguageType;
};

const useProjectPermissionTable = createComponentHook(
  (props: ProjectPermissionTableProps) => {
    const { t } = useTranslation();

    const permissions = [];
    if (props.type === "org") {
      permissions.push(...getOrgData());
    } else if (props.type === "project") {
      permissions.push(...getProjectData());
    }

    const sortedPermissions = sortBy(permissions, (p) => p.section);

    type MobilePermissionFormatType = {
      permission: PermissionType;
      section: SectionType;
      roles: Record<
        | "administrator"
        | "disabled"
        | "limited"
        | "owner"
        | "readOnly"
        | "standard",
        boolean
      >;
    };
    const mobilePermissionFormatType = sortedPermissions.map((permission) => {
      return {
        permission: permission.permission,
        roles: {
          administrator: permission.administrator,
          disabled: permission.disabled,
          limited: permission.limited,
          owner: permission.owner,
          readOnly: permission.readOnly,
          standard: permission.standard,
        },
        section: permission.section,
      };
    });

    const columns = React.useMemo<Array<Column<MobilePermissionFormatType>>>(
      () => [
        {
          Cell: (props: CellProps<MobilePermissionFormatType>) => (
            <Stack css={{ whiteSpace: "normal" }} space="small">
              <PermissionTranslation
                permission={props.cell.row.original.permission}
              />
              <Badge
                color={sectionBadgesColors[props.cell.row.original.section]}
                size="medium"
              >
                <SectionTranslation section={props.cell.row.original.section} />
              </Badge>
            </Stack>
          ),
          Header: () =>
            t(
              "screens.ProjectPermissionTable.columns.permission",
              "Permission"
            ),
          id: "permission",
          widht: "300px",
        },
        {
          Cell: (props: CellProps<MobilePermissionFormatType>) => (
            <Box
              display="flex"
              alignItems="flexStart"
              justifyContent="flexStart"
              flexDirection="column"
            >
              {props.cell.row.original.roles.owner && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.owner}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t("screens.ProjectPermissionTable.columns.owner", "Owner")}
                </Box>
              )}
              {props.cell.row.original.roles.administrator && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.administrator}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t("screens.ProjectPermissionTable.columns.admin", "Admin")}
                </Box>
              )}
              {props.cell.row.original.roles.standard && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.standard}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t(
                    "screens.ProjectPermissionTable.columns.standard",
                    "Standard"
                  )}
                </Box>
              )}
              {props.cell.row.original.roles.limited && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.limited}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t(
                    "screens.ProjectPermissionTable.columns.limited",
                    "Limited"
                  )}
                </Box>
              )}
              {props.cell.row.original.roles.readOnly && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.readOnly}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t(
                    "screens.ProjectPermissionTable.columns.readOnly",
                    "Read only"
                  )}
                </Box>
              )}
              {props.cell.row.original.roles.disabled && (
                <Box justifyContent={"flexStart"} display="flex">
                  <Icon
                    label={`icon-permission ${props.cell.row.original.roles.disabled}}`}
                    size="small"
                    css={{ marginRight: "$space$2" }}
                  >
                    <CheckIcon />
                  </Icon>
                  {t(
                    "screens.ProjectPermissionTable.columns.disabled",
                    "disabled"
                  )}
                </Box>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTableMobile.columns.roles", "Roles"),
          id: "roles",
        },
      ],
      [t]
    );
    return {
      state: {
        columns,
        newPermissionFormat: mobilePermissionFormatType,
      },
    };
  }
);

const PermissionTableMobile = (props: ProjectPermissionTableProps) => {
  const { state } = useProjectPermissionTable(props);
  return (
    <Box css={{ ...props.css }}>
      <Box marginBottom="medium">
        <Text size="xxlarge" weight="medium">
          {props.title}
        </Text>
      </Box>
      <VirtualTable
        estimateSize={() => CELL_HEIGHT}
        data={state.newPermissionFormat}
        columns={state.columns}
      />
      {/* gives and additional space for scrolling on mobile devices */}
      <Placeholder background="body" height="medium" width="full" />
    </Box>
  );
};

export { PermissionTableMobile };
