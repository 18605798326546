const ImageContentTypes = [
  // Formats supported by ImageViewer (see: ImageViewer.constants.ts)
  "image/jpeg",
  "image/jpg",
  "image/png",
  // "image/gif",
  // "image/apng",
  // "image/avif",
  // "image/svg+xml",
  // "image/webp",
];

function fileIsAnImage(fileType: string): boolean {
  return ImageContentTypes.includes(fileType);
}

export { fileIsAnImage };
