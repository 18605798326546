import * as React from "react";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";

type ContentProps = {
  children: React.ReactNode;
  css?: CSS;
};

function Content(props: ContentProps) {
  return <Box css={props.css}>{props.children}</Box>;
}

export type { ContentProps };
export { Content };
