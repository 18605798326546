/* eslint-disable react/prop-types */
import * as React from "react";
import type { FieldProps } from "../Field";
import { Field } from "../Field";
import { Tones } from "../tokens";
import type { DateFieldInputProps } from "./components/DateFieldInput/DateFieldInput";
import { DateFieldInput } from "./components/DateFieldInput/DateFieldInput";

type DateFieldProps = Omit<DateFieldInputProps & FieldProps, "children">;

const DateField = React.forwardRef<HTMLInputElement, DateFieldProps>(
  function DateField(props, ref) {
    const tone = props.error ? Tones.critical : props.tone;

    const fieldProps: Omit<FieldProps, "children"> = {
      cornerHint: props.cornerHint,
      dataIntercomTarget: props.dataIntercomTarget,
      error: props.error,
      label: props.label,
      message: props.message,
      name: props.name,
      required: props.required,
      secondaryLabel: props.secondaryLabel,
      tone,
    };
    const dateFieldInputProps: DateFieldInputProps = {
      autoFocus: props.autoFocus,
      disabled: props.disabled,
      error: props.error,
      max: props.max,
      min: props.min,
      name: props.name,
      onBlur: props.onBlur,
      onChange: props.onChange,
      onClear: props.onClear,
      onFocus: props.onFocus,
      readOnly: props.readOnly,
      required: props.required,
      step: props.step,
      tone,
      type: props.type,
      value: props.value,
    };

    return (
      <Field {...fieldProps}>
        <DateFieldInput ref={ref} {...dateFieldInputProps} />
      </Field>
    );
  }
);

export type { DateFieldProps };
export { DateField };
