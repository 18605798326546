import type * as Types from '../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTeamMembersQueryVariables = Types.Exact<{
  team_id: Types.Scalars['uuid'];
}>;


export type GetTeamMembersQuery = { teams_by_pk?: { project: { id: string }, users: Array<{ user: { id: string } }> } | null | undefined };

export type GetTeamMembersOptionsQueryVariables = Types.Exact<{
  project_id: Types.Scalars['uuid'];
}>;


export type GetTeamMembersOptionsQuery = { projects_by_pk?: { members: Array<{ user: { avatar?: string | null | undefined, full_name?: string | null | undefined, id: string } }> } | null | undefined };

export type UpdateUsersInTeamMutationVariables = Types.Exact<{
  input: Types.UpdateUsersInTeamInput;
}>;


export type UpdateUsersInTeamMutation = { updateUsersInTeam?: { __typename: 'UpdateUsersInTeamErrors' } | { __typename: 'UpdateUsersInTeamSuccess', project_id: string, team_id: string } | null | undefined };


export const GetTeamMembersDocument = gql`
    query getTeamMembers($team_id: uuid!) {
  teams_by_pk(id: $team_id) {
    project {
      id
    }
    users {
      user {
        id
      }
    }
  }
}
    `;

export function useGetTeamMembersQuery(options: Omit<Urql.UseQueryArgs<GetTeamMembersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetTeamMembersQuery>({ query: GetTeamMembersDocument, ...options });
};
export const GetTeamMembersOptionsDocument = gql`
    query getTeamMembersOptions($project_id: uuid!) {
  projects_by_pk(id: $project_id) {
    members {
      user {
        avatar
        full_name
        id
      }
    }
  }
}
    `;

export function useGetTeamMembersOptionsQuery(options: Omit<Urql.UseQueryArgs<GetTeamMembersOptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetTeamMembersOptionsQuery>({ query: GetTeamMembersOptionsDocument, ...options });
};
export const UpdateUsersInTeamDocument = gql`
    mutation updateUsersInTeam($input: UpdateUsersInTeamInput!) {
  updateUsersInTeam(input: $input) {
    __typename
    ... on UpdateUsersInTeamSuccess {
      project_id
      team_id
    }
  }
}
    `;

export function useUpdateUsersInTeamMutation() {
  return Urql.useMutation<UpdateUsersInTeamMutation, UpdateUsersInTeamMutationVariables>(UpdateUsersInTeamDocument);
};