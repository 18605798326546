import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "~/screens/App/components/CloseButton";

function DrawerHeader() {
  const { t } = useTranslation();

  return (
    <Box
      css={{ backgroundColor: "$indigo700", color: "$white" }}
      padding="gutter"
    >
      <Stack space="medium">
        <Inline width="full" align="between">
          <Heading level="3">
            {t(
              "screens.CreateOrgProjectSummaryDrawerContent.DrawerHeader.title",
              "Create your org project infos"
            )}
          </Heading>
          <DrawerClose asChild>
            <CloseButton dataIntercomTarget="project-profile-org-project-summary-create-close" />
          </DrawerClose>
        </Inline>
        <Text css={{ color: "$white" }}>
          {t(
            "screens.CreateOrgProjectSummaryDrawerContent.DrawerHeader.message",
            "You can change those information at any time"
          )}
        </Text>
      </Stack>
    </Box>
  );
}

export { DrawerHeader };
