import tw from "twin.macro";
import { styled } from "~/config/stitches";

const CheckboxWrapper = styled("div", {
  "&": {
    alignItems: "center",
    display: "flex",
  },

  variants: {
    disabled: {
      false: tw``,
      true: {
        "& > button": { ...tw`bg-neutral-200`, cursor: "default" },
      },
    },
  },
});

export { CheckboxWrapper };
