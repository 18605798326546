import { AnalyticsBrowser } from "@segment/analytics-next";
import debounce from "lodash/debounce";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { env } from "../env/index";

const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(null);

type AnalyticConfigProps = {
  children: React.ReactNode;
};

function SegmentProvider(props: AnalyticConfigProps) {
  const analytics = React.useMemo(
    () =>
      env.VITE_IS_STAGING || env.VITE_IS_PRODUCTION
        ? AnalyticsBrowser.load({ writeKey: env.VITE_SEGMENT_API_KEY })
        : null,
    []
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}

// Create an analytics hook that we can use with other components.
const useSegment = () => {
  const result = React.useContext(AnalyticsContext);
  return result;
};

const useTrackPage = () => {
  const [previousLocation, setPreviousLocation] = React.useState<string | null>(
    null
  );
  const location = useLocation();
  const AnalyticContext = useSegment();

  const sendPageView = React.useCallback(async () => {
    if (AnalyticContext) {
      void AnalyticContext.page();
    }
  }, []);

  // We use this logic to avoid location changes duplicates due to internal router rules
  // eg: Clicking on "docs" section will redirect to "/docs" which will redirect to "/docs/:projectRootFolder"
  // we want to avoid sending all of those changes to Segment as they are not relevant
  const delayedPageView = React.useCallback(
    debounce(() => sendPageView(), 1000),
    []
  );

  React.useEffect(() => {
    if (location.pathname !== previousLocation) {
      void delayedPageView();
    }
    setPreviousLocation(location.pathname);
  }, [location.pathname]);
};

export { SegmentProvider, useSegment, useTrackPage };
