import * as React from "react";
import { Box } from "~/design-system/Box";
import { Drawer, DrawerContent } from "~/design-system/Drawer";
import { OrgProfileContainer } from "./components/OrgProfile/OrgProfileContainer";

interface OrgProfileDrawerProps {
  orgId: string;
  children: React.ReactNode;
  onClose?: () => void;
}

function OrgProfileDrawer(props: OrgProfileDrawerProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <Drawer open={open} setOpen={setOpen}>
      <DrawerContent position="right" onClose={props.onClose}>
        <Box width="screen" maxWidth="xsmall">
          <OrgProfileContainer
            orgId={props.orgId}
            handleClose={() => {
              props.onClose?.();
              setOpen(false);
            }}
          />
        </Box>
      </DrawerContent>
      {props.children}
    </Drawer>
  );
}

export { OrgProfileDrawer };
