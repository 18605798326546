import * as React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "~/design-system/Avatar";
import { Button } from "~/design-system/Button";
import { Field } from "~/design-system/Field";
import { Inline } from "~/design-system/Inline";
import { createComponentHook } from "~/types";
//import { usePrepareProjectAssetForUploadMutation } from "../../../CreateGroupForm.graphql";

type AvatarGroupFieldProps = {
  avatarUrl?: ArrayBuffer | File | string | null;
  setGroupAvatar: React.Dispatch<
    React.SetStateAction<ArrayBuffer | File | string | null>
  >;
};

const useAvatarGroupField = createComponentHook(
  (props: AvatarGroupFieldProps) => {
    const { t } = useTranslation();

    const fileInputRef = React.createRef<HTMLInputElement>();
    const [avatarUrl, setAvatarUrl] = React.useState<
      ArrayBuffer | File | string | null | undefined
    >(props.avatarUrl as string);

    const handleButtonClick: React.MouseEventHandler<
      HTMLButtonElement
    > = () => {
      fileInputRef.current?.click();
    };

    const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      async function uploadFile() {
        const [file] = Array.from(e.target.files ?? []);

        if (!file) {
          return;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
          const avatarUrl = e.target?.result;
          setAvatarUrl(avatarUrl);
        };

        reader.readAsDataURL(file);
        props.setGroupAvatar(file);
      }

      void uploadFile();
    };
    return {
      actions: {
        handleButtonClick,
        handleFileInputChange,
      },
      state: {
        avatarUrl,
        fileInputRef,
      },
      t,
    };
  }
);

function AvatarGroupField(props: AvatarGroupFieldProps) {
  const { actions, state, t } = useAvatarGroupField(props);

  const input = (
    <input
      ref={state.fileInputRef}
      type="file"
      onChange={actions.handleFileInputChange}
      accept="image/x-png,image/gif,image/jpeg"
      hidden
    />
  );
  return (
    <Field
      label={t(
        "screens.CreateGroupForm.AvatarGroupField.avatarLabel",
        "Avatar"
      )}
      name="projectAvatar"
      dataIntercomTarget="project-edit-avatar"
    >
      <Inline space="small">
        {state.avatarUrl && typeof state.avatarUrl === "string" && (
          <Avatar src={state.avatarUrl} alt={"Avatar group"} />
        )}
        <Button
          type="button"
          variant="ghost"
          size="small"
          onClick={actions.handleButtonClick}
          dataIntercomTarget={`project-info-edit-${
            state.avatarUrl ? "change" : "add"
          }-avatar`}
        >
          {props.avatarUrl
            ? t(
                "screens.CreateGroupForm.AvatarGroupField.changeAvatar",
                "Change project avatar"
              )
            : t(
                "screens.CreateGroupForm.AvatarGroupField.addAvatar",
                "Add project Group avatar"
              )}
          {input}
        </Button>
      </Inline>
    </Field>
  );
}

export { AvatarGroupField };
