import * as React from "react";
import { Text } from "../../../../Text";

const CornerHint = (props: { cornerHint: React.ReactNode }) => {
  const { cornerHint } = props;
  if (typeof cornerHint === "string") {
    return (
      <Text size="small" tone="neutral">
        {cornerHint}
      </Text>
    );
  }
  return <>{cornerHint}</>;
};

export { CornerHint };
