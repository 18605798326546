import { DocumentTextIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import type { Path, UseFormRegister } from "~/config/react-hook-form";
import { Badge } from "~/design-system/Badge";
import { Box } from "~/design-system/Box";
import type {
  BasicMultiSelectFieldInputOption,
  BasicMultiSelectFieldInputProps,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { BasicMultiSelectFieldInput } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { IconAndTextLabel } from "~/screens/App/screens/Project/Tasks/components/TaskFiltersDialog/components/TaskFiltersForm/components/IconAndTextLabel";
import { createComponentHook } from "~/types";

// TODO.
// BasicMultiSelectFieldInput should by itself do the functions of this component,
// for some reason it was failing, in the future it is important to refactor
// this component to base it only on BasicMultiSelectFieldInput.
const useClientFilterButton = createComponentHook(
  (props: { value: string | null | undefined }) => {
    const { t } = useTranslation();
    const refContainer = React.createRef();
    const [clientSelected, setClientSelected] = React.useState<string | null>();

    React.useEffect(() => {
      setClientSelected(props.value);
    }, [props.value]);

    return {
      actions: {
        setClientSelected,
      },
      state: {
        clientSelected,
        refContainer,
      },
      t,
    };
  }
);

type ClientFilterButtonProps<T> = {
  control: BasicMultiSelectFieldInputProps<T>["control"];
  name: string;
  registerInput: UseFormRegister<T>;
  options: BasicMultiSelectFieldInputOption<string>[];
  onSelect: (value: string) => void;
  value: string | null | undefined;
  onRemove?: () => void;
  required?: BasicMultiSelectFieldInputProps<T>["required"];
  error?: BasicMultiSelectFieldInputProps<T>["error"];
};

type Option = {
  value: string;
  label: string;
};

function ClientFilterButton<T>(props: ClientFilterButtonProps<T>) {
  const { actions, state, t } = useClientFilterButton({ value: props.value });
  return (
    <>
      {!state.clientSelected ? (
        <BasicMultiSelectFieldInput
          label={
            <IconAndTextLabel
              icon={<DocumentTextIcon />}
              text={t(
                "screens.App.components.clientFilter",
                "Clients of the projects"
              )}
            />
          }
          control={props.control}
          options={props.options}
          multiple={false}
          onCreateOption={async (value) => {
            props.onSelect(value);
            actions.setClientSelected(value);
          }}
          onSelect={(value_) => {
            const value = value_ as unknown as Option;
            props.onSelect(value.value);
            actions.setClientSelected(value.value);
          }}
          {...props.registerInput(props.name as Path<T>)}
          dataIntercomTarget="client-filter-button"
          required={props.required}
          error={props.error}
        />
      ) : (
        <>
          <Box display={"flex"}>
            <IconAndTextLabel
              icon={<DocumentTextIcon />}
              text={t(
                "screens.App.components.clientFilter",
                "Clients of the projects"
              )}
            />
          </Box>
          <Box marginTop={"small"}>
            <Badge
              size="large"
              onRemove={() => {
                // This remove is to prevent the last selected value from being rendered. This deletes the internal value of the BasicMultiSelectFieldInput.
                props.onRemove?.();
                actions.setClientSelected(undefined);
              }}
            >
              {state.clientSelected}
            </Badge>
          </Box>
        </>
      )}
    </>
  );
}

export { ClientFilterButton };
