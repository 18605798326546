import * as React from "react";
import { SpinLoader } from "~/design-system/Spinner/SpinLoader";
import { Stack } from "~/design-system/Stack";

function FullPageSpinner(): JSX.Element {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack space={"large"} align={"center"} width={"full"}>
        <SpinLoader />
      </Stack>
    </div>
  );
}
export { FullPageSpinner };
