import * as React from "react";
import type { CellProps } from "react-table";
import type { CSS } from "~/config/stitches";
import { FileIcon } from "~/design-system/FileIcon";
import { FolderIcon } from "~/design-system/FolderIcon";
import { ListItem } from "~/design-system/List";
import { VirtualList } from "~/screens/App/components/VirtualList";
import { createComponentHook } from "~/types";
import type { FileFolder } from "../../FilesFoldersNavigator";
import { useFileFoldersNavigatorContext } from "../../FilesFoldersNavigatorContext";

const LIST_ITEM_HEIGHT = 74;

type FilesFoldersNavigatorListMobileProps = {
  css?: CSS;
  data: Array<FileFolder>;
};

type FilesFoldersNavigatorListItemProps = {
  data: FileFolder;
};
function FilesFoldersNavigatorListItem(
  props: FilesFoldersNavigatorListItemProps
) {
  if (props.data.type === "folder") {
    const folder = props.data.folder;
    return (
      <ListItem avatar={<FolderIcon size={"large"} />} title={folder.name} />
    );
  }
  const file = props.data.file;
  return (
    <ListItem
      avatar={
        <FileIcon
          size={"large"}
          format={file.extension ?? undefined}
          url={file.last_version?.[0].url ?? undefined}
        />
      }
      title={file.name}
      disabled={props.data.disabled}
    />
  );
}
const useFilesFoldersNavigatorListMobile = createComponentHook(() => {
  const { navigate, selectFile } = useFileFoldersNavigatorContext((state) => ({
    navigate: state.navigate,
    selectFile: state.selectFile,
  }));
  const rowClickHandler = React.useCallback(
    (row: FileFolder) => {
      if (row.type === "folder") {
        return navigate(row.folder.id);
      }
      if (row.type === "file") {
        return selectFile({
          fileId: row.file.id,
          fileVersionId: row.file.last_version![0].id,
        });
      }
    },
    [navigate, selectFile]
  );

  return {
    actions: {
      rowClickHandler,
    },
  };
});

function FilesFoldersNavigatorListMobile(
  props: FilesFoldersNavigatorListMobileProps
) {
  const { actions } = useFilesFoldersNavigatorListMobile();

  const rowPropsSetter = (row: FileFolder) => {
    if (row.type === "folder" || (row.type === "file" && !row.disabled)) {
      return { onClick: actions.rowClickHandler };
    }
  };

  return (
    <VirtualList
      css={props.css}
      data={props.data}
      item={{
        Cell: (props: CellProps<FileFolder>) => (
          <FilesFoldersNavigatorListItem data={props.cell.row.original} />
        ),
        accessor: (row: FileFolder) =>
          `${row.type === "folder" ? row.folder.name : row.file.name}`,
        id: "name",
      }}
      estimateSize={() => LIST_ITEM_HEIGHT}
      initialState={{
        sortBy: [],
      }}
      rowProps={(row) => rowPropsSetter(row)}
    />
  );
}

export type { FilesFoldersNavigatorListMobileProps };
export { FilesFoldersNavigatorListMobile };
