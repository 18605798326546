import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import type * as Stitches from "@stitches/react";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

const Overlay = styled(motion.div, {
  backgroundColor: "rgba(0, 0, 0, .15)",
  bottom: 0,
  left: 0,
  position: "fixed",
  right: 0,
  top: 0,
});

const Content = styled(motion.div, {
  ...{
    "&:focus": {
      outline: "none",
    },
    position: "fixed",
  },

  defaultVariants: {
    size: "regular",
  },

  variants: {
    size: {
      full: {
        bottom: "0",
        left: "0",
        right: "0",
        top: "0",
      },
      regular: {
        bottom: "auto",
        left: "50%",
        minWidth: 200,
        padding: "$medium",
        right: "auto",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
      },
    },
    width: {
      large: {
        maxWidth: "$screen-lg",
      },
    },
  },
});

const AlertOpenContext = React.createContext(false);

type UncontrolledAlertDialogProps = {
  children: React.ReactNode;
  open?: never;
  setOpen?: never;
};

type ControlledAlertDialogProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AlertDialogRoot = (props: ControlledAlertDialogProps) => (
  <AlertOpenContext.Provider value={props.open}>
    <AlertDialogPrimitive.Root open={props.open} onOpenChange={props.setOpen}>
      {props.children}
    </AlertDialogPrimitive.Root>
  </AlertOpenContext.Provider>
);

type AlertDialogProps =
  | ControlledAlertDialogProps
  | UncontrolledAlertDialogProps;

// Some rules about how to use popup for a good UX: https://www.nngroup.com/articles/popups/
const AlertDialog = (props: AlertDialogProps) => {
  const [open, setOpen] = React.useState(false);

  if (props.open !== undefined) {
    return <AlertDialogRoot {...props} />;
  }

  return <AlertDialogRoot open={open} setOpen={setOpen} {...props} />;
};

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;
const AlertDialogAction = AlertDialogPrimitive.Action;

type AlertDialogContentProps = Stitches.VariantProps<typeof Content> & {
  children: React.ReactNode;
  css?: CSS;
  // You want to use this option when your AlertDialogContent is in the
  // subtree of another element with an onClick listener on it
  stopClickPropagation?: boolean;
};

const stopClickPropagationHandler = (event: React.MouseEvent<unknown>) => {
  event.stopPropagation();
};

const AlertDialogContent = (props: AlertDialogContentProps) => {
  const open = React.useContext(AlertOpenContext);

  const animationsProps =
    props.size === "full"
      ? // When we are on mobile, the animation must end at a different point
        {
          animate: { opacity: 1, scale: 1, y: 0 },
          exit: { opacity: 0, scale: 0.96, y: "2%" },
          initial: { opacity: 0, scale: 0.96, y: "2%" },
          transition: { bounce: 0 },
        }
      : // When on desktop, we need to make our animation end the popup at translate(-50%, -50%) for centering
        {
          animate: { opacity: 1, scale: 1, x: "-50%", y: "-50%" },
          exit: { opacity: 0, scale: 0.96, y: "-48%" },
          initial: { opacity: 0, scale: 0.96, x: `-50%`, y: "-48%" },
          transition: { bounce: 0 },
        };

  return (
    <AnimatePresence>
      {open && (
        <>
          <AlertDialogPrimitive.Overlay forceMount asChild>
            <Overlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={
                props.stopClickPropagation
                  ? stopClickPropagationHandler
                  : undefined
              }
            />
          </AlertDialogPrimitive.Overlay>
          <AlertDialogPrimitive.Content forceMount asChild>
            <Content
              size={props.size}
              {...animationsProps}
              width={props.width}
              css={props.css}
              onClick={
                props.stopClickPropagation
                  ? stopClickPropagationHandler
                  : undefined
              }
            >
              {props.children}
            </Content>
          </AlertDialogPrimitive.Content>
        </>
      )}
    </AnimatePresence>
  );
};

export type { AlertDialogProps };
export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTrigger,
  AlertOpenContext,
};
