import { FolderOpenIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DialogTrigger } from "~/design-system/Dialog";
import { MenuItem } from "~/design-system/DropdownMenu";

const useFromClovisMenuItem = () => {
  const { t } = useTranslation();

  return {
    t,
  };
};

function FromClovisMenuItem() {
  const { t } = useFromClovisMenuItem();

  return (
    <DialogTrigger asChild>
      <MenuItem
        icon={<FolderOpenIcon />}
        dataIntercomTarget="task-create-add-attachment-from-clovis-docs"
      >
        {t(
          "screens.Project.Tasks.CreateTaskForm.AddAttachmentButton.FromClovisMenuItem.label",
          "From Clovis Docs"
        )}
      </MenuItem>
    </DialogTrigger>
  );
}

export { FromClovisMenuItem };
