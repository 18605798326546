import * as React from "react";
import { Tones } from "../tokens";
import { FieldContent } from "./components/FieldContent";
import type { FieldLabelProps } from "./components/FieldLabel/FieldLabel";
import { FieldLabel } from "./components/FieldLabel/FieldLabel";
import { FieldMessage } from "./components/FieldMessage";

type FieldProps = FieldLabelProps & {
  message?: React.ReactNode;
  children: React.ReactNode;
  rootProps?: React.HTMLAttributes<HTMLDivElement>;
  dataIntercomTarget: string;
};

function Field(props: FieldProps) {
  const {
    children,
    dataIntercomTarget,
    message: propMessage,
    rootProps,
    ...fieldLabelProps
  } = props;
  const tone = props.error ? Tones.critical : props.tone;
  const message = props.error ?? propMessage;

  return (
    <div {...rootProps}>
      <FieldLabel
        {...fieldLabelProps}
        dataIntercomTarget={dataIntercomTarget}
      />
      <FieldContent>{children}</FieldContent>
      {message && <FieldMessage tone={tone}>{message}</FieldMessage>}
    </div>
  );
}

export type { FieldProps };
export { Field };
