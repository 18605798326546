import { LightningBoltIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useWorkerStatusContext } from "~/config/user/workerstatus/WorkerStatus";
import { Button } from "~/design-system/Button";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer, DrawerTrigger } from "~/design-system/Drawer";
import { Spinner } from "~/design-system/Spinner/Spinner";
import { createComponentHook } from "~/types";
import { WorkerStatusDetailsDrawerContent } from "./components/WorkerStatusDetailsDrawerContent";

interface WorkerStatusDetailsDrawerProps {
  controlled?: Pick<ControlledDrawerProps, "open" | "setOpen">;
  children?: React.ReactNode;
}

const useWorkerStatusDetailsDrawer = createComponentHook(
  (props: WorkerStatusDetailsDrawerProps) => {
    const [open, setOpen] = React.useState(false);
    const state = props.controlled ?? { open, setOpen };
    return {
      state,
    };
  }
);

function WorkerStatusDetailsDrawerTrigger() {
  const { hasPending } = useWorkerStatusContext();
  return (
    <DrawerTrigger asChild>
      <Button
        variant="transparent"
        size="small"
        circular={true}
        dataIntercomTarget="project-workerstatus"
      >
        {hasPending ? <Spinner size="small" /> : <LightningBoltIcon />}
      </Button>
    </DrawerTrigger>
  );
}

function WorkerStatusDetailsDrawer(props: WorkerStatusDetailsDrawerProps) {
  const { state } = useWorkerStatusDetailsDrawer(props);
  return (
    <Drawer open={state.open} setOpen={state.setOpen}>
      <WorkerStatusDetailsDrawerContent onClose={() => state.setOpen(false)} />
      {props.children}
    </Drawer>
  );
}

export { WorkerStatusDetailsDrawer, WorkerStatusDetailsDrawerTrigger };
