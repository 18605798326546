import { PaperClipIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/design-system/Button";
import type { MenuItemProps } from "~/design-system/DropdownMenu";
import {
  DropdownMenu,
  DropdownMenuContent,
} from "~/design-system/DropdownMenu";
import { FromClovisMenuItem } from "~/screens/App/screens/Project/Tasks/components/CreateTaskForm/components/AddAttachmentButton/components/FromClovisMenuItem";
import { FromMyDeviceMenuItem } from "~/screens/App/screens/Project/Tasks/components/CreateTaskForm/components/AddAttachmentButton/components/FromMyDeviceMenuItem";
import { SelectFileDialog } from "~/screens/App/screens/Project/Tasks/components/SelectFileDialog/SelectFileDialog";
import { createComponentHook } from "~/types";

//
type AddAttachmentApprovalButton = {
  taskId?: string;
  projectId: string;
  onFileSelected: (fileSelected: { file_version_id: string }) => void;
  onAttachmentChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useAddAttachmentButton = createComponentHook(
  (props: AddAttachmentApprovalButton) => {
    const { t } = useTranslation();
    const fileInputRef = React.createRef<HTMLInputElement>();

    const handleButtonClick: MenuItemProps["onClick"] = (event) => {
      fileInputRef.current?.click();
    };

    const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      //Add in ApprovalForm new Files
      props.onAttachmentChange?.(e);
    };

    const handleFileSelected = (file: { fileVersionId: string }) => {
      props.onFileSelected({
        file_version_id: file.fileVersionId,
      });
    };

    return {
      actions: {
        handleButtonClick,
        handleFileInputChange,
        handleFileSelected,
      },
      state: {
        fileInputRef,
      },
      t,
    };
  }
);
function AddAttachmentApprovalButton(props: AddAttachmentApprovalButton) {
  const { actions, state, t } = useAddAttachmentButton(props);

  // Hide File Input
  const fileInput = (
    <input
      ref={state.fileInputRef}
      onChange={actions.handleFileInputChange}
      type="file"
      multiple
      hidden
    />
  );
  return (
    <SelectFileDialog
      projectId={props.projectId}
      onFileSelected={actions.handleFileSelected}
    >
      <>
        <DropdownMenu
          label="Add attachment"
          trigger={
            <Button
              size="small"
              variant="ghost"
              type="button"
              leadingIcon={<PaperClipIcon />}
              iconLabel="Add attachments"
              width="full"
              dataIntercomTarget="task-edit-add-attachments"
            >
              {t(
                "screens.Project.Tasks.UpdateTaskForm.AddAttachmentButton.addAttachments",
                "Add attachments"
              )}
            </Button>
          }
        >
          <DropdownMenuContent>
            <FromClovisMenuItem />
            <FromMyDeviceMenuItem onClick={actions.handleButtonClick} />
          </DropdownMenuContent>
        </DropdownMenu>
        {fileInput}
      </>
    </SelectFileDialog>
  );
}
export { AddAttachmentApprovalButton };
