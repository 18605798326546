import type { Color } from "@clovis/server/src/app/config/colors/colors";
import React from "react";
import type { MultiValueProps } from "react-select";
import { Badge } from "~/design-system/Badge";
import {
  OrgBadge,
  ProjectBadge,
  ProjectCategoryBadge,
  ProjectLabelBadge,
  TeamBadge,
  UserBadge,
} from "../../Badges";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";
import { BasicMultiSelectFieldInputOptionType } from "../BasicMultiSelectFieldInput";

export function MultiValue(
  props: MultiValueProps<BasicMultiSelectFieldInputOption<any>>
) {
  /* Set handleRemove as undefined when the input is disabled to hide the "cross" on the badges */
  const handleRemove = props.isDisabled
    ? undefined
    : (e: React.MouseEvent<HTMLButtonElement>) =>
        props.removeProps.onClick?.(
          e as unknown as React.MouseEvent<HTMLDivElement>
        );

  switch (props.data.type) {
    case BasicMultiSelectFieldInputOptionType.TEAM:
      return (
        <TeamBadge
          color={props.data.color as Color}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </TeamBadge>
      );
    case BasicMultiSelectFieldInputOptionType.PROJECT_CATEGORY:
      return (
        <ProjectCategoryBadge
          color={props.data.color as Color}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </ProjectCategoryBadge>
      );
    case BasicMultiSelectFieldInputOptionType.PROJECT_LABEL:
      return (
        <ProjectLabelBadge
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          color={props.data.color as Color}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </ProjectLabelBadge>
      );
    case BasicMultiSelectFieldInputOptionType.ORG:
      return (
        <OrgBadge
          avatar={props.data.avatar}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </OrgBadge>
      );
    case BasicMultiSelectFieldInputOptionType.TEMPORARY_ORG:
      return (
        <OrgBadge
          avatar={props.data.avatar}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </OrgBadge>
      );
    case BasicMultiSelectFieldInputOptionType.PROJECT:
      return (
        <ProjectBadge
          avatar={props.data.avatar}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </ProjectBadge>
      );
    case BasicMultiSelectFieldInputOptionType.USER:
      return (
        <UserBadge
          avatar={props.data.avatar}
          size="large"
          onRemove={handleRemove}
        >
          {props.data.label}
        </UserBadge>
      );
    default:
      return (
        <Badge size="large" onRemove={handleRemove}>
          {props.data.label}
        </Badge>
      );
  }
}
