import {
  Color,
  colorToHexValues,
} from "@clovis/server/src/app/config/colors/colors";
import type { Core } from "@pdftron/webviewer";

const COLORS = [
  colorToHexValues.get(Color.Red)!.base,
  colorToHexValues.get(Color.Orange)!.base,
  colorToHexValues.get(Color.Green)!.base,
  colorToHexValues.get(Color.Blue)!.base,
  colorToHexValues.get(Color.Purple)!.base,
  colorToHexValues.get(Color.Dark)!.base,
];

const Tool = {
  Arrow: "AnnotationCreateArrow" as unknown as Core.Tools.ToolNames,
  DoubleArrow: "AnnotationCreateDoubleArrow",
  Edit: "AnnotationEdit" as unknown as Core.Tools.ToolNames,
  Ellipse: "AnnotationCreateEllipse" as unknown as Core.Tools.ToolNames,
  FreeHand: "AnnotationCreateFreeHand" as unknown as Core.Tools.ToolNames,
  FreeText: "AnnotationCreateFreeText" as unknown as Core.Tools.ToolNames,
  Line: "AnnotationCreateLine" as unknown as Core.Tools.ToolNames,
  Pan: "Pan" as unknown as Core.Tools.ToolNames,
  PolygonCloud:
    "AnnotationCreatePolygonCloud" as unknown as Core.Tools.ToolNames,
  Rectangle: "AnnotationCreateRectangle" as unknown as Core.Tools.ToolNames,
};

type ToolValue = (typeof Tool)[keyof typeof Tool];

export { COLORS, Tool };
export type { ToolValue };
