import tw from "twin.macro";
import { styled } from "~/config/stitches";

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const CheckboxItemWrapper = styled("div", {
  "&": {
    display: "flex",
    flexDirection: "column",
  },

  compoundVariants: [
    {
      checked: true,
      css: tw`bg-indigo-50 border-indigo-200 z-10`,
      variant: "list",
    },
  ],

  variants: {
    checked: {
      false: tw``,
      true: tw``,
    },
    variant: {
      list: {
        ...tw`relative flex
        border border-gray-200 
        cursor-pointer 
        focus:outline-none
        w-full
        `,
        "&:first-child": tw`rounded-tl-md rounded-tr-md`,
        "&:last-child": tw`rounded-bl-md rounded-br-md`,
      },
      simple: tw``,
    },
  },
});

/* needed for border-collapse and padding */
const SecondWrapper = styled("div", {
  variants: {
    variant: {
      list: {
        padding: "0.85rem",
      },
      simple: tw``,
    },
  },
});

export { CheckboxItemWrapper, SecondWrapper };
