import { ChevronDownIcon } from "@heroicons/react/solid";
import * as React from "react";
import tw from "twin.macro";
import { css } from "~/config/stitches";
import { Icon } from "../../Icon";
import { DropdownMenuButton } from "./DropdownMenuButton";

export function DefaultTrigger(props: { label: string }) {
  return (
    <DropdownMenuButton variant="default">
      {props.label}
      <Icon size="small" label="dropdown trigger icon">
        <ChevronDownIcon
          className={css({ ...tw`-mr-1 ml-2` })()}
          aria-hidden="true"
        />
      </Icon>
    </DropdownMenuButton>
  );
}
