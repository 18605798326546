import * as React from "react";
import useDefaultBreakpoint from "use-breakpoint";
import { BREAKPOINTS_NUM } from "./tokens";

const useBreakpoint = (defaultBreakpoint = "mobile" as const) => {
  return useDefaultBreakpoint(BREAKPOINTS_NUM, defaultBreakpoint);
};

const isDesktopApp = () => {
  // https://docs.todesktop.com/customizing-your-app/adding-custom-css-to-your-desktop-app
  const classnameApplied = document.querySelector("html.todesktop");

  return !!classnameApplied;
};

const useDeviceType = () => {
  // This is the main type setted at start
  const [mainType, setMainType] = React.useState<"mouse" | "touch">("mouse");

  // For hybrid devices, get the current type
  const [currentType, setCurrentType] = React.useState<"mouse" | "touch">(
    "mouse"
  );

  // https://gist.github.com/esedic/39a16a7521d42ae205203e3d40dc19f5
  // Method 1
  if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
    if (mainType !== "touch") setMainType("touch");
  }

  function touchDetected() {
    setCurrentType("touch");
  }

  function mouseDetected() {
    setCurrentType("mouse");
  }

  // Update the current device type for hybrid devices
  React.useEffect(() => {
    if (currentType === "mouse") {
      document.addEventListener("touchstart", touchDetected, false);
      document.removeEventListener("mouseover", mouseDetected, false);
    } else {
      document.addEventListener("mouseover", mouseDetected, false);
      document.removeEventListener("touchstart", touchDetected, false);
    }
  }, [currentType]);

  return { currentType, mainType };
};

export { isDesktopApp, useBreakpoint, useDeviceType };
