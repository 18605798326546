import * as React from "react";
import {
  useUpdateBrowserLanguageMutation,
  useUpdateBrowserTimezoneMutation,
} from "./UpdateBrowserInfos.graphql";

type UpdateBrowserInfosProps = {
  children: React.ReactNode;
};

const useUpdateBrowserInfos = () => {
  const [, updateBrowserTimezone] = useUpdateBrowserTimezoneMutation();
  const [, updateBrowserLanguage] = useUpdateBrowserLanguageMutation();

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserLanguage = navigator.language;

  React.useEffect(() => {
    void updateBrowserTimezone({ timezone: userTimezone });
  }, [updateBrowserTimezone, userTimezone]);

  React.useEffect(() => {
    void updateBrowserLanguage({ language: browserLanguage });
  }, [updateBrowserLanguage, browserLanguage]);
};

function UpdateBrowserInfos(props: UpdateBrowserInfosProps) {
  useUpdateBrowserInfos();

  return <>{props.children}</>;
}

export { UpdateBrowserInfos };
