import { ExclamationCircleIcon } from "@heroicons/react/outline";
import * as React from "react";
import tw from "twin.macro";
import { Box } from "../../../../Box";
import { Icon } from "../../../../Icon";
import type { Tones } from "../../../../tokens";

export const TrailingIcon = (props: {
  trailingIcon: React.ReactNode;
  tone?: Tones.critical | Tones.neutral | Tones.positive;
}) => (
  <Box
    position="absolute"
    right={0}
    paddingRight="small"
    display="flex"
    alignItems="center"
    pointerEvents="none"
    css={tw`inset-y-0`}
  >
    {props.tone === "critical" ? (
      <Icon color="critical" label="exclamation trailing icon">
        <ExclamationCircleIcon />
      </Icon>
    ) : (
      <Icon color="neutral" label="trailing icon">
        {props.trailingIcon}
      </Icon>
    )}
  </Box>
);
