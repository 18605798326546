import { MenuIcon } from "@heroicons/react/outline";
import * as React from "react";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { SafeArea } from "~/design-system/SafeArea";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { AllNotificationsBadge } from "~/screens/App/components/NotificationsBadge/AllNotificationsBadge";
import type { UserInfos } from "../../../NavigationDrawer";

type SettingsDrawerButtonProps = {
  user: UserInfos;
};

const CircularButtonNotificationWrapper = (props: {
  children: React.ReactNode;
}) => {
  return (
    <Box position="relative">
      <Box position="absolute" css={{ right: "-6px", top: "-6px" }}>
        <AllNotificationsBadge />
      </Box>
      {props.children}
    </Box>
  );
};

const SettingsDrawerButton = (props: SettingsDrawerButtonProps) => {
  const userFirstName = props.user.first_name ?? " ";
  const userLastName = props.user.last_name ?? " ";
  const userFullName = `${userFirstName} ${userLastName}`;
  return (
    <Box
      display="flex"
      alignItems="center"
      width="full"
      paddingY="xsmall"
      paddingX="small"
      css={{
        "&:hover": {
          backgroundColor: "$slate700",
        },
        backgroundColor: "$slate800",
      }}
    >
      <SafeArea bottom css={{ width: "100%" }}>
        <Columns space="small" alignY="center">
          <Column width="content">
            {props.user.avatar ? (
              <Avatar
                alt={`${userFullName} avatar`}
                src={props.user.avatar}
                shape="circular"
                size="large"
              />
            ) : (
              <Avatar size="large" shape="circular">
                {userFullName}
              </Avatar>
            )}
          </Column>
          <Column>
            <Stack>
              <Text
                truncate={true}
                align="left"
                tone="white"
              >{`${userFullName}`}</Text>
              <Text align="left" size="small" tone="neutral" truncate={true}>
                {props.user.contact?.email}
              </Text>
            </Stack>
          </Column>
          <Column width="content" align="right">
            <CircularButtonNotificationWrapper>
              <Button
                circular={true}
                size="small"
                as="div"
                dataIntercomTarget="navigation-drawer-settings"
              >
                <MenuIcon />
              </Button>
            </CircularButtonNotificationWrapper>
          </Column>
        </Columns>
      </SafeArea>
    </Box>
  );
};

export { SettingsDrawerButton };
