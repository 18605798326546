import type { PermissionDataType as PermissionType } from "@clovis/server/src/app/domain/utils/permissions/permissions";
import {
  getOrgData,
  getProjectData,
} from "@clovis/server/src/app/domain/utils/permissions/permissions";
import { CheckIcon } from "@heroicons/react/solid";
import { sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import type { CellProps, Column } from "react-table";
import { i18n } from "~/config/i18next";
import type { CSS } from "~/config/stitches";
import { getSectionPriority } from "~/config/users-rights-permissions/utils";
import { Badge } from "~/design-system/Badge";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { CELL_HEIGHT } from "~/design-system/Table";
import { Text } from "~/design-system/Text";
import { VirtualTable } from "~/screens/App/components/VirtualTable";
import type { LanguageType } from "~/screens/App/screens/AnonRightsScreen/components/LanguageDropdown/LanguageDropdown";
import { PermissionTranslation } from "~/screens/App/screens/AnonRightsScreen/components/PermissionTable/components/PermissionTranslation";
import {
  sectionBadgesColors,
  SectionTranslation,
} from "~/screens/App/screens/AnonRightsScreen/components/PermissionTable/components/SectionTranslation";
import { createComponentHook } from "~/types";

type ProjectPermissionTableProps = {
  type: "org" | "project";
  title: string;
  css?: CSS;
  language: LanguageType;
};

const useProjectPermissionTable = createComponentHook(
  (props: ProjectPermissionTableProps) => {
    const { t } = useTranslation();

    React.useEffect(() => {
      if (props.language !== i18n.language) {
        void i18n.changeLanguage(props.language);
      }
    }, [props.language]);

    // TODO add types on permissions
    // Example of one permission in the array
    //   {
    //     "administrator": true,
    //     "disabled": false,
    //     "limited": false,
    //     "owner": true,
    //     "permission": "UpdateProjectUserInfos",
    //     "readOnly": false,
    //     "section": "user",
    //     "standard": true
    // }
    const permissions = [];
    if (props.type === "org") {
      permissions.push(...getOrgData());
    } else if (props.type === "project") {
      permissions.push(...getProjectData());
    }

    const sortedPermissions = sortBy(permissions, (p) =>
      getSectionPriority(p.section)
    );

    const columns = React.useMemo<Array<Column<PermissionType>>>(
      () => [
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-section ${props.cell.row.original.permission}}`}
              // Here the id is usefull to help to scroll directly to one permission
              id={props.cell.row.original.permission}
            >
              <Badge
                color={sectionBadgesColors[props.cell.row.original.section]}
              >
                <SectionTranslation section={props.cell.row.original.section} />
              </Badge>
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.section", "Section"),
          id: "section",
          width: "150px",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              css={{ whiteSpace: "normal" }}
              data-intercom-target={`column-permission ${props.cell.row.original.permission}}`}
            >
              <PermissionTranslation
                permission={props.cell.row.original.permission}
              />
            </Box>
          ),
          Header: () =>
            t(
              "screens.ProjectPermissionTable.columns.permission",
              "Permission"
            ),
          id: "permission",
          width: "300px",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-owner ${props.cell.row.original.permission}}`}
              justifyContent={"center"}
            >
              {props.cell.row.original.owner && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.owner", "Owner"),
          id: "owner",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-administrator ${props.cell.row.original.permission}}`}
            >
              {props.cell.row.original.administrator && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.admin", "Admin"),
          id: "administrator",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-standard ${props.cell.row.original.permission}}`}
            >
              {props.cell.row.original.standard && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.standard", "Standard"),
          id: "standard",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-limited ${props.cell.row.original.permission}}`}
            >
              {props.cell.row.original.limited && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.limited", "Limited"),
          id: "limited",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-readOnly ${props.cell.row.original.permission}}`}
            >
              {props.cell.row.original.readOnly && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.readOnly", "Read only"),
          id: "readOnly",
        },
        {
          Cell: (props: CellProps<PermissionType>) => (
            <Box
              data-intercom-target={`column-disabled ${props.cell.row.original.permission}}`}
            >
              {props.cell.row.original.disabled && (
                <Icon
                  label={`icon-permission ${props.cell.row.original.permission}}`}
                  size="small"
                >
                  <CheckIcon />
                </Icon>
              )}
            </Box>
          ),
          Header: () =>
            t("screens.ProjectPermissionTable.columns.disabled", "disabled"),
          id: "disabled",
        },
      ],
      [t]
    );
    return {
      state: {
        columns,
        sortedPermissions,
      },
    };
  }
);

const PermissionTableDesktop = (props: ProjectPermissionTableProps) => {
  const { state } = useProjectPermissionTable(props);
  return (
    <Box css={{ ...props.css }}>
      <Box marginBottom="medium">
        <Text size="xxlarge" weight="medium">
          {props.title}
        </Text>
      </Box>
      <VirtualTable
        estimateSize={() => CELL_HEIGHT}
        data={state.sortedPermissions}
        columns={state.columns}
        manualSort
      />
    </Box>
  );
};

export { PermissionTableDesktop };
