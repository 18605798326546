import type {
  IProjectNotification,
  IProjectNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import {
  IProjectNotificationTypePrefix,
  ProjectNotificationNamespace,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";

interface IChatNotificationPayload extends IProjectNotificationPayload {
  channelId: string;
  messageId?: string;
}

// All Chat notifications must extend from this interface
const IChatNotificationTypePrefix =
  `${IProjectNotificationTypePrefix}/${ProjectNotificationNamespace.Chat}` as const;
interface IChatNotification extends IProjectNotification {
  type: `${typeof IChatNotificationTypePrefix}/${string}`;
  readonly event_id: null;
  readonly payload: IChatNotificationPayload;
}

export type { IChatNotification };
export { IChatNotificationTypePrefix };
