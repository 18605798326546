import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DialogClose, DialogContent } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { useBreakpoint } from "~/design-system/hooks";
import { Stack } from "~/design-system/Stack";
import { CloseButton } from "../../CloseButton";
import { CreateDemoProjectForm } from "./CreateDemoProjectForm";

const useCreateDemoProjectContent = () => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();

  return {
    state: { breakpoint },
    t,
  };
};

function CreateDemoProjectContent(props: {
  onSuccess?: (projectId: string) => void;
}) {
  const { state, t } = useCreateDemoProjectContent();

  return (
    <DialogContent
      css={{ maxWidth: "$md" }}
      size={state.breakpoint === "mobile" ? "full" : "regular"}
      stopClickPropagation
    >
      <Box
        background="card"
        boxShadow="medium"
        padding="gutter"
        height={state.breakpoint === "mobile" ? "full" : undefined}
        borderRadius={state.breakpoint !== "mobile" ? "standard" : undefined}
      >
        <Stack space="gutter">
          <Box display="flex" justifyContent="spaceBetween" alignItems="center">
            <Heading level="3">
              {t(
                "components.CreateDemoProjectDialog.CreateDemoProjectContent.title",
                "Create a demo project"
              )}
            </Heading>
            <DialogClose asChild>
              <CloseButton dataIntercomTarget="create-demo-project-close" />
            </DialogClose>
          </Box>
          <CreateDemoProjectForm onSuccess={props.onSuccess} />
        </Stack>
      </Box>
    </DialogContent>
  );
}

export { CreateDemoProjectContent };
