import * as React from "react";
import type { PolymorphicComponentProps } from "react-polymorphic-box";
import type { CSS } from "~/config/stitches";
import { Icon } from "../Icon";
import { BaseMenuItem, ContextBaseMenuItem } from "./components/BaseMenuItem";

type MenuItemOwnProps = {
  dataIntercomTarget: string;
  children: React.ReactNode;
  clovisIcon?: React.ReactNode;
  tone?: "caution" | "critical" | "info" | "neutral" | "positive";
  disabled?: boolean;
  onClick?: (event: Event) => void;
  icon?: React.ReactNode;
  css?: CSS;
  allowOnClickOnDisabled?: boolean;
};

type MenuItemProps<E extends React.ElementType = "div"> =
  PolymorphicComponentProps<E, MenuItemOwnProps>;

const MenuItem = React.forwardRef(function MenuItem<
  E extends React.ElementType = "div"
>(props: MenuItemProps<E>, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    allowOnClickOnDisabled,
    children,
    clovisIcon,
    dataIntercomTarget,
    disabled,
    icon,
    onClick,
    ...baseMenuItemProps
  } = props;
  return (
    <BaseMenuItem
      /* used to follow radix logic, attribute we use for styling */
      data-disabled={disabled}
      {...baseMenuItemProps}
      ref={ref}
      onSelect={!disabled || allowOnClickOnDisabled ? onClick : undefined}
      disabled={disabled}
      css={props.css}
      data-intercom-target={dataIntercomTarget}
    >
      {icon && (
        <Icon
          size="small"
          aria-hidden={true}
          color={props.tone ?? "neutral"}
          label="menu icon"
        >
          {icon}
        </Icon>
      )}
      {clovisIcon}
      {children}
    </BaseMenuItem>
  );
});

const ContextMenuItem = React.forwardRef(function MenuItem<
  E extends React.ElementType = "div"
>(props: MenuItemProps<E>, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    allowOnClickOnDisabled,
    children,
    clovisIcon,
    disabled,
    icon,
    onClick,
    ...baseMenuItemProps
  } = props;
  return (
    <ContextBaseMenuItem
      /* used to follow radix logic, attribute we use for styling */
      data-disabled={disabled}
      {...baseMenuItemProps}
      ref={ref}
      onSelect={!disabled || allowOnClickOnDisabled ? onClick : undefined}
      disabled={disabled}
      css={props.css}
    >
      {icon && (
        <Icon
          size="small"
          aria-hidden={true}
          color={props.tone ?? "neutral"}
          label="menu icon"
        >
          {icon}
        </Icon>
      )}
      {clovisIcon}
      {children}
    </ContextBaseMenuItem>
  );
});

export type { MenuItemProps };
export { ContextMenuItem, MenuItem };
