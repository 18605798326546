import type * as Types from '../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FileBadge_FileVersionFragment = { id: string, name: string, content_type: string, url?: string | null | undefined };

export type FileBadge_DataQueryVariables = Types.Exact<{
  fileVersionId: Types.Scalars['uuid'];
}>;


export type FileBadge_DataQuery = { file_versions_by_pk?: { id: string, name: string, content_type: string, url?: string | null | undefined } | null | undefined };

export const FileBadge_FileVersionFragmentDoc = gql`
    fragment FileBadge_fileVersion on file_versions {
  id
  name
  content_type
  url
}
    `;
export const FileBadge_DataDocument = gql`
    query FileBadge_data($fileVersionId: uuid!) {
  file_versions_by_pk(id: $fileVersionId) {
    ...FileBadge_fileVersion
  }
}
    ${FileBadge_FileVersionFragmentDoc}`;

export function useFileBadge_DataQuery(options: Omit<Urql.UseQueryArgs<FileBadge_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FileBadge_DataQuery>({ query: FileBadge_DataDocument, ...options });
};