import { UsersIcon } from "@heroicons/react/outline";
import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type TeamBadgeProps = Pick<
  BadgeProps,
  "children" | "clickable" | "color" | "onRemove" | "shadow" | "size"
>;

const TeamBadge = (props: TeamBadgeProps) => {
  const { children, ...rest } = props;

  return (
    <Badge leadingIcon={<UsersIcon />} maxWidth="full" {...rest}>
      <Text size="inherit" truncate>
        {children}
      </Text>
    </Badge>
  );
};

export { TeamBadge };
