import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GroupsList_ItemFragment = { id: string, name: string, color: string, avatar?: string | null | undefined };

export type UpdateProjectGroupMutationVariables = Types.Exact<{
  input: Types.UpdateProjectGroupInput;
}>;


export type UpdateProjectGroupMutation = { updateProjectGroup?: { __typename: 'UpdateProjectGroupErrors' } | { __typename: 'UpdateProjectGroupSuccess', project_group_id: string } | null | undefined };

export type DeleteProjectGroupMutationVariables = Types.Exact<{
  input: Types.DeleteProjectGroupInput;
}>;


export type DeleteProjectGroupMutation = { deleteProjectGroup?: { __typename: 'DeleteProjectGroupErrors' } | { __typename: 'DeleteProjectGroupSuccess', project_group_id: string } | null | undefined };

export const GroupsList_ItemFragmentDoc = gql`
    fragment GroupsList_item on project_groups {
  id
  name
  color
  avatar
}
    `;
export const UpdateProjectGroupDocument = gql`
    mutation updateProjectGroup($input: UpdateProjectGroupInput!) {
  updateProjectGroup(input: $input) {
    __typename
    ... on UpdateProjectGroupSuccess {
      project_group_id
    }
  }
}
    `;

export function useUpdateProjectGroupMutation() {
  return Urql.useMutation<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>(UpdateProjectGroupDocument);
};
export const DeleteProjectGroupDocument = gql`
    mutation deleteProjectGroup($input: DeleteProjectGroupInput!) {
  deleteProjectGroup(input: $input) {
    __typename
    ... on DeleteProjectGroupSuccess {
      project_group_id
    }
  }
}
    `;

export function useDeleteProjectGroupMutation() {
  return Urql.useMutation<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>(DeleteProjectGroupDocument);
};