import type {
  events_workers_status,
  Prisma,
} from "@clovis/server/src/app/config/prisma";
import type {
  ProjectWorkerStatus,
  UserWorkerStatus,
  WorkerStatusUnion,
} from "@clovis/server/src/app/workers/status/workerstatus.types";

// eslint-disable-next-line @typescript-eslint/ban-types
enum WorkerStatusNamespace {
  Project = "PROJECT",
  User = "USER",
}

type WorkerStatus = "ERROR" | "PENDING" | "SUCCESS" | "TIMEOUT";

// eslint-disable-next-line @typescript-eslint/ban-types
interface IWorkerStatus<T extends Prisma.InputJsonValue = {}> {
  readonly type: `${WorkerStatusNamespace}/${string}`;
  readonly creator_id: string;
  readonly event_id: string;
  readonly status: WorkerStatus;
  readonly payload: T;
}

type DistributivePick<T, K extends keyof T> = T extends T ? Pick<T, K> : never;

type DomainToPrismaWorkerStatus = DistributivePick<
  events_workers_status,
  "created_at" | "id" | "updated_at"
> &
  WorkerStatusUnion;

type DomainPrismaWorkerStatus<T extends DomainToPrismaWorkerStatus["type"]> =
  DistributivePick<
    Extract<DomainToPrismaWorkerStatus, { type: T }>,
    "payload" | "type"
  > &
    DomainToPrismaWorkerStatus;

export type {
  DomainPrismaWorkerStatus,
  DomainToPrismaWorkerStatus,
  IWorkerStatus,
  ProjectWorkerStatus,
  UserWorkerStatus,
  WorkerStatus,
};
export { WorkerStatusNamespace };
