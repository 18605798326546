import * as React from "react";
import create from "zustand";
import createContext from "zustand/context";

type FilesFoldersNavigatorState = {
  folderId: string;
  folderName: string;
  initialFolderId: string;
  projectId: string;
  navigate: (folderId: string) => void;
  selectFile: (file: { fileId: string; fileVersionId: string }) => void;
  setFolderName: (folderName: string) => void;
};

const { Provider, useStore: useFileFoldersNavigatorContext } =
  createContext<FilesFoldersNavigatorState>();

type FilesFoldersNavigatorModesProviderProps = {
  initialFolderId: string;
  projectId: string;
  children: React.ReactNode;
  onFolderChange?: (folderId: string) => unknown;
  onFileSelect?: (file: { fileId: string; fileVersionId: string }) => unknown;
};

function FilesFoldersNavigatorProvider(
  props: FilesFoldersNavigatorModesProviderProps
) {
  return (
    <Provider
      createStore={() =>
        create<FilesFoldersNavigatorState>((set) => ({
          folderId: props.initialFolderId,
          folderName: "",
          initialFolderId: props.initialFolderId,
          navigate: (folderId: string) => {
            set({ folderId });
            props.onFolderChange?.(folderId);
          },
          projectId: props.projectId,
          selectFile: (file: { fileId: string; fileVersionId: string }) => {
            props.onFileSelect?.(file);
          },
          setFolderName: (folderName: string) => set({ folderName }),
        }))
      }
    >
      {props.children}
    </Provider>
  );
}

export type { FilesFoldersNavigatorModesProviderProps };
export { FilesFoldersNavigatorProvider, useFileFoldersNavigatorContext };
