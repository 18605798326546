import { DocumentTextIcon } from "@heroicons/react/outline";
import type * as Stitches from "@stitches/react";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { ImagePreview } from "~/design-system/ImagePreview";
import { IMAGE_VIEWER_SUPPORTED_EXTENSIONS } from "~/screens/App/components/FileViewerDialog/components/FileViewer/components/ImageViewer/ImageViewer.constants";
import type { IconProps } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";
import { FileIconLock } from "./components/FileIconLock";
import { FileIconSize } from "./FileIconConstants";

const filesExtensionsColors = new Map<string, IconProps["color"]>([
  /* cad */
  ["dwg", "info"],
  ["dxf", "info"],
  ["rvt", "info"],
  ["dwf", "info"],
  ["dgn", "info"],
  /* microsoft */
  ["doc", "info"],
  ["docx", "info"],
  ["xls", "brandAccent"],
  ["xlsx", "brandAccent"],
  ["ppt", "clovisRed"],
  ["pptx", "clovisRed"],
  ["pub", "clovisRed"],
  /* document */
  ["rtf", "info"],
  ["odt", "info"],
  ["ods", "brandAccent"],
  ["odp", "clovisRed"],
  ["wpf", "info"],
  /* pdf */
  ["pdf", "clovisRed"],
  ["fdf", "brandAccent"],
  ["xfdf", "brandAccent"],
  /* image */
  ["jpeg", "caution"], //not on pdftron list but working
  ["jpg", "caution"],
  ["png", "caution"],
  ["tiff", "caution"],
  ["tif", "caution"],
  ["bmp", "caution"],
  ["wmf", "caution"],
  ["emf", "caution"],
  ["gif", "caution"],
  ["hdp", "caution"],
  ["jp2", "caution"],
  ["jpc", "caution"],
  /* text */
  ["txt", "info"],
  /* other */
  ["ifc", "info"], //not on pdftron list to test
  /* video */
  ["mp4", "caution"],
  ["ogg", "caution"],
  ["webm", "caution"],
  ["mov", "caution"], //not on pdftron list to test
  ["ogv", "caution"], //not on pdftron list to test
  ["m4v", "caution"], //not on pdftron list to test
  /* web */
  ["html", "info"],
  ["htm", "info"],
  ["mht", "caution"],
  ["svg", "caution"],
  /* mail */
  ["msg", "info"],
]);

const FileIconWrapper = styled("div", {
  defaultVariants: {
    size: FileIconSize.XLARGE,
  },
  display: "inline-block",
  position: "relative",
  variants: {
    size: {
      large: { height: "2rem", width: "2rem" },
      medium: { height: "1.5rem", width: "1.5rem" },
      small: { height: "1.25rem", width: "1.25rem" },
      xlarge: { height: "2.5rem", width: "2.5rem" },
    },
  },
});

type FileIconProps = {
  format?: string;
  hasVersions?: boolean;
  locked?: boolean;
  size: Stitches.VariantProps<typeof FileIconWrapper>["size"];
  iconProps?: {
    css?: CSS;
  };
  children?: React.ReactNode;
  url?: string;
};

const FileIcon = (props: FileIconProps) => {
  const iconColor = props.format
    ? filesExtensionsColors.get(props.format) ?? "neutral"
    : "neutral";

  if (
    props.format &&
    IMAGE_VIEWER_SUPPORTED_EXTENSIONS.includes(props.format) &&
    props.url
  ) {
    return (
      <>
        <ImagePreview size="xsmall" src={props.url} />
        {props.locked && <FileIconLock size={props.size} color={iconColor} />}
        {props.children}
      </>
    );
  }
  return (
    <FileIconWrapper
      size={props.size}
      css={props.iconProps?.css}
      className="fileIcon"
    >
      <Icon label={`${props.format} file`} size={props.size} color={iconColor}>
        <DocumentTextIcon />
      </Icon>
      {props.locked && <FileIconLock size={props.size} color={iconColor} />}
      {props.children}
    </FileIconWrapper>
  );
};

FileIcon.defaultProps = {
  size: FileIconSize.XLARGE,
};

export type { FileIconProps };
export { FileIcon, FileIconSize };
