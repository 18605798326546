import { PencilIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "~/config/stitches";
import { Box } from "../../Box";
import { Icon } from "../../Icon";
import { Text } from "../../Text";
import type { ImagePreviewProps } from "../ImagePreview";

const EditButtonWrapper = styled("button", {
  "&:hover": {
    opacity: "0.8",
  },
  backgroundColor: "$black",
  borderRadius: "0 0.5rem 0 0.5rem",
  bottom: "0",
  color: "$white",
  left: "0",
  opacity: "0.6",
  padding: "$xxsmall",
  position: "absolute",
});

type EditButtonProps = Pick<ImagePreviewProps, "size"> & {
  onClick?: (event: React.MouseEvent) => unknown;
};

function EditButton(props: EditButtonProps) {
  const { t } = useTranslation();

  return (
    <EditButtonWrapper onClick={props.onClick} type="button">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Icon size="xxsmall" label="remove">
          <PencilIcon />
        </Icon>
        {props.size === "medium" || props.size === "large" ? (
          <Text size="xsmall">
            {t("DesignSystem.ImagePreview.annotate", "Annotate")}
          </Text>
        ) : null}
      </Box>
    </EditButtonWrapper>
  );
}

export { EditButton };
