import { OfficeBuildingIcon } from "@heroicons/react/outline";
import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type ProjectCategoryBadgeProps = Pick<
  BadgeProps,
  "children" | "clickable" | "color" | "onRemove" | "size"
> & {
  hideIcon?: boolean;
};

const ProjectCategoryBadge = (props: ProjectCategoryBadgeProps) => {
  const { children, ...rest } = props;

  return (
    <Badge
      leadingIcon={props.hideIcon ? null : <OfficeBuildingIcon />}
      maxWidth="full"
      {...rest}
    >
      <Text size="inherit" truncate>
        {children}
      </Text>
    </Badge>
  );
};

export { ProjectCategoryBadge };
