import * as React from "react";
import { Box } from "~/design-system/Box";
import { createComponentHook } from "~/types";
import { Infos } from "./Infos";
import { UpdateTeamForm } from "./UpdateTeamForm/UpdateTeamForm";
import type { TeamsList_ItemFragment } from "./UpdateTeamForm/UpdateTeamForm.graphql";

const useTeamsListItem = createComponentHook(() => {
  const [editMode, setEditMode] = React.useState(false);

  const enableForm = () => {
    setEditMode(true);
  };

  const disableForm = () => {
    setEditMode(false);
  };

  return {
    actions: {
      disableForm,
      enableForm,
    },
    state: {
      editMode,
    },
  };
});

type TeamsListItemProps = {
  team: TeamsList_ItemFragment;
};

function TeamsListItem(props: TeamsListItemProps) {
  const { actions, state } = useTeamsListItem();

  return (
    <Box marginBottom="small">
      {state.editMode ? (
        <UpdateTeamForm team={props.team} onSuccess={actions.disableForm} />
      ) : (
        <Infos team={props.team} onEditClick={actions.enableForm} />
      )}
    </Box>
  );
}

export { TeamsListItem };
