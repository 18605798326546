/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import * as React from "react";
import type { Column } from "react-table";
import type { CSS } from "~/config/stitches";
import type { SortType } from "../screens/Project/Docs/screens/ProjectDocumentsScreen/components/FilesFolders/components/FilesFoldersList/FilesSort.store";
import type { VirtualTableProps } from "./VirtualTable";
import { VirtualTable } from "./VirtualTable";

type VirtualListProps<D extends object> = {
  css?: CSS;
  data: VirtualTableProps<D>["data"];
  item: Column<D>;
  estimateSize?: VirtualTableProps<D>["estimateSize"];
  initialState?: VirtualTableProps<D>["initialState"];
  rowProps?: VirtualTableProps<D>["rowProps"];
  canSelectItems?: boolean;
  setSelectedItems?: (selectedItems: D[]) => void;
  noHeader?: boolean;
  setClearSelection?: (clearSelection: boolean) => void;
  clearSelection?: boolean;
  onSort?: (sortBy?: SortType) => void;
  rounded?: boolean;
  manualSort?: boolean;
};

const useVirtualList = <D extends object>(props: VirtualListProps<D>) => {
  const columns = React.useMemo<Array<Column<D>>>(
    () => [props.item],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    state: { columns },
  };
};

function VirtualList<D extends object>(props: VirtualListProps<D>) {
  const { state } = useVirtualList(props);

  return (
    <VirtualTable
      clearSelection={props.clearSelection}
      setClearSelection={props.setClearSelection}
      setSelectedItems={props.setSelectedItems}
      canSelectItems={props.canSelectItems}
      noHeader={props.noHeader}
      css={{
        ...props.css,
        borderRadius: props.rounded ? "$space$2" : "inherit",
      }}
      columns={state.columns}
      data={props.data}
      estimateSize={props.estimateSize}
      initialState={props.initialState}
      rowProps={props.rowProps}
      onSort={props.onSort}
      manualSort={props.manualSort}
    />
  );
}

VirtualList.defaultProps = {
  noHeader: true,
};

export type { VirtualListProps };
export { VirtualList };
