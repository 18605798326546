import tw from "twin.macro";
import { styled } from "~/config/stitches";

const CheckboxItems = styled("div", {
  ...tw``,

  variants: {
    variant: {
      list: tw`bg-white rounded-md -space-y-px`,
      simple: tw`pt-3 space-y-4`,
    },
  },
});

export { CheckboxItems };
