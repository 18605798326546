import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Text } from "../../Text/Text";

const NotificationWrapper = styled("span", {
  ...tw`block rounded-full`,

  compoundVariants: [
    {
      css: {
        "& > span": { fontSize: "0.3rem" },
      },
      numberNotification: true,
      size: "xxsmall",
    },
    {
      css: {
        "& > span": { fontSize: "0.5rem" },
      },
      numberNotification: true,
      size: "xsmall",
    },
    {
      css: {
        "& > span": { fontSize: "0.625rem" },
      },
      numberNotification: true,
      size: "small",
    },
    {
      css: {
        "& > span": { fontSize: "0.75rem" },
      },
      numberNotification: true,
      size: "medium",
    },
    {
      css: {
        "& > span": { fontSize: "0.875rem" },
      },
      numberNotification: true,
      size: "large",
    },
    {
      css: tw`absolute top-0 ring-2 ring-white`,
      shape: "circular",
      topNotification: true,
    },
    {
      css: tw`absolute top-0 ring-2 ring-white`,
      shape: "rounded",
      topNotification: true,
    },
    {
      bottomNotification: true,
      css: tw`absolute bottom-0 ring-2 ring-white`,
      shape: "circular",
    },
    {
      bottomNotification: true,
      css: tw`absolute bottom-0  ring-2 ring-white`,
      shape: "rounded",
    },
    {
      bottomNotification: true,
      css: {
        bottom: "-2px",
        right: "-3px",
      },
      notificationAlign: "right",
      shape: "rounded",
    },
    {
      bottomNotification: true,
      css: {
        bottom: "-3px",
        left: "-3px",
      },
      notificationAlign: "left",
      shape: "rounded",
    },
    {
      css: {
        left: "-3px",
        top: "-3px",
      },
      notificationAlign: "left",
      shape: "rounded",
      topNotification: true,
    },
    {
      css: {
        top: "-3px",
      },
      notificationAlign: "right",
      shape: "rounded",
      topNotification: true,
    },
    {
      css: {
        ...tw`ring-1`,
      },
      shape: "circular",
      size: "xxsmall",
    },
    {
      css: {
        right: "1px",
        ...tw`ring-1`,
      },
      notificationAlign: "right",
      shape: "circular",
      size: "xxsmall",
      topNotification: true,
    },
    {
      css: {
        left: "-1px",
        top: "-1px",
        ...tw`ring-1`,
      },
      notificationAlign: "left",
      shape: "rounded",
      size: "xxsmall",
      topNotification: true,
    },
    {
      css: {
        right: "0px",
        top: "-1px",
        ...tw`ring-1`,
      },
      notificationAlign: "right",
      shape: "rounded",
      size: "xxsmall",
      topNotification: true,
    },
    {
      bottomNotification: true,
      css: {
        bottom: "1px",
        left: "-1px",
        ...tw`ring-1`,
      },
      notificationAlign: "left",
      shape: "rounded",
      size: "xxsmall",
    },
    {
      bottomNotification: true,
      css: {
        bottom: "-1px",
        right: "1px",
        ...tw`ring-1`,
      },
      notificationAlign: "right",
      shape: "rounded",
      size: "xxsmall",
    },
    {
      bottomNotification: true,
      css: {
        ...tw`ring-1`,
        bottom: "-1px",
        right: "1px",
      },
      notificationAlign: "right",
      shape: "circular",
      size: "xxsmall",
    },
    {
      bottomNotification: true,
      css: {
        bottom: "2px",
        left: "-1px",
        ...tw`ring-1`,
      },
      notificationAlign: "left",
      shape: "circular",
      size: "xxsmall",
    },
  ],

  defaultVariants: {
    notificationAlign: "left",
    notificationTone: "neutral",
    shape: "circular",
    size: "medium",
  },

  variants: {
    bottomNotification: {
      true: tw``,
    },
    notificationAlign: {
      left: tw`left-0`,
      right: tw`right-0`,
    },
    notificationTone: {
      critical: tw`bg-critical-400`,
      neutral: tw`bg-neutral-300`,
      positive: tw`bg-positive-400`,
    },
    numberNotification: {
      false: tw``,
      true: tw`bg-white rounded-sm flex justify-center items-center`,
    },
    shape: {
      circular: tw``,
      rounded: tw``,
    },
    size: {
      large: tw`h-3 w-3`,
      medium: tw`h-2.5 w-2.5`,
      small: tw`h-2 w-2`,
      xlarge: tw`h-3.5 w-3.5`,
      xsmall: tw`h-1.5 w-1.5`,
      xxsmall: tw`h-1 w-1`,
    },
    topNotification: {
      true: tw``,
    },
  },
});

type NotificationProps = React.ComponentProps<typeof NotificationWrapper> & {
  children?: React.ReactNode;
};

function Notification(props: NotificationProps) {
  const { children, ...notificationProps } = props;
  return (
    <NotificationWrapper {...notificationProps}>
      <Text tone="neutral">{children}</Text>
    </NotificationWrapper>
  );
}

export { Notification };
