import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import * as React from "react";
import { useNavigate } from "react-router";
import { env } from "~/config/env";

/**
 * Will create a native app compatible url APP_ID://path/url
 * @param path patht to navigate to (like: /project/something)
 * @returns
 */
function buildNativeSchemeUrl(path: string) {
  return `${env.VITE_NATIVE_APP_ID}:/${path}`;
}

/**
 * Will take a native app url like APP_ID://path/url
 * and turn it into a /path/url compatible with our app router
 * @param href Native app scheme url
 * @returns
 */
function nativeSchemeUrlToRelative(href: string) {
  return href.replace(`${env.VITE_NATIVE_APP_ID}:/`, "");
}

const AppUrlListener: React.FC = (props) => {
  const navigate = useNavigate();
  const isNativePlatform = Capacitor.isNativePlatform();

  React.useEffect(() => {
    if (Capacitor.isPluginAvailable("App") && isNativePlatform) {
      void App.getLaunchUrl().then((launchUrl) => {
        if (launchUrl?.url && !launchUrl.url.includes("callback")) {
          if (launchUrl.url.includes(`${env.VITE_NATIVE_APP_ID}://`)) {
            const replacedAppScheme = nativeSchemeUrlToRelative(launchUrl.url);
            navigate(replacedAppScheme);
          } else {
            const url = new URL(launchUrl.url);
            navigate(url);
          }
        }
      });
      void App.addListener("appUrlOpen", (event) => {
        // doesn't have anything to do with our Auth0 login logic
        if (event?.url && !event.url.includes("callback")) {
          const url = new URL(event.url);
          navigate(url);
        }
      });
    }
  }, [isNativePlatform]);
  return <>{props.children}</>;
};

export { AppUrlListener, buildNativeSchemeUrl };
