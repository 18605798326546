/*
    This file defines all the files pdftron can support.
    It should be kept dependency free as it's shared between frontend and backend.
    Cf: https://www.notion.so/clovisteam/Supported-files-in-Clovis-afac5b1237054ee691ba6a06f6a7c3cc#867637be80dd416a81dc9c3b3258c65b
*/

const PDF_VIEWER_SUPPORTED_EXTENSIONS = [
  "pdf",
  "fdf",
  "msg",
  "html",
  // CAD documents
  "dgn",
  "dwg",
  "dwf",
  "dxf",
  /* not supported yet */
  //"rvt",
  //"xfdf",
  //"mht",
];

const OFFICE_VIEWER_BINARY_EXTENSIONS = [
  "doc",
  "ppt",
  "xls",
  "pub",
  /* not supported yet */
  // "wpf",
  // "wpd",
];

const OFFICE_VIEWER_SUPPORTED_EXTENSIONS = [
  ...OFFICE_VIEWER_BINARY_EXTENSIONS,
  "docx",
  "pptx",
  "xlsx",
  "rtf",
  "odt",
  "ods",
  "odp",
  "txt",
];

const IMAGE_VIEWER_SUPPORTED_EXTENSIONS = [
  "jpeg",
  "jpg",
  "png",
  "tiff",
  "tif",
  "bmp",
  "wmf",
  "emf",
  "gif",
  "jp2",
  "svg",
  /* not supported yet */
  // "hdp",
];

const PDFTRON_SUPPORTED_EXTENSIONS = [
  ...PDF_VIEWER_SUPPORTED_EXTENSIONS,
  ...OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
  ...IMAGE_VIEWER_SUPPORTED_EXTENSIONS,
];

export {
  IMAGE_VIEWER_SUPPORTED_EXTENSIONS,
  OFFICE_VIEWER_BINARY_EXTENSIONS,
  OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
  PDF_VIEWER_SUPPORTED_EXTENSIONS,
  PDFTRON_SUPPORTED_EXTENSIONS,
};
