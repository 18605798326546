import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CreateProjectDrawer } from "~/screens/App/components/CreateProjectDrawer/CreateProjectDrawer";
import { createComponentHook } from "~/types";

type NoProjectProps = { closeDrawer: () => void };

const useNoProject = createComponentHook((props: NoProjectProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCreateProjectSuccess = (projectId: string) => {
    props.closeDrawer();
    navigate(`/project/${projectId}`);
  };

  return {
    actions: {
      onCreateProjectSuccess,
    },
    t,
  };
});

const NoProject = (props: NoProjectProps) => {
  const { actions, t } = useNoProject(props);
  return (
    <Box
      css={{
        position: "absolute",
        top: "50%",
        width: "100%",
      }}
    >
      <Stack align="center" space="small">
        <Text tone="brandAccent" align="center">
          {t(
            "screens.NavigationDrawer.ProjectListMenu.NoProject",
            "You aren't participating in any project yet."
          )}
        </Text>
        <CreateProjectDrawer onSuccess={actions.onCreateProjectSuccess}>
          <DrawerTrigger asChild>
            <Button
              leadingIcon={<PlusSmIcon />}
              tone="brandAccent"
              dataIntercomTarget="navigation-drawer-project-list-create"
            >
              {t(
                "screens.NavigationDrawer.ProjectListMenu.CreateProject",
                "Create a project"
              )}
            </Button>
          </DrawerTrigger>
        </CreateProjectDrawer>
      </Stack>
    </Box>
  );
};

export { NoProject };
