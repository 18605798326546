/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ArrowLeftIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { PropsValue } from "react-select";
import tw from "twin.macro";
import type { CreatableSelect, Select } from "~/config/react-select";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { useBreakpoint } from "~/design-system/hooks";
import { Inline } from "~/design-system/Inline";
import { hasNotch, SafeArea } from "~/design-system/SafeArea";
import { createComponentHook } from "~/types";
import type {
  BasicMultiSelectFieldInputOption,
  SelectProps,
} from "../BasicMultiSelectFieldInput";
import { Option } from "./Option";

type SelectComponent = typeof CreatableSelect | typeof Select;

type CustomSelectProps = SelectProps<any> & {
  selectComponent: SelectComponent;
  onCreateOption?: (inputValue: string) => void;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  label: React.ReactNode;
  controlled?: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  css?: CSS;
};

const useCustomSelect = createComponentHook((props: CustomSelectProps) => {
  const { breakpoint } = useBreakpoint();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  /* internal value state needed when not always dealt in parent */
  const [customSelectValue, setCustomSelectValue] = React.useState<
    string | undefined
  >(props.defaultInputValue);
  const openState = props.controlled ?? { open, setOpen };

  // this useEffect is needed to show the selected value
  // in the Box component ( line 92 ), in mobile view.
  React.useEffect(() => {
    // I added this type assertion (as { label: string; value: string } ) to avoid modifying
    // the file BasicMultiSelectFieldInput.jsx
    // particularly the type definition of SelectProps.
    // Because that modification (over SelectProps) could affect BasicMultiSelectFieldInput ( a core component )
    const inputValue = props.value as
      | { label: string | null; value: string | null }
      | undefined;

    setCustomSelectValue(inputValue?.label ?? undefined);
  }, [props.value]);

  return {
    actions: {
      setCustomSelectValue,
      setOpen: openState.setOpen,
    },
    state: {
      breakpoint,
      customSelectValue,
      open: openState.open,
    },
    t,
  };
});

const MobileCustomSelect = React.forwardRef(function CustomSelect(
  props: CustomSelectProps,
  ref: React.Ref<any>
) {
  const { actions, state, t } = useCustomSelect(props);
  const SelectComponent = props.selectComponent;
  const value: PropsValue<BasicMultiSelectFieldInputOption<any>> | undefined =
    props.value;

  return (
    <>
      <Button
        variant="transparent"
        size="xsmall"
        onClick={(e) => {
          e.preventDefault();
          actions.setOpen(true);
        }}
        css={{
          ...tw`border-gray-300`,
          minHeight: "42px",
          width: "100%",
        }}
        dataIntercomTarget="basic-multi-select-field-input-open"
      >
        <Inline space="xsmall">
          <Box display="flex" gap="xsmall" alignItems="center" flexWrap="wrap">
            {value && Array.isArray(value) && value.length > 0
              ? (value as BasicMultiSelectFieldInputOption[]).map((value) => (
                  <Option key={value.label} data={value} />
                ))
              : state.customSelectValue && state.customSelectValue?.length > 0
              ? state.customSelectValue
              : null}
          </Box>
        </Inline>
      </Button>
      <Box
        style={{
          backgroundColor: "white",
          display: state.open ? "block" : "none",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 2,
          ...((props.css ?? {}) as React.CSSProperties),
        }}
      >
        <SafeArea top />
        <Box
          paddingX="small"
          paddingTop={hasNotch() ? undefined : "small"}
          display="flex"
          gap="xsmall"
          flexDirection="column"
          height="full"
        >
          <Columns>
            <Column>
              <Button
                circular
                variant="ghost"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  actions.setOpen(false);
                }}
                leadingIcon={<ArrowLeftIcon />}
                iconSize="medium"
                dataIntercomTarget="basic-multi-select-field-input-left"
              ></Button>
            </Column>
            <Column width="2-3">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="full"
              >
                {props.label}
              </Box>
            </Column>
            <Column width="content">
              <Button
                variant="ghost"
                tone="brandAccent"
                onClick={(e) => {
                  e.preventDefault();
                  actions.setOpen(false);
                }}
                css={{ border: "none" }}
                dataIntercomTarget="basic-multi-select-field-input-done"
              >
                {t("components.BasicMultiSelectFieldInput.done", "Done")}
              </Button>
            </Column>
          </Columns>
          <Box width="full" height="full">
            <SelectComponent
              {...props}
              ref={ref}
              onChange={(value, actionMeta) => {
                props.onChange?.(value, actionMeta);
                if (!props.isMulti) {
                  if (value) {
                    /* use select value as local state for cases where not setted in parent */
                    actions.setCustomSelectValue(
                      (value as any).label as string
                    );
                  }
                  /* close mobile select after selection if not multi select */
                  actions.setOpen(false);
                }
              }}
              onCreateOption={props.onCreateOption}
              formatCreateLabel={props.formatCreateLabel}
              menuIsOpen={true}
              menuPortalTarget={null}
              styles={{
                container: (base) => ({
                  ...base,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }),
                menu: (base) => ({
                  ...base,
                  flex: 1,
                  marginBottom: 0,
                  position: "initial",
                  transform: "translateX(-12px)",
                  width: "calc(100% + 24px)",
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }),
                valueContainer: (base) => ({
                  ...base,
                  gap: "4px",
                  maxHeight: "20vh",
                  paddingRight: "0",
                  scrollBehavior: "smooth",
                  scrollbarColor: "transparent",
                  scrollbarWidth: "none",
                }),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

export { MobileCustomSelect };
