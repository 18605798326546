import React from "react";
import type { IndicatorsContainerProps } from "react-select";
import { Box } from "~/design-system/Box";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";

export function IndicatorsContainer({
  children,
  innerProps,
}: IndicatorsContainerProps<BasicMultiSelectFieldInputOption<any>>) {
  return (
    <div {...innerProps}>
      <Box
        display="flex"
        flexDirection={"rowReverse"}
        alignItems={"center"}
        height={"full"}
      >
        {children}
      </Box>
    </div>
  );
}
