import type * as Types from '../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddUserApprovalToFileVersionMutationVariables = Types.Exact<{
  input: Types.AddUserApprovalToFileVersionInput;
}>;


export type AddUserApprovalToFileVersionMutation = { addUserApprovalToFileVersion?: { __typename: 'AddUserApprovalToFileVersionErrors' } | { __typename: 'AddUserApprovalToFileVersionSuccess', file_approval_id: string } | null | undefined };

export type PrepareFileApprovalsAttachmentForUploadMutationVariables = Types.Exact<{
  input: Types.PrepareFileApprovalsAttachmentForUploadInput;
}>;


export type PrepareFileApprovalsAttachmentForUploadMutation = { prepareFileApprovalsAttachmentForUpload?: { __typename: 'PrepareFileApprovalsAttachmentForUploadErrors' } | { __typename: 'PrepareFileApprovalsAttachmentForUploadSuccess', key: string, url: string } | null | undefined };

export type AddFileApprovalsFileVersionsMutationVariables = Types.Exact<{
  input: Types.AddFileApprovalsFileVersionsInput;
}>;


export type AddFileApprovalsFileVersionsMutation = { addFileApprovalsFileVersions?: { saved: boolean } | {} | null | undefined };

export type FinalizeUploadedFileApprovalsAttachmentMutationVariables = Types.Exact<{
  input: Types.FinalizeUploadedFileApprovalsAttachmentInput;
}>;


export type FinalizeUploadedFileApprovalsAttachmentMutation = { finalizeUploadedFileApprovalsAttachment?: { file_approval_attachment_id: string } | {} | null | undefined };

export type AddUserMultiApprovalToFilesVersionMutationVariables = Types.Exact<{
  input: Types.AddUserMultiApprovalToFilesVersionInput;
}>;


export type AddUserMultiApprovalToFilesVersionMutation = { addUserMultiApprovalToFilesVersion?: { __typename: 'AddMultiUserApprovalToFileVersionSuccess', file_ids: Array<{ file_approval_id: string, file_version_id: string }> } | { __typename: 'AddUserMultiApprovalToFilesVersionErrors' } | null | undefined };


export const AddUserApprovalToFileVersionDocument = gql`
    mutation addUserApprovalToFileVersion($input: AddUserApprovalToFileVersionInput!) {
  addUserApprovalToFileVersion(input: $input) {
    __typename
    ... on AddUserApprovalToFileVersionSuccess {
      file_approval_id
    }
  }
}
    `;

export function useAddUserApprovalToFileVersionMutation() {
  return Urql.useMutation<AddUserApprovalToFileVersionMutation, AddUserApprovalToFileVersionMutationVariables>(AddUserApprovalToFileVersionDocument);
};
export const PrepareFileApprovalsAttachmentForUploadDocument = gql`
    mutation PrepareFileApprovalsAttachmentForUpload($input: PrepareFileApprovalsAttachmentForUploadInput!) {
  prepareFileApprovalsAttachmentForUpload(input: $input) {
    __typename
    ... on PrepareFileApprovalsAttachmentForUploadSuccess {
      key
      url
    }
  }
}
    `;

export function usePrepareFileApprovalsAttachmentForUploadMutation() {
  return Urql.useMutation<PrepareFileApprovalsAttachmentForUploadMutation, PrepareFileApprovalsAttachmentForUploadMutationVariables>(PrepareFileApprovalsAttachmentForUploadDocument);
};
export const AddFileApprovalsFileVersionsDocument = gql`
    mutation AddFileApprovalsFileVersions($input: AddFileApprovalsFileVersionsInput!) {
  addFileApprovalsFileVersions(input: $input) {
    ... on AddFileApprovalsFileVersionsSuccess {
      saved
    }
  }
}
    `;

export function useAddFileApprovalsFileVersionsMutation() {
  return Urql.useMutation<AddFileApprovalsFileVersionsMutation, AddFileApprovalsFileVersionsMutationVariables>(AddFileApprovalsFileVersionsDocument);
};
export const FinalizeUploadedFileApprovalsAttachmentDocument = gql`
    mutation FinalizeUploadedFileApprovalsAttachment($input: FinalizeUploadedFileApprovalsAttachmentInput!) {
  finalizeUploadedFileApprovalsAttachment(input: $input) {
    ... on FinalizeUploadedFileApprovalsAttachmentSuccess {
      file_approval_attachment_id
    }
  }
}
    `;

export function useFinalizeUploadedFileApprovalsAttachmentMutation() {
  return Urql.useMutation<FinalizeUploadedFileApprovalsAttachmentMutation, FinalizeUploadedFileApprovalsAttachmentMutationVariables>(FinalizeUploadedFileApprovalsAttachmentDocument);
};
export const AddUserMultiApprovalToFilesVersionDocument = gql`
    mutation addUserMultiApprovalToFilesVersion($input: AddUserMultiApprovalToFilesVersionInput!) {
  addUserMultiApprovalToFilesVersion(input: $input) {
    __typename
    ... on AddMultiUserApprovalToFileVersionSuccess {
      file_ids {
        file_approval_id
        file_version_id
      }
    }
  }
}
    `;

export function useAddUserMultiApprovalToFilesVersionMutation() {
  return Urql.useMutation<AddUserMultiApprovalToFilesVersionMutation, AddUserMultiApprovalToFilesVersionMutationVariables>(AddUserMultiApprovalToFilesVersionDocument);
};