import * as React from "react";
import tw from "twin.macro";
import { css, styled } from "~/config/stitches";

type ConnexionBadgeProps = {
  active: boolean;
};

const BadgeWrapper = styled("span", {
  ...tw`ml-2.5 flex-shrink-0 inline-block h-2 w-2 rounded-full`,

  variants: {
    active: {
      false: tw`bg-neutral-300`,
      true: tw`bg-green-400`,
    },
  },
});

// TODO i18n
export function ConnexionBadge(props: ConnexionBadgeProps) {
  return (
    <BadgeWrapper active={props.active}>
      <span className={css({ ...tw`sr-only` })()}>
        {props.active ? "Online" : "Offline"}
      </span>
    </BadgeWrapper>
  );
}
