import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { LabelGroup } from "./components/LabelGroup";
import { ToggleInput } from "./components/ToggleInput";

type ToggleProps = {
  label?: React.ReactNode;
  on?: boolean;
  onChange?: (pressed: boolean) => void;
  align?: "left" | "right";
  width?: "auto" | "full";
  justify?: "between" | "inherit";
  offIcon?: React.ReactNode;
  onIcon?: React.ReactNode;
  description?: React.ReactNode;
};

const BaseToggle = (props: ToggleProps) => {
  const { align: toggleAlign = "right", justify, width } = props;
  return (
    <ToggleGroup
      css={{
        ...(toggleAlign === "left" ? tw`flex-row` : tw`flex-row-reverse`),
      }}
      width={width}
      justify={justify}
    >
      <ToggleInput
        on={props.on}
        onChange={props.onChange}
        offIcon={props.offIcon}
        onIcon={props.onIcon}
      />
      <LabelGroup
        toggleAlign={toggleAlign}
        label={props.label}
        description={props.description}
      />
    </ToggleGroup>
  );
};

//TODO use better adapt stitches selectors
const ToggleGroup = styled("div", {
  ...tw`
  flex items-center
  justify-between
  `,

  '& button[data-state="off"]': {
    "& .styledToggleSpan": {
      "& .offIcon": {
        display: "block",
      },
      "& .onIcon": {
        display: "none",
      },
    },
    "& ~ div span": {
      ...tw`text-neutral-500`,
      "&.description": tw`text-neutral-400`,
    },
  },

  '& button[data-state="on"]': {
    ...tw`bg-sky-500`,
    "& .styledToggleSpan": {
      "& .offIcon": {
        display: "none",
      },
      "& .onIcon": {
        display: "block",
      },
      transform: "translateX(1.25rem)",
    },
    "& ~ div span": {
      "&.description": tw`text-neutral-500`,
    },
  },

  defaultVariants: {
    justify: "between",
    width: "full",
  },

  variants: {
    justify: {
      between: tw`justify-between`,
      inherit: { justifyContent: "inherit" },
    },
    width: {
      auto: tw`w-auto`,
      full: tw`w-full`,
    },
  },
});

export type { ToggleProps };
export { BaseToggle as Toggle };
