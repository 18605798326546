import * as React from "react";
import { Icon } from "../../Icon";
import { StyledToggle } from "./StyledToggle";
import { StyledToggleSpan } from "./StyledToggleSpan";

export const ToggleInput = (props: {
  on?: boolean;
  onChange?: (pressed: boolean) => void;
  offIcon?: React.ReactNode;
  onIcon?: React.ReactNode;
}) => (
  <StyledToggle
    aria-label="toggle"
    pressed={props.on}
    onPressedChange={props.onChange}
  >
    <StyledToggleSpan aria-hidden="true" className="styledToggleSpan">
      {props.offIcon && (
        <Icon
          size="xsmall"
          color="neutral"
          className="offIcon"
          label="off icon"
        >
          {props.offIcon}
        </Icon>
      )}
      {props.onIcon && (
        <Icon size="xsmall" color="neutral" className="onIcon" label="on icon">
          {props.onIcon}
        </Icon>
      )}
    </StyledToggleSpan>
  </StyledToggle>
);
