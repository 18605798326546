import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import type { Maybe } from "~/schema.graphql";
import { UserBadgeButton } from "~/screens/App/components/Badges";
import { createComponentHook } from "~/types";
import type {
  OrgProfileContainerQuery,
  ProjectInfosQuery,
} from "../OrgProfileContainer.graphql";

type InfoItemProps = {
  title: string;
  description?: Maybe<string>;
  badges?: React.ReactNode[];
};

function InfoItem(props: InfoItemProps) {
  return (
    <Stack>
      <Text tone="neutral">{props.title}</Text>
      {props.description && <Text>{props.description}</Text>}
      {props.badges && (
        <Box paddingTop="xxsmall">
          <Inline space="small">{props.badges.map((badge) => badge)}</Inline>
        </Box>
      )}
    </Stack>
  );
}

type InfoListProps = {
  org: NonNullable<OrgProfileContainerQuery["orgs_by_pk"]>;
  orgMembersInProject?: NonNullable<
    ProjectInfosQuery["projects_by_pk"]
  >["members"];
  projectName?: NonNullable<ProjectInfosQuery["projects_by_pk"]>["name"];
  handleClose: () => void;
};

const useInfoList = createComponentHook((props: InfoListProps) => {
  const { t } = useTranslation();

  const infos: InfoItemProps[] = [];
  if (props.orgMembersInProject) {
    infos.push({
      badges: props.orgMembersInProject.map((u) => (
        <UserBadgeButton
          size="large"
          key={u.user.id}
          userId={u.user.id}
          avatar={u.user.avatar}
          onCloseDrawer={props.handleClose}
        >
          {u.user.full_name}
        </UserBadgeButton>
      )),
      title: t(
        "components.OrgProfileDrawer.InfoList.members",
        "Members of this org inside {{projectName}} project",
        { projectName: props.projectName }
      ),
    });
  }
  if (props.org.description) {
    infos.push({
      description: props.org.description,
      title: t(
        "components.OrgProfileDrawer.InfoList.description",
        "Description"
      ),
    });
  }
  if (props.org.phone) {
    infos.push({
      description: props.org.phone,
      title: t("components.OrgProfileDrawer.InfoList.phone", "Phone"),
    });
  }
  if (props.org.legal_number) {
    infos.push({
      description: props.org.legal_number,
      title: t(
        "components.OrgProfileDrawer.InfoList.legal_numer",
        "Legal Number"
      ),
    });
  }

  return {
    state: { infos },
  };
});

function InfoList(props: InfoListProps) {
  const { state } = useInfoList(props);

  return (
    <Stack space="small">
      {state.infos.map((info, index) => (
        <InfoItem
          key={index}
          title={info.title}
          description={info.description}
          badges={info.badges}
        />
      ))}
    </Stack>
  );
}

export { InfoList };
