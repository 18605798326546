import * as SentryBrowser from "@sentry/browser";
import * as SentryReact from "@sentry/react";
import { toPlainObject } from "lodash";
import { env } from "~/config/env";

// Will be used to store our original console.error && console.warn
let _error: typeof console.error;
let _warn: typeof console.warn;
// TODO: use SentryCapacitor once we find a better way to share
// sourcesmaps with sentry. Actually SentryCapacitor destroy our access to sources maps
// by prefixing the Url with app://
const sentrySettings = {
  dist: "1",

  dsn: env.VITE_SENTRY_DSN,
  integrations: [
    /* Many integrations are still enabled by default, see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/ */

    // USER REPLAY
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/replaycanvas/ */
    SentryBrowser.replayCanvasIntegration(),
    /* https://docs.sentry.io/platforms/javascript/guides/react/session-replay/ */
    SentryBrowser.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      blockAllMedia: false,
      /* Mask all inputs in recordings */
      maskAllInputs: false,
      /* Mask all text in recordings */
      maskAllText: false,
      /* If false, will create a new session per pageload. Otherwise, saves session */
      stickySession: true,
    }),

    // MORE DATA CAPTURE OF ERRORS
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole/ */
    SentryBrowser.captureConsoleIntegration({
      levels: ["error"],
    }),
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/sessiontiming/ */
    SentryBrowser.sessionTimingIntegration(),
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/debug/ */
    SentryBrowser.debugIntegration(),
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/extraerrordata/ */
    SentryBrowser.extraErrorDataIntegration(),

    // APP PERFORMANCE TRACKING
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/browserprofiling/ */
    SentryBrowser.browserProfilingIntegration(),
    /* https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/browsertracing/ */
    SentryBrowser.browserTracingIntegration(),
  ],

  // To set your release and dist versions
  release: `clovis-app-frontend@${env.VITE_RELEASE}`,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
};

const captureException: typeof SentryReact.captureException = (...props) => {
  // on development, log the it to the console
  console.info("Sentry exception captured: ", ...props);
  if (env.VITE_SENTRY_DSN && !env.VITE_IS_TEST) {
    return SentryReact.captureException(...props);
  }
  return "";
};
const captureMessage: typeof SentryReact.captureMessage = (...props) => {
  // on development, log the it to the console
  console.info("Sentry message captured: ", ...props);
  if (env.VITE_SENTRY_DSN && !env.VITE_IS_TEST) {
    return SentryReact.captureMessage(...props);
  }
  return "";
};
const captureEvent: typeof SentryReact.captureEvent = (...props) => {
  // on development, log the it to the console
  console.info("Sentry event captured: ", ...props);
  if (env.VITE_SENTRY_DSN && !env.VITE_IS_TEST) {
    return SentryReact.captureEvent(...props);
  }
  return "";
};

function initSentryReact() {
  if (env.VITE_SENTRY_DSN && env.VITE_SENTRY_ORG_SLUG && env.VITE_RELEASE) {
    console.info("🔎 Init Sentry");
    void SentryReact.init(sentrySettings);
  }

  // Here we override our console.error and console.warn
  // to gather all 3rd parties errors into sentry
  (function () {
    _error = console.error;
    _warn = console.warn;

    console.error = (message?: any, ...optionalParams: any[]): void => {
      captureException(message ?? "console.error", {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        extra: toPlainObject(optionalParams),
        level: "error",
      });
    };

    console.warn = (...data: any[]): void => {
      captureMessage("console.warn", {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        extra: toPlainObject(data),
        level: "warning",
      });
    };
  });

  //   window.onunhandledrejection = (event) => {
  //     captureException(event.reason, {
  //       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //       extra: toPlainObject(event),
  //       level: Severity.Error,
  //     });
  //   };

  //   window.onerror = (message, source, lineNumber, colno, error) => {
  //     captureException(error, {
  //       extra: {
  //         colno,
  //         lineNumber,
  //         message,
  //         source,
  //       },
  //       level: Severity.Error,
  //     });
  //   };
  // })();
}

export {
  captureEvent,
  captureException,
  captureMessage,
  initSentryReact,
  SentryReact,
};
