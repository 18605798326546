import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamsList_ItemFragment = { id: string, name: string, color: string };

export type UpdateTeamMutationVariables = Types.Exact<{
  input: Types.UpdateTeamInput;
}>;


export type UpdateTeamMutation = { updateTeam?: { __typename: 'UpdateTeamErrors' } | { __typename: 'UpdateTeamSuccess', team_id: string } | null | undefined };

export type DeleteTeamMutationVariables = Types.Exact<{
  input: Types.DeleteTeamInput;
}>;


export type DeleteTeamMutation = { deleteTeam?: { __typename: 'DeleteTeamErrors' } | { __typename: 'DeleteTeamSuccess', team_id: string } | null | undefined };

export const TeamsList_ItemFragmentDoc = gql`
    fragment TeamsList_item on teams {
  id
  name
  color
}
    `;
export const UpdateTeamDocument = gql`
    mutation updateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    __typename
    ... on UpdateTeamSuccess {
      team_id
    }
  }
}
    `;

export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
};
export const DeleteTeamDocument = gql`
    mutation deleteTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input) {
    __typename
    ... on DeleteTeamSuccess {
      team_id
    }
  }
}
    `;

export function useDeleteTeamMutation() {
  return Urql.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument);
};