import tw from "twin.macro";
import { styled } from "~/config/stitches";

const BaseBar = styled("div", {
  ...tw`
  h-10 
  w-full 
  bg-info-600
  rounded-3xl	
  absolute`,
});

export { BaseBar };
