/* eslint-disable react/prop-types */
import * as React from "react";
import { createComponentHook } from "~/types";
import { AroundInput, Input, LeadingIcon, TrailingIcon } from "../../../Field";
import { Tones } from "../../../tokens";
import { TextArea } from "./components/TextArea";

type TextFieldInputProps = {
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; // React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: number | string | readonly string[];
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  error?: string;
  placeholder?: string;
  tone?: Tones.critical | Tones.neutral | Tones.positive;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  maxLength?: number;
  minLength?: number;
  cols?: number;
  rows?: number;
  autoFocus?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
};

const useTextFieldInput = createComponentHook((props: TextFieldInputProps) => {
  const { type = "text" } = props;

  const tone = props.error ? Tones.critical : props.tone;

  const withTrailingIcon =
    props.trailingIcon || tone === Tones.critical ? true : false;

  const withLeadingIcon = props.leadingIcon !== undefined;

  return {
    state: {
      tone,
      type,
      withLeadingIcon,
      withTrailingIcon,
    },
  };
});

const TextFieldInput = React.forwardRef<HTMLInputElement, TextFieldInputProps>(
  function TextFieldInput(props, ref) {
    const { state } = useTextFieldInput(props);

    return (
      <AroundInput>
        <LeadingIcon leadingIcon={props.leadingIcon} />
        {props.multiline ? (
          <TextArea
            onPaste={props.onPaste}
            withTrailingIcon={state.withTrailingIcon}
            withLeadingIcon={state.withLeadingIcon}
            value={props.value}
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
            tone={state.tone}
            required={props.required}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            maxLength={props.maxLength}
            minLength={props.minLength}
            cols={props.cols}
            rows={props.rows}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            onKeyPress={props.onKeyPress}
            readOnly={props.readOnly}
          />
        ) : (
          <Input
            onPaste={props.onPaste}
            withTrailingIcon={state.withTrailingIcon}
            withLeadingIcon={state.withLeadingIcon}
            type={state.type}
            id={props.name}
            ref={ref}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            tone={state.tone}
            required={props.required}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            readOnly={props.readOnly}
          />
        )}
        <TrailingIcon trailingIcon={props.trailingIcon} tone={state.tone} />
      </AroundInput>
    );
  }
);

export type { TextFieldInputProps };
export { TextFieldInput };
