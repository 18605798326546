import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Card } from "~/design-system/Card";
import type { ControlledDialogProps } from "~/design-system/Dialog";
import { Dialog, DialogClose, DialogContent } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { useBreakpoint } from "~/design-system/hooks";
import { Stack } from "~/design-system/Stack";
import { CloseButton } from "~/screens/App/components/CloseButton";
import { FilesFoldersNavigator } from "~/screens/App/screens/Project/Docs/components/FilesFoldersNavigator/FilesFoldersNavigator";
import {
  FilesFoldersNavigatorProvider,
  useFileFoldersNavigatorContext,
} from "~/screens/App/screens/Project/Docs/components/FilesFoldersNavigator/FilesFoldersNavigatorContext";
import { createComponentHook } from "~/types";
import { useSelectFileDialog_DataQuery } from "./SelectFileDialog.graphql";

function DialogCloseButton() {
  return (
    <DialogClose asChild>
      <CloseButton dataIntercomTarget="tasks-select-file-close" />
    </DialogClose>
  );
}

function SelectFileDialogTopbar() {
  const { t } = useTranslation();

  return (
    <Box
      width="full"
      css={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Heading level="4">
        {t("screens.Projects.SelectFileDialog.dialogTitle", "Select a file")}
      </Heading>
      <DialogCloseButton />
    </Box>
  );
}

type SelectFileDialogProps = {
  children: React.ReactNode;
  projectId: string;
  onFileSelected?: (file: { fileId: string; fileVersionId: string }) => unknown;
  filesLocalizable?: boolean;
};

const useSelectFileDialogWrapped = createComponentHook(() => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const currentFolder = useFileFoldersNavigatorContext(
    (state) => state.folderId
  );

  return {
    state: {
      breakpoint,
      currentFolder,
      open,
    },
    t,
  };
});

function SelectFileDialogWrapped(
  props: ControlledDialogProps & SelectFileDialogProps
) {
  const { state } = useSelectFileDialogWrapped();

  if (state.breakpoint === "mobile") {
    return (
      <Dialog open={props.open} setOpen={props.setOpen}>
        {props.children}
        <DialogContent size="full">
          <Box
            background="card"
            width="full"
            css={{
              height: "100vh",
              padding: "$gutter",
            }}
          >
            <Stack space={"gutter"}>
              <Box
                display="flex"
                justifyContent="spaceBetween"
                alignItems="center"
              >
                <SelectFileDialogTopbar />
              </Box>
              <FilesFoldersNavigator
                // The maxheight of our list gonnna be the size of our popup
                // minus the (topbar + spacing) ($space$gutter * 2)
                // minus the top and bottom padding inside the "card": ($space$gutter * 2)
                height={
                  "calc(100vh - (($space$gutter * 2) + ($space$gutter * 2)))"
                }
                filesLocalizable={props.filesLocalizable}
              />
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={props.open} setOpen={props.setOpen}>
      {props.children}
      <DialogContent
        css={{ maxWidth: "$lg" }}
        size="regular"
        stopClickPropagation
      >
        <Card padding="gutter">
          <Box css={{ height: "80vh" }}>
            <Stack space={"gutter"}>
              <Box
                display="flex"
                justifyContent="spaceBetween"
                alignItems="center"
              >
                <SelectFileDialogTopbar />
              </Box>
              <FilesFoldersNavigator
                // The maxheight of our list gonnna be the size of our popup
                // minus the (topbar + spacing) ($space$gutter * 2)
                // minus the top and bottom padding inside the "card": ($space$gutter * 2)
                height={
                  "calc(80vh - (($space$gutter * 2) + ($space$gutter * 2)))"
                }
                filesLocalizable={props.filesLocalizable}
              />
            </Stack>
          </Box>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
const useSelectFileDialog = createComponentHook(
  (props: SelectFileDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const [{ data }] = useSelectFileDialog_DataQuery({
      variables: { project_id: props.projectId },
    });
    return {
      actions: {
        setOpen,
      },
      state: {
        initialFolder: data?.projects_by_pk?.root_folder_id,
        open,
      },
    };
  }
);

function SelectFileDialog(props: SelectFileDialogProps) {
  const { actions, state } = useSelectFileDialog(props);
  if (state.initialFolder) {
    return (
      <FilesFoldersNavigatorProvider
        initialFolderId={state.initialFolder}
        projectId={props.projectId}
        onFileSelect={(file) => {
          props.onFileSelected?.(file);
          actions.setOpen(false);
        }}
      >
        <SelectFileDialogWrapped
          {...props}
          open={state.open}
          setOpen={actions.setOpen}
        />
      </FilesFoldersNavigatorProvider>
    );
  }
  return null;
}

export { SelectFileDialog };
