import { DotsVerticalIcon } from "@heroicons/react/solid";
import * as React from "react";
import tw from "twin.macro";
import { css } from "~/config/stitches";
import { Icon } from "../../Icon";
import { DropdownMenuButton } from "./DropdownMenuButton";

export function MinimalTrigger(props: { label: string }) {
  return (
    <DropdownMenuButton variant="minimal">
      <span className={css({ ...tw`sr-only` })()}>{props.label}</span>
      <Icon size="small" label="dropdown trigger icon">
        <DotsVerticalIcon aria-hidden="true" />
      </Icon>
    </DropdownMenuButton>
  );
}
