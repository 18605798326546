import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

type NotificationBadgeProps = {
  children?: React.ReactNode;
  variant: "strong" | "weak";
  css?: CSS;
};

const NotificationBadgeWrapper = styled("div", {
  "&": {
    alignItems: "center",
    borderRadius: "5rem",
    color: "white",
    display: "flex",
    fontSize: "0.75rem",
    fontWeight: "bold",
    height: "1.15rem",
    justifyContent: "center",
    minWidth: "1.15rem",
    padding: "0 0.3rem",
    width: "initial",
    ...tw`ring-1.5 ring-white`,
  },

  variants: {
    variant: {
      strong: tw`bg-critical-500`,
      weak: tw`bg-info-500`,
    },
  },
});

const EmptyNotificationBadge = styled("div", {
  "&": {
    borderRadius: "5rem",
    height: "0.6rem",
    width: "0.6rem",
    ...tw`ring-1 ring-white`,
  },
  variants: {
    variant: {
      strong: tw`bg-critical-500`,
      weak: tw`bg-info-500`,
    },
  },
});

export function NotificationBadge(props: NotificationBadgeProps) {
  if (props.children) {
    return (
      <NotificationBadgeWrapper
        title={`notification badge ${props.variant}`}
        css={props.css}
        variant={props.variant}
      >
        {props.children}
      </NotificationBadgeWrapper>
    );
  }
  return (
    <EmptyNotificationBadge
      title={`notification badge ${props.variant}`}
      variant={props.variant}
    />
  );
}
