import type * as Types from '../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SelectFileDialog_DataQueryVariables = Types.Exact<{
  project_id: Types.Scalars['uuid'];
}>;


export type SelectFileDialog_DataQuery = { projects_by_pk?: { root_folder_id?: string | null | undefined } | null | undefined };


export const SelectFileDialog_DataDocument = gql`
    query SelectFileDialog_data($project_id: uuid!) {
  projects_by_pk(id: $project_id) {
    root_folder_id
  }
}
    `;

export function useSelectFileDialog_DataQuery(options: Omit<Urql.UseQueryArgs<SelectFileDialog_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SelectFileDialog_DataQuery>({ query: SelectFileDialog_DataDocument, ...options });
};