import React from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "~/design-system/hooks";
import { SafeArea } from "~/design-system/SafeArea";
import type { LanguageType } from "~/screens/App/screens/AnonRightsScreen/components/LanguageDropdown/LanguageDropdown";
import { createComponentHook } from "~/types";
import { PermissionTableDesktop } from "./components/PermissionTableDesktop/PermissionTableDesktop";
import { PermissionTableMobile } from "./components/PermissionTableMobile/PermissionTableMobile";

type ProjectPermissionTableProps = {
  language: LanguageType;
};

const useProjectPermissionTable = createComponentHook(() => {
  const { breakpoint } = useBreakpoint();
  const { t } = useTranslation();

  return {
    state: { breakpoint },
    t,
  };
});

function PermissionTable(props: ProjectPermissionTableProps) {
  const { state, t } = useProjectPermissionTable();

  if (state.breakpoint == "mobile" || state.breakpoint == "tablet") {
    return (
      <>
        <PermissionTableMobile
          language={props.language}
          title={t(
            "screens.Rights.components.PermissionTable.clovisOrgRights",
            "Clovis Org Rights"
          )}
          type="org"
        />
        <PermissionTableMobile
          language={props.language}
          title={t(
            "screens.Rights.components.PermissionTable.clovisProjectRights",
            "Clovis Project Rights"
          )}
          type="project"
          css={{ marginTop: "40px" }}
        />
      </>
    );
  } else {
    return (
      <SafeArea>
        <PermissionTableDesktop
          language={props.language}
          title={t(
            "screens.Rights.components.PermissionTable.clovisOrgRights",
            "Clovis Org Rights"
          )}
          type="org"
        />
        <PermissionTableDesktop
          language={props.language}
          title={t(
            "screens.Rights.components.PermissionTable.clovisProjectRights",
            "Clovis Project Rights"
          )}
          css={{ marginTop: "40px" }}
          type="project"
        />
      </SafeArea>
    );
  }
}

export { PermissionTable };
