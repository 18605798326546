import { TagIcon } from "@heroicons/react/outline";
import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type ProjectLabelBadgeProps = Pick<
  BadgeProps,
  "children" | "color" | "onRemove" | "shadow" | "size"
>;

const ProjectLabelBadge = (props: ProjectLabelBadgeProps) => {
  const { children, ...rest } = props;

  return (
    <Badge
      leadingIcon={<TagIcon />}
      shape="rightRounded"
      maxWidth="full"
      {...rest}
    >
      <Text size="inherit" truncate>
        {children}
      </Text>
    </Badge>
  );
};

export { ProjectLabelBadge };
