import type { ResultSuccess } from "@clovis/server/src/app/webhooks/authinfos/types";
import * as React from "react";
import { useNavigate } from "react-router";
import { useAuth0 } from "~/config/auth0/use-auth0";
import { captureException } from "~/config/sentry";
import { FullPageSpinner } from "./components/FullPageSpinner/FullPageSpinner";
import { useRedirectAfterLogin } from "./utils/useRedirectAfterLogin/useRedirectAfterLogin";

async function fetchAuthInfos(secretKey: string) {
  try {
    const response = await fetch(`/api/rest/webhooks/auth/authinfos`, {
      headers: { atk: secretKey },
      method: "GET",
    });
    if (response.ok && response.status === 200) {
      const result = (await response.json()) as ResultSuccess;
      return result;
    }
  } catch (e) {
    captureException(e);
  }
  return null;
}

const AuthenticatedLinkRedirect = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  const { setAfterLoginRedirectPath } = useRedirectAfterLogin();

  React.useEffect(() => {
    if (!isLoading) {
      try {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        // atk stand for AuthToken
        const atk = searchParams.get("atk");
        // if the user is already authenticated, just redirect him to the proper page
        if (isAuthenticated && redirect) {
          navigate(redirect, { replace: true });
          return;
        }
        if (!isAuthenticated && atk) {
          // if the user is not authenticated, and an authtoken is provided, retrieve user infos from our public endpoint
          // to know if we need to redirect him to the signup or login workflow and save the redirect after login parameter
          if (redirect) {
            setAfterLoginRedirectPath(redirect);
          }
          void fetchAuthInfos(atk).then((value) => {
            if (value) {
              // If the user has already register, send him to the login page with his email prefilled
              if (value.type === "login") {
                const searchParams = new URLSearchParams({
                  login_hint: value.email,
                  screen_hint: "login",
                });
                navigate(`/callback?${searchParams.toString()}`, {
                  replace: true,
                });
                return;
              } else {
                const searchParams = new URLSearchParams({
                  first_name: value.first_name ?? "",
                  last_name: value.last_name ?? "",
                  login_hint: value.email,
                  phone: value.phone ?? "",
                  screen_hint: "signup",
                });
                navigate(`/callback?${searchParams.toString()}`, {
                  replace: true,
                });
                return;
              }
            } else {
              // infos are null for some reason (invalid atk, connection error) fallback to the /
              navigate("/callback", { replace: true });
              return;
            }
          });
        }
      } catch (e) {
        console.error("CLOVIS Login error:", e);
        captureException(e);
        navigate("/callback", { replace: true });
      }
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return <FullPageSpinner />;
  }
  return null;
};

export { AuthenticatedLinkRedirect };
