/* Cf https://github.com/joelvh/react-safe-area-component */
/* WARNING Safe area components can create non scrollable elements on desktop,
  do,n't hesitate to apply height = 100% on it to fill well the container height and don't overflow it */
import React from "react";

/* Add typing to the global typescript window object */
declare global {
  interface Window {
    // MSStream interface has been removed from typescript to be isolated later on
    // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1029
    MSStream: any;
  }
  interface Screen {
    mozOrientation: any;
  }
}

function hasNotch(): boolean {
  let proceed = false;
  const div = document.createElement("div");
  if (CSS.supports("padding-bottom: env(safe-area-inset-bottom)")) {
    div.style.paddingBottom = "env(safe-area-inset-bottom)";
    proceed = true;
  } else if (CSS.supports("padding-bottom: constant(safe-area-inset-bottom)")) {
    div.style.paddingBottom = "constant(safe-area-inset-bottom)";
    proceed = true;
  }
  if (proceed) {
    document.body.appendChild(div);
    const calculatedPadding = parseInt(
      window.getComputedStyle(div).paddingBottom
    );
    document.body.removeChild(div);
    if (calculatedPadding > 0) {
      return true;
    }
  }
  return false;
}

const getSafeAreaInsetValue = () => {
  const styles = getComputedStyle(document.documentElement);
  const get = (key: string) =>
    parseFloat(styles.getPropertyValue(`--safe-area-inset-${key}`).trim());
  return {
    bottom: get("bottom"),
    left: get("left"),
    right: get("right"),
    top: get("top"),
  };
};

// Detect iOS
function iOS() {
  const basiciOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  // iPad on iOS 13 detection
  const onIpadiOS13 =
    navigator.userAgent.includes("Mac") && "ontouchend" in document;
  const oniOS = basiciOS || onIpadiOS13;
  // The !window.MSStream is to not incorrectly detect IE11
  return !window.MSStream && oniOS;
}

// Detect Chrome on iOS
function isCriOS() {
  return iOS() && /CriOS/.test(navigator.userAgent);
}

// Detect iPhone X notch position
function notchPosition() {
  let { orientation } = window;
  const { screen } = window;
  let position;

  if (orientation) {
    position = {
      "-90": "right",
      "90": "left",
    }[orientation];
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    orientation =
      "orientation" in screen
        ? screen.orientation.type
        : "mozOrientation" in screen
        ? screen.mozOrientation
        : undefined;

    position = {
      "landscape-primary": "left",
      "landscape-secondary": "right",
    }[orientation];
  }
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return position || "none";
}

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

const SIDES = ["left", "right", "top", "bottom"] as const;
type Sides = (typeof SIDES)[number];

type SafeAreaProps = {
  /* Can be a div, a h1, h2 etc... */
  component?: string;
  children?: JSX.Element | React.ReactNode | string;
  applyStyles?: () => React.HTMLAttributes<Element>;
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
  css?: React.CSSProperties;
};

function defaultApplyStyles({
  props: { css, ...props },
  sides,
  styles,
}: {
  props: { css?: React.CSSProperties };
  sides: Sides[];
  styles: React.CSSProperties;
}) {
  /* handle safe-area-inset for storybook, see ~/client/.storybook/preview-body.html */
  const classNames = sides.map((side) => `storybook-safeAreaInset-${side}`);
  return {
    ...props,
    className: classNames.join(" "),
    style: {
      ...css,
      ...styles,
    },
  };
}

const SafeArea = (props: SafeAreaProps) => {
  const {
    applyStyles = defaultApplyStyles,
    bottom = false,
    children,
    component = "div",
    left = false,
    right = false,
    top = false,
    ...rest
  } = props;
  let componentProps = rest;

  const sides = SIDES.filter(
    (side) =>
      props[side] &&
      (side === "bottom" || side === "top" || side === notchPosition())
  );
  // @ts-expect-error HTMLAttributes<Element> expecting style rather than css prop (which we don't pass)
  componentProps = applyStyles({
    props: rest,
    sides,
    styles: sides.reduce((styles: React.CSSProperties, side) => {
      /* Handle env for safe-area-inset */
      styles[
        `padding${capitalize(side) as Capitalize<Sides>}`
      ] = `env(safe-area-inset-${side})`;
      return styles;
    }, {}),
  });

  return React.createElement(component, componentProps, children);
};

export {
  getSafeAreaInsetValue,
  hasNotch,
  iOS,
  isCriOS,
  notchPosition,
  SafeArea,
};
export type { SafeAreaProps };
