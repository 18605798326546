/* eslint-disable react/prop-types */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../Box";
import { Text } from "../../../Text";
import type { Tones } from "../../../tokens";
import { CornerHint } from "./components/CornerHint";
import { Label } from "./components/Label";

type FieldLabelProps = {
  name: string;
  error?: string;
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  cornerHint?: React.ReactNode;
  tone?: Tones.critical | Tones.neutral | Tones.positive;
  required?: boolean;
  dataIntercomTarget?: string;
};

function useFieldLabel(props: FieldLabelProps) {
  const { t } = useTranslation();

  const secondaryLabel = props.required
    ? undefined
    : t("DesignSystem.TextField.optional", "optional");

  return {
    state: {
      secondaryLabel,
    },
    t,
  };
}

function FieldLabel(props: FieldLabelProps) {
  const { state } = useFieldLabel(props);

  return (
    <Box width="full">
      <Label
        htmlFor={props.name}
        data-intercom-target={props.dataIntercomTarget}
      >
        {props.label && (
          <Text
            size="small"
            weight="medium"
            css={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.label}
            {state.secondaryLabel && (
              <Text size="small" tone="secondary">
                &nbsp;({state.secondaryLabel})
              </Text>
            )}
          </Text>
        )}
        <CornerHint cornerHint={props.cornerHint} />
      </Label>
    </Box>
  );
}

export type { FieldLabelProps };
export { FieldLabel };
