import { OrgRolesHierarchyMap } from "@clovis/server/src/app/domain/org/role";
import { OrgPermission } from "@clovis/server/src/app/domain/utils/permissions";
import type { TFunction } from "i18next";
import { Role } from "../../screens/App/screens/Org/Org.authorize";
import type { UserPermissionsOptions } from "./utils";

function getOrgPermissionsOptions(t: TFunction): UserPermissionsOptions {
  return [
    {
      name: t(
        "screens.permissions.description.CreateOrgProject",
        "Create organization project"
      ),
      permission: OrgPermission.CreateOrgProject,
    },
    {
      name: t(
        "screens.permissions.description.CreateProjectCategory",
        "Create project category"
      ),
      permission: OrgPermission.CreateProjectCategory,
    },
    {
      name: t(
        "screens.permissions.description.GenerateOrgApiToken",
        "Generate org api token"
      ),
      permission: OrgPermission.GenerateOrgApiToken,
    },
    {
      name: t(
        "screens.permissions.description.JoinOtherOrgMemberProject",
        "Join other organization member to project"
      ),
      permission: OrgPermission.JoinOtherOrgMemberProject,
    },
    {
      name: t(
        "screens.permissions.description.OrgInvitations",
        "Organization invitations"
      ),
      permission: OrgPermission.OrgInvitations,
    },
    {
      name: t(
        "screens.permissions.description.PrepareOrgAssetForUpload",
        "Prepare organization asset for upload"
      ),
      permission: OrgPermission.PrepareOrgAssetForUpload,
    },
    {
      name: t(
        "screens.permissions.description.PrepareProjectSpreadsheetForUpload",
        "Prepare project spreadsheet for upload"
      ),
      permission: OrgPermission.PrepareProjectSpreadsheetForUpload,
    },
    {
      name: t(
        "screens.permissions.description.RemoveFromOrganization",
        "Remove from organization"
      ),
      permission: OrgPermission.RemoveFromOrganization,
    },
    {
      name: t(
        "screens.permissions.description.UpdateOrgMember",
        "Edit organization member"
      ),
      permission: OrgPermission.UpdateOrgMember,
    },
    {
      name: t(
        "screens.permissions.description.UpdateOrgProfile",
        "Edit organization profile"
      ),
      permission: OrgPermission.UpdateOrgProfile,
    },
    {
      name: t(
        "screens.permissions.description.UpdateOrgRole",
        "Edit organization role"
      ),
      permission: OrgPermission.UpdateOrgRole,
    },

    {
      name: t(
        "screens.permissions.description.UpdateProjectCategory",
        "Edit project category"
      ),
      permission: OrgPermission.UpdateProjectCategory,
    },
    {
      name: t(
        "screens.permissions.description.DeleteProjectCategory",
        "Delete project category"
      ),
      permission: OrgPermission.DeleteProjectCategory,
    },
    {
      name: t(
        "screens.permissions.description.UpdateOrgUserInfos",
        "Edit organization user infos"
      ),
      permission: OrgPermission.UpdateOrgUserInfos,
    },
  ];
}

type UserRolesOptions = {
  name: string;
  value: Role;
}[];

function getUserOrgRolesOptions(t: TFunction): UserRolesOptions {
  return [
    { name: t("enums.orgRoles.owner", "Owner"), value: Role.Owner },
    { name: t("enums.orgRoles.admin", "Admin"), value: Role.Administrator },
    { name: t("enums.orgRoles.standard", "Standard"), value: Role.Standard },
    { name: t("enums.orgRoles.limited", "Limited"), value: Role.Limited },
    { name: t("enums.orgRoles.readonly", "Readonly"), value: Role.Readonly },
    { name: t("enums.orgRoles.disabled", "Disabled"), value: Role.Disabled },
  ];
}

function getI18nOrgRole(role: Role, t: TFunction) {
  return getUserOrgRolesOptions(t).find((option) => option.value === role)
    ?.name;
}

// Check if the current user is allowed to update a given role
const isAllowedToUpdateRole = (
  currentUserRole: Role,
  roleToChange: Role
): boolean => {
  const rolesAllowedToUpdate = OrgRolesHierarchyMap[currentUserRole];

  // Check the role hierarchy
  return rolesAllowedToUpdate.includes(roleToChange);
};

// Check if the current user is allowed to update a given member
const isAllowedToUpdateMember = (
  currentUserRole: Role,
  roleToChange: Role
): boolean => {
  if (isAllowedToUpdateRole(currentUserRole, roleToChange)) {
    // Owner & Admin user can update member below their role
    if (
      [Role.Owner, Role.Administrator].includes(currentUserRole) &&
      currentUserRole !== roleToChange
    ) {
      return true;
    }
  }

  return false;
};

export {
  getI18nOrgRole,
  getOrgPermissionsOptions,
  getUserOrgRolesOptions,
  isAllowedToUpdateMember,
  isAllowedToUpdateRole,
};
export type { UserRolesOptions };
