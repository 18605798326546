import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import { isProjectNotification } from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterProjectBadge(
  notification: UserBadgeNotification,
  projectId: string
) {
  return (
    isProjectNotification(notification) &&
    notification.payload.project.id === projectId
  );
}

const useProjectBadge = createComponentHook((props: ProjectBadgeProps) => {
  const notifications = useUserNotificationsContext((state) =>
    state.notifications.filter((n) => filterProjectBadge(n, props.projectId))
  );
  const { strongNotificationsCount, weakNotif } =
    getDisplayNotificationProps(notifications);

  return {
    state: {
      strongNotificationsCount,
      weakNotif,
    },
  };
});

type ProjectBadgeProps = { projectId: string };

const ProjectBadge = (props: ProjectBadgeProps) => {
  const { state } = useProjectBadge(props);

  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { filterProjectBadge, ProjectBadge, useProjectBadge };
