import { z } from "zod";

const optionsSchema = z
  .object({
    invitationMemberByEmail: z.boolean().default(false),
    labels: z.boolean().default(false),
    members: z.boolean().default(false),
    publicDocuments: z.boolean().default(false),
    restrictedDocuments: z.boolean().default(false),
    tasks: z.boolean().default(false),
    tasksAttachments: z.boolean().default(false),
    tasksComments: z.boolean().default(false),
    teams: z.boolean().default(false),
  })
  .transform((val) => {
    // if members is "false" then restrictedDocuments too
    if (!val.members) {
      val.restrictedDocuments = false;
    }

    // if task is "false" then tasksComments too
    if (!val.tasks) {
      val.tasksComments = false;
    }

    return val;
  });

export const validationSchema = z.object({
  isDemoProject: z.boolean().optional(),
  name: z.string().nonempty().nullable().optional(),
  options: optionsSchema,
  projectId: z.string().uuid(),
});
