import { z } from "zod";

export const validationSchema = z.object({
  companyData: z
    .object({
      city: z.string().nullable().optional(),
      country_code: z.string().nullable().optional(),
      creation_date: z.string().nullable().optional(),
      lat: z.number().nullable().optional(),
      legal_number: z.string().nullable().optional(),
      lng: z.number().nullable().optional(),
      name: z.string().transform((v) => v.toUpperCase()),
      postal_code: z.string().nullable().optional(),
      size_max: z.number().nullable().optional(),
      size_min: z.number().nullable().optional(),
      standard_industrial_classification: z.string().nullable().optional(),
    })
    .optional()
    .nullable(),
  projectId: z.string().uuid(),
  userIds: z.array(z.string().uuid()),
});
