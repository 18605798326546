import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import * as React from "react";
import { styled } from "~/config/stitches";
import { createComponentHook } from "~/types";
import { BottomArrow } from "./BottomArrow";
import { TopArrow } from "./TopArrow";

const DropdownMenuContentStyles = {
  "&": {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "$md",
    marginTop: "$xxsmall",
    minWidth: "$36",
    zIndex: "4",
  },

  "&:focus": {
    outline: "none",
  },
};

const BasicDropdownMenuContent = styled(RadixDropdownMenu.Content, {
  ...DropdownMenuContentStyles,
});

type DropdownMenuContentProps = React.ComponentProps<
  typeof BasicDropdownMenuContent
> & {
  /* required only from DropdownMenu component, since passing the props itself */
  dropdownOpen?: boolean;
};

const useDropdownMenuContent = createComponentHook(
  (props: DropdownMenuContentProps) => {
    /* use ref to retrieve dropdownContent clientHeight, scrollHeight and scrollTop */
    const dropdownContentRef = React.useRef<HTMLDivElement>(null);
    /* from DropdownMenu component */
    const { dropdownOpen } = props;

    const [dropdownContentClientHeight, setDropdownContentClientHeight] =
      React.useState<number>();
    const [dropdownContentScrollHeight, setDropdownContentScrollHeight] =
      React.useState<number>();
    const [dropdownContentScrollTop, setDropdownContentScrollTop] =
      React.useState<number>(0);
    const [displayBottomArrow, setDisplayBottomArrow] = React.useState(false);
    const [displayTopArrow, setDisplayTopArrow] = React.useState(false);

    React.useEffect(() => {
      /* if dropdown open, set dropdownContent heights based on updated ref (updated because of dropdownOpen update) */
      if (dropdownOpen) {
        setDropdownContentClientHeight(
          dropdownContentRef.current?.clientHeight
        );
        setDropdownContentScrollHeight(
          dropdownContentRef.current?.scrollHeight
        );
      }
    }, [dropdownOpen]);

    const onScroll = () => {
      /* onScroll in dropdownContent, update scrollTop value */
      if (dropdownContentRef.current) {
        setDropdownContentScrollTop(dropdownContentRef.current.scrollTop);
      }
    };

    /* dropdownContentClientHeight, dropdownContentScrollHeight and dropdownContentScrollTop are used to display or not top/bottom arrows */
    React.useEffect(() => {
      /* display bottom arrow only if scroll is needed & if not at bottom */
      if (
        dropdownContentClientHeight &&
        dropdownContentScrollHeight &&
        dropdownContentClientHeight < dropdownContentScrollHeight &&
        dropdownContentScrollTop <
          dropdownContentScrollHeight - dropdownContentClientHeight - 1
      ) {
        setDisplayBottomArrow(true);
      } else {
        setDisplayBottomArrow(false);
      }

      /* display top arrow only if scroll is needed & if not at top */
      if (
        dropdownContentClientHeight &&
        dropdownContentScrollHeight &&
        dropdownContentClientHeight < dropdownContentScrollHeight &&
        dropdownContentScrollTop !== 0
      ) {
        setDisplayTopArrow(true);
      } else {
        setDisplayTopArrow(false);
      }
    }, [
      dropdownContentClientHeight,
      dropdownContentScrollHeight,
      dropdownContentScrollTop,
    ]);

    /* in order to trigger top or bottom scroll, need anchor to which scroll */
    const topRef = React.useRef<HTMLDivElement>(null);
    const bottomRef = React.useRef<HTMLDivElement>(null);

    return {
      actions: {
        onScroll,
      },
      state: {
        bottomRef,
        displayBottomArrow,
        displayTopArrow,
        dropdownContentRef,
        topRef,
      },
    };
  }
);

function DropdownMenuContent(props: DropdownMenuContentProps) {
  const { actions, state } = useDropdownMenuContent(props);
  const { children, ...dropdownMenuContentProps } = props;

  return (
    <BasicDropdownMenuContent
      {...dropdownMenuContentProps}
      onScroll={actions.onScroll}
      ref={state.dropdownContentRef}
      css={{
        //The menu should not be higher than half of the height of the screen
        maxHeight: "calc(50vh - $space$gutter)",
        overflowY: "auto",
      }}
    >
      {state.displayTopArrow && (
        <TopArrow
          onClick={() =>
            state.topRef.current?.scrollIntoView({ behavior: "smooth" })
          }
        />
      )}
      <div ref={state.topRef} />
      {children}
      <div ref={state.bottomRef} />
      {state.displayBottomArrow && (
        <BottomArrow
          onClick={() =>
            state.bottomRef.current?.scrollIntoView({ behavior: "smooth" })
          }
        />
      )}
    </BasicDropdownMenuContent>
  );
}

export {
  DropdownMenuContent,
  DropdownMenuContentStyles,
  useDropdownMenuContent,
};
export type { DropdownMenuContentProps };
