import { ArrowSmRightIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { env } from "~/config/env";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { useBreakpoint } from "~/design-system/hooks";
import { Image } from "~/design-system/Image";
import { Text } from "~/design-system/Text";
import { createComponentHook } from "~/types";
import type { LanguageDropdownProps } from "../LanguageDropdown/LanguageDropdown";
import { LanguageDropdown } from "../LanguageDropdown/LanguageDropdown";

const useRightsHeader = createComponentHook((props: LanguageDropdownProps) => {
  const { breakpoint } = useBreakpoint();
  const { t } = useTranslation();

  return {
    actions: { setLanguage: props.setLanguage },
    state: { breakpoint, language: props.language },
    t,
  };
});

const RightsHeader = (props: LanguageDropdownProps) => {
  const { actions, state, t } = useRightsHeader(props);
  const navigate = useNavigate();

  /* if the previous route was inside the app, go back to the app, otherwise go to login */
  const backButton = document.referrer?.includes(
    env.VITE_AUTH0_DOMAIN?.replace("auth.", "")
  )
    ? {
        content: t(
          "screens.Rights.components.RightsHeader.backButtonClovis",
          "Clovis.app Users Rights"
        ),
        onClick: () => navigate(-1),
      }
    : {
        content: t(
          "screens.Rights.components.RightsHeader.backButtonLoginClovis",
          "Login to Clovis"
        ),
        onClick: () => {
          window.location.href = env.VITE_APP_BASE_URL;
        },
      };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        margin="medium"
        flexDirection={state.breakpoint != "mobile" ? "row" : "column"}
        justifyContent={
          state.breakpoint != "mobile" ? "spaceBetween" : "center"
        }
        css={{
          marginLeft: "0px",
          paddingLeft: "0px",
        }}
      >
        <Box
          display="block"
          overflow="hidden"
          css={{ height: "110px", width: "110px" }}
          borderRadius="standard"
        >
          <Image src={"/logo.jpg"} />
        </Box>
        <Box margin="medium" display="flex" justifyContent="center">
          <Text
            css={{
              fontSize: "$3xl",
              lineHeight: "35px",
              paddingRight: "5px",
            }}
            weight="medium"
            data-intercom-target="rights-header-title"
            align="center"
          >
            {t(
              "screens.Rights.components.RightsHeader.title",
              "Clovis.app Users Rights"
            )}
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="spaceBetween"
          alignItems="center"
          margin="small"
        >
          <LanguageDropdown
            language={state.language}
            setLanguage={actions.setLanguage}
          />
          <Button
            size="standard"
            variant="ghost"
            css={{ marginLeft: "$space$4" }}
            trailingIcon={<ArrowSmRightIcon />}
            dataIntercomTarget="rights-go-back-toclovis"
            onClick={backButton.onClick}
          >
            {backButton.content}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export { RightsHeader };
