import {
  DesktopComputerIcon,
  DeviceMobileIcon,
  DeviceTabletIcon,
} from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { MenuItemProps } from "~/design-system/DropdownMenu";
import { MenuItem } from "~/design-system/DropdownMenu";
import { useBreakpoint } from "~/design-system/hooks";
import { createComponentHook } from "~/types";

const usePlatformIcon = createComponentHook(() => {
  const { breakpoint } = useBreakpoint();

  return {
    state: { breakpoint },
  };
});

const PlatformIcon = () => {
  const { state } = usePlatformIcon();

  switch (state.breakpoint) {
    case "mobile":
      return <DeviceMobileIcon />;
    case "tablet":
      return <DeviceTabletIcon />;
    case "desktop":
      return <DesktopComputerIcon />;
  }
};

type FromMyDeviceMenuItemProps = {
  onClick: MenuItemProps["onClick"];
};

const useFromMyDeviceMenuItem = createComponentHook(() => {
  const { t } = useTranslation();

  return {
    t,
  };
});

const FromMyDeviceMenuItem = (props: FromMyDeviceMenuItemProps) => {
  const { t } = useFromMyDeviceMenuItem();

  return (
    <MenuItem
      icon={<PlatformIcon />}
      onClick={props.onClick}
      dataIntercomTarget="task-create-add-attachment-from-device"
    >
      {t(
        "screens.Project.Tasks.CreateTaskForm.AddAttachmentButton.FromMyDeviceMenuItem.label",
        "From my device"
      )}
    </MenuItem>
  );
};

export type { FromMyDeviceMenuItemProps };
export { FromMyDeviceMenuItem };
