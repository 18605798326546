import type { GetState, SetState } from "zustand";

type FilesInformationStore = {
  name: string;
  id: string;
};

type FilesFoldersState = {
  filesInformation: FilesInformationStore[];
  setFilesInformation: (filesInformation: FilesInformationStore[]) => void;
};

const FilesFoldersStore = (
  set: SetState<FilesFoldersState>,
  get: GetState<FilesFoldersState>
) => ({
  filesInformation: [],
  setFilesInformation: (filesInformation: FilesInformationStore[]) =>
    set(() => ({ filesInformation })),
});

export type { FilesFoldersState, FilesInformationStore };
export { FilesFoldersStore };
