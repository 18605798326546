import { Indicator } from "@radix-ui/react-checkbox";
import * as React from "react";
import { CheckedStyledCheckbox } from "./components/CheckedStyledCheckbox";
import { StyledCheckbox } from "./components/StyledCheckbox";

type CheckboxProps = React.ComponentProps<typeof StyledCheckbox>;

const Checkbox = React.forwardRef(function (
  props: CheckboxProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <StyledCheckbox id={props.value as string} ref={ref} {...props}>
      <Indicator asChild>
        <CheckedStyledCheckbox color={props.color} rounded={!!props.rounded} />
      </Indicator>
    </StyledCheckbox>
  );
});

export { Checkbox };
