import { styled } from "~/config/stitches";

const Row = styled("tr", {
  variants: {
    clickable: {
      true: {
        "&:hover": {
          backgroundColor: "$gray100",
        },
        cursor: "pointer",
      },
    },
  },
});

export { Row };
