import type * as Types from '../../../../../../../../schema.graphql';

import { gql } from 'urql';
import { TeamsList_ItemFragmentDoc } from './components/UpdateTeamForm/UpdateTeamForm.graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamsList_DataQueryVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
}>;


export type TeamsList_DataQuery = { projects_by_pk?: { teams: Array<{ id: string, name: string, color: string }> } | null | undefined };


export const TeamsList_DataDocument = gql`
    query TeamsList_data($projectId: uuid!) {
  projects_by_pk(id: $projectId) {
    teams {
      ...TeamsList_item
    }
  }
}
    ${TeamsList_ItemFragmentDoc}`;

export function useTeamsList_DataQuery(options: Omit<Urql.UseQueryArgs<TeamsList_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamsList_DataQuery>({ query: TeamsList_DataDocument, ...options });
};