import { TrashIcon } from "@heroicons/react/outline";
import React from "react";
import type { ClearIndicatorProps } from "react-select";
import { TrailingIconButton } from "~/design-system/Field";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";

export function ClearIndicator({
  innerProps,
}: ClearIndicatorProps<BasicMultiSelectFieldInputOption<any>>) {
  return (
    <div {...innerProps} style={{ height: "100%" }}>
      <TrailingIconButton
        color="neutral"
        icon={<TrashIcon />}
        css={{
          borderWidth: "0 0 0 1px",
          height: "100%",
          padding: "0 $xsmall !important",
          position: "inherit !important",
        }}
      />
    </div>
  );
}
