import type * as Types from '../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateDemoProjectForm_DataFragment = { id?: string | null | undefined, type?: string | null | undefined };

export type CreateDemoProjectFormQueryVariables = Types.Exact<{
  language: Types.Scalars['String'];
}>;


export type CreateDemoProjectFormQuery = { templates: Array<{ id?: string | null | undefined, type?: string | null | undefined }> };

export type CopyProjectMutationVariables = Types.Exact<{
  input: Types.CopyProjectInput;
}>;


export type CopyProjectMutation = { copyProject?: { __typename: 'CopyProjectErrors' } | { __typename: 'CopyProjectSuccess', project_id: string } | null | undefined };

export const CreateDemoProjectForm_DataFragmentDoc = gql`
    fragment CreateDemoProjectForm_data on project_templates_overview {
  id
  type
}
    `;
export const CreateDemoProjectFormDocument = gql`
    query CreateDemoProjectForm($language: String!) {
  templates: project_templates_overview(where: {language: {_eq: $language}}) {
    ...CreateDemoProjectForm_data
  }
}
    ${CreateDemoProjectForm_DataFragmentDoc}`;

export function useCreateDemoProjectFormQuery(options: Omit<Urql.UseQueryArgs<CreateDemoProjectFormQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CreateDemoProjectFormQuery>({ query: CreateDemoProjectFormDocument, ...options });
};
export const CopyProjectDocument = gql`
    mutation copyProject($input: CopyProjectInput!) {
  copyProject(input: $input) {
    __typename
    ... on CopyProjectSuccess {
      project_id
    }
  }
}
    `;

export function useCopyProjectMutation() {
  return Urql.useMutation<CopyProjectMutation, CopyProjectMutationVariables>(CopyProjectDocument);
};