import type { PluginListenerHandle } from "@capacitor/core";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import * as React from "react";
import { Box } from "~/design-system/Box";
import { hasNotch } from "~/design-system/SafeArea";
import { createComponentHook } from "~/types";
import { AppBar } from "./components/AppBar";
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

interface LayoutProps {
  children: React.ReactNode;
}

const useLayout = createComponentHook(() => {
  const [hideFooter, setHideFooter] = React.useState(false);

  React.useEffect(() => {
    let showListener: PluginListenerHandle;
    let hideListener: PluginListenerHandle;
    async function hideOnKeyboard() {
      if (Capacitor.isPluginAvailable("Keyboard")) {
        showListener = await Keyboard.addListener("keyboardWillShow", () => {
          setHideFooter(true);
        });
        hideListener = await Keyboard.addListener("keyboardDidHide", () => {
          setHideFooter(false);
        });
      }
    }
    void hideOnKeyboard();
    return () => {
      async function cleanListeners() {
        if (showListener) {
          await showListener.remove();
        }
        if (hideListener) {
          await hideListener.remove();
        }
      }
      void cleanListeners();
    };
  }, []);

  return {
    state: {
      hideFooter,
    },
  };
});

function Layout(props: LayoutProps) {
  const { state } = useLayout();
  return (
    <Box
      css={{
        height: "100vh",
      }}
    >
      <Box css={{ height: "100%", width: "100vw" }}>
        <AppBar
          css={{
            left: 0,
            padding: hasNotch() ? "0 $small $xsmall $small" : "$xsmall $small",
            position: "absolute",
            top: 0,
            width: "100vw",
            zIndex: 3,
          }}
        />
        <Content
          css={{
            height: "100%",
            padding: "$xsmall $small 0 $small",
            width: "100vw",
          }}
        >
          {props.children}
        </Content>
        <Footer
          css={{
            bottom: 0,
            display: state.hideFooter ? "none" : "block",
            position: "absolute",
            width: "calc(100vw - var(--space-xsmall) * 2)",
            zIndex: 3,
          }}
        />
      </Box>
    </Box>
  );
}

export { Layout };
export type { LayoutProps };
