import create from "zustand";

type NavigationDrawerStoreProps = {
  groupIndexContext: number | null;
  setGroupIndexContext: (index: number | null) => void;
};

const useNavigationDrawerStore = create<NavigationDrawerStoreProps>((set) => ({
  groupIndexContext: null,
  setGroupIndexContext: (index: number | null) =>
    set(() => ({ groupIndexContext: index })),
}));

export { useNavigationDrawerStore };
