import * as React from "react";
import type { IconAndTextStyledItemProps } from "./components/IconAndTextStyledItem";
import { IconAndTextStyledItem } from "./components/IconAndTextStyledItem";

type DesktopNavigationButtonProps = IconAndTextStyledItemProps & {
  wrapper?: (children: React.ReactElement) => React.ReactElement;
};

const DesktopNavigationButton = (props: DesktopNavigationButtonProps) => {
  const { wrapper, ...restProps } = props;
  if (wrapper) {
    return wrapper(<IconAndTextStyledItem {...restProps} />);
  } else {
    return <IconAndTextStyledItem {...restProps} />;
  }
  return null;
};

export { DesktopNavigationButton };
