import * as React from "react";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Drawer, DrawerContent, DrawerTrigger } from "~/design-system/Drawer";
import { createComponentHook } from "~/types";
import { UserProfileContainer } from "./components/UserProfile/UserProfileContainer";

const useUserProfileDrawer = createComponentHook(() => {
  const [open, setOpen] = React.useState(false);

  return {
    actions: {
      setOpen,
    },
    state: {
      open,
    },
  };
});

interface UserProfileDrawerProps {
  userId: string;
  /* ONLY when the component is controlled there is no need for children */
  children?: React.ReactNode;
  open?: ReturnType<typeof useUserProfileDrawer>["state"]["open"];
  setOpen?: ReturnType<typeof useUserProfileDrawer>["actions"]["setOpen"];
  onClose?: () => void;
}

function UserProfileDrawer(props: UserProfileDrawerProps) {
  const { actions, state } = useUserProfileDrawer();
  return (
    <Drawer
      open={props.open ?? state.open}
      setOpen={props.setOpen ?? actions.setOpen}
    >
      <DrawerContent position="right" onClose={props.onClose}>
        <Box width="screen" maxWidth="xsmall">
          <UserProfileContainer
            userId={props.userId}
            handleClose={() => {
              props.onClose?.();
              props.setOpen ? props.setOpen(false) : actions.setOpen(false);
            }}
          />
        </Box>
      </DrawerContent>
      {props.children}
    </Drawer>
  );
}

function UserProfileDrawerAvatarTrigger(props: {
  userId: string;
  onClose: () => void;
  altText?: string;
  avatar?: string;
}) {
  return (
    <UserProfileDrawer userId={props.userId} onClose={props.onClose}>
      <DrawerTrigger
        style={{ alignItems: "center", display: "flex", height: "100%" }}
      >
        <Avatar alt={props.altText} src={props.avatar} />
      </DrawerTrigger>
    </UserProfileDrawer>
  );
}

export { UserProfileDrawer, UserProfileDrawerAvatarTrigger };
