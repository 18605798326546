import { ChevronRightIcon } from "@heroicons/react/solid";
import * as React from "react";
import { styled } from "~/config/stitches";
import type { FileIconProps } from "../../FileIcon";
import { Icon } from "../../Icon";
import type { FolderIconSize } from "../FolderIconConstant";
import { folderSizeToIconSize } from "../FolderIconConstant";

const FolderIconChevronWrapper = styled("div", {
  left: "-35%",
  position: "absolute",
  top: "27%",
});

type FolderIconChevronProps = Pick<FileIconProps, "size">;

const FolderIconChevron = (props: FolderIconChevronProps) => {
  return (
    <FolderIconChevronWrapper>
      <Icon
        label={`chevron`}
        size={folderSizeToIconSize.get(
          props.size as (typeof FolderIconSize)[keyof typeof FolderIconSize]
        )}
        css={{ color: "$blue200" }}
      >
        <ChevronRightIcon />
      </Icon>
    </FolderIconChevronWrapper>
  );
};

export { FolderIconChevron };
