import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import { UserActiveOrg_DataFragmentDoc } from '../../../../config/user/UserProvider.graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OriginalInviterFragment = { id: string, full_name?: string | null | undefined, avatar?: string | null | undefined, active_org?: { org: { id: string, name: string, avatar?: string | null | undefined } } | null | undefined };

export type NavigationDrawer_UserQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type NavigationDrawer_UserQuery = { user?: { id: string, avatar?: string | null | undefined, first_name: string, last_name: string, full_name?: string | null | undefined, contact?: { email?: string | null | undefined } | null | undefined, active_org?: { org: { id: string, name: string, avatar?: string | null | undefined, background?: string | null | undefined, address?: { lat?: number | null | undefined, lng?: number | null | undefined } | null | undefined, project?: { id: string } | null | undefined } } | null | undefined, projects: Array<{ project: { id: string, name: string, avatar?: string | null | undefined, is_demo: boolean, labels: Array<{ color: string, name: string }>, org_project_summary?: Array<{ is_project_template: boolean, is_archived: boolean, project_groups: Array<{ project_group: { color: string, name: string, avatar?: string | null | undefined } }> }> | null | undefined } }>, originalInviter: Array<{ inviter?: { id: string, full_name?: string | null | undefined, avatar?: string | null | undefined, active_org?: { org: { id: string, name: string, avatar?: string | null | undefined } } | null | undefined } | null | undefined }> } | null | undefined };

export type NavigationDrawer_OrgQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type NavigationDrawer_OrgQuery = { org?: { project_groups: Array<{ color: string, name: string, avatar?: string | null | undefined }>, settings?: { show_menu_by_group: boolean } | null | undefined } | null | undefined };

export const OriginalInviterFragmentDoc = gql`
    fragment originalInviter on users {
  id
  full_name
  avatar
  active_org {
    org {
      id
      name
      avatar
    }
  }
}
    `;
export const NavigationDrawer_UserDocument = gql`
    query NavigationDrawer_user($id: uuid!) {
  user: users_by_pk(id: $id) {
    id
    avatar
    contact {
      email
    }
    first_name
    last_name
    full_name
    active_org {
      ...UserActiveOrg_data
    }
    projects(
      order_by: {project: {name: asc}}
      where: {project: {id: {_is_null: false}, _not: {org: {id: {_is_null: false}}}, _or: [{_or: [{is_archived: {_eq: false}}, {org_project_summary: {_and: [{is_archived: {_eq: false}}]}}]}, {members: {user: {_and: [{company_name: {_is_null: true}}, {id: {_eq: $id}}]}}}]}}
    ) {
      project {
        id
        name
        avatar
        labels {
          color
          name
        }
        org_project_summary {
          is_project_template
          is_archived
        }
        is_demo
        org_project_summary {
          project_groups {
            project_group {
              color
              name
              avatar
            }
          }
        }
      }
    }
    originalInviter: projects(order_by: {created_at: asc}, limit: 1) {
      inviter {
        ...originalInviter
      }
    }
  }
}
    ${UserActiveOrg_DataFragmentDoc}
${OriginalInviterFragmentDoc}`;

export function useNavigationDrawer_UserQuery(options: Omit<Urql.UseQueryArgs<NavigationDrawer_UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NavigationDrawer_UserQuery>({ query: NavigationDrawer_UserDocument, ...options });
};
export const NavigationDrawer_OrgDocument = gql`
    query NavigationDrawer_org($orgId: uuid!) {
  org: orgs_by_pk(id: $orgId) {
    project_groups(order_by: {name: asc}) {
      color
      name
      avatar
    }
    settings {
      show_menu_by_group
    }
  }
}
    `;

export function useNavigationDrawer_OrgQuery(options: Omit<Urql.UseQueryArgs<NavigationDrawer_OrgQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NavigationDrawer_OrgQuery>({ query: NavigationDrawer_OrgDocument, ...options });
};