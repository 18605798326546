import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Stack } from "~/design-system/Stack";
import { CloseButton } from "~/screens/App/components/CloseButton";

function DrawerCloseButton() {
  return (
    <Box>
      <DrawerClose asChild>
        <CloseButton dataIntercomTarget="org-project-categories-close" />
      </DrawerClose>
    </Box>
  );
}

function Header() {
  const { t } = useTranslation();

  return (
    <Box padding="gutter">
      <Stack space="medium">
        <Box
          display="flex"
          justifyContent="spaceBetween"
          alignItems="center"
          width="full"
        >
          <Heading level="3">
            {t(
              "screens.OrgProjectGroupsDrawer.Header.title",
              "List of project groups"
            )}
          </Heading>
          <DrawerCloseButton />
        </Box>
      </Stack>
    </Box>
  );
}

export { Header };
