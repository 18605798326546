import * as React from "react";
import { useTranslation } from "react-i18next";
import Undraw from "react-undraw";
import { colors } from "~/config/stitches/colors";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

function EmptyWorkerStatus() {
  const { t } = useTranslation();

  return (
    <Box
      padding="gutter"
      css={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Stack align="center" space="small">
        <Box
          style={{ maxHeight: "20vh", maxWidth: "20vw" }}
          width="full"
          height="full"
        >
          <Undraw
            name="no-data"
            primaryColor={colors.emerald500}
            height="100%"
          />
        </Box>
        <Text>
          {t(
            "WorkerStatusDetailsDrawer.EmptyWorkerStatus.message",
            "No long processes pending or in history"
          )}
        </Text>
      </Stack>
    </Box>
  );
}

export { EmptyWorkerStatus };
