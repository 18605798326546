/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from "react";
import { AbacProvider } from "react-abac";
import type { UserContextType } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import { ProfilePicture } from "~/design-system/ProfilePicture";
import { Stack } from "~/design-system/Stack";
import type { Role } from "~/screens/App/screens/Project/Project.authorize";
import { rules } from "~/screens/App/screens/Project/Project.authorize";
import { Actions } from "./components/Actions";
import { InfoList } from "./components/InfoList/InfoList";
import { ProjectInfoList } from "./components/InfoList/ProjectInfoList";
import { Title } from "./components/Title";
import { Toolbar } from "./components/Toolbar";
import type {
  UserProfileContainer_DataQuery,
  UserProjectInfo_DataQuery,
} from "./UserProfileContainer.graphql";

type UserProfileProps = {
  user: NonNullable<UserProfileContainer_DataQuery["users_by_pk"]>;
  handleClose: () => void;
  userProjectInfos?: UserProjectInfo_DataQuery["projects_by_pk"];
  currentUserProjectRole?: string;
  currentUser: UserContextType["user"];
};

export function UserProfile(props: UserProfileProps) {
  return (
    <Box background="card" paddingY="medium" paddingX="gutter">
      <Stack space="small">
        <Toolbar handleClose={props.handleClose} />
        <ProfilePicture
          altText={`${props.user.full_name} picture`}
          url={props.user.avatar}
          css={{ margin: "auto" }}
        >
          {props.user.full_name}
        </ProfilePicture>
        <Title
          name={`${props.user.full_name}`}
          active={props.user.is_connected}
        />
        <Actions userPhone={props.user.contact?.phone} />
        <InfoList user={props.user} handleClose={props.handleClose} />
        {/* we need to wrape ProjectInfoList with AbacProvider because we need to handle rights, teams edit permission */}
        {props.userProjectInfos?.id && (
          <AbacProvider
            key={props.userProjectInfos.id}
            roles={[props.currentUserProjectRole as Role]}
            rules={rules}
            user={props.currentUser}
          >
            <ProjectInfoList
              user={props.user}
              handleClose={props.handleClose}
              userProjectInfos={props.userProjectInfos}
            />
          </AbacProvider>
        )}
      </Stack>
    </Box>
  );
}
