import * as React from "react";
import { OFFICE_VIEWER_SUPPORTED_EXTENSIONS } from "~/screens/App/components/FileViewerDialog/components/FileViewer/components/OfficeViewer/OfficeViewer.constants";
import { PDF_VIEWER_SUPPORTED_EXTENSIONS } from "~/screens/App/components/FileViewerDialog/components/FileViewer/components/PDFViewer/PDFViewer.constants";
import type { File, FileFolder, Folder } from "../../FilesFoldersNavigator";
import type { GetFilesFoldersNavigatorQuery } from "../../FilesFoldersNavigator.graphql";
import { FilesFoldersNavigatorListMobile } from "./FilesFoldersNavigatorListMobile";

const LOCALIZABLE_FILE_EXTENSIONS = [
  /* disable for now, we must support TaskLocation on ImageViewer first */
  // ...IMAGE_VIEWER_SUPPORTED_EXTENSIONS,
  ...OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
  ...PDF_VIEWER_SUPPORTED_EXTENSIONS,
  ...OFFICE_VIEWER_SUPPORTED_EXTENSIONS,
];

type FilesFoldersNavigatorListProps = {
  folders: NonNullable<
    GetFilesFoldersNavigatorQuery["folders_by_pk"]
  >["folders"];
  files: NonNullable<GetFilesFoldersNavigatorQuery["folders_by_pk"]>["files"];
  filesDisabled?: boolean;
  filesLocalizable?: boolean;
  hiddenFiles?: boolean;
};
function FilesFoldersNavigatorList(props: FilesFoldersNavigatorListProps) {
  const contentList: FileFolder[] = [
    ...(props.folders.map((folder) => ({
      folder,
      type: "folder" as const,
    })) as Folder[]),
    // If hiddenFiles is false, then map the files
    ...(props.hiddenFiles
      ? []
      : (props.files.map((file) => ({
          disabled:
            Boolean(props.filesDisabled) ||
            (props.filesLocalizable &&
              file.extension &&
              !LOCALIZABLE_FILE_EXTENSIONS.includes(file.extension)),
          file,
          type: "file" as const,
        })) as File[])),
  ];

  return (
    <FilesFoldersNavigatorListMobile
      css={{ borderRadius: 0, boxShadow: "none", display: "flex", flexGrow: 1 }}
      data={contentList}
    />
  );
}

export { FilesFoldersNavigatorList };
