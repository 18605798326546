import * as React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Inline } from "~/design-system/Inline";
import { Text } from "~/design-system/Text";
import { UserProfileDrawer } from "~/screens/App/components/UserProfileDrawer/UserProfileDrawer";
import { OrgProfileDrawer } from "~/screens/App/screens/Org/components/OrgProfileDrawer/OrgProfileDrawer";
import type { OriginalInviterFragment } from "../../../NavigationDrawer.graphql";

type HeaderInvitedByProps = {
  originalInviter: OriginalInviterFragment;
};

const OrgAvatar = (props: {
  org: { name: string; avatar?: string | null };
}) => {
  return (
    <Avatar
      alt={props.org?.name ?? ""}
      src={props.org?.avatar ?? undefined}
      shape="rounded"
      size="large"
    />
  );
};

const HeaderInvitedBy = (props: HeaderInvitedByProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" paddingY="small" gap="small">
      {props.originalInviter?.active_org?.org && (
        <OrgAvatar org={props.originalInviter.active_org.org} />
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap="xsmall"
        width="full"
      >
        <Text align="left" tone="neutralLight" size="small" truncate>
          {t(
            "screens.NavigationDrawer.NavigationDrawerHeader.HeaderInvitedBy.invitedBy",
            "You have been invited by"
          )}
        </Text>
        <Inline space="xxsmall" align="left">
          <UserProfileDrawer userId={props.originalInviter.id}>
            <DrawerTrigger asChild>
              <Button
                variant="text"
                css={{ minHeight: "fit-content" }}
                dataIntercomTarget="navigation-drawer-user-profile-drawer"
              >
                <Avatar
                  src={props.originalInviter?.avatar ?? undefined}
                  size="xxsmall"
                  shape="circular"
                  alt={props.originalInviter.full_name!}
                  css={{
                    display: "inline",
                    marginRight: "$xsmall",
                  }}
                />
                <Text tone="white" size="small">
                  {props.originalInviter.full_name}
                </Text>
              </Button>
            </DrawerTrigger>
          </UserProfileDrawer>
          {props.originalInviter?.active_org?.org && (
            <>
              <Text tone="neutralLight" size="small">
                {t(
                  "screens.NavigationDrawer.NavigationDrawerHeader.HeaderInvitedBy.from",
                  "from"
                )}
              </Text>
              <OrgProfileDrawer orgId={props.originalInviter.active_org.org.id}>
                <DrawerTrigger asChild>
                  <Button
                    variant="text"
                    css={{ minHeight: "fit-content" }}
                    dataIntercomTarget="navigation-drawer-org-profile-drawer"
                  >
                    <Text size="small" tone="white">
                      {props.originalInviter.active_org.org.name}
                    </Text>
                  </Button>
                </DrawerTrigger>
              </OrgProfileDrawer>
            </>
          )}
        </Inline>
      </Box>
    </Box>
  );
};

export { HeaderInvitedBy };
