import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DialogClose } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { CloseButton } from "~/screens/App/components/CloseButton";
import { SimpleDialogContent } from "~/screens/App/components/SimpleDialogContent/SimpleDialogContent";
import { createComponentHook } from "~/types";
import type { Member } from "../../../../../ProjectMemberList.types";
import { EditUserForm } from "./EditUserForm/EditUserForm";

const useProjectInvitationDialogContent = createComponentHook(
  (props: EditUserDialogContentProps) => {
    return {
      actions: {
        handleSubmitCancel: props.closeDialog,
        handleSubmitSuccess: props.closeDialog,
      },
    };
  }
);

function DialogCloseButton() {
  return (
    <DialogClose asChild>
      <CloseButton dataIntercomTarget="project-members-edit-close" />
    </DialogClose>
  );
}

function EditUserDialogTopbar() {
  const { t } = useTranslation();

  return (
    <Box
      width="full"
      css={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Heading level="4">
        {t("components.EditUserDialog.editUser", "Edit user")}
      </Heading>
      <DialogCloseButton />
    </Box>
  );
}

type EditUserDialogContentProps = {
  closeDialog: () => void;
  member: Member;
};

function EditUserDialogContent(props: EditUserDialogContentProps) {
  const { actions } = useProjectInvitationDialogContent(props);

  return (
    <SimpleDialogContent topbar={<EditUserDialogTopbar />} space="gutter">
      <EditUserForm
        member={props.member}
        handleSuccess={actions.handleSubmitSuccess}
        handleCancel={actions.handleSubmitCancel}
      />
    </SimpleDialogContent>
  );
}

export { EditUserDialogContent };
