import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTeamMutationVariables = Types.Exact<{
  input: Types.CreateTeamInput;
}>;


export type CreateTeamMutation = { createTeam?: { __typename: 'CreateTeamErrors' } | { __typename: 'CreateTeamSuccess', team_id: string } | null | undefined };


export const CreateTeamDocument = gql`
    mutation createTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    __typename
    ... on CreateTeamSuccess {
      team_id
    }
  }
}
    `;

export function useCreateTeamMutation() {
  return Urql.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument);
};