import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UseRedirectAfterLogin_DataQueryVariables = Types.Exact<{
  auth0_id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UseRedirectAfterLogin_DataQuery = { users: Array<{ active_org?: { org_id: string } | null | undefined, projects: Array<{ project: { id: string, name: string } }> }> };


export const UseRedirectAfterLogin_DataDocument = gql`
    query useRedirectAfterLogin_data($auth0_id: String) {
  users(where: {auth0_id: {_eq: $auth0_id}}, limit: 1) {
    active_org {
      org_id
    }
    projects {
      project {
        id
        name
      }
    }
  }
}
    `;

export function useUseRedirectAfterLogin_DataQuery(options: Omit<Urql.UseQueryArgs<UseRedirectAfterLogin_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UseRedirectAfterLogin_DataQuery>({ query: UseRedirectAfterLogin_DataDocument, ...options });
};