import Avatar from "boring-avatars";
import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { Image } from "../Image";

const ProfilePictureWrapper = styled("div", {
  ...tw`relative`,

  defaultVariants: {
    size: "regular",
  },

  variants: {
    ring: {
      caution: {
        "& > span:first-child, & > div > svg, & img": tw`ring ring-caution-500`,
      },
      critical: {
        "& > span:first-child, & > div > svg, & img": tw`ring ring-critical-500`,
      },
      positive: {
        "& > span:first-child, & > div > svg, & img": tw`ring ring-positive-500`,
      },
      white: {
        "& > span:first-child, & > div > svg, & img": tw`ring ring-white`,
      },
    },
    size: {
      regular: tw`h-40 w-40`,
      small: tw`h-24 w-24`,
    },
  },
});

const ProfilePictureImage = styled(Image, {
  "& img": tw`absolute h-full w-full object-cover`,

  defaultVariants: {
    shape: "circular",
  },

  variants: {
    shape: {
      circular: {
        "& *": tw`rounded-full`,
      },
      rounded: {
        "& *": tw`rounded-lg`,
      },
    },
  },
});

type ProfilePictureProps = {
  url?: string | null;
  altText: string;
  children?: React.ReactNode;
  size?: "regular" | "small";
  css?: CSS;
  shape?: "circular" | "rounded";
  ring?: "caution" | "critical" | "positive" | "white";
};

const StyledWrapper = styled("div", {
  defaultVariants: {
    shape: "circular",
  },
  variants: {
    shape: {
      circular: {
        "& > svg": {
          borderRadius: "9999px",
        },
      },
      rounded: {
        "& > svg": {
          borderRadius: "0.375rem",
        },
      },
    },
  },
});

export function ProfilePicture(props: ProfilePictureProps) {
  return (
    <ProfilePictureWrapper css={props.css} size={props.size} ring={props.ring}>
      {props.url ? (
        <ProfilePictureImage
          src={props.url}
          alt={props.altText}
          shape={props.shape}
        />
      ) : (
        <StyledWrapper shape={props.shape}>
          <Avatar
            square
            size={props.size === "small" ? 96 : 160}
            name={props.children?.toString() ?? props.altText}
            variant="marble"
            colors={["#f4b350", "#22a7f0", "#f64747", "#f4b350", "#00b16a"]}
          />
        </StyledWrapper>
      )}
    </ProfilePictureWrapper>
  );
}
