import { z } from "zod";
import { validEmail } from "../../../config/zod/custom-validator";

export const validationSchema = z.object({
  categories: z.array(z.string().uuid()).nullable().optional(),
  client_email: validEmail().nonempty().nullable().optional(),
  client_fullname: z.string().nonempty().nullable().optional(),
  client_phone_number: z.string().nonempty().nullable().optional(),
  end_date: z.date().nullable().optional(),
  orgId: z.string().uuid(),
  price_estimate: z.string().nonempty().nullable().optional(),
  projectId: z.string().uuid(),
  reference: z.string().nonempty().nullable().optional(),
  start_date: z.date().nullable().optional(),
});
