import { styled } from "~/config/stitches";
import { Input as FieldInput } from "../../../../Field";

const Input = styled(FieldInput, {});

Input.defaultProps = {
  type: "date",
};

export { Input };
