/* eslint-disable react/prop-types */
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import * as React from "react";
import { createComponentHook } from "~/types";
import { AroundInput, TrailingIconButton } from "../../../Field";
import { Tones } from "../../../tokens";
import { Input } from "./components/Input";

type DateFieldInputProps = {
  name: string;
  onChange?: React.FocusEventHandler<HTMLInputElement>;
  type?: "date" | "datetime-local" | "month" | "time" | "week";
  value?: string;
  error?: string;
  min?: number | string;
  max?: number | string;
  step?: number;
  readOnly?: boolean;
  tone?: Tones.critical | Tones.neutral | Tones.positive;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
};

const useDateFieldInput = createComponentHook((props: DateFieldInputProps) => {
  const { type = "date" } = props;
  const tone = props.error ? Tones.critical : props.tone;

  return {
    state: {
      tone,
      type,
    },
  };
});

const DateFieldInput = React.forwardRef<HTMLInputElement, DateFieldInputProps>(
  function DateFieldInput(props, ref) {
    const { state } = useDateFieldInput(props);
    return (
      <AroundInput>
        <Input
          type={state.type}
          id={props.name}
          ref={ref}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          tone={state.tone}
          required={props.required}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          autoComplete={props.autoComplete}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          min={props.min}
          max={props.max}
          step={props.step}
          readOnly={props.readOnly}
          withTrailingIconButton={Boolean(props.onClear)}
        />
        {props.onClear && (
          <TrailingIconButton
            color="neutral"
            onClick={props.onClear}
            icon={<TrashIcon />}
          />
        )}
      </AroundInput>
    );
  }
);

export type { DateFieldInputProps };
export { DateFieldInput };
