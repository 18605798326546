import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { UserProfileDrawer } from "../UserProfileDrawer/UserProfileDrawer";
import { UserBadge } from "./UserBadge";

type UserBadgeButtonProps = Pick<
  BadgeProps,
  "children" | "onRemove" | "size"
> & {
  avatar?: string | null;
  userId: string;
  onCloseDrawer?: () => void;
};

const UserBadgeButton = (props: UserBadgeButtonProps) => {
  const { avatar, userId, ...rest } = props;

  return (
    <UserProfileDrawer userId={userId} onClose={props.onCloseDrawer}>
      <DrawerTrigger asChild>
        <Button
          variant="text"
          css={{
            display: "inline-flex",
            justifyContent: "start",
            maxWidth: "100%",
            minHeight: "fit-content",
          }}
          dataIntercomTarget="badges-user-open"
        >
          <UserBadge clickable avatar={avatar} {...rest}>
            {props.children}
          </UserBadge>
        </Button>
      </DrawerTrigger>
    </UserProfileDrawer>
  );
};

export { UserBadgeButton };
