import { env } from "~/config/env";

// Come from TwicPics itself, used to compute paddingTop
function computeRatio(
  height: number | undefined,
  ratio: string | undefined,
  width: number | undefined
): Array<number> {
  // eslint-disable-next-line no-nested-ternary
  return (
    ratio ? ratio.split(`/`) : width && height ? [width, height] : [1, 1]
  ).map((x) => Number(x));
}

function isLocalDevelopmentImage(url: string) {
  if (
    // If we are on development with localhost served assets by minio
    url.includes("localhost") ||
    (env.VITE_NGROK_AWS_MINIO_ENDPOINT &&
      url.includes(env.VITE_NGROK_AWS_MINIO_ENDPOINT)) ||
    // Exclude random generated images from our stories to avoid hitting twicpics on all our stories with random images
    url.includes("loremflickr.com") ||
    // Or if we are not using absolute urls (eg: storybook)
    (!url.startsWith("https://") && !url.startsWith("http://"))
  ) {
    return true;
  }
}

// Remove each X-Amz-xxx params from the URL
function removeQueryParamsFromPresignedUrl(src?: string) {
  return src?.replace(/X-Amz-[\w]+=[^X]+&/g, "");
}

export {
  computeRatio,
  isLocalDevelopmentImage,
  removeQueryParamsFromPresignedUrl,
};
