import type {
  PermissionType,
  SectionType,
} from "@clovis/server/src/app/domain/utils/permissions";
import { Section } from "@clovis/server/src/app/domain/utils/permissions";
import type { TFunction } from "i18next";
import { getOrgPermissionsOptions } from "./org";
import { getProjectPermissionsOptions } from "./project";

type UserSectionsOptions = {
  name: SectionType;
  section: string;
  priority?: number;
}[];

function getUserSectionsOptions(t: TFunction): UserSectionsOptions {
  return [
    {
      name: t("screens.permissions.section.board", "Dashboard"),
      section: Section.board,
    },
    {
      name: t("screens.permissions.section.chat", "Chat"),
      priority: 3,
      section: Section.chat,
    },
    {
      name: t("screens.permissions.section.document", "Docs"),
      priority: 4,
      section: Section.document,
    },
    {
      name: t("screens.permissions.section.file", "Files"),
      priority: 4.3,
      section: Section.file,
    },
    {
      name: t("screens.permissions.section.filesfolders", "Files & Folders"),
      priority: 4.2,
      section: Section.filesFolders,
    },
    {
      name: t("screens.permissions.section.folder", "Folders"),
      priority: 4.1,
      section: Section.folder,
    },
    {
      name: t("screens.permissions.section.info", "Info"),
      priority: 1,
      section: Section.info,
    },
    {
      name: t("screens.permissions.section.org", "Org"),
      section: Section.org,
    },
    {
      name: t("screens.permissions.section.orgProject", "Org project"),
      section: Section.orgProject,
    },
    {
      name: t("screens.permissions.section.project", "Project"),
      priority: 6,
      section: Section.project,
    },
    {
      name: t("screens.permissions.section.task", "Tasks"),
      priority: 4,
      section: Section.task,
    },
    {
      name: t("screens.permissions.section.participants", "Participants"),
      priority: 2,
      section: Section.participants,
    },
    {
      name: t("screens.permissions.section.members", "Members"),
      priority: 2,
      section: Section.members,
    },
  ];
}

function getI18nSection(section: SectionType, t: TFunction) {
  return getUserSectionsOptions(t).find((option) => option.section === section)
    ?.name;
}

function getSectionPriority(section: SectionType) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return getUserSectionsOptions(() => {}).find(
    (option) => option.section === section
  )?.priority;
}

type UserPermissionsOptions = {
  name: PermissionType;
  permission: string;
  /* priority helps to organise elements in the frontend */
  priority?: number;
}[];

function getI18nPermission(permission: PermissionType, t: TFunction) {
  const allI18nPermissionsOptions = [
    ...getOrgPermissionsOptions(t),
    ...getProjectPermissionsOptions(t),
  ];

  return allI18nPermissionsOptions.find(
    (option) => option.permission === permission
  )?.name;
}

export { getI18nPermission, getI18nSection, getSectionPriority };
export type { UserPermissionsOptions };
