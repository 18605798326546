import * as React from "react";
import { Stack } from "~/design-system/Stack";
import type { ProjectTeamDrawer_TeamQuery } from "../ProjectTeamDrawer.graphql";
import { Header } from "./components/Header";
import { ProjectTeamMembers } from "./components/ProjectTeamMembers";

type ProjectTeamContentProps = {
  team: NonNullable<ProjectTeamDrawer_TeamQuery["teams_by_pk"]>;
  handleClose: () => void;
};

function ProjectTeamContent(props: ProjectTeamContentProps) {
  return (
    <Stack>
      <Header
        name={props.team.name}
        projectId={props.team.project.id}
        handleClose={props.handleClose}
      />
      <ProjectTeamMembers
        members={props.team.users}
        handleClose={props.handleClose}
        projectName={props.team.project.name}
        teamName={props.team.name}
        teamId={props.team.id}
      />
    </Stack>
  );
}

export { ProjectTeamContent };
