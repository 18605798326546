import type * as Types from '../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserNotification_BadgeNotificationFragment = { id: string, is_strong: boolean, type: string, payload: any };

export type UserNotification_NotificationFragment = { created_at: string, creator_id: string, event_id?: string | null | undefined, read_at?: string | null | undefined, updated_at: string, id: string, is_strong: boolean, type: string, payload: any };

export type UserNotifcationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type UserNotifcationsQuery = { unreadNotifications: Array<{ created_at: string, creator_id: string, event_id?: string | null | undefined, read_at?: string | null | undefined, updated_at: string, id: string, is_strong: boolean, type: string, payload: any }> };

export type ReadNotificationsMutationVariables = Types.Exact<{
  notificationIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type ReadNotificationsMutation = { readNotifications?: { __typename: 'ReadNotificationsErrors' } | { __typename: 'ReadNotificationsSuccess' } | null | undefined };

export type UnreadNotificationsMutationVariables = Types.Exact<{
  notificationIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type UnreadNotificationsMutation = { unreadNotifications?: { __typename: 'UnreadNotificationsErrors' } | { __typename: 'UnreadNotificationsSuccess' } | null | undefined };

export const UserNotification_BadgeNotificationFragmentDoc = gql`
    fragment UserNotification_badgeNotification on user_notifications {
  id
  is_strong
  type
  payload
}
    `;
export const UserNotification_NotificationFragmentDoc = gql`
    fragment UserNotification_notification on user_notifications {
  ...UserNotification_badgeNotification
  created_at
  creator_id
  event_id
  read_at
  updated_at
}
    ${UserNotification_BadgeNotificationFragmentDoc}`;
export const UserNotifcationsDocument = gql`
    query userNotifcations($userId: uuid!) {
  unreadNotifications: user_notifications(
    where: {recipient_id: {_eq: $userId}, read_at: {_is_null: true}}
  ) {
    ...UserNotification_notification
  }
}
    ${UserNotification_NotificationFragmentDoc}`;

export function useUserNotifcationsQuery(options: Omit<Urql.UseQueryArgs<UserNotifcationsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserNotifcationsQuery>({ query: UserNotifcationsDocument, ...options });
};
export const ReadNotificationsDocument = gql`
    mutation readNotifications($notificationIds: [uuid!]!) {
  readNotifications(input: {notificationIds: $notificationIds}) {
    __typename
  }
}
    `;

export function useReadNotificationsMutation() {
  return Urql.useMutation<ReadNotificationsMutation, ReadNotificationsMutationVariables>(ReadNotificationsDocument);
};
export const UnreadNotificationsDocument = gql`
    mutation unreadNotifications($notificationIds: [uuid!]!) {
  unreadNotifications(input: {notificationIds: $notificationIds}) {
    __typename
  }
}
    `;

export function useUnreadNotificationsMutation() {
  return Urql.useMutation<UnreadNotificationsMutation, UnreadNotificationsMutationVariables>(UnreadNotificationsDocument);
};