import { ChevronDownIcon } from "@heroicons/react/solid";
import { countries } from "countries-list";
import * as React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Icon } from "../Icon";

type CountryCode = keyof typeof countries;

const CountriesSelectorLabel = styled("label", {
  ...tw`sr-only`,
});

const CountriesSelectorSelect = styled("select", {
  ...tw`
  focus:ring-slate-400 focus:border-slate-400
  h-full
  border-transparent bg-transparent
  text-gray-500
  sm:text-sm rounded-md
  opacity-0
  z-2
  `,
  "&": {
    width: "15%",
  },
});

const CountriesFlagDisplay = styled("div", {
  "&": {
    alignItems: "center",
    borderRadius: "0.375rem",
    display: "flex",
    fontSize: "1.5rem",
    lineHeight: "2.3rem",
    marginLeft: "3px",
    paddingLeft: "11px",
    position: "absolute",
    textAlign: "center",
    width: "3.5rem",
    zIndex: 1,
  },
  variants: {
    focused: {
      true: tw`border-slate-400 ring-slate-400 ring`,
    },
  },
});

type CountriesSelectorProps = {
  actualCountry: CountryCode;
  onChange: (value: CountryCode) => void;
  disabled?: boolean;
};

const CountriesSelector = React.forwardRef<
  HTMLSelectElement,
  CountriesSelectorProps
>((props: CountriesSelectorProps, ref) => {
  const { t } = useTranslation();
  const countriesArr = Object.entries(countries).sort((a, b) => {
    return a[1].name.localeCompare(b[1].name);
  });
  const [clickedOnSelect, setClickedOnSelect] = React.useState(false);
  return (
    <>
      <CountriesSelectorLabel htmlFor="countryPhoneField">
        {t(
          "DesignSystem.CountriesPhoneField.CountriesPhoneFieldInput.CountriesSelector",
          "Country"
        )}
      </CountriesSelectorLabel>
      <CountriesSelectorSelect
        disabled={props.disabled}
        ref={ref}
        id="countryPhoneField"
        name="countryPhoneField"
        value={props.actualCountry}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const countryCodeValue = e.target.value as CountryCode;
          props.onChange(countryCodeValue);
        }}
        onFocus={() => setClickedOnSelect(true)}
        onBlur={() => setClickedOnSelect(false)}
      >
        {countriesArr?.map((c) => (
          <option key={c[0]} value={c[0]}>
            {`${c[1].name} +${c[1].phone} ${c[1].emoji} `}
          </option>
        ))}
      </CountriesSelectorSelect>
      <CountriesFlagDisplay focused={clickedOnSelect}>
        {countries[props.actualCountry]?.emoji}
        <Icon color="neutral" size="small" label="dropdown trigger icon">
          <ChevronDownIcon aria-hidden="true" />
        </Icon>
      </CountriesFlagDisplay>
    </>
  );
});

export { CountriesSelector };
export type { CountriesSelectorProps, CountryCode };
