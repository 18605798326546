import * as React from "react";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import { CreateTeamForm } from "./components/CreateTeamForm/CreateTeamForm";
import { TeamsListItem } from "./components/TeamsListItem";
import { useTeamsList_DataQuery } from "./TeamsList.graphql";

const useTeamsList = createComponentHook((props: TeamsListProps) => {
  const queryContext = React.useMemo(
    () => ({ additionalTypenames: ["teams"] }),
    []
  );
  const [{ data }] = useTeamsList_DataQuery({
    context: queryContext,
    variables: { projectId: props.projectId },
  });

  return {
    state: {
      teams: data?.projects_by_pk?.teams,
    },
  };
});

type TeamsListProps = {
  projectId: string;
};

function TeamsList(props: TeamsListProps) {
  const { state } = useTeamsList(props);

  return (
    <Box padding="gutter">
      <Stack space="small">
        {state.teams?.map((team) => (
          <TeamsListItem key={team.id} team={team} />
        ))}
        <CreateTeamForm projectId={props.projectId} />
      </Stack>
    </Box>
  );
}

export { TeamsList };
