import tw from "twin.macro";
import { styled } from "~/config/stitches";

const Paragraph = styled("p", {
  defaultVariants: {
    size: "standard",
    weight: "regular",
  },

  variants: {
    align: {
      center: tw`text-center`,
      left: tw`text-left`,
      right: tw`text-right`,
    },
    italic: {
      false: tw``,
      true: tw`italic`,
    },
    size: {
      large: tw`text-lg`,
      small: tw`text-sm`,
      standard: tw`text-base`,
      xlarge: tw`text-xl`,
      xsmall: tw`text-xs`,
    },
    tone: {
      black: tw`text-black`,
      brandAccent: tw`text-emerald-500`,
      caution: tw`text-caution-500`,
      critical: tw`text-critical-500`,
      formAccent: tw`text-info-700`,
      info: tw`text-info-400`,
      link: tw`text-info-700 cursor-pointer`,
      neutral: tw`text-neutral-500`,
      neutralLight: tw`text-neutral-300`,
      positive: tw`text-positive-500`,
      promote: tw`text-promote-500`,
      secondary: tw`text-gray-500`,
      white: tw`text-white`,
    },
    underline: {
      true: tw`underline`,
    },
    uppercase: {
      true: tw`uppercase`,
    },
    weight: {
      medium: tw`font-medium`,
      regular: tw`font-normal`,
      strong: tw`font-bold`,
    },
    whitespace: {
      normal: tw`whitespace-normal`,
      nowrap: tw`whitespace-nowrap`,
      pre: tw`whitespace-pre`,
      preline: tw`whitespace-pre-line`,
      prewrap: tw`whitespace-pre-wrap`,
    },
  },
});

type ParagraphProps = React.ComponentProps<typeof Paragraph>;

export type { ParagraphProps };
export { Paragraph };
