import { dayjs } from "@clovis/server/src/app/config/dayjs";
import { i18n } from "@clovis/server/src/app/config/i18next";

enum FileApprovalExportFormat {
  Xlsx = "xlsx",
  Csv = "csv",
}

enum FileApprovalReportFormat {
  Pdf = "pdf",
  Docx = "docx",
}

// TODO: Get this type directly from the generated nexus type it must be possible somehow
enum FileApprovalStatusId {
  APPROVED_WITHOUT_COMMENTS = "APPROVED_WITHOUT_COMMENTS",
  APPROVED_WITH_COMMENTS = "APPROVED_WITH_COMMENTS",
  DENIED = "DENIED",
  NC = "NC",
}

enum ApprovalExportStatusId {
  PENDING = "PENDING",
  PENDING_OVER_DEADLINE = "PENDING_OVER_DEADLINE",
}

/*
 * Return the global approval status of file
 */
function getGeneralApprovalStatus(
  statuses: (ApprovalExportStatusId | FileApprovalStatusId)[],
  deadline?: Date | null
) {
  if (statuses.includes(FileApprovalStatusId.DENIED)) {
    return FileApprovalStatusId.DENIED;
  }

  if (statuses.includes(ApprovalExportStatusId.PENDING)) {
    if (deadline && dayjs().isSameOrAfter(deadline)) {
      return ApprovalExportStatusId.PENDING_OVER_DEADLINE;
    }

    return ApprovalExportStatusId.PENDING;
  }

  if (statuses.includes(FileApprovalStatusId.APPROVED_WITH_COMMENTS)) {
    return FileApprovalStatusId.APPROVED_WITH_COMMENTS;
  }

  if (statuses.includes(FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS)) {
    return FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS;
  }

  return FileApprovalStatusId.NC;
}

/*
 * Return the approval status translated for each language
 */
function translateApprovalStatus(
  status: ApprovalExportStatusId | FileApprovalStatusId,
  language: string
) {
  if (status === FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS) {
    return i18n.t(
      "file.approval.status.approved-without-comments",
      "Approved without comments",
      {
        lng: language,
      }
    );
  }
  if (status === FileApprovalStatusId.APPROVED_WITH_COMMENTS) {
    return i18n.t(
      "file.approval.status.approved-with-comments",
      "Approved with comments",
      {
        lng: language,
      }
    );
  }
  if (status === FileApprovalStatusId.DENIED) {
    return i18n.t("file.approval.status.denied", "Denied", {
      lng: language,
    });
  }

  if (status === ApprovalExportStatusId.PENDING) {
    return i18n.t("file.approval.status.pending", "Pending", {
      lng: language,
    });
  }

  if (status === ApprovalExportStatusId.PENDING_OVER_DEADLINE) {
    return i18n.t(
      "file.approval.status.pending-over-deadline",
      "Pending over deadline",
      {
        lng: language,
      }
    );
  }

  if (status === FileApprovalStatusId.NC) {
    return i18n.t("file.approval.status.nc", "Not concerned", {
      lng: language,
    });
  }
}

/*
 * Return the approval status acronym translated for each language
 */
function translateApprovalStatusAcronym(
  status: ApprovalExportStatusId | FileApprovalStatusId,
  language: string
) {
  if (status === FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS) {
    return i18n.t(
      "file.approval.status.acronym.approved-without-comments",
      "AWOC",
      {
        lng: language,
      }
    );
  }
  if (status === FileApprovalStatusId.APPROVED_WITH_COMMENTS) {
    return i18n.t(
      "file.approval.status.acronym.approved-with-comments",
      "AWC",
      {
        lng: language,
      }
    );
  }
  if (status === FileApprovalStatusId.DENIED) {
    return i18n.t("file.approval.status.acronym.denied", "DENIED", {
      lng: language,
    });
  }

  if (status === ApprovalExportStatusId.PENDING) {
    return i18n.t("file.approval.status.acronym.pending", "PENDING", {
      lng: language,
    });
  }

  if (status === ApprovalExportStatusId.PENDING_OVER_DEADLINE) {
    return i18n.t(
      "file.approval.status.acronym.pending-over-deadline",
      "PENDING_DEADLINE",
      {
        lng: language,
      }
    );
  }

  if (status === FileApprovalStatusId.NC) {
    return i18n.t("file.approval.status.acronym.nc", "NC", {
      lng: language,
    });
  }
}

export {
  ApprovalExportStatusId,
  FileApprovalExportFormat,
  FileApprovalReportFormat,
  FileApprovalStatusId,
  getGeneralApprovalStatus,
  translateApprovalStatus,
  translateApprovalStatusAcronym,
};
