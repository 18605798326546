import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/design-system/Button";
import { Card } from "~/design-system/Card";
import { Column, Columns } from "~/design-system/Columns";
import { Dialog, DialogClose, DialogContent } from "~/design-system/Dialog";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

type DeleteConfirmationDialogProps = {
  children: React.ReactNode;
  onConfirm: () => void;
};

function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog>
      {props.children}
      <DialogContent
        css={{ maxWidth: "$md" }}
        size="regular"
        stopClickPropagation
      >
        <Card padding="gutter">
          <Stack space="gutter">
            <Stack align="left">
              <Text size="standard" weight="medium" tone="secondary">
                {t(
                  "screens.ProjectTeamsDrawer.DeleteConfirmationDialog.deleteInformation",
                  "The team will not be recoverable after deletion."
                )}
              </Text>
              <Text size="standard" weight="medium" tone="secondary">
                {t(
                  "screens.ProjectTeamsDrawer.DeleteConfirmationDialog.deleteConfirmation",
                  "Are you sure you want to delete it ?"
                )}
              </Text>
            </Stack>
            <Columns space="small">
              <Column>
                <DialogClose asChild>
                  <Button
                    variant="solid"
                    width="full"
                    dataIntercomTarget="project-teams-delete-cancel"
                  >
                    {t(
                      "screens.ProjectTeamsDrawer.DeleteConfirmationDialog.cancelButton",
                      "Cancel"
                    )}
                  </Button>
                </DialogClose>
              </Column>
              <Column>
                <Button
                  onClick={props.onConfirm}
                  variant="solid"
                  tone="critical"
                  width="full"
                  dataIntercomTarget="project-teams-delete-submit"
                >
                  {t(
                    "screens.ProjectTeamsDrawer.DeleteConfirmationDialog.submitButton",
                    "Delete"
                  )}
                </Button>
              </Column>
            </Columns>
          </Stack>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export { DeleteConfirmationDialog };
