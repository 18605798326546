import * as React from "react";
import { Badge } from "~/design-system/Badge";
import { ImagePreview } from "~/design-system/ImagePreview";
import { fileIsAnImage } from "~/screens/App/screens/Project/Tasks/utils";
import { useFileBadge_DataQuery } from "./FileBadge.graphql";

type FileBadgeProps = {
  fileVersionId: string;
  onRemove: (fileVersionId: string) => void;
};

const useFileBadge = (props: FileBadgeProps) => {
  const [{ data }] = useFileBadge_DataQuery({
    variables: { fileVersionId: props.fileVersionId },
  });

  const handleRemove: React.MouseEventHandler = () => {
    props.onRemove(props.fileVersionId);
  };

  const isImage = data?.file_versions_by_pk
    ? fileIsAnImage(data?.file_versions_by_pk?.content_type)
    : undefined;

  return {
    actions: {
      handleRemove,
    },
    state: {
      fileVersion: data?.file_versions_by_pk,
      isImage,
    },
  };
};

function FileBadge(props: FileBadgeProps) {
  const { actions, state } = useFileBadge(props);

  if (!state.fileVersion) {
    return null;
  }

  if (state.isImage) {
    return (
      <ImagePreview
        size="medium"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        src={state.fileVersion.url!}
        onRemoveClick={actions.handleRemove}
      />
    );
  }

  return (
    <Badge size="large" shape="rounded" onRemove={actions.handleRemove}>
      {state.fileVersion.name}
    </Badge>
  );
}

export type { FileBadgeProps };
export { FileBadge };
