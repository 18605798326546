import * as React from "react";
import { Box } from "~/design-system/Box";
import { DialogContent } from "~/design-system/Dialog";
import { useBreakpoint } from "~/design-system/hooks";
import type { StackProps } from "~/design-system/Stack";
import { Stack } from "~/design-system/Stack";

type SimpleDialogContentProps = {
  topbar: React.ReactNode;
  children: React.ReactNode;
  space?: StackProps["space"];
};

function SimpleMobileDialogContent(props: SimpleDialogContentProps) {
  return (
    <DialogContent size="full" stopClickPropagation>
      <Box
        background="card"
        width="full"
        css={{
          maxHeight: "calc(100vh - $space$gutter * 2)",
          overflowY: "auto",
          padding: "$gutter",
        }}
      >
        <Stack space={props.space}>
          <Box display="flex" justifyContent="spaceBetween" alignItems="center">
            {props.topbar}
          </Box>
          {props.children}
        </Stack>
      </Box>
    </DialogContent>
  );
}

function SimpleDesktopDialogContent(props: SimpleDialogContentProps) {
  return (
    <DialogContent width="large" stopClickPropagation>
      <Box
        background="card"
        borderRadius="standard"
        width="full"
        css={{
          maxHeight: "calc(100vh - $space$medium * 2)",
          overflowY: "auto",
          padding: "$gutter",
        }}
      >
        <Stack space={props.space}>
          {props.topbar}
          {props.children}
        </Stack>
      </Box>
    </DialogContent>
  );
}

function SimpleDialogContent(props: SimpleDialogContentProps) {
  const { breakpoint } = useBreakpoint();
  if (breakpoint === "desktop") {
    return <SimpleDesktopDialogContent {...props} />;
  }
  return <SimpleMobileDialogContent {...props} />;
}

export { SimpleDialogContent };
