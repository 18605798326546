import { useAuth0 } from "~/config/auth0/use-auth0";
import { useFirebaseToken } from "~/config/capacitor/push-notifications/PushNotifications";
import { useDeleteFirebaseTokenMutation } from "./useLogout.graphql";

const useLogout = () => {
  const firebaseToken = useFirebaseToken((state) => state.token);
  const [, deleteFirebaseToken] = useDeleteFirebaseTokenMutation();
  const { logout } = useAuth0();

  return {
    logout: async () => {
      if (firebaseToken) {
        await deleteFirebaseToken({ input: { token: firebaseToken } }).catch(
          (error) => {
            console.error(error);
          }
        );
      }
      logout({ returnTo: window.location.origin });
    },
  };
};

export { useLogout };
