import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserAppMetadataInfosMutationVariables = Types.Exact<{
  appMobile?: Types.InputMaybe<Types.Scalars['Boolean']>;
  appDesktop?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateUserAppMetadataInfosMutation = { updateUserAppMetadata?: { __typename: 'UpdateUserAppMetadataErrors' } | { __typename: 'UpdateUserAppMetadataSuccess', user_id: string } | null | undefined };


export const UpdateUserAppMetadataInfosDocument = gql`
    mutation updateUserAppMetadataInfos($appMobile: Boolean, $appDesktop: Boolean) {
  updateUserAppMetadata(
    input: {app_desktop_installed: $appDesktop, app_mobile_installed: $appMobile}
  ) {
    __typename
    ... on UpdateUserAppMetadataSuccess {
      user_id
    }
  }
}
    `;

export function useUpdateUserAppMetadataInfosMutation() {
  return Urql.useMutation<UpdateUserAppMetadataInfosMutation, UpdateUserAppMetadataInfosMutationVariables>(UpdateUserAppMetadataInfosDocument);
};