import * as React from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import type { ElementOf } from "ts-essentials";
import type { UserPropsAuth0 } from "~/config/auth0/use-auth0";
import { getAuth0Id, useAuth0 } from "~/config/auth0/use-auth0";
import { env } from "~/config/env";
import { i18n } from "~/config/i18next";
import { captureException, SentryReact } from "~/config/sentry";
import { setStreamChatUser } from "~/config/stream-chat/helpers";
import { FullPageSpinner } from "~/screens/App/components/FullPageSpinner/FullPageSpinner";
import { createComponentHook } from "~/types";
import { useSegment } from "../segment/SegmentProvider";
import { UserNotificationsProvider } from "./user-notifications/UserNotifications";
import type {
  UserProvider_UserQuery,
  UserTeams_DataFragment,
} from "./UserProvider.graphql";
import { useUserProvider_UserQuery } from "./UserProvider.graphql";
import { WorkerStatusProvider } from "./workerstatus/WorkerStatus";

type UserContextType = {
  user: ElementOf<UserProvider_UserQuery["users"]>;
  getUserTeamsByProjectId: (projectId: string) => UserTeams_DataFragment[];
};

const UserContext = React.createContext<UserContextType>({} as UserContextType);

/**
 * Hook to get the user context
 *
 * **Warning**: There are provided a function *getUserTeamsByProjectId*
 * to easily get the user teams by project id
 * because the user.teams has all user projects
 */
function useUser() {
  return React.useContext(UserContext);
}

type UserConfigProps = {
  children: React.ReactNode;
};

const useUserProvider = createComponentHook(() => {
  const { logout, user } = useAuth0<UserPropsAuth0>();
  const navigate = useNavigate();
  const [{ data, error, fetching }] = useUserProvider_UserQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: {
      auth0_id: getAuth0Id(user) ?? user!.sub,
    },
  });

  const SegmentAnalyticsContext = useSegment();
  const IntercomContext = useIntercom();

  const currentUser = data?.users[0];

  const getUserTeamsByProjectId = React.useCallback(
    (projectId: string) => {
      if (!currentUser) {
        return [];
      }
      return currentUser.teams.filter((team) => {
        return team.team.project_id === projectId;
      });
    },
    [currentUser]
  );

  const isError = fetching === false && !currentUser;

  if (isError) {
    /* Having an jwt error here may come from:
      - if you changed of branch, verify that your ran well yarn clean %% yarn install at the root of the repo to reinstall all the right dependencies
      - verify that no other node process are still running on your computer (restart your computer)
      - a wrong seed on the backend when running yarn dev (check opening hasura console on http://localhost:9695/ or http://localhost:8795/)
      - a wrong HASURA_GRAPHQL_JWT_SECRET variable in server (check that you don't use the test HASURA JWT)
      - not matching userId and auth0_id between the dev database and auth0 dev account (check inside auth0 and hasura console to verify the ids are the same)
      - a wrong clock in your terminal or computer (reboot it)
    */
    captureException([
      "Error while fetching database user with Auth0 id and token, check inline doc in code to solve it under dev :",
      error,
    ]);
    logout();
  }

  React.useEffect(() => {
    console.info("currentUser?.language", currentUser?.language);
    if (currentUser?.language) void i18n.changeLanguage(currentUser?.language);
  }, [currentUser?.language]);

  /* Initialise StreamChat with current user stream_user_id */
  React.useEffect(() => {
    async function initChat() {
      if (currentUser?.stream_chat_token?.token.length) {
        await setStreamChatUser({
          id: currentUser.stream_user_id,
          token: currentUser.stream_chat_token.token,
        });
      }
    }
    void initChat();
  }, [currentUser]);

  React.useEffect(() => {
    if (SegmentAnalyticsContext && currentUser) {
      console.info("Initialize Segment Analytics User");
      void SegmentAnalyticsContext.identify(currentUser.id);
      if (currentUser.active_org) {
        void SegmentAnalyticsContext.group(currentUser.active_org.org.id);
      }
    }

    if (IntercomContext && currentUser) {
      console.info("Initialize Intercom Analytics User");
      /* Just give to Intercom the email and userId, as the other values will be catched by Segment */
      void IntercomContext?.boot({
        ...(currentUser.active_org && {
          company: {
            companyId: currentUser.active_org.org.id,
            name: currentUser.active_org.org.name,
          },
        }),
        customAttributes: {
          clovis_app_version: env.VITE_RELEASE,
        },
        email: currentUser.contact?.email ?? "",
        languageOverride: currentUser.language,
        userId: currentUser.id,
      });
    }
  }, [currentUser, IntercomContext, SegmentAnalyticsContext]);

  React.useEffect(() => {
    // If a user is "locked" => redirect to unavailable page
    if (currentUser?.is_locked) {
      navigate("/unavailable", { replace: true });
    }
  }, [currentUser]);

  return {
    actions: {
      getUserTeamsByProjectId,
    },
    state: {
      user: currentUser,
    },
  };
});

function UserProvider(props: UserConfigProps) {
  const { actions, state } = useUserProvider();

  /* Show a void page while fetching the user
     Warning: this may render a void page under dev if auth0 is not fully synced with the database */
  if (!state.user) {
    return <FullPageSpinner />;
  }

  // Setup the logged user for all our sentry context
  SentryReact.setUser(state.user);

  const value = { ...state, ...actions };

  return (
    // cast our state as we checked that user isn't null
    <UserContext.Provider value={value as UserContextType}>
      <UserNotificationsProvider userId={state.user.id}>
        <WorkerStatusProvider userId={state.user.id}>
          {props.children}
        </WorkerStatusProvider>
      </UserNotificationsProvider>
    </UserContext.Provider>
  );
}

export type { UserContextType };
export { UserProvider, useUser };
