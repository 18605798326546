import * as React from "react";
import type { CSS } from "~/config/stitches";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import {
  isMemberInvitedToOrgNotification,
  isOrgNotification,
} from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterOrgMemberListAvatarBadge(
  notification: UserBadgeNotification,
  orgId: string,
  userId: string
) {
  return (
    isOrgNotification(notification) &&
    isMemberInvitedToOrgNotification(notification) &&
    notification.payload.org.id === orgId &&
    notification.payload.invited.id === userId
  );
}

const useOrgMemberListAvatarBadge = createComponentHook(
  (props: OrgMemberListAvatarBadgeProps) => {
    const notifications = useUserNotificationsContext((state) =>
      state.notifications.filter((n) =>
        filterOrgMemberListAvatarBadge(n, props.orgId, props.userId)
      )
    );
    const { strongNotificationsCount, weakNotif } =
      getDisplayNotificationProps(notifications);

    return {
      state: {
        strongNotificationsCount,
        weakNotif,
      },
    };
  }
);

type OrgMemberListAvatarBadgeProps = {
  orgId: string;
  userId: string;
  css?: CSS;
};

const OrgMemberListAvatarBadge = (props: OrgMemberListAvatarBadgeProps) => {
  const { state } = useOrgMemberListAvatarBadge(props);

  return (
    <DisplayNotification
      css={props.css}
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export {
  filterOrgMemberListAvatarBadge,
  OrgMemberListAvatarBadge,
  useOrgMemberListAvatarBadge,
};
