import { SelectorIcon } from "@heroicons/react/solid";
import React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Icon } from "~/design-system/Icon";

const ButtonArrowWrapper = styled("span", {
  ...tw`ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none`,
});

function ButtonArrow() {
  return (
    <ButtonArrowWrapper>
      <Icon size="small" color="neutral" label="arrow icon">
        <SelectorIcon aria-hidden="true" />
      </Icon>
    </ButtonArrowWrapper>
  );
}

export { ButtonArrow };
