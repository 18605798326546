import type * as Uppy from "@uppy/core";
import * as React from "react";
import {
  UploadProgressBar,
  UploadProgressBarLayoutWrapper,
} from "~/design-system/UploadProgressBar";
import { createComponentHook } from "~/types";

type FilesUploaderProgressBarProps = {
  files: Array<Uppy.State["files"][string]>;
  totalProgress: Uppy.State["totalProgress"];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const useFilesUploaderProgressBar = createComponentHook(
  (props: FilesUploaderProgressBarProps) => {
    return {
      state: {
        files: props.files.filter((f) => f.progress),
        totalProgress: props.totalProgress,
      },
    };
  }
);

function FilesUploaderProgressBar(props: FilesUploaderProgressBarProps) {
  const { state } = useFilesUploaderProgressBar(props);

  if (state.files.length === 0) {
    return null;
  }

  return (
    <UploadProgressBarLayoutWrapper>
      <UploadProgressBar onClick={props.onClick} {...state} />
    </UploadProgressBarLayoutWrapper>
  );
}

export { FilesUploaderProgressBar };
