import * as React from "react";
import { Dialog } from "~/design-system/Dialog";
import { createComponentHook } from "~/types";
import type { Member } from "../../../../ProjectMemberList.types";
import { EditUserDialogContent } from "./components/EditUserDialogContent";

const useProjectInvitationDialog = createComponentHook(() => {
  const [open, setOpen] = React.useState(false);

  return {
    actions: {
      setOpen,
    },
    state: {
      open,
    },
  };
});

type EditUserDialogProps = {
  /* ONLY when the component is controlled there is no need for children */
  children?: React.ReactNode;
  open?: ReturnType<typeof useProjectInvitationDialog>["state"]["open"];
  setOpen?: ReturnType<typeof useProjectInvitationDialog>["actions"]["setOpen"];
  member: Member;
};

function EditUserDialog(props: EditUserDialogProps) {
  const { actions, state } = useProjectInvitationDialog();

  return (
    <Dialog
      open={props.open ?? state.open}
      setOpen={props.setOpen ?? actions.setOpen}
    >
      {props.children}
      <EditUserDialogContent
        closeDialog={() =>
          props.setOpen ? props.setOpen(false) : actions.setOpen(false)
        }
        member={props.member}
      />
    </Dialog>
  );
}

export { EditUserDialog };
