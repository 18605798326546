import React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

// TODO: move into the design system once we have responsive padding per endpoints
const CELL_HEIGHT = 75;

const enum CellParent {
  BODY = "BODY",
  HEAD = "HEAD",
}

const CellContext = React.createContext<CellParent>(CellParent.BODY);

const useCellContext = () => React.useContext(CellContext);

const HeadCell = styled("th", {
  ...tw`
    px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider overflow-hidden overflow-ellipsis whitespace-nowrap
  `,
});
HeadCell.defaultProps = {
  scope: "col",
};

const BodyCell = styled("td", {
  ...tw`
    px-6 py-4 whitespace-nowrap overflow-hidden
  `,
  "& > *": {
    verticalAlign: "middle",
  },
});

type CellProps = {
  css?: CSS;
  children?: React.ReactNode;
  align?: "center" | "left" | "right";
};

const Cell = (props: CellProps) => {
  const parent = useCellContext();

  switch (parent) {
    case CellParent.BODY:
      return <BodyCell {...props} />;
    case CellParent.HEAD:
      return <HeadCell {...props} />;
    default:
      return null;
  }
};

export { Cell, CELL_HEIGHT, CellContext, CellParent };
