import { CloudUploadIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Text } from "~/design-system/Text";
import { createComponentHook } from "~/types";

const useToolbar = createComponentHook(() => {
  const { t } = useTranslation();

  return { t };
});

export function Toolbar() {
  const { t } = useToolbar();

  return (
    <Box display="flex" justifyContent="spaceBetween" width="full">
      <Inline space="small">
        <Icon label="icon" color="positive">
          <CloudUploadIcon />
        </Icon>
        <Text size="large" weight="strong" tone="positive">
          {t(
            "components.FilesUnderUploadDrawer.Toolbar.currentUploads",
            "Current uploads"
          )}
        </Text>
      </Inline>
      <DrawerClose>
        <Icon color="neutral" size="medium" label="dismiss">
          <XIcon />
        </Icon>
      </DrawerClose>
    </Box>
  );
}
