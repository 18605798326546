import type { INotification } from "@clovis/server/src/app/domain/notification/types";
import { NotificationNamespace } from "@clovis/server/src/app/domain/notification/types";

interface IProject {
  id: string;
}

interface IProjectNotificationPayload {
  project: IProject;
}

enum ProjectNotificationNamespace {
  Task = "TASK",
  Document = "DOCUMENT",
  Chat = "CHAT",
  Misc = "MISC",
  Info = "INFO",
}

const IProjectNotificationTypePrefix =
  `${NotificationNamespace.Project}` as const;
// All Chat notifications must extend from this interface rien qu
interface IProjectNotification extends INotification {
  type: `${typeof IProjectNotificationTypePrefix}/${string}`;
  readonly payload: IProjectNotificationPayload;
}

export type { IProjectNotification, IProjectNotificationPayload };
export { IProjectNotificationTypePrefix, ProjectNotificationNamespace };
