import * as React from "react";

let interval: NodeJS.Timer | undefined = undefined;

type artificialProgressHookProps = {
  initialProgress: number;
  artificialSpeed?: number;
};

/* Thus webhook is here to make the progress advance a little bit 
   so the users sees the progress is not stuck */
function useArtificialProgressHook(props: artificialProgressHookProps) {
  const [running, setRunning] = React.useState(true);
  const [artificialProgress, setArtificialProgress] = React.useState(
    props.initialProgress
  );

  /* reset the progress when officialy setup */
  React.useEffect(() => {
    if (props.initialProgress < 99) {
      setArtificialProgress(props.initialProgress);
    }
  }, [props.initialProgress]);

  /* iterate without time interval */
  React.useEffect(() => {
    if (running) {
      /* More the increment is high, more increment with low value*/
      const incrementedValue = 1 / (1 + artificialProgress * 100);

      interval = setInterval(() => {
        setArtificialProgress((prev) => prev + incrementedValue);
      }, props.artificialSpeed ?? 200);
    } else {
      clearInterval(interval);
    }
  }, [running]);

  /* stop the artificial progress when >= 99 */
  React.useEffect(() => {
    if (artificialProgress >= 99) {
      setRunning(false);
      clearInterval(interval);
    }
  }, [artificialProgress]);

  return {
    artificialProgress,
  };
}

export { useArtificialProgressHook };
