import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CategoriesList_ItemFragment = { id: string, name: string, color: string };

export type UpdateProjectCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateProjectCategoryInput;
}>;


export type UpdateProjectCategoryMutation = { updateProjectCategory?: { __typename: 'UpdateProjectCategoryErrors' } | { __typename: 'UpdateProjectCategorySuccess', project_category_id: string } | null | undefined };

export type DeleteProjectCategoryMutationVariables = Types.Exact<{
  input: Types.DeleteProjectCategoryInput;
}>;


export type DeleteProjectCategoryMutation = { deleteProjectCategory?: { __typename: 'DeleteProjectCategoryErrors' } | { __typename: 'DeleteProjectCategorySuccess', project_category_id: string } | null | undefined };

export const CategoriesList_ItemFragmentDoc = gql`
    fragment CategoriesList_item on project_categories {
  id
  name
  color
}
    `;
export const UpdateProjectCategoryDocument = gql`
    mutation updateProjectCategory($input: UpdateProjectCategoryInput!) {
  updateProjectCategory(input: $input) {
    __typename
    ... on UpdateProjectCategorySuccess {
      project_category_id
    }
  }
}
    `;

export function useUpdateProjectCategoryMutation() {
  return Urql.useMutation<UpdateProjectCategoryMutation, UpdateProjectCategoryMutationVariables>(UpdateProjectCategoryDocument);
};
export const DeleteProjectCategoryDocument = gql`
    mutation deleteProjectCategory($input: DeleteProjectCategoryInput!) {
  deleteProjectCategory(input: $input) {
    __typename
    ... on DeleteProjectCategorySuccess {
      project_category_id
    }
  }
}
    `;

export function useDeleteProjectCategoryMutation() {
  return Urql.useMutation<DeleteProjectCategoryMutation, DeleteProjectCategoryMutationVariables>(DeleteProjectCategoryDocument);
};