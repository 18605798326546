import * as React from "react";
import { useUser } from "~/config/user/UserProvider";
import { useWorkerStatusContext } from "~/config/user/workerstatus/WorkerStatus";
import { workerStatusMap } from "~/config/user/workerstatus/workerStatusMap";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import type { WorkersStatusVirtualListProps } from "~/screens/App/components/WorkersStatusVirtualList/WorkersStatusVirtualList";
import { WorkersStatusVirtualList } from "~/screens/App/components/WorkersStatusVirtualList/WorkersStatusVirtualList";
import { createComponentHook } from "~/types";
import { DrawerHeader } from "./DrawerHeader";
import { EmptyWorkerStatus } from "./EmptyWorkerStatus";

const useWorkerStatusDetailsList = createComponentHook(
  (props: WorkerStatusDetailsDrawerContentProps) => {
    const { workerStatus } = useWorkerStatusContext();
    const { user } = useUser();

    return { state: { user, workerStatus } };
  }
);

type WorkerStatusDetailsDrawerContentProps = {
  onClose: () => void;
};

function WorkerStatusDetailsList(props: WorkerStatusDetailsDrawerContentProps) {
  const { state } = useWorkerStatusDetailsList(props);

  if (!state.workerStatus || state.workerStatus.length === 0) {
    return <EmptyWorkerStatus />;
  }

  const virtualListData: WorkersStatusVirtualListProps["data"] =
    state.workerStatus.map((ws) => {
      const translation = workerStatusMap[ws.type](
        { user: state.user },
        ws as never
      );
      return {
        created_at: ws.created_at,
        event_id: ws.event_id,
        percentDone: translation.percentDone,
        status: ws.status,
        subtitle: translation.subtitle,
        title: translation.title,
        updated_at: ws.updated_at,
      };
    });
  return (
    <WorkersStatusVirtualList data={virtualListData} onClose={props.onClose} />
  );
}

function WorkerStatusDetailsDrawerContent(
  props: WorkerStatusDetailsDrawerContentProps
) {
  return (
    <DrawerContent position="right">
      <Box
        width="screen"
        css={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
        maxWidth="xsmall"
        id="worker-status-drawer"
      >
        <DrawerHeader />
        <Box css={{ display: "flex", flexGrow: 1, overflowY: "auto" }}>
          <WorkerStatusDetailsList onClose={props.onClose} />
        </Box>
      </Box>
    </DrawerContent>
  );
}

export type { WorkerStatusDetailsDrawerContentProps };
export { WorkerStatusDetailsDrawerContent };
