import { styled } from "~/config/stitches";

type NavListItemProps = {
  children: React.ReactNode;
};

const NavListItem = styled("li", {});

export { NavListItem };
export type { NavListItemProps };
