import * as React from "react";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import { Header } from "./Header";
import { TeamsList } from "./TeamsList/TeamsList";

type ProjectTeamsDrawerContentProps = {
  projectId: string;
};

function ProjectTeamsDrawerContent(props: ProjectTeamsDrawerContentProps) {
  return (
    <DrawerContent position="right">
      <Box width="screen" maxWidth="xsmall">
        <Header />
        <TeamsList projectId={props.projectId} />
      </Box>
    </DrawerContent>
  );
}

export type { ProjectTeamsDrawerContentProps };
export { ProjectTeamsDrawerContent };
