import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateProjectGroupMutationVariables = Types.Exact<{
  input: Types.CreateProjectGroupInput;
}>;


export type CreateProjectGroupMutation = { createProjectGroup?: { __typename: 'CreateProjectGroupErrors' } | { __typename: 'CreateProjectGroupSuccess', project_group_id: string } | null | undefined };

export type PrepareProjectGroupAssetForUploadMutationVariables = Types.Exact<{
  input: Types.PrepareProjectGroupAssetForUploadInput;
}>;


export type PrepareProjectGroupAssetForUploadMutation = { PrepareProjectGroupAssetForUpload?: { __typename: 'PrepareProjectGroupAssetForUploadErrors' } | { __typename: 'PrepareProjectGroupAssetForUploadSuccess', url: string, key: string } | null | undefined };


export const CreateProjectGroupDocument = gql`
    mutation createProjectGroup($input: CreateProjectGroupInput!) {
  createProjectGroup(input: $input) {
    __typename
    ... on CreateProjectGroupSuccess {
      project_group_id
    }
  }
}
    `;

export function useCreateProjectGroupMutation() {
  return Urql.useMutation<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>(CreateProjectGroupDocument);
};
export const PrepareProjectGroupAssetForUploadDocument = gql`
    mutation PrepareProjectGroupAssetForUpload($input: PrepareProjectGroupAssetForUploadInput!) {
  PrepareProjectGroupAssetForUpload(input: $input) {
    __typename
    ... on PrepareProjectGroupAssetForUploadSuccess {
      url
      key
    }
  }
}
    `;

export function usePrepareProjectGroupAssetForUploadMutation() {
  return Urql.useMutation<PrepareProjectGroupAssetForUploadMutation, PrepareProjectGroupAssetForUploadMutationVariables>(PrepareProjectGroupAssetForUploadDocument);
};