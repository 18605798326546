import React from "react";
import type { ControlProps } from "react-select";
import tw from "twin.macro";
import { Box } from "~/design-system/Box";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";

export function Control({
  children,
  innerProps,
  innerRef,
  isFocused,
}: ControlProps<BasicMultiSelectFieldInputOption<any>>) {
  return (
    <div ref={innerRef} {...innerProps}>
      <Box
        background="input"
        borderWidth="xsmall"
        css={{
          borderRadius: "0.375rem",
          minHeight: "42px",
          ...(isFocused
            ? tw`ring-2 ring-slate-400`
            : { borderColor: "$gray300" }),
        }}
        display={"flex"}
        boxShadow="xsmall"
      >
        {children}
      </Box>
    </div>
  );
}
