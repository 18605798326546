import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";
import * as React from "react";
import { styled } from "~/config/stitches";
import { BottomArrow } from "./BottomArrow";
import type { DropdownMenuContentProps } from "./DropdownMenuContent";
import {
  DropdownMenuContentStyles,
  useDropdownMenuContent,
} from "./DropdownMenuContent";
import { TopArrow } from "./TopArrow";

const BasicContextMenuPrimitiveContent = styled(ContextMenuPrimitive.Content, {
  ...DropdownMenuContentStyles,
});

function ContextMenuPrimitiveContent(props: DropdownMenuContentProps) {
  const { actions, state } = useDropdownMenuContent(props);
  const { children, ...dropdownMenuContentProps } = props;
  return (
    <BasicContextMenuPrimitiveContent
      {...dropdownMenuContentProps}
      onScroll={actions.onScroll}
      ref={state.dropdownContentRef}
      css={{
        //The menu should not be higher than half of the height of the screen
        maxHeight: "calc(50vh - $space$gutter)",
        overflowY: "auto",
      }}
    >
      {state.displayTopArrow && (
        <TopArrow
          onClick={() =>
            state.topRef.current?.scrollIntoView({ behavior: "smooth" })
          }
        />
      )}
      <div ref={state.topRef} />
      {children}
      <div ref={state.bottomRef} />
      {state.displayBottomArrow && (
        <BottomArrow
          onClick={() =>
            state.bottomRef.current?.scrollIntoView({
              behavior: "smooth",
            })
          }
        />
      )}
    </BasicContextMenuPrimitiveContent>
  );
}

export { ContextMenuPrimitiveContent };
