// TODO: find a way to automatically keep it in sync and generate this file

// Those enums allow us to safely use our seeded roles data in our code
// it must be kept in sync with "/hasura/seeds/default/xxx_org_roles.sql"
enum OrgRoleId {
  Owner = "owner",
  Administrator = "administrator",
  Standard = "standard",
  Limited = "limited",
  Readonly = "readonly",
  Disabled = "disabled",
}

const OrgRoleDefault = OrgRoleId.Standard;

// This constant is here to avoid trip to database to know which role
// have the most privileges. If you change the order in this list, you should
// also change the value of "weight" in "org_roles" tables accordingly
const OrgRolesHierarchy = [
  OrgRoleId.Owner,
  OrgRoleId.Administrator,
  OrgRoleId.Standard,
  OrgRoleId.Limited,
  OrgRoleId.Readonly,
  OrgRoleId.Disabled,
];

// This constant is simply a convenient way to lookup which roles you can
// access from your current role with O(1)
// eg: OrgRolesHierarchyMap['standard'] === ['standard', 'limited', 'disabled']
const OrgRolesHierarchyMap: Record<OrgRoleId, OrgRoleId[]> =
  OrgRolesHierarchy.reduce(
    (acc, role) => ({
      ...acc,
      [role]: OrgRolesHierarchy.slice(
        OrgRolesHierarchy.findIndex((c) => c === role)
      ),
    }),
    {} as Record<OrgRoleId, OrgRoleId[]>
  );

export { OrgRoleDefault, OrgRoleId, OrgRolesHierarchy, OrgRolesHierarchyMap };
