import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

export const DropdownMenuButton = styled(RadixDropdownMenu.Trigger, {
  // temporary disabling outlines until "we make beautiful ones"
  //...tw`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
  ...tw`rounded-md`,
  "&": {
    alignItems: "center",
    height: "$buttonHeight",
  },
  variants: {
    variant: {
      default: tw`
      inline-flex justify-center 
      border border-gray-300 shadow-sm px-4 py-2 bg-white 
      text-sm font-medium text-gray-700 hover:bg-gray-50 
      `,
      minimal: {
        ...tw`
        flex items-center justify-center 
        text-gray-400 
        hover:text-gray-600 
        `,
        minWidth: "$buttonHeight",
      },
    },
  },
});
