import * as React from "react";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer } from "~/design-system/Drawer";
import { OrgProjectGroupsDrawerContent } from "./components/OrgProjectGroupsContent";

type OrgProjectGroupsDrawerProps = {
  children?: React.ReactNode;
  controlled?: Pick<ControlledDrawerProps, "open" | "setOpen">;
  orgId: string;
};

function OrgProjectGroupsDrawer(props: OrgProjectGroupsDrawerProps) {
  if (props.controlled) {
    return (
      <Drawer open={props.controlled.open} setOpen={props.controlled.setOpen}>
        <OrgProjectGroupsDrawerContent orgId={props.orgId} />
        {props.children}
      </Drawer>
    );
  }
  return (
    <Drawer>
      <OrgProjectGroupsDrawerContent orgId={props.orgId} />
      {props.children}
    </Drawer>
  );
}

export type { OrgProjectGroupsDrawerProps };
export { OrgProjectGroupsDrawer };
