import { getApp, getApps, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { env } from "~/config/env";
import { captureException } from "~/config/sentry";

const FIREBASE_SW_REGISTRATION_PATH = "/firebase-messaging-sw.js";

const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_API_KEY!,
  appId: env.VITE_FIREBASE_APP_ID!,
  authDomain: env.VITE_FIREBASE_AUTH_DOMAIN!,
  measurementId: env.VITE_FIREBASE_MEASUREMENT_ID!,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID!,
  projectId: env.VITE_FIREBASE_PROJECT_ID!,
  storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET!,
};

function getFirebaseApp() {
  const apps = getApps();
  if (apps.length > 0) {
    return getApp();
  }
  return initializeApp(firebaseConfig);
}

/*
 * Initialize the service worker that catch firebase notification sent when we aren't in focus
 */
async function initServiceWorker() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        FIREBASE_SW_REGISTRATION_PATH
      );
      await registration.update();
      console.info(
        "ServiceWorker registration successful with scope: ",
        registration.scope
      );
    } catch (err) {
      captureException(new Error(JSON.stringify(err)));
    }
  }
}

async function hasNotificationPermission() {
  // If the browser notification preferences has already been set
  // no need to request permission
  if (Notification.permission !== "default") {
    return Notification.permission === "granted";
  }
  // Some browsers preferences will force us to wait for a first user interaction with the page
  // before allowing us to request for Notification permissions.
  // In that case, we want to listen the first "event click" performed by the user on the page
  // THEN request for notification permissions
  // See: https://linear.app/clovis/issue/CLVS-2054/firebase-error
  return new Promise((resolve, reject) => {
    document.addEventListener(
      "click",
      () => {
        Notification.requestPermission()
          .then((permission) => {
            resolve(permission === "granted");
          })
          .catch(reject);
      },
      { once: true }
    );
  });
}

/*
 * Retrieve firebase notification sent when we are in focus
 */
async function onPushMessageListener() {
  try {
    const isMessagingSupported = await isSupported();
    if (!isMessagingSupported) {
      throw new Error("messaging not supported in this window");
    }
    const app = getApp();
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      console.info("Message received: ", payload);
      // Like on "mobiles" platform, user will not see webpush notifications pop-up
      // when the tab is focused by default, but only when it's on background.
      // If you DO WANT to have webpush notifications pop-up to the user when he's browsing
      // and focused on your app, uncomment the following piece of code:
      // ---------------------
      // if ("serviceWorker" in navigator) {
      //   void navigator.serviceWorker
      //     .getRegistration(FIREBASE_SW_REGISTRATION_PATH)
      //     .then((registration) => {
      //       if (registration && payload?.notification?.title) {
      //         // Note that contrary as when the notification is in background
      //         // cliking on it here (when in foreground) will not cause the user to be redirected
      //         // This is a standard best practice to avoid disturbing the current user navigation
      //         // for now, I don't know if we can bypass this limitation
      //         return registration.showNotification(
      //           payload.notification.title,
      //           { body: payload.notification.body ?? "" }
      //         );
      //       }
      //     });
      // }
    });
  } catch (err) {
    captureException(new Error(JSON.stringify(err)));
  }
}

/*
 * Retrieve firebase device token
 */
async function getFirebaseToken() {
  try {
    const isMessagingSupported = await isSupported();
    if (!isMessagingSupported) {
      throw new Error("messaging not supported in this window");
    }
    const registration = await navigator.serviceWorker.getRegistration(
      FIREBASE_SW_REGISTRATION_PATH
    );
    if (!registration) {
      throw new Error("registration cannot be found");
    }
    const app = getFirebaseApp();
    const messaging = getMessaging(app);

    const token = await getToken(messaging, {
      serviceWorkerRegistration: registration,
      vapidKey: env.VITE_FIREBASE_VAPIDKEY!,
    });
    return token;
  } catch (err) {
    captureException(new Error(JSON.stringify(err)));
  }
}

export {
  getFirebaseToken,
  hasNotificationPermission,
  initServiceWorker,
  onPushMessageListener,
};
