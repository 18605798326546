import type {
  Auth0ContextInterface,
  LogoutUrlOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { env } from "~/config/env";

function getNativeAppRedirectUri(path: string, searchParams?: URLSearchParams) {
  // window.appInfos will be set when capacitor is initialized
  const APP_ID = window.appInfos?.id ?? "dev.clovis.app";
  if (searchParams?.toString()) {
    return `${APP_ID}://${
      env.VITE_AUTH0_DOMAIN
    }/capacitor/${APP_ID}/callback${path}?${searchParams?.toString()}`;
  }
  return `${APP_ID}://${env.VITE_AUTH0_DOMAIN}/capacitor/${APP_ID}/callback${path}`;
}

function canUseNative() {
  try {
    const isNativePlatform = Capacitor.isNativePlatform();
    if (isNativePlatform) {
      const canUseBrowser = Capacitor.isPluginAvailable("Browser");
      // For this plugin we must append the "Plugin" suffix to the plugin name as the plugin itself is badly named
      const canUseCache = Capacitor.isPluginAvailable("SecureStoragePlugin");
      return canUseBrowser && canUseCache;
    }
  } catch (_) {
    // Do nothing
  }
  return false;
}

function buildNativeAuthorizeUrlFactory(
  buildAuthorizeUrl: Auth0ContextInterface["buildAuthorizeUrl"]
) {
  const nativeBuildAuthorizeUrl = async (
    opts?: RedirectLoginOptions
  ): Promise<string> => {
    const authorizeUrl = await buildAuthorizeUrl(opts);
    const url = new URL(authorizeUrl);
    // On mobile, we add the current app https base url to allow auth0 action to know which url
    // to hit for his webhooks
    url.searchParams.append("webhook_origin", env.VITE_APP_BASE_URL);
    return url.href;
  };
  return nativeBuildAuthorizeUrl;
}

function buildNativeLogoutUrlFactory(
  buildLogoutUrl: Auth0ContextInterface["buildLogoutUrl"]
) {
  const nativeBuildLogoutUrl = (opts?: LogoutUrlOptions): string => {
    const authorizeUrl = buildLogoutUrl(opts);
    const url = new URL(authorizeUrl);
    // On mobile, we add the current app https base url to allow auth0 action to know which url
    // to hit for his webhooks
    url.searchParams.append("webhook_origin", env.VITE_APP_BASE_URL);
    return url.href;
  };
  return nativeBuildLogoutUrl;
}

export {
  buildNativeAuthorizeUrlFactory,
  buildNativeLogoutUrlFactory,
  canUseNative,
  getNativeAppRedirectUri,
};
