import type {
  IProjectNotification,
  IProjectNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import {
  IProjectNotificationTypePrefix,
  ProjectNotificationNamespace,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";

interface IProjectMisc {
  id: string;
}

interface IProjectMiscNotificationPayload extends IProjectNotificationPayload {
  project: IProjectMisc;
}

const IProjectMiscNotificationTypePrefix =
  `${IProjectNotificationTypePrefix}/${ProjectNotificationNamespace.Misc}` as const;
interface IProjectMiscNotification extends IProjectNotification {
  type: `${typeof IProjectMiscNotificationTypePrefix}/${string}`;
  readonly payload: IProjectMiscNotificationPayload;
}

export type { IProjectMiscNotification, IProjectMiscNotificationPayload };
export { IProjectMiscNotificationTypePrefix };
