import * as React from "react";
import { Box } from "~/design-system/Box";
import { createComponentHook } from "~/types";
import { Infos } from "./Infos";
import { UpdateCategoryForm } from "./UpdateCategoryForm/UpdateCategoryForm";
import type { CategoriesList_ItemFragment } from "./UpdateCategoryForm/UpdateCategoryForm.graphql";

const useUpdateCategoryForm = createComponentHook(() => {
  const [editMode, setEditMode] = React.useState(false);

  const enableForm = () => {
    setEditMode(true);
  };

  const disableForm = () => {
    setEditMode(false);
  };

  return {
    actions: {
      disableForm,
      enableForm,
    },
    state: {
      editMode,
    },
  };
});

type CategoriesListItemProps = {
  category: CategoriesList_ItemFragment;
  orgId: string;
};

function CategoriesListItem(props: CategoriesListItemProps) {
  const { actions, state } = useUpdateCategoryForm();

  return (
    <Box marginBottom="small">
      {state.editMode ? (
        <UpdateCategoryForm
          category={props.category}
          orgId={props.orgId}
          onSuccess={actions.disableForm}
        />
      ) : (
        <Infos category={props.category} onEditClick={actions.enableForm} />
      )}
    </Box>
  );
}

export { CategoriesListItem };
