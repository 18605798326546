import { Color } from "@clovis/server/src/app/config/colors/colors";
import type { TFunction } from "react-i18next";

function getColorsOptions(
  t: TFunction
): Array<{ label: string; value: Color }> {
  return [
    { label: t("enums.colors.red", "Red"), value: Color.Red },
    { label: t("enums.colors.orange", "Orange"), value: Color.Orange },
    { label: t("enums.colors.amber", "Amber"), value: Color.Amber },
    { label: t("enums.colors.yellow", "Yellow"), value: Color.Yellow },
    { label: t("enums.colors.lime", "Lime"), value: Color.Lime },
    { label: t("enums.colors.green", "Green"), value: Color.Green },
    { label: t("enums.colors.emerald", "Emerald"), value: Color.Emerald },
    { label: t("enums.colors.teal", "Teal"), value: Color.Teal },
    { label: t("enums.colors.cyan", "Cyan"), value: Color.Cyan },
    { label: t("enums.colors.sky", "Sky"), value: Color.Sky },
    { label: t("enums.colors.blue", "Blue"), value: Color.Blue },
    { label: t("enums.colors.indigo", "Indigo"), value: Color.Indigo },
    { label: t("enums.colors.violet", "Violet"), value: Color.Violet },
    { label: t("enums.colors.purple", "Purple"), value: Color.Purple },
    { label: t("enums.colors.fuchsia", "Fuchsia"), value: Color.Fuchsia },
    { label: t("enums.colors.pink", "Pink"), value: Color.Pink },
    { label: t("enums.colors.rose", "Rose"), value: Color.Rose },
    { label: t("enums.colors.gray", "Gray"), value: Color.Gray },
    { label: t("enums.colors.dark", "Dark"), value: Color.Dark },
    { label: t("enums.colors.brown", "Brown"), value: Color.Brown },
  ];
}

export { getColorsOptions };
