import type * as Types from '../../../../../../../../schema.graphql';

import { gql } from 'urql';
import { GroupsList_ItemFragmentDoc } from './components/UpdateGroupForm/UpdateGroupForm.graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GroupsList_DataQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GroupsList_DataQuery = { orgs_by_pk?: { id: string, project_groups: Array<{ id: string, name: string, color: string, avatar?: string | null | undefined }> } | null | undefined };


export const GroupsList_DataDocument = gql`
    query GroupsList_data($orgId: uuid!) {
  orgs_by_pk(id: $orgId) {
    id
    project_groups {
      ...GroupsList_item
    }
  }
}
    ${GroupsList_ItemFragmentDoc}`;

export function useGroupsList_DataQuery(options: Omit<Urql.UseQueryArgs<GroupsList_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GroupsList_DataQuery>({ query: GroupsList_DataDocument, ...options });
};