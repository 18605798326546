import * as React from "react";
import tw from "twin.macro";
import { css } from "~/config/stitches";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { Text } from "../Text";
import {
  CheckboxItemWrapper,
  SecondWrapper,
} from "./components/CheckboxItemWrapper";
import { CheckboxLabel } from "./components/CheckboxLabel";
import { CheckboxWrapper } from "./components/CheckboxWrapper";

type CheckboxItemProps = {
  value: string;
  checkboxGroupName: string;
  label: React.ReactNode;
  onChange?:
    | React.ComponentProps<typeof Checkbox>["onCheckedChange"]
    | undefined;
  checked?: boolean;
  description?: React.ReactNode;
  children?: React.ReactNode;
  variant?: "list" | "simple";
  tone?: "critical" | "positive";
  disabled?: boolean;
  required?: boolean;
  dataIntercomTarget: string;
};

// TODO add Badge to label when existing
const CheckboxItem = React.forwardRef(function CheckboxItem(
  props: CheckboxItemProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const [checked, setChecked] = React.useState(props.checked ?? false);
  const onCheckedChange: React.ComponentProps<
    typeof Checkbox
  >["onCheckedChange"] = (checked) => {
    const isChecked = checked === "indeterminate" ? false : checked;
    setChecked(isChecked);
    if (props.onChange) {
      props.onChange(isChecked);
    }
  };

  // TODO: find a better way because it's triggering the onChange method twice
  // const triggerChecked: React.MouseEventHandler<HTMLDivElement> = (e) => {
  //   e.preventDefault();
  //   if (!props.disabled && checkBtn?.current) {
  //     checkBtn.current.click();
  //     checkBtn.current.focus();
  //   }
  // };
  return (
    <CheckboxItemWrapper
      variant={props.variant}
      checked={checked}
      // onClick={triggerChecked}
    >
      <SecondWrapper variant={props.variant}>
        <CheckboxWrapper disabled={props.disabled}>
          <Checkbox
            id={props.value}
            value={props.value}
            name={props.checkboxGroupName}
            disabled={props.disabled}
            onCheckedChange={onCheckedChange}
            checked={checked}
            required={props.required}
            tone={props.tone}
            ref={ref}
          />
          <CheckboxLabel
            htmlFor={props.value}
            data-intercom-target={props.dataIntercomTarget}
          >
            <Text
              size="standard"
              weight="regular"
              className={
                props.variant == "list" && checked
                  ? css({ ...tw`text-indigo-900` })()
                  : props.disabled === true
                  ? css({ ...tw`text-neutral-500` })()
                  : ""
              }
            >
              {props.label}
            </Text>
          </CheckboxLabel>
        </CheckboxWrapper>
        <Box css={tw`ml-7`}>
          <Text
            size="small"
            tone="neutral"
            className={
              props.variant == "list" && checked
                ? css({ ...tw`text-indigo-700` })()
                : ""
            }
          >
            {props.description}
          </Text>
          <Box
            display={checked && !props.disabled ? "block" : "none"}
            className="nestedContent"
          >
            {props.children}
          </Box>
        </Box>
      </SecondWrapper>
    </CheckboxItemWrapper>
  );
});

export { CheckboxItem };
export type { CheckboxItemProps };
