import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import * as React from "react";
import type { CellProps } from "react-table";
import type { UserBadgeWorkerStatus } from "~/config/user/workerstatus/utils/types";
import { Icon } from "~/design-system/Icon";
import type { ListItemProps } from "~/design-system/List";
import { ListItem } from "~/design-system/List";
import { Spinner } from "~/design-system/Spinner/Spinner";
import { VirtualList } from "~/screens/App/components/VirtualList";
import { WorkerStatusListItemSubtitle } from "./WorkerStatusListItemSubtitle";

// Approximative height of a row for virtualized list and scrolling
const LIST_ITEM_HEIGHT = 74;
type WorkerStatus = {
  status: UserBadgeWorkerStatus["status"];
  event_id: UserBadgeWorkerStatus["event_id"];
  title: ListItemProps["title"];
  subtitle: ListItemProps["subtitle"];
  created_at: string;
  updated_at: string;
  percentDone: number;
};

type WorkersStatusVirtualListProps = {
  data: Array<WorkerStatus>;
  onClose: () => void;
};

type WorkerStatusListItemProps = {
  data: WorkerStatus;
  onClose: () => void;
};

function WorkerStatusListItemAvatar(props: { status: WorkerStatus["status"] }) {
  if (props.status === "PENDING") {
    return <Spinner size="small" tone="info" />;
  }
  if (props.status === "SUCCESS") {
    return (
      <Icon label="success-icon" color="brandAccent" size="small">
        <CheckCircleIcon />
      </Icon>
    );
  }
  if (props.status === "ERROR") {
    return (
      <Icon label="error-icon" color="critical" size="small">
        <XCircleIcon />
      </Icon>
    );
  }
  if (props.status === "TIMEOUT") {
    return (
      <Icon label="timeout-icon" color="caution" size="small">
        <ClockIcon />
      </Icon>
    );
  }
  return null;
}

function WorkerStatusListItem(props: WorkerStatusListItemProps) {
  return (
    <ListItem
      avatar={<WorkerStatusListItemAvatar status={props.data.status} />}
      title={props.data.title}
      subtitle={
        <WorkerStatusListItemSubtitle
          text={props.data.subtitle}
          createdAt={props.data.created_at}
          updatedAt={props.data.updated_at}
          percentDone={props.data.percentDone}
          status={props.data.status}
        />
      }
    />
  );
}

function WorkersStatusVirtualList(props: WorkersStatusVirtualListProps) {
  const { onClose } = props;
  return (
    <VirtualList
      css={{
        borderRadius: 0,
        boxShadow: "none",
        flexGrow: 1,
        overflowY: "auto",
      }}
      data={props.data}
      item={{
        Cell: (props: CellProps<WorkerStatus>) => (
          <WorkerStatusListItem
            data={props.cell.row.original}
            onClose={onClose}
          />
        ),
        id: "workerStatus",
      }}
      estimateSize={() => LIST_ITEM_HEIGHT}
      initialState={{}}
      rowProps={{}}
    />
  );
}

export type { WorkersStatusVirtualListProps };
export { WorkersStatusVirtualList };
