import type { State, Store } from "@uppy/core";
import type { State as TState } from "zustand";
import create from "zustand";
import type { FileMeta } from "./types";

interface UppyStoreState extends State<FileMeta>, TState {}

function createStore() {
  // @ts-expect-error Empty store
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const useStore = create<UppyStoreState>(() => {});

  const store: Store = {
    getState: () => useStore.getState() as State,
    setState: (patch?: Partial<UppyStoreState>) => {
      useStore.setState({
        ...useStore.getState(),
        ...patch,
      });
    },
    subscribe: (listener) => {
      return useStore.subscribe((nextState, prevState) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        listener(prevState, nextState);
      });
    },
  };

  return { store, useStore };
}

export type { UppyStoreState };

export { createStore };
