// Type of channel
enum ChannelTypeEnum {
  DirectMessage = "direct-message",
  Org = "org",
  Project = "project",
  Task = "task",
  FileApproval = "file-approval",
}

enum TaskTreatmentChatMessagesEnum {
  Done = "done",
  Undone = "undone",
  UndoneOrg = "undoneOrg",
}

enum MessagesTypesEnum {
  Validation = "validation", // validation or unvalidation messages,
  DataUpdate = "data-update", // all updates of a task for example or an approval update for an example, or archiving / deleting (TODO)
}

// Visibility of channel
enum ChannelVisibilityEnum {
  Private = "private",
  Public = "public",
}

// Notification mode for new message (create or not a notification)
enum NotificationModeEnum {
  All = "all",
  Direct = "direct",
  Mute = "mute",
}

// The context in which the direct message has been sent (from a project / org / ...) page
enum DirectMessageContextEnum {
  Project = "project",
}

// Limit numbers of included members in each event
// Above this, you'll need to do api call to retrieve the other members
const LIMIT_MEMBERS_EVENT = 50;

export {
  ChannelTypeEnum,
  ChannelVisibilityEnum,
  DirectMessageContextEnum,
  LIMIT_MEMBERS_EVENT,
  MessagesTypesEnum,
  NotificationModeEnum,
  TaskTreatmentChatMessagesEnum,
};
