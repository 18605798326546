import * as React from "react";
import { useNavigate } from "react-router";
import { isPendingOnboardingWorkerStatus } from "~/config/user/workerstatus/utils/filters";
import { useWorkerStatusContext } from "~/config/user/workerstatus/WorkerStatus";
import { useRedirectAfterLogin as useRedirect } from "~/screens/App/utils/useRedirectAfterLogin/useRedirectAfterLogin";

type RedirectAfterLoginProps = {
  children: React.ReactNode;
};

const useRedirectAfterLogin = () => {
  const { currentUser, redirect } = useRedirect();
  const navigate = useNavigate();
  const { getWorkerStatus, hasPending } = useWorkerStatusContext();

  React.useEffect(() => {
    // If pending onboarding worker status => redirect to /onboarding screen
    if (hasPending) {
      const hasPendingOnboarding = getWorkerStatus(
        isPendingOnboardingWorkerStatus
      );
      if (hasPendingOnboarding.length) {
        navigate("/onboarding", { replace: true });
      }
    }
  }, [hasPending, getWorkerStatus, navigate]);

  React.useEffect(() => {
    // Apply custom redirection after login
    redirect();
  }, [redirect, currentUser]);
};

function RedirectAfterLogin(props: RedirectAfterLoginProps) {
  useRedirectAfterLogin();

  return <>{props.children}</>;
}

export { RedirectAfterLogin };
