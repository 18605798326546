import { LockClosedIcon } from "@heroicons/react/solid";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { Icon, IconSize } from "../../Icon";
import type { IconProps } from "../../Icon/Icon";
import type { FileIconProps } from "../FileIcon";
import { FileIconSize } from "../FileIconConstants";

const fileSizeToIconSize = new Map([
  [FileIconSize.LARGE, IconSize.XSMALL],
  [FileIconSize.MEDIUM, IconSize.XXSMALL],
  [FileIconSize.SMALL, IconSize.XXSMALL],
  [FileIconSize.XLARGE, IconSize.SMALL],
]);

const FileIconLockWrapper = styled("div", {
  backgroundColor: "$white",
  borderRadius: "50% 50% 0 0",
  bottom: 0,
  position: "absolute",
  right: 0,
});

type FileIconLockProps = Pick<FileIconProps, "size"> & {
  css?: CSS;
  color: IconProps["color"];
};

const FileIconLock = (props: FileIconLockProps) => {
  return (
    <FileIconLockWrapper>
      <Icon
        label={`lock`}
        size={fileSizeToIconSize.get(
          props.size as (typeof FileIconSize)[keyof typeof FileIconSize]
        )}
        color={props.color}
        css={props.css}
      >
        <LockClosedIcon />
      </Icon>
    </FileIconLockWrapper>
  );
};

export { FileIconLock };
