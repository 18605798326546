enum TemplateTypeId {
  Generic = "generic",
  Architect = "architect",
  RealEstate = "real_estate",
  WoodBuilder = "wood_builder",
  Electrician = "electrician",
  Photovoltaic = "photovoltaic",
  Plumber = "plumber",
  PhotovoltaicSimple = "photovoltaic_simple",
}

export { TemplateTypeId };
