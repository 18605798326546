import * as React from "react";
import tw from "twin.macro";
import { Box } from "../../../../Box";
import { Icon } from "../../../../Icon";

export const LeadingIcon = (props: { leadingIcon: React.ReactNode }) => (
  <Box
    left={0}
    position="absolute"
    paddingLeft="small"
    display="flex"
    alignItems="center"
    pointerEvents="none"
    css={tw`inset-y-0`}
  >
    <Icon color="neutral" label="leading icon">
      {props.leadingIcon}
    </Icon>
  </Box>
);
