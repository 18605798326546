/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Slot } from "nano-slots";
import * as React from "react";
import { Box } from "~/design-system/Box";
import { AppBar } from "./components/AppBar";
import { Content } from "./components/Content";
import { SideBar } from "./components/SideBar/SideBar";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout(props: LayoutProps) {
  return (
    <Box
      css={{
        height: "100vh",
      }}
    >
      <Box position="absolute" top="0" width="full" zIndex="sticky">
        <Slot name="Layout.Banner" />
      </Box>
      <Box
        css={{
          "& > *": {
            /* 
            in grid, the width auto adjust on content, so we should set min-width: 0 for all children
            to prevent their width to be higher than 100% if their content can stretch
            see https://css-tricks.com/preventing-a-grid-blowout/
            */
            minWidth: 0,
          },
          /* This helps our todesktop app to be draggable. If we experience problems with clicks / drags, check this fix: https://github.com/electron/electron/issues/1354 */
          WebkitAppRegion: "drag",
          display: "grid",
          gap: "$3",
          gridTemplateColumns: "$appbar 1fr",
          gridTemplateRows: "$appbar 1fr",
          height: "100%",
          padding: "$3",
        }}
      >
        <AppBar
          css={{
            gridColumn: "1 / -1",
            gridRow: "1 / 2",
            position: "relative",
            zIndex: 3,
          }}
        />
        <SideBar
          css={{
            gridColumn: "1 / 2",
            gridRow: "2 / 5",
            zIndex: 1,
          }}
        />
        <Content
          css={{
            WebkitAppRegion: "no-drag",
            gridColumn: "2 / -1",
            gridRow: "2 / 5",
            position: "relative",
          }}
        >
          {props.children}
        </Content>
        {/* remove footer for now on desktop
        <Footer css={{ gridColumn: "1 / -1", gridRow: "4" }} />
        */}
      </Box>
    </Box>
  );
}

export { Layout };
export type { LayoutProps };
