import {
  Color,
  colorToHexValues,
} from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { StylesConfig } from "react-select";
import { getColorsOptions } from "~/config/colors";
import type {
  UseFormRegisterReturn,
  UseFormReturn,
} from "~/config/react-hook-form";
import { Controller } from "~/config/react-hook-form";
import { Select } from "~/config/react-select";

type ColorOption = {
  value: Color;
  label: string;
};

type SelectColorInputProps = UseFormRegisterReturn & {
  control: UseFormReturn<any>["control"];
  withoutLabel?: boolean;
  defaultColor?: Color;
};

const SelectColorInput = React.forwardRef<
  HTMLSelectElement,
  SelectColorInputProps
>(function SelectColorInput(props: SelectColorInputProps, ref) {
  const { t } = useTranslation();

  const colorOptions = getColorsOptions(t);

  const dot = (colorName: Color = Color.Gray, withoutLabel?: boolean) => ({
    ":before": {
      backgroundColor: colorToHexValues.get(colorName)!.base,
      borderRadius: "50%",
      content: '" "',
      display: "block",
      height: "1rem",
      marginRight: "0.5rem",
      minWidth: "1rem",
      width: "1rem",
    },
    alignItems: "center",
    display: "flex",
    ...{ fontSize: withoutLabel ? 0 : "inherit" },
  });

  const colourStyles = (
    withoutLabel?: boolean
  ): StylesConfig<ColorOption, false> => ({
    container: (styles) => ({
      ...styles,
      ...{
        width: withoutLabel ? "5rem" : "auto",
      },
    }),
    option: (styles, { data }) => ({
      ...styles,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      ...dot(data.value, withoutLabel),
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.value, withoutLabel),
    }),
  });

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        const selectValue = colorOptions.filter(
          (option) => option.value === value
        );

        /* Set gray as default */
        const defaultColor = props.defaultColor ?? Color.Gray;

        const defaultColorOption = colorOptions.filter(
          (option) => option.value === defaultColor
        );

        return (
          <Select
            value={selectValue.length > 0 ? selectValue : defaultColorOption}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            ref={ref as any}
            onChange={(color) => onChange(color?.value)}
            options={colorOptions}
            styles={colourStyles(props.withoutLabel)}
          />
        );
      }}
    />
  );
});

export type { SelectColorInputProps };
export { SelectColorInput };
