import { IconSize } from "../Icon";

const FolderIconSize = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  XLARGE: "xlarge",
} as const;

const folderSizeToIconSize = new Map([
  [FolderIconSize.LARGE, IconSize.XSMALL],
  [FolderIconSize.MEDIUM, IconSize.XXSMALL],
  [FolderIconSize.SMALL, IconSize.XXSMALL],
  [FolderIconSize.XLARGE, IconSize.SMALL],
]);

export { FolderIconSize, folderSizeToIconSize };
