import { CheckIcon, TrashIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DialogTrigger } from "~/design-system/Dialog";
import { Form } from "~/design-system/Form";
import { TextFieldInput } from "~/design-system/TextField";
import { SelectColorInput } from "~/screens/App/components/SelectColorField/components/SelectColorInput";
import { DeleteConfirmationDialog } from "~/screens/App/screens/Project/components/ProjectTeamsDrawer/components/TeamsList/components/UpdateTeamForm/DeleteConfirmationDialog";
import { createComponentHook } from "~/types";
import { validationSchema } from "./update-category.validation";
import type { CategoriesList_ItemFragment } from "./UpdateCategoryForm.graphql";
import {
  useDeleteProjectCategoryMutation,
  useUpdateProjectCategoryMutation,
} from "./UpdateCategoryForm.graphql";

const useUpdateCategoryForm = createComponentHook(
  (props: UpdateCategoryFormProps) => {
    const { t } = useTranslation();
    const [, updateProjectCategory] = useUpdateProjectCategoryMutation();
    const [, deleteProjectCategory] = useDeleteProjectCategoryMutation();

    const form = useForm(validationSchema, {
      defaultValues: {
        color: props.category.color,
        name: props.category.name,
        projectCategoryId: props.category.id,
      },
    });

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data, error } = await updateProjectCategory(
        {
          input,
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.updateProjectCategory?.__typename ===
        "UpdateProjectCategorySuccess"
      ) {
        toast.success(
          t(
            "screens.OrgProjectCategoriesDrawer.UpdateCategoryForm.updateProjectCategorySuccessToast",
            "Your category has been updated"
          )
        );
        props.onSuccess?.();
      } else {
        captureException(error);
        toast.error(
          t(
            "screens.OrgProjectCategoriesDrawer.UpdateCategoryForm.updateProjectCategoryErrorsToast",
            "Something went wrong while updating the category."
          )
        );
      }
    });

    const handleRemove = async () => {
      const { data, error } = await deleteProjectCategory(
        {
          input: {
            projectCategoryId: props.category.id,
          },
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.deleteProjectCategory?.__typename ===
        "DeleteProjectCategorySuccess"
      ) {
        toast.success(
          t(
            "screens.OrgProjectCategoriesDrawer.UpdateCategoryForm.deleteProjectCategorySuccessToast",
            "Your category has been deleted"
          )
        );
      } else {
        captureException(error);
        toast.error(
          t(
            "screens.OrgProjectCategoriesDrawer.UpdateCategoryForm.deleteProjectCategoryErrorsToast",
            "Something went wrong while deleting the category."
          )
        );
      }
    };

    return {
      actions: {
        handleRemove,
        handleSubmit,
        registerInput: form.register,
      },
      state: {
        control: form.control,
        errors: form.formState.errors,
      },
      t,
    };
  }
);

type UpdateCategoryFormProps = {
  category: CategoriesList_ItemFragment;
  orgId: string;
  onSuccess?: () => void;
};

function UpdateCategoryForm(props: UpdateCategoryFormProps) {
  const { actions, state, t } = useUpdateCategoryForm(props);

  return (
    <Form onSubmit={actions.handleSubmit}>
      <Columns space="small">
        <Column>
          <Columns space="small">
            <Column>
              <TextFieldInput
                {...actions.registerInput("name")}
                placeholder={t(
                  "screens.OrgProjectCategoriesDrawer.UpdateCategoryForm.namePlaceholder",
                  "Name"
                )}
                required
                error={state.errors.name?.message}
              />
            </Column>
            <Column width="content">
              <SelectColorInput
                {...actions.registerInput("color")}
                control={state.control}
              />
            </Column>
          </Columns>
        </Column>
        <Column width="content">
          <Columns space="small">
            <Column>
              <DeleteConfirmationDialog onConfirm={actions.handleRemove}>
                <DialogTrigger asChild>
                  <Button
                    type="button"
                    variant="transparent"
                    circular
                    dataIntercomTarget="org-project-categories-update-delete"
                  >
                    <TrashIcon />
                  </Button>
                </DialogTrigger>
              </DeleteConfirmationDialog>
            </Column>
            <Column>
              <Button
                tone="brandAccent"
                variant="solid"
                circular
                dataIntercomTarget="org-project-categories-update-check"
              >
                <CheckIcon />
              </Button>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Form>
  );
}

export { UpdateCategoryForm };
