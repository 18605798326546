import * as React from "react";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import { useCategoriesList_DataQuery } from "./CategoriesList.graphql";
import { CategoriesListItem } from "./components/CategoriesListItem";
import { CreateCategoryForm } from "./components/CreateCategoryForm/CreateCategoryForm";

const useCategoriesList = createComponentHook((props: CategoriesListProps) => {
  const queryContext = React.useMemo(
    () => ({ additionalTypenames: ["orgs"] }),
    []
  );
  const [{ data }] = useCategoriesList_DataQuery({
    context: queryContext,
    variables: { orgId: props.orgId },
  });

  return {
    state: {
      project_categories: data?.orgs_by_pk?.project_categories,
    },
  };
});

type CategoriesListProps = {
  orgId: string;
};

function CategoriesList(props: CategoriesListProps) {
  const { state } = useCategoriesList(props);

  return (
    <Box padding="gutter">
      <Stack space="small">
        {state.project_categories?.map((category) => (
          <CategoriesListItem
            key={category.id}
            orgId={props.orgId}
            category={category}
          />
        ))}
        <CreateCategoryForm orgId={props.orgId} />
      </Stack>
    </Box>
  );
}

export { CategoriesList };
