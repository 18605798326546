import tw from "twin.macro";
import { styled } from "~/config/stitches";

const Inline = styled("div", {
  ...tw`flex flex-wrap flex-row`,

  defaultVariants: {
    align: "left",
    alignY: "center",
    width: "full",
  },

  variants: {
    align: {
      around: tw`justify-around`,
      between: tw`justify-between`,
      center: tw`justify-center`,
      left: tw`justify-start`,
      right: tw`justify-end`,
    },
    alignY: {
      baseline: tw`items-baseline`,
      bottom: tw`items-end`,
      center: tw`items-center`,
      top: tw`items-start`,
    },
    gap: {
      gutter: {
        gap: "$gutter",
      },
      large: {
        gap: "$large",
      },
      medium: {
        gap: "$medium",
      },
      small: {
        gap: "$small",
      },
      xlarge: {
        gap: "$xlarge",
      },
      xsmall: {
        gap: "$xsmall",
      },
      xxsmall: {
        gap: "$xxsmall",
      },
    },
    noWrap: {
      true: {
        flexFlow: "nowrap",
      },
    },
    space: {
      gutter: {
        "& > *": {
          marginLeft: "$gutter",
          marginTop: "$gutter",
        },
        marginLeft: "$-gutter",
        marginTop: "$-gutter",
      },
      large: {
        "& > *": {
          marginLeft: "$large",
          marginTop: "$large",
        },
        marginLeft: "$-large",
        marginTop: "$-large",
      },
      medium: {
        "& > *": {
          marginLeft: "$medium",
          marginTop: "$medium",
        },
        marginLeft: "$-medium",
        marginTop: "$-medium",
      },
      small: {
        "& > *": {
          marginLeft: "$small",
          marginTop: "$small",
        },
        marginLeft: "$-small",
        marginTop: "$-small",
      },
      xlarge: {
        "& > *": {
          marginLeft: "$xlarge",
          marginTop: "$xlarge",
        },
        marginLeft: "$-xlarge",
        marginTop: "$-xlarge",
      },
      xsmall: {
        "& > *": {
          marginLeft: "$xsmall",
          marginTop: "$xsmall",
        },
        marginLeft: "$-xsmall",
        marginTop: "$-xsmall",
      },
      xxlarge: {
        "& > *": {
          marginLeft: "$xxlarge",
          marginTop: "$xxlarge",
        },
        marginLeft: "$-xxlarge",
        marginTop: "$-xxlarge",
      },
      xxsmall: {
        "& > *": {
          marginLeft: "$xxsmall",
          marginTop: "$xxsmall",
        },
        marginLeft: "$-xxsmall",
        marginTop: "$-xxsmall",
      },
    },
    width: {
      auto: tw`w-auto`,
      full: tw`w-full`,
    },
  },
});

type InlineProps = React.ComponentProps<typeof Inline>;

export type { InlineProps };
export { Inline };
