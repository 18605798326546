import { TemplateTypeId as Template } from "@clovis/server/src/app/domain/project/template/types";
import type { TFunction } from "i18next";

type ProjectTemplatesOptions = {
  name: string;
  value: Template;
}[];

function getProjectTemplatesOptions(t: TFunction): ProjectTemplatesOptions {
  return [
    {
      name: t("enums.projectTemplate.generic", "Generic"),
      value: Template.Generic,
    },
    {
      name: t("enums.projectTemplate.architect", "Architect"),
      value: Template.Architect,
    },
    {
      name: t("enums.projectTemplate.real_estate", "RealEstate"),
      value: Template.RealEstate,
    },
    {
      name: t("enums.projectTemplate.wood_builder", "WoodBuilder"),
      value: Template.WoodBuilder,
    },
    {
      name: t("enums.projectTemplate.electrician", "Electrician"),
      value: Template.Electrician,
    },
    {
      name: t("enums.projectTemplate.photovoltaic", "Photovoltaic"),
      value: Template.Photovoltaic,
    },
    {
      name: t("enums.projectTemplate.plumber", "Plumber"),
      value: Template.Plumber,
    },
    {
      name: t(
        "enums.projectTemplate.photovoltaic_simple",
        "PhotovoltaicSimple"
      ),
      value: Template.PhotovoltaicSimple,
    },
  ];
}

function getI18nTemplate(template: Template, t: TFunction) {
  return getProjectTemplatesOptions(t).find(
    (option) => option.value === template
  )?.name;
}

export { getI18nTemplate };
