import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { OrgProfileDrawer } from "~/screens/App/screens/Org/components/OrgProfileDrawer/OrgProfileDrawer";
import { OrgBadge } from "./OrgBadge";

type OrgBadgeButtonProps = Pick<
  BadgeProps,
  "children" | "onRemove" | "size"
> & {
  avatar?: string | null;
  orgId: string;
  onCloseDrawer?: () => void;
};

const OrgBadgeButton = (props: OrgBadgeButtonProps) => {
  const { avatar, orgId, ...rest } = props;

  return (
    <OrgProfileDrawer orgId={orgId} onClose={props.onCloseDrawer}>
      <DrawerTrigger asChild>
        <Button
          variant="text"
          css={{
            display: "inline-flex",
            justifyContent: "start",
            maxWidth: "100%",
            minHeight: "fit-content",
          }}
          dataIntercomTarget="badges-org-open"
        >
          <OrgBadge clickable avatar={avatar} {...rest}>
            {props.children}
          </OrgBadge>
        </Button>
      </DrawerTrigger>
    </OrgProfileDrawer>
  );
};

export { OrgBadgeButton };
