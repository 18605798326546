const enum Permission {
  RemoveFromOrganization = "RemoveFromOrganization",
  UpdateOrgRole = "UpdateOrgRole",
  UpdateOrgMember = "UpdateOrgMember",
  UpdateOrgProfile = "UpdateOrgProfile",
  OrgInvitations = "OrgInvitations",
  CreateProjectCategory = "CreateProjectCategory",
  CreateProjectGroup = "CreateProjectGroup",
  UpdateProjectCategory = "UpdateProjectCategory",
  UpdateProjectGroup = "UpdateProjectGroup",
  DeleteProjectCategory = "DeleteProjectCategory",
  DeleteProjectGroup = "DeleteProjectGroup",
  GenerateOrgApiToken = "GenerateOrgApiToken",
  JoinOtherOrgMemberProject = "JoinOtherOrgMemberProject",
  CreateOrgProject = "CreateOrgProject",
  PrepareOrgAssetForUpload = "PrepareOrgAssetForUpload",
  PrepareProjectSpreadsheetForUpload = "PrepareProjectSpreadsheetForUpload",
  UpdateOrgUserInfos = "UpdateOrgUserInfos",
  UpdateOrgProjectSummary = "UpdateOrgProjectSummary",
  CreateOrgFilters = "CreateOrgFilters",
}

const PermissionList = [
  "RemoveFromOrganization",
  "UpdateOrgRole",
  "UpdateOrgMember",
  "UpdateOrgProfile",
  "GenerateOrgApiToken",
  "OrgInvitations",
  "CreateProjectCategory",
  "CreateProjectGroup",
  "DeleteProjectGroup",
  "UpdateProjectGroup",
  "UpdateProjectCategory",
  "DeleteProjectCategory",
  "JoinOtherOrgMemberProject",
  "CreateOrgProject",
  "PrepareOrgAssetForUpload",
  "PrepareProjectSpreadsheetForUpload",
  "UpdateOrgUserInfos",
  "UpdateOrgProjectSummary",
  "CreateOrgFilters",
] as const;

export { Permission, PermissionList };
