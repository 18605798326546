import { Listbox } from "@headlessui/react";
import React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { createComponentHook } from "~/types";
import { useFileFoldersNavigatorContext } from "../../../../FilesFoldersNavigatorContext";
import { ButtonArrow } from "./components/ButtonArrow";
import type { NavOptionProps } from "./components/NavOption";
import { NavOption } from "./components/NavOption";
import { OptionContent } from "./components/OptionContent";
import { SelectButton } from "./components/SelectButton";
import { SelectOptions } from "./components/SelectOptions";

type NavSelectProps = {
  options: Array<NavOptionProps>;
};

const useNavSelect = createComponentHook((props: NavSelectProps) => {
  const { folderId, navigate } = useFileFoldersNavigatorContext((state) => ({
    folderId: state.folderId,
    navigate: state.navigate,
  }));

  const [selectedOption, setSelectedOption] = React.useState<
    NavOptionProps | undefined
  >(() => props.options.find((option) => option.to === folderId));

  React.useLayoutEffect(() => {
    const selected = props.options.find((option) => option.to === folderId);
    // Make sure a new item is selected before re-rendering to avoid component blinking
    if (selected) {
      setSelectedOption(selected);
    }
  }, [props.options, folderId]);

  const handleChange = (option: NavOptionProps): void => {
    setSelectedOption(option);
    navigate(option.to);
  };

  return {
    actions: { handleChange },
    state: {
      selectedOption,
    },
  };
});

const SelectWrapper = styled("div", {
  ...tw`mt-1 relative w-full`,
});

function NavSelect(props: NavSelectProps) {
  const { actions, state } = useNavSelect(props);

  const NavSelectOptions = () => (
    <SelectOptions>
      {props.options.map((option) => (
        <NavOption key={option.to} {...option} />
      ))}
    </SelectOptions>
  );

  return (
    <Listbox value={state.selectedOption} onChange={actions.handleChange}>
      <SelectWrapper>
        <SelectButton>
          {state.selectedOption && <OptionContent {...state.selectedOption} />}
          <ButtonArrow />
        </SelectButton>
        <NavSelectOptions />
      </SelectWrapper>
    </Listbox>
  );
}

export { NavSelect };
