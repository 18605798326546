import * as React from "react";
import { useBreakpoint } from "~/design-system/hooks";
import { createComponentHook } from "~/types";
import { useFileFoldersNavigatorContext } from "../../FilesFoldersNavigatorContext";
import { TabletAndBelowBreadcrumb } from "./components/TabletAndBelowBreadcrumb";

type FolderProps = {
  name: string;
  id: string;
  strongNotification?: number;
  weakNotif: boolean;
};

type BreadcrumbProps = {
  folders: Array<FolderProps>;
};

const useBreadcrumb = createComponentHook(() => {
  const folderId = useFileFoldersNavigatorContext((state) => state.folderId);
  const { breakpoint } = useBreakpoint();

  return {
    state: {
      actualFolderId: folderId,
      breakpoint,
    },
  };
});

const getFoldersToDisplay = ({
  actualFolderId,
  folders,
}: {
  folders: Array<FolderProps>;
  actualFolderId?: string;
}): Array<FolderProps> => {
  if (actualFolderId) {
    const actualFolderIndex = folders.findIndex((f) => f.id === actualFolderId);
    return folders.slice(0, actualFolderIndex + 1);
  } else {
    return folders;
  }
};

function Breadcrumb(props: BreadcrumbProps) {
  const { state } = useBreadcrumb();

  const foldersToDisplay = getFoldersToDisplay({
    actualFolderId: state.actualFolderId,
    folders: props.folders,
  });

  return <TabletAndBelowBreadcrumb foldersToDisplay={foldersToDisplay} />;
}

export type { BreadcrumbProps, FolderProps };
export { Breadcrumb };
