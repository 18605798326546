import * as React from "react";
import { Box } from "../Box/Box";
import { useBreakpoint } from "../hooks";

const UploadProgressBarLayoutWrapper = (props: {
  children: React.ReactNode;
}) => {
  const { breakpoint } = useBreakpoint();
  return (
    <Box
      style={{
        left: "50%",
        position: "fixed",
        top: breakpoint === "mobile" ? "1.5rem" : "3.5rem",
        transform: "translateX(-50%)",
        width: breakpoint === "mobile" ? "80%" : "40%",
        zIndex: 9999, // Progress bar needs to be displayed above everything. It may create some bad UI on mobile opening a file, but that's a really rare case
      }}
    >
      {props.children}
    </Box>
  );
};

export { UploadProgressBarLayoutWrapper };
