/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Color,
  colorToHexValues,
} from "@clovis/server/src/app/config/colors/colors";
import React from "react";
import { keyframes, styled } from "~/config/stitches";

const rotation = keyframes({
  from: { transform: "rotate(0deg)" },
  to: { transform: "rotate(359deg)" },
});

const SpinnerBody = styled("span", {
  animation: `${rotation} 0.6s linear infinite`,
  borderRadius: "50%",
  borderStyle: "solid",
  boxSizing: "border-box",
  defaultVariants: {
    ring: "info",
  },
  display: "block",
  left: 0,
  position: "absolute",
  top: 0,
  variants: {
    ring: {
      caution: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.Orange)?.plain.background,
      },
      critical: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.Red)?.plain.background,
      },
      info: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.Blue)?.plain.background,
      },
      lightWhite: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.White)?.plain.background,
      },
      neutral: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.Gray)?.plain.background,
      },
      positive: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.Emerald)?.plain.background,
      },
      white: {
        borderColor: "transparent",
        borderTopColor: colorToHexValues.get(Color.White)?.plain.background,
      },
    },
  },
});

const SpinnerWrapper = styled("div", {
  defaultVariants: {
    size: "medium",
  },
  display: "contents",
  variants: {
    size: {
      large: {
        height: "3.125rem",
        width: "3.125rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "3.125rem",
          strokeLinecap: "round",
          width: "3.125rem",
        },
      },
      medium: {
        height: "2.625rem",
        width: "2.625rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "2.625rem",
          strokeLinecap: "round",
          width: "2.625rem",
        },
      },
      small: {
        height: "2rem",
        width: "2rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "2rem",
          strokeLinecap: "round",
          width: "2rem",
        },
      },
      xlarge: {
        height: "3.5rem",
        width: "3.5rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "3.5rem",
          strokeLinecap: "round",
          width: "3.5rem",
        },
      },
      xsmall: {
        height: "1.5rem",
        width: "1.5rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "1.5rem",
          strokeLinecap: "round",
          width: "1.5rem",
        },
      },
      xxsmall: {
        height: "1rem",
        width: "1rem",
        [`& ${SpinnerBody}`]: {
          borderWidth: "3px",
          height: "1rem",
          strokeLinecap: "round",
          width: "1rem",
        },
      },
    },
  },
});

type SpinnerProps = React.ComponentProps<typeof SpinnerBody> &
  React.ComponentProps<typeof SpinnerWrapper>;

const Spinner = (props: SpinnerProps) => {
  const { ring, size } = props;

  return (
    <SpinnerWrapper size={size}>
      <SpinnerBody ring={ring} />
    </SpinnerWrapper>
  );
};

export { Spinner };
