import React from "react";
import type { LinkProps } from "react-router-dom";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Icon } from "~/design-system/Icon";
import { Text } from "~/design-system/Text";
import { useFileFoldersNavigatorContext } from "../../../../../FilesFoldersNavigatorContext";

type OptionContentProps = {
  icon?: React.ReactNode;
  notifBadge?: React.ReactNode;
  selected?: boolean;
  active?: boolean;
  children: React.ReactNode;
  to: string;
  target?: string;
  displayOnlySpan?: boolean;
};

const DisplayOnlyOptionContentWrapper = styled("span", {
  ...tw`py-2 pl-3 pr-9`,

  "&": {
    alignItems: "center",
    display: "flex",
    height: "$buttonHeight",
  },

  defaultVariants: {
    active: false,
  },

  variants: {
    active: {
      false: tw`text-neutral-700`,
      true: tw`bg-neutral-100 text-neutral-900`,
    },
    selected: {
      false: tw`text-gray-500 hover:text-gray-700`,
      true: tw`text-gray-700`,
    },
  },
});

// We don't want to have anything to do with react-router-dom
// Since we are handling the navigation on our own in this component.
const FakeLink = (props: LinkProps & { to: string }) => {
  const { children, to, ...rest } = props;
  const navigate = useFileFoldersNavigatorContext((state) => state.navigate);
  return (
    <a {...rest} onClick={() => navigate(to)}>
      {children}
    </a>
  );
};

const OptionContentWrapper = styled(FakeLink, {
  ...tw`py-2 pl-3 pr-9`,

  "&": {
    alignItems: "center",
    display: "flex",
    height: "$buttonHeight",
  },

  defaultVariants: {
    active: false,
  },

  variants: {
    active: {
      false: tw`text-neutral-700`,
      true: tw`bg-neutral-100 text-neutral-900`,
    },
    selected: {
      false: tw`text-gray-500 hover:text-gray-700`,
      true: tw`text-gray-700`,
    },
  },
});

const NotificationWrapper = styled("div", {
  "&": {
    display: "flex",
    marginLeft: "0.5rem",
  },
});

function OptionContent(props: OptionContentProps) {
  return (
    <>
      {props.displayOnlySpan ? (
        <DisplayOnlyOptionContentWrapper
          active={props.active}
          selected={props.selected}
        >
          {props.icon && (
            <Icon css={{ marginRight: "0.5rem" }} label="options icon">
              {props.icon}
            </Icon>
          )}
          <Text truncate weight={props.selected ? "strong" : "regular"}>
            {props.children}
          </Text>
          {props.notifBadge && (
            <NotificationWrapper>{props.notifBadge}</NotificationWrapper>
          )}
        </DisplayOnlyOptionContentWrapper>
      ) : (
        <OptionContentWrapper
          active={props.active}
          selected={props.selected}
          to={props.to}
          target={props.target}
        >
          {props.icon && (
            <Icon css={{ marginRight: "0.5rem" }} label="options icon">
              {props.icon}
            </Icon>
          )}
          <Text truncate weight={props.selected ? "strong" : "regular"}>
            {props.children}
          </Text>
          {props.notifBadge && (
            <NotificationWrapper>{props.notifBadge}</NotificationWrapper>
          )}
        </OptionContentWrapper>
      )}
    </>
  );
}

export { OptionContent };
