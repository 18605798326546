import { LightningBoltIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "~/screens/App/components/CloseButton";

function DrawerCloseButton() {
  return (
    <DrawerClose asChild>
      <CloseButton dataIntercomTarget="worker-status-details-close" />
    </DrawerClose>
  );
}

function DrawerHeader() {
  const { t } = useTranslation();

  return (
    <Box padding="gutter" css={{ height: "$drawerHeader" }}>
      <Stack space="medium">
        <Inline width="full" align="between">
          <Heading level="3">
            <Inline>
              <Icon label="eyeicon" css={{ marginRight: "$small" }}>
                <LightningBoltIcon />
              </Icon>
              {t(
                "WorkerStatusDetailsDrawer.DrawerHeader.title",
                "Pending processes"
              )}
            </Inline>
          </Heading>
          <DrawerCloseButton />
        </Inline>
        <Text>
          {t(
            "WorkerStatusDetailsDrawer.DrawerHeader.message",
            "The status of your long running processes on clovis"
          )}
        </Text>
      </Stack>
    </Box>
  );
}

export { DrawerHeader };
