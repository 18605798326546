import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import * as React from "react";
import tw from "twin.macro";
import { Box } from "../../Box";
import { Icon } from "../../Icon";
import { Text } from "../../Text";

type CheckboxGroupMessageProps = {
  message?: React.ReactNode;
  tone?: "critical" | "neutral" | "positive";
};

export const CheckboxGroupMessage = (props: CheckboxGroupMessageProps) => (
  <Box paddingTop="small" display="flex" alignItems="center">
    {props.tone === "critical" && (
      <Icon color="critical" size="small" label="exclamation icon">
        <ExclamationCircleIcon />
      </Icon>
    )}
    {props.tone === "positive" && (
      <Icon color="positive" size="small" label="check icon">
        <CheckCircleIcon />
      </Icon>
    )}
    <Text tone={props.tone} css={tw`pl-1`} size="small">
      {props.message}
    </Text>
  </Box>
);
