import type * as Types from '../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateProjectMutationVariables = Types.Exact<{
  input: Types.CreateProjectInput;
}>;


export type CreateProjectMutation = { createProject?: { __typename: 'CreateProjectErrors' } | { __typename: 'CreateProjectSuccess', project_id: string } | null | undefined };

export type CopyProjectMutationVariables = Types.Exact<{
  input: Types.CopyProjectInput;
}>;


export type CopyProjectMutation = { copyProject?: { __typename: 'CopyProjectErrors' } | { __typename: 'CopyProjectSuccess', project_id: string } | null | undefined };


export const CreateProjectDocument = gql`
    mutation createProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    __typename
    ... on CreateProjectSuccess {
      project_id
    }
  }
}
    `;

export function useCreateProjectMutation() {
  return Urql.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument);
};
export const CopyProjectDocument = gql`
    mutation copyProject($input: CopyProjectInput!) {
  copyProject(input: $input) {
    __typename
    ... on CopyProjectSuccess {
      project_id
    }
  }
}
    `;

export function useCopyProjectMutation() {
  return Urql.useMutation<CopyProjectMutation, CopyProjectMutationVariables>(CopyProjectDocument);
};