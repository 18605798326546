import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { Slot } from "nano-slots";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DrawerTrigger } from "~/design-system/Drawer";
import { isDesktopApp, useBreakpoint } from "~/design-system/hooks";
import { NavigationDrawerButton } from "~/screens/App/components/NavigationDrawer/components/NavigationDrawerButton/NavigationDrawerButton";
import { NavigationDrawer } from "~/screens/App/components/NavigationDrawer/NavigationDrawer";
import { createComponentHook } from "~/types";

type AppBarProps = {
  css?: CSS;
};

const useAppBar = createComponentHook(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [historyDelta, setHistoryDelta] = React.useState<number>();
  const [displayNextButton, setDisplayNextButton] = React.useState(false);
  const { breakpoint } = useBreakpoint();

  const showNavigationArrows = isDesktopApp() || breakpoint !== "desktop";

  const onPrevClick = () => {
    /* navigate back */
    navigate(-1);
    /* save history delta for first time */
    if (!historyDelta) {
      setHistoryDelta(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.history.length - window.history.state.idx
      );
    }
    /* display next button if not displayed */
    if (!displayNextButton) {
      setDisplayNextButton(true);
    }
  };

  const checkHistoryForNextButtonDisplay = (actualDelta: number) => {
    /* if original delta equal actual delta, that means user is back to last route, so remove next button */
    if (historyDelta && historyDelta === actualDelta) {
      setDisplayNextButton(false);
    }
  };

  const onNextClick = () => {
    /* navigate forward */
    navigate(1);
    checkHistoryForNextButtonDisplay(
      /* use actual delta - 1 because of navigation */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      window.history.length - window.history.state.idx - 1
    );
  };

  /* on user navigation */
  React.useEffect(() => {
    /* check for next button display */
    if (displayNextButton) {
      checkHistoryForNextButtonDisplay(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.history.length - window.history.state.idx
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    actions: {
      onNextClick,
      onPrevClick,
    },
    state: {
      displayNextButton,
      showNavigationArrows,
    },
  };
});

function AppBar(props: AppBarProps) {
  const { actions, state } = useAppBar();
  return (
    <Box css={props.css} paddingTop="safe">
      <Columns alignY="center" space="small">
        <Column width="content">
          <NavigationDrawer>
            <DrawerTrigger asChild>
              <NavigationDrawerButton />
            </DrawerTrigger>
          </NavigationDrawer>
        </Column>
        <Column width="content">
          {state.showNavigationArrows && (
            <Button
              variant="transparent"
              size="small"
              circular={true}
              dataIntercomTarget="layout-nav-prev"
              onClick={actions.onPrevClick}
              /* disabled prev button if index equals 0, meaning there is no prev route */
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              disabled={window.history.state?.idx === 0}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          {state.showNavigationArrows && state.displayNextButton && (
            <Button
              variant="transparent"
              size="small"
              circular={true}
              dataIntercomTarget="layout-nav-next"
              onClick={actions.onNextClick}
            >
              <ArrowRightIcon />
            </Button>
          )}
        </Column>
        <Column>
          <Slot name="Layout.Tabs" />
        </Column>
        <Column width="content">
          {location.pathname !== "/dashboard/calendar" && (
            <Slot name="Layout.Actions" />
          )}
          <Box>
            <Slot name="Layout.PrimaryAction" />
          </Box>
        </Column>
      </Columns>
    </Box>
  );
}

export { AppBar };
