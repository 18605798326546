import * as React from "react";
import { ConnexionBadge } from "~/design-system/ConnexionBadge";
import { Heading } from "~/design-system/Heading";
import { Inline } from "~/design-system/Inline";

type TitleProps = {
  name: string;
  active: boolean;
};

export function Title(props: TitleProps) {
  return (
    <Inline space="small">
      <Heading level="2">{props.name}</Heading>
      <ConnexionBadge active={props.active} />
    </Inline>
  );
}
