import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectTeamsMultiSelect_TeamFragment = { id: string, name: string, color: string };

export type GetProjectTeamsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
}>;


export type GetProjectTeamsQuery = { projects_by_pk?: { teams: Array<{ id: string, name: string, color: string }> } | null | undefined };

export const ProjectTeamsMultiSelect_TeamFragmentDoc = gql`
    fragment ProjectTeamsMultiSelect_team on teams {
  id
  name
  color
}
    `;
export const GetProjectTeamsDocument = gql`
    query getProjectTeams($projectId: uuid!) {
  projects_by_pk(id: $projectId) {
    teams {
      ...ProjectTeamsMultiSelect_team
    }
  }
}
    ${ProjectTeamsMultiSelect_TeamFragmentDoc}`;

export function useGetProjectTeamsQuery(options: Omit<Urql.UseQueryArgs<GetProjectTeamsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectTeamsQuery>({ query: GetProjectTeamsDocument, ...options });
};