import * as React from "react";
import { Dialog } from "~/design-system/Dialog";
import { createComponentHook } from "~/types";
import { UpdateTeamMembersDialogContent } from "./components/UpdateTeamMembersDialogContent";

const useUpdateTeamMembersDialog = createComponentHook(() => {
  const [open, setOpen] = React.useState(false);

  return {
    actions: {
      setOpen,
    },
    state: {
      open,
    },
  };
});

type UpdateTeamMembersDialogProps = {
  children: React.ReactNode;
  teamId: string;
  teamName: string;
};

function UpdateTeamMembersDialog(props: UpdateTeamMembersDialogProps) {
  const { actions, state } = useUpdateTeamMembersDialog();
  return (
    <Dialog open={state.open} setOpen={actions.setOpen}>
      {props.children}
      <UpdateTeamMembersDialogContent
        teamId={props.teamId}
        onSuccess={() => actions.setOpen(false)}
        teamName={props.teamName}
      />
    </Dialog>
  );
}

export { UpdateTeamMembersDialog };
