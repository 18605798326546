import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { SelectFieldProps } from "~/design-system/SelectField";
import { SelectField } from "~/design-system/SelectField";
import { createComponentHook } from "~/types";

type SelectLanguageFieldProps = Omit<SelectFieldProps, "children">;

const useSelectLanguageField = createComponentHook(() => {
  const { t } = useTranslation();
  const availableLanguages = [
    {
      languageName: t("languages.english", "English"),
      value: Language.EN,
    },
    {
      languageName: t("languages.french", "French"),
      value: Language.FR,
    },
    {
      languageName: t("languages.german", "German"),
      value: Language.DE,
    },
    {
      languageName: t("languages.spanish", "Spanish"),
      value: Language.ES,
    },
    {
      languageName: t("languages.dutch", "Dutch"),
      value: Language.NL,
    },
    {
      languageName: t("languages.portuguese", "Portuguese"),
      value: Language.PT,
    },
  ];

  return {
    state: {
      availableLanguages,
    },
  };
});

const SelectLanguageField = React.forwardRef<
  HTMLSelectElement,
  SelectLanguageFieldProps
>(function SelectLanguageField(props: SelectLanguageFieldProps, ref) {
  const { state } = useSelectLanguageField();
  return (
    <SelectField {...props} ref={ref}>
      {state.availableLanguages.map((language) => (
        <option key={language.value} value={language.value}>
          {language.languageName}
        </option>
      ))}
    </SelectField>
  );
});

export { SelectLanguageField };
