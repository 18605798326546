import * as React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import type { UserBadgeNotifications } from "~/config/user/user-notifications/utils/types";
import { Box } from "~/design-system/Box";
import { useBreakpoint } from "~/design-system/hooks";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { getDisplayNotificationProps } from "~/screens/App/components/NotificationsBadge/helpers";
import { createComponentHook } from "~/types";
import { AllNotificationsBadge } from "../../../NotificationsBadge/AllNotificationsBadge";
import { filterExcludeProjectBadge } from "../../../NotificationsBadge/AllNotificationsButActualProjectBadge";
import { useNavigationDrawer } from "../../NavigationDrawer";
import { AboveMobileButton } from "./components/AboveMobileButton";
import { MobileButton } from "./components/MobileButton";

const useNavigationDrawerButton = createComponentHook(() => {
  const { breakpoint } = useBreakpoint();
  const location = useLocation();
  const { t } = useTranslation();
  const navigationDrawerHook = useNavigationDrawer();
  const notifications = useUserNotificationsContext(
    (state) => state.notifications
  );
  const { activeOrg, activeProjectId, projects, user } =
    navigationDrawerHook.state;
  let filteredNotifications: UserBadgeNotifications = [];

  let showOrgAvatar = false;

  const defaultMenuText = t(
    "NavigationDrawer.NavigationDrawerButton.defaultMenuText",
    "Menu"
  );

  if (
    matchPath("/org/*", location.pathname) ||
    matchPath("/dashboard/*", location.pathname) ||
    matchPath("/pilot/*", location.pathname) ||
    (activeProjectId && activeProjectId === activeOrg?.project?.id)
  ) {
    showOrgAvatar = true;
  }

  let textToDisplay, imageURL, imageAlt;

  if (showOrgAvatar && activeOrg) {
    // on org screens
    textToDisplay = activeOrg.name;
    // Get all notifications
    filteredNotifications = notifications;
    if (activeProjectId && activeProjectId === activeOrg?.project?.id) {
      filteredNotifications = notifications.filter((n) =>
        filterExcludeProjectBadge(n, activeProjectId)
      );
    }
    imageURL = activeOrg.avatar;
    imageAlt = activeOrg.name;
  } else if (activeProjectId) {
    // on project screens
    const activeProject = projects.find((p) => p?.id === activeProjectId);
    // Get all notifications but the actual project notifications if we are on a project page
    filteredNotifications = notifications.filter((n) =>
      filterExcludeProjectBadge(n, activeProjectId)
    );
    textToDisplay = activeProject?.name ?? defaultMenuText;
    imageURL = activeProject?.avatar;
    imageAlt = activeProject?.name;
  } else {
    // on other screens not dealt yet
    textToDisplay = defaultMenuText;
    imageURL = user?.avatar;
    imageAlt = user?.full_name;
  }

  if (matchPath("/dashboard/*", location.pathname)) {
    // on dashboard screen
    textToDisplay = t(
      "NavigationDrawer.NavigationDrawerButton.dashboard",
      "Dashboard"
    );
  }

  if (location.pathname.includes("user/profile")) {
    // on user profile screen
    textToDisplay = t(
      "NavigationDrawer.NavigationDrawerButton.myAccount",
      "My account"
    );
  }

  // TODO check URL + button text
  if (location.pathname.includes("user/history")) {
    // on user history screen
    textToDisplay = t(
      "NavigationDrawer.NavigationDrawerButton.myHistory",
      "My history"
    );
  }
  if (location.pathname.includes("user/notifications")) {
    // on user notifications screen
    textToDisplay = t(
      "NavigationDrawer.NavigationDrawerButton.myNotifications",
      "My notifications"
    );
    // Get all notifications if we are on the "My notifications" page
    filteredNotifications = notifications;
  }

  // From our filtered notifications, get ready to use strong count and weak notifs
  const { strongNotificationsCount, weakNotif } = getDisplayNotificationProps(
    filteredNotifications
  );

  return {
    state: {
      breakpoint,
      imageAlt,
      imageURL,
      strongNotificationsCount,
      textToDisplay,
      weakNotif,
    },
  };
});

const NavigationDrawerButton = React.forwardRef<HTMLButtonElement>(
  function NavigationDrawerButton(props, ref) {
    const { state } = useNavigationDrawerButton();

    if (state.breakpoint === "mobile") {
      return (
        <MobileButton
          ref={ref}
          {...props}
          imageURL={state.imageURL}
          imageAlt={state.imageAlt}
          textToDisplay={state.textToDisplay}
          notif={
            <Box
              position="absolute"
              css={{
                right: 0,
                top: 0,
              }}
            >
              <AllNotificationsBadge />
            </Box>
          }
        />
      );
    }
    return (
      <AboveMobileButton
        ref={ref}
        {...props}
        imageURL={state.imageURL}
        imageAlt={state.imageAlt}
        textToDisplay={state.textToDisplay}
        notif={
          state.strongNotificationsCount || state.weakNotif ? (
            <Box css={{ marginRight: "$2" }}>
              <DisplayNotification
                strongNotification={state.strongNotificationsCount}
                weakNotif={state.weakNotif}
              />
            </Box>
          ) : null
        }
      />
    );
  }
);

export { NavigationDrawerButton };
