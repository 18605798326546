import { countries as countriesDatabase } from "countries-list";
import { parsePhoneNumber } from "libphonenumber-js";
import * as React from "react";
import { useEffect } from "react";
import { getCountryFromBrowser } from "~/config/i18next/helpers";
import { useUser } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import type { CountryCode } from "~/design-system/CountriesSelector";
import { CountriesSelector } from "~/design-system/CountriesSelector";
import { createComponentHook } from "~/types";
import { AroundInput, Input } from "../../Field";
import type { Tones } from "../../tokens";

type PhoneFieldInputProps = {
  name: string;
  /* defaultCountryCode is only used if there is no value */
  defaultCountryCode?: CountryCode;
  onChange: (value: string) => void;
  value?: string | null;
  error?: string;
  placeholder?: string;
  tone?: Tones.critical | Tones.neutral | Tones.positive;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
};

const actualCountryReducer = (
  _: string,
  action: {
    newCountryCode: CountryCode;
    phoneValue: string;
    newCountryDialCode: string;
    previousCountryDialCode: string;
    onChange: (value: string) => void;
  }
): CountryCode => {
  const splitter = action.phoneValue.startsWith(
    `+${action.previousCountryDialCode}`
  )
    ? `+${action.previousCountryDialCode}`
    : action.phoneValue.startsWith(`+${action.newCountryDialCode}`)
    ? `+${action.newCountryDialCode}`
    : "+";
  const numberWithoutPreviousCountryDialCode = action.phoneValue
    .split(splitter)
    .pop();
  const numberWithNewCountryDialCode = `+${action.newCountryDialCode}${
    numberWithoutPreviousCountryDialCode ?? ""
  }`;
  action.onChange(numberWithNewCountryDialCode);
  return action.newCountryCode;
};

const getCountryCodeFromPhone = (phone: string): CountryCode | undefined => {
  const countriesCodes = Object.keys(countriesDatabase) as Array<CountryCode>;
  let tempCountryCodeFromPhone;
  try {
    tempCountryCodeFromPhone = parsePhoneNumber(phone)
      ?.country as CountryCode | null;
  } catch (error) {
    tempCountryCodeFromPhone = null;
  }
  /* assign to countryCodeFromPhone only if countryCode is included in countries list */
  if (
    tempCountryCodeFromPhone &&
    countriesCodes.includes(tempCountryCodeFromPhone)
  ) {
    return tempCountryCodeFromPhone;
  }
};

const usePhoneFieldInput = createComponentHook(
  (props: PhoneFieldInputProps) => {
    const { user } = useUser();
    /* determine country code from phone if props.value is defined */
    let countryCodeFromPhone;
    if (props.value?.length) {
      countryCodeFromPhone = getCountryCodeFromPhone(props.value);
    }

    /* if countryCodeFromPhone is undefined, 
    take defaultCountryCode, 
    or take user country (computed field based on user_metadatas country_code (user managed) ?? user_locations country_code (from last connexion)), 
    or countryCode from browser  */
    const countryCode =
      // because defaultCountryCode can be defined but empty
      /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
      countryCodeFromPhone ||
      props.defaultCountryCode ||
      (user?.country_code as CountryCode) ||
      getCountryFromBrowser().countryCode;

    const [actualCountry, dispatchActualCountry] = React.useReducer(
      actualCountryReducer,
      countryCode
    );

    const handleInputChange = (newValue: string) => {
      const resultPhone = changeNumberValue({
        countryDialCode: countriesDatabase[actualCountry].phone,
        number: newValue,
      });
      props.onChange(resultPhone);
    };

    const changeNumberValue = ({
      countryDialCode,
      number,
    }: {
      number: string;
      countryDialCode: string;
    }) => {
      let formattedNumber;
      // Just keep "+" and numbers, remove everything else (-, ., spaces etc.)
      formattedNumber = number.match(/[+\d]/g)?.join("");

      if (formattedNumber) {
        if (!formattedNumber.startsWith("+")) {
          // Country code is not set (e.g. +33)
          if (formattedNumber.startsWith("0")) {
            // Remove "0"
            formattedNumber = formattedNumber.substring(1);
          }
          formattedNumber = `+${countryDialCode}${formattedNumber}`;
        }

        // Detect country code from formatted phone number
        let countryCodeDetected: CountryCode | null;
        try {
          countryCodeDetected = parsePhoneNumber(formattedNumber)
            ?.country as CountryCode | null;
        } catch (error) {
          countryCodeDetected = null;
        }

        // If a country code is detected (and different from previous), change the country flag
        if (countryCodeDetected && countryCodeDetected !== actualCountry) {
          dispatchActualCountry({
            newCountryCode: countryCodeDetected,
            newCountryDialCode: countriesDatabase[countryCodeDetected].phone,
            onChange: props.onChange,
            phoneValue: formattedNumber,
            previousCountryDialCode: actualCountry,
          });
        }
      }

      return formattedNumber ?? "";
    };

    /* If a value is still provided in the import, run the prefix formating */
    useEffect(() => {
      // Check if the value is neither null, undefined, nor an empty string
      if (
        props.value !== "" &&
        props.value !== undefined &&
        props.value !== null
      ) {
        handleInputChange(props.value);
      }
    }, []);

    return {
      actions: {
        changeNumberValue,
        dispatchActualCountry,
        handleInputChange,
      },
      state: {
        actualCountry,
      },
    };
  }
);

const PhoneFieldInput = React.forwardRef<
  HTMLInputElement,
  PhoneFieldInputProps
>(function PhoneFieldInput(props, _) {
  const { actions, state } = usePhoneFieldInput(props);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onChangeSelectedCountry = (newCountryCode: CountryCode) => {
    inputRef.current?.focus();
    actions.dispatchActualCountry({
      newCountryCode,
      newCountryDialCode: countriesDatabase[newCountryCode].phone,
      onChange: props.onChange,
      phoneValue: props.value ?? "",
      previousCountryDialCode: countriesDatabase[state.actualCountry].phone,
    });
  };

  return (
    <AroundInput>
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        display="flex"
        alignItems="center"
        width="touchable"
      >
        <CountriesSelector
          disabled={props.disabled ?? props.readOnly}
          actualCountry={state.actualCountry}
          onChange={onChangeSelectedCountry}
        />
      </Box>
      <Input
        style={{ paddingLeft: "4rem", zIndex: 3 }}
        type="tel"
        id={props.name}
        ref={(instance: HTMLInputElement) => {
          /* TODO
            ref(instance)
          */
          inputRef.current = instance;
        }}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value === null ? undefined : props.value}
        onChange={(e) => actions.handleInputChange(e.target.value)}
        tone={props.tone}
        required={props.required}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        readOnly={props.readOnly}
        autoComplete={props.autoComplete}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    </AroundInput>
  );
});

export type { PhoneFieldInputProps };
export { getCountryCodeFromPhone, PhoneFieldInput };
