import { ChatIcon, CheckIcon, XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Card } from "~/design-system/Card";
import type { CircledIconProps } from "~/design-system/CircledIcon";
import { CircledIcon } from "~/design-system/CircledIcon";
import { DialogClose, DialogContent } from "~/design-system/Dialog";
import { Heading } from "~/design-system/Heading";
import { Paragraph } from "~/design-system/Paragraph";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import type { AddApprovalFormProps } from "./components/AddApprovalForm/AddApprovalForm";
import { AddApprovalForm } from "./components/AddApprovalForm/AddApprovalForm";

function DialogCloseButton() {
  return (
    <DialogClose asChild>
      <Box display="flex" justifyContent="flexEnd" width="full">
        <Button
          css={{ padding: 0 }}
          circular
          size="standard"
          color="neutral"
          variant="transparent"
          iconLabel="dismiss"
          dataIntercomTarget="file-viewer-add-approval-close"
        >
          <XIcon />
        </Button>
      </Box>
    </DialogClose>
  );
}

type AddApprovalDialogContentProps = {
  fileVersionId?: AddApprovalFormProps["fileVersionId"];
  fileVersionIds?: AddApprovalFormProps["fileVersionIds"];
  mode: AddApprovalFormProps["mode"];
  onSuccess?: AddApprovalFormProps["onSuccess"];
  projectId: string;
};

const useAddApprovalDialogContent = createComponentHook(
  (props: AddApprovalDialogContentProps) => {
    const { t } = useTranslation();
    let title, subtitle, icon, iconTone: CircledIconProps["tone"], iconLabel;
    switch (props.mode) {
      case "deny":
        iconLabel = t(
          "screens.Projects.Docs.AddApprovalDialogContent.deny",
          "deny"
        );
        iconTone = "critical";
        icon = <ChatIcon />;
        title = t(
          "screens.Projects.Docs.AddApprovalDialogContent.addComments",
          "Add comments"
        );
        subtitle = t(
          "screens.Projects.Docs.AddApprovalDialogContent.addCommentsRefusal",
          "Add the comments you want to add to your refusal."
        );
        break;
      case "validate":
        iconLabel = t(
          "screens.Projects.Docs.AddApprovalDialogContent.validate",
          "validate"
        );
        iconTone = "brandAccent";
        icon = <CheckIcon />;
        title = t(
          "screens.Projects.Docs.AddApprovalDialogContent.confirmApproval",
          "Confirm approval"
        );
        subtitle = t(
          "screens.Projects.Docs.AddApprovalDialogContent.confirmApprovalText",
          "Please confirm that you want to approve this version of the document"
        );
        break;
      case "validateWithComments":
        iconLabel = t(
          "screens.Projects.Docs.AddApprovalDialogContent.validateWithComments",
          "validate with comments"
        );
        iconTone = "caution";
        icon = <ChatIcon />;
        title = t(
          "screens.Projects.Docs.AddApprovalDialogContent.addComments",
          "Add comments"
        );
        subtitle = t(
          "screens.Projects.Docs.AddApprovalDialogContent.addCommentsApproval",
          "Add the comments you want to add with your approval."
        );
        break;
      case "nc":
        iconLabel = t(
          "screens.Projects.Docs.AddApprovalDialogContent.notConcerned",
          "not concerned"
        );
        iconTone = "brandAccent";
        icon = <XIcon />;
        title = t(
          "screens.Projects.Docs.AddApprovalDialogContent.confirmNotConcerned",
          "Confirm Not Concerned"
        );
        subtitle = t(
          "screens.Projects.Docs.AddApprovalDialogContent.addCommentsNotConcerned",
          "The document stackholders will be notified that you're not concerned about this version of the document."
        );
        break;
    }
    return {
      state: {
        icon,
        iconLabel,
        iconTone,
        subtitle,
        title,
      },
    };
  }
);

function AddApprovalDialogContent(props: AddApprovalDialogContentProps) {
  const { state } = useAddApprovalDialogContent(props);
  return (
    <DialogContent
      css={{ maxWidth: "$md" }}
      size="regular"
      stopClickPropagation
    >
      <Card css={{ padding: "$4" }}>
        <Stack space="small" align="center">
          <Stack>
            <DialogCloseButton />
            <CircledIcon
              tone={state.iconTone}
              iconLabel={state.iconLabel}
              icon={state.icon}
            />
          </Stack>
          <Stack space="xsmall" align="center">
            <Heading level="4" weight="medium">
              {state.title}
            </Heading>
            <Box textAlign="center">
              <Paragraph size="small" tone="secondary">
                {state.subtitle}
              </Paragraph>
            </Box>
          </Stack>
          <AddApprovalForm
            fileVersionId={props.fileVersionId}
            fileVersionIds={props.fileVersionIds}
            mode={props.mode}
            onSuccess={props.onSuccess}
            projectId={props.projectId}
          />
        </Stack>
      </Card>
    </DialogContent>
  );
}

export { AddApprovalDialogContent, DialogCloseButton };
