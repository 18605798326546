import "dayjs/locale/en";
import "dayjs/locale/fr";
import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.locale(Language.EN);

function isInFuture(date: Date | number | string) {
  return !dayjs().isAfter(date);
}

// Convert a JS Date to an ISO8601 string (without fraction seconds e.g. '2020-04-02T08:02:17-05:00')
function formatDateToTimestamptzString(date: Date | null | undefined) {
  return date ? dayjs(date).format() : date;
}

function setHoursOnDateValue(
  date: Date | null | undefined,
  time: string | null | undefined
) {
  if (date && time?.includes(":")) {
    const [hours, minutes] = time.split(":");
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
  }

  return date;
}

export {
  dayjs,
  formatDateToTimestamptzString,
  isInFuture,
  setHoursOnDateValue,
};
