import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Inline } from "../Inline/Inline";
import { Text } from "../Text/Text";

type ProgressBarProps = {
  uploadPercentage: number;
  text?: React.ReactNode;
  color?: "caution" | "critical" | "info" | "positive" | "white";
};

const ProgressBarWrapper = styled("div", {
  ...tw`flex-1 relative flex items-center`,
});

const BaseBar = styled("div", {
  ...tw`
  h-2 
  w-full 
  bg-neutral-200
  rounded	
  absolute`,
});

const MainBar = styled("div", {
  ...tw`
  h-2
  rounded
  absolute`,
  defaultVariants: {
    color: "positive",
  },
  variants: {
    color: {
      caution: tw`bg-caution-500`,
      critical: tw`bg-critical-500`,
      info: tw`bg-info-500`,
      positive: tw`bg-positive-500`,
      white: tw`bg-white`,
    },
  },
});

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <Inline>
      <ProgressBarWrapper>
        <BaseBar />
        <MainBar
          color={props.color}
          style={{ width: `${props.uploadPercentage}%` }}
        />
      </ProgressBarWrapper>
      <Text
        size="xsmall"
        tone="neutral"
        style={{ lineHeight: "1.5rem", paddingLeft: "1rem" }}
      >
        {props.text ?? `${props.uploadPercentage}%`}
      </Text>
    </Inline>
  );
};

export type { ProgressBarProps };
export { ProgressBar };
