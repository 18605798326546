import { CheckIcon, TrashIcon } from "@heroicons/react/outline";
import * as React from "react";
import { AllowedTo } from "react-abac";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import type { FileMeta } from "~/config/uppy";
import { uppy } from "~/config/uppy";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DialogTrigger } from "~/design-system/Dialog";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextFieldInput } from "~/design-system/TextField";
import { SelectColorInput } from "~/screens/App/components/SelectColorField/components/SelectColorInput";
import { Permission } from "~/screens/App/screens/Org/Org.authorize";
import { DeleteConfirmationDialog } from "~/screens/App/screens/Project/components/ProjectTeamsDrawer/components/TeamsList/components/UpdateTeamForm/DeleteConfirmationDialog";
import { createComponentHook } from "~/types";
import { AvatarGroupField } from "../AvatarGroupField";
import { usePrepareProjectGroupAssetForUploadMutation } from "../CreateGroupForm/CreateGroupForm.graphql";
import { validationSchema } from "./update-group.validation";
import type { GroupsList_ItemFragment } from "./UpdateGroupForm.graphql";
import {
  useDeleteProjectGroupMutation,
  useUpdateProjectGroupMutation,
} from "./UpdateGroupForm.graphql";

const useUpdateGroupForm = createComponentHook(
  (props: UpdateGroupFormProps) => {
    const { t } = useTranslation();
    const [groupAvatar, setGroupAvatar] = React.useState<
      ArrayBuffer | File | string | null
    >(props.group.avatar! ?? null);
    const [, updateProjectGroup] = useUpdateProjectGroupMutation();
    const [, deleteProjectGroup] = useDeleteProjectGroupMutation();
    const [, PrepareProjectGroupAssetForUploadDocument] =
      usePrepareProjectGroupAssetForUploadMutation();

    const form = useForm(validationSchema, {
      defaultValues: {
        color: props.group.color,
        name: props.group.name,
        projectGroupId: props.group.id,
      },
    });

    const handleSubmit = form.handleSubmit(async (input) => {
      const { data, error } = await updateProjectGroup(
        {
          input,
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.updateProjectGroup?.__typename === "UpdateProjectGroupSuccess"
      ) {
        toast.success(
          t(
            "screens.OrgProjectGroupsDrawer.UpdateGroupForm.updateProjectGroupSuccessToast",
            "Your group has been updated"
          )
        );
        await handleSubmitAvatarGroup(data.updateProjectGroup.project_group_id);
        props.onSuccess?.();
      } else {
        captureException(error);
        toast.error(
          t(
            "screens.OrgProjectGroupsDrawer.UpdateGroupForm.updateProjectGroupErrorsToast",
            "Something went wrong while updating the group."
          )
        );
      }
    });

    const handleRemove = async () => {
      const { data, error } = await deleteProjectGroup(
        {
          input: {
            projectGroupId: props.group.id,
          },
        },
        { additionalTypenames: ["orgs"] }
      );
      if (
        data?.deleteProjectGroup?.__typename === "DeleteProjectGroupSuccess"
      ) {
        toast.success(
          t(
            "screens.OrgProjectGroupsDrawer.UpdateGroupForm.deleteProjectGroupSuccessToast",
            "Your group has been deleted"
          )
        );
      } else {
        captureException(error);
        toast.error(
          t(
            "screens.OrgProjectGroupsDrawer.UpdateGroupForm.deleteProjectGroupErrorsToast",
            "Something went wrong while deleting the group."
          )
        );
      }
    };

    const handleSubmitAvatarGroup = async (projectGroupId: string) => {
      if (groupAvatar && groupAvatar instanceof File) {
        const { data, error } = await PrepareProjectGroupAssetForUploadDocument(
          {
            input: {
              name: groupAvatar.name,
              projectGroupId,
            },
          },
          { additionalTypenames: ["orgs"] }
        );
        if (
          data?.PrepareProjectGroupAssetForUpload?.__typename !==
          "PrepareProjectGroupAssetForUploadSuccess"
        ) {
          return;
        } else {
          captureException(error);
          toast.error(
            t(
              "screens.OrgProjectGroupDrawer.CreateGroupForm.uploadError",
              "The upload didn't succeed"
            )
          );
        }

        const { key, url } = data.PrepareProjectGroupAssetForUpload;

        uppy.addFile<FileMeta>({
          data: groupAvatar,
          meta: {
            internalFileType: "projectGroupAvatar",
            key,
            onFinalizeEnd: (_meta, _data, error) => {
              // Reset the fileInput value to "empty" once the upload is done
              if (groupAvatar) {
                setGroupAvatar(null);
              }
              if (error) {
                toast.error(
                  t(
                    "screens.OrgProjectGroupDrawer.CreateGroupForm.finalizeFilesUploadError",
                    "An error occured while uploading your avatar"
                  )
                );
              } else {
                toast.success(
                  t(
                    "screens.OrgProjectGroupDrawer.CreateGroupForm.finalizeFilesUploadSuccess",
                    "Project avatar has been successfully uploaded"
                  )
                );
              }
            },
            url,
          },
          name: groupAvatar.name,
          size: groupAvatar.size,
          type: groupAvatar.type,
        });
      }
    };

    return {
      actions: {
        handleRemove,
        handleSubmit,
        registerInput: form.register,
        setGroupAvatar,
      },
      state: {
        control: form.control,
        errors: form.formState.errors,
        groupAvatar,
      },
      t,
    };
  }
);

type UpdateGroupFormProps = {
  group: GroupsList_ItemFragment;
  orgId: string;
  onSuccess?: () => void;
};

function UpdateGroupForm(props: UpdateGroupFormProps) {
  const { actions, state, t } = useUpdateGroupForm(props);

  return (
    <Stack space="small">
      <AvatarGroupField
        setGroupAvatar={actions.setGroupAvatar}
        avatarUrl={state.groupAvatar}
      />
      <Form>
        <Columns space="small">
          <Column>
            <Columns space="small">
              <Column>
                <TextFieldInput
                  {...actions.registerInput("name")}
                  placeholder={t(
                    "screens.OrgProjectGroupsDrawer.UpdateGroupForm.namePlaceholder",
                    "Name"
                  )}
                  required
                  error={state.errors.name?.message}
                />
              </Column>
              <Column width="content">
                <SelectColorInput
                  {...actions.registerInput("color")}
                  control={state.control}
                />
              </Column>
            </Columns>
          </Column>
          <Column width="content">
            <Columns space="small">
              <Column>
                <AllowedTo perform={Permission.DeleteProjectGroup}>
                  <DeleteConfirmationDialog onConfirm={actions.handleRemove}>
                    <DialogTrigger asChild>
                      <Button
                        type="button"
                        variant="transparent"
                        circular
                        dataIntercomTarget="org-project-groups-update-delete"
                      >
                        <TrashIcon />
                      </Button>
                    </DialogTrigger>
                  </DeleteConfirmationDialog>
                </AllowedTo>
              </Column>
              <Column>
                <Button
                  tone="brandAccent"
                  variant="solid"
                  circular
                  dataIntercomTarget="org-project-groups-update-check"
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    await actions.handleSubmit();
                  }}
                >
                  <CheckIcon />
                </Button>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Form>
    </Stack>
  );
}

export { UpdateGroupForm };
