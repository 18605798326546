import React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";

// TODO: Remove this rule once stitches fixes its styles insertion bug : https://github.com/modulz/stitches/issues/483
/* eslint-disable sort-keys-fix/sort-keys-fix */

const ColumnWrapper = styled("div", {
  ...tw`min-w-0 w-full`,

  "&:first-child > div": {
    paddingTop: 0,
  },

  variants: {
    width: {
      "1-2": { flex: "0 0 50%", width: "auto" },
      "1-3": { flex: "0 0 33.33333333333333%", width: "auto" },
      "1-4": { flex: "0 0 25%", width: "auto" },
      "1-5": { flex: "0 0 20%", width: "auto" },
      "1-10": { flex: "0 0 10%", width: "auto" },
      "2-3": { flex: "0 0 66.66666666666666%", width: "auto" },
      "2-5": { flex: "0 0 40%", width: "auto" },
      "3-4": { flex: "0 0 75%", width: "auto" },
      "3-5": { flex: "0 0 60%", width: "auto" },
      "4-5": { flex: "0 0 80%", width: "auto" },
      content: tw`flex-shrink-0 w-auto`,
      fit: tw`flex-grow flex-shrink-0 w-auto`,
    },
  },
});

const ColumnContent = styled("div", {
  ...tw`block h-full`,

  compoundVariants: [
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$xxsmall",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$xxsmall",
        },
      },
      space: "xxsmall",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$xsmall",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$xsmall",
        },
      },
      space: "xsmall",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$small",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$small",
        },
      },
      space: "small",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$medium",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$medium",
        },
      },
      space: "medium",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$gutter",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$gutter",
        },
      },
      space: "gutter",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$large",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$large",
        },
      },
      space: "large",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$xlarge",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$xlarge",
        },
      },
      space: "xlarge",
    },
    {
      collapseBelow: "desktop",
      css: {
        paddingTop: "$xxlarge",
        "@desktop": {
          paddingTop: 0,
          paddingLeft: "$xxlarge",
        },
      },
      space: "xxlarge",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$xxsmall",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$xxsmall",
        },
      },
      space: "xxsmall",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$xsmall",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$xsmall",
        },
      },
      space: "xsmall",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$small",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$small",
        },
      },
      space: "small",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$medium",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$medium",
        },
      },
      space: "medium",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$gutter",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$gutter",
        },
      },
      space: "gutter",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$large",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$large",
        },
      },
      space: "large",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$xlarge",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$xlarge",
        },
      },
      space: "xlarge",
    },
    {
      collapseBelow: "tablet",
      css: {
        paddingTop: "$xxlarge",
        "@tablet": {
          paddingTop: 0,
          paddingLeft: "$xxlarge",
        },
      },
      space: "xxlarge",
    },
  ],

  variants: {
    align: {
      center: tw`justify-center`,
      left: tw`justify-start`,
      right: tw`justify-end`,
    },
    alignY: {
      bottom: tw`flex items-end`,
      center: tw`flex items-center`,
      top: tw`flex items-start`,
    },
    collapseBelow: {
      desktop: tw``,
      tablet: tw``,
    },
    space: {
      gutter: {
        paddingLeft: "$gutter",
      },
      large: {
        paddingLeft: "$large",
      },
      medium: {
        paddingLeft: "$medium",
      },
      small: {
        paddingLeft: "$small",
      },
      xlarge: {
        paddingLeft: "$xlarge",
      },
      xsmall: {
        paddingLeft: "$xsmall",
      },
      xxlarge: {
        paddingLeft: "$xxlarge",
      },
      xxsmall: {
        paddingLeft: "$xxsmall",
      },
    },
  },
});

type ColumnProps = {
  css?: CSS;
  children: React.ReactNode;
  width?:
    | "1-2"
    | "1-3"
    | "1-4"
    | "1-5"
    | "1-10"
    | "2-3"
    | "2-5"
    | "3-4"
    | "3-5"
    | "4-5"
    | "content"
    | "fit";
  /* from Columns */
  space?: "gutter" | "large" | "medium" | "small" | "xsmall" | "xxsmall";
  collapseBelow?: "desktop" | "tablet";
  alignY?: "bottom" | "center" | "top";
  align?: "center" | "left" | "right";
  wrapperStyle?: CSS;
};

function Column(props: ColumnProps) {
  return (
    <ColumnWrapper width={props.width} css={props.wrapperStyle}>
      <ColumnContent
        space={props.space}
        collapseBelow={props.collapseBelow}
        alignY={props.alignY}
        align={props.align}
        css={props.css}
      >
        {props.children}
      </ColumnContent>
    </ColumnWrapper>
  );
}

export type { ColumnProps };
export { Column };
