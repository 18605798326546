import { Network } from "@capacitor/network";
import { useEffect, useState } from "react";

/**
 * Custom hook to monitor network connectivity status
 * Returns an object with isOffline boolean state
 * Used across the app to handle offline/online scenarios
 */
export const useNetwork = () => {
  // Track if the device/browser is offline
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    /**
     * Check the initial network status when component mounts
     * Important for initial app state and offline-first functionality
     */
    const checkInitialStatus = async () => {
      try {
        // Get current network status using Capacitor's Network API
        const status = await Network.getStatus();
        // Update offline state based on connection status
        setIsOffline(!status.connected);
      } catch (error) {
        // If we can't determine network status, assume we're online
        console.error("Failed to get initial network status:", error);
        setIsOffline(false);
      }
    };

    // Execute initial check without blocking
    void checkInitialStatus();

    /**
     * Set up real-time network status monitoring
     * This listener will trigger whenever network status changes
     * Works across different platforms (iOS, Android, Web)
     */
    const networkListener = Network.addListener(
      "networkStatusChange",
      (status) => {
        // Update offline state when network status changes
        setIsOffline(!status.connected);
      }
    );

    /**
     * Cleanup function to remove network listener
     * Prevents memory leaks and unnecessary updates
     * Called when component unmounts
     */
    return () => {
      void networkListener.remove();
    };
  }, []); // Empty dependency array means this effect runs once on mount

  // Return current offline status to consuming components
  return { isOffline };
};

/**
 * Usage example:
 *
 * function MyComponent() {
 *   const { isOffline } = useNetwork();
 *
 *   if (isOffline) {
 *     return <OfflineMessage />;
 *   }
 *
 *   return <OnlineContent />;
 * }
 */

/**
 * Usage Example:
 *
 * function MyComponent() {
 *   const { isOffline } = useNetwork();
 *
 *   return (
 *     <div>
 *       {isOffline ? (
 *         <OfflineMessage />
 *       ) : (
 *         <OnlineContent />
 *       )}
 *     </div>
 *   );
 * }
 */
