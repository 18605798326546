import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Undraw from "react-undraw";
import { colors } from "~/config/stitches/colors";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CreateOrgDrawer } from "../../CreateOrgDrawer/CreateOrgDrawer";

function OrgRequiredMessage() {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" height="full">
      <Stack space="gutter" align="center">
        <Undraw name="empty" primaryColor={colors.emerald500} height="100%" />
        <Text>
          {t(
            "screens.Project.CreateProjectDrawer.OrgRequiredMessage.message",
            "You need to create an organization before creating a project"
          )}
        </Text>
        <CreateOrgDrawer>
          <DrawerTrigger asChild>
            <Button
              tone="brandAccent"
              leadingIcon={<PlusSmIcon />}
              dataIntercomTarget="create-project-create-org"
            >
              {t(
                "screens.Project.CreateProjectDrawer.OrgRequiredMessage.createButton",
                "Create an organization"
              )}
            </Button>
          </DrawerTrigger>
        </CreateOrgDrawer>
      </Stack>
    </Box>
  );
}

export { OrgRequiredMessage };
