import { Color } from "@clovis/server/src/app/config/colors/colors";
import type { SectionType } from "@clovis/server/src/app/domain/utils/permissions";
import React from "react";
import { useTranslation } from "react-i18next";
import { getI18nSection } from "~/config/users-rights-permissions/utils";
import type { BadgeProps } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";

type ProjectSectionTableProps = {
  section: SectionType;
};

const sectionBadgesColors: Record<
  SectionType,
  Pick<BadgeProps, "color">["color"]
> = {
  board: Color.Blue,
  chat: Color.Green,
  document: Color.Yellow,
  file: Color.Purple,
  filesFolders: Color.Teal,
  filters: Color.Indigo,
  folder: Color.Sky,
  info: Color.Orange,
  members: Color.Pink,
  org: Color.Rose,
  participants: Color.Pink,
  project: Color.Emerald,
  task: Color.Red,
};

const SectionTranslation = (props: ProjectSectionTableProps) => {
  const { t } = useTranslation();
  return <Text>{getI18nSection(props.section, t)}</Text>;
};

export { sectionBadgesColors, SectionTranslation };
