import * as React from "react";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

type InfoItemProps = {
  title: string;
  description: string;
  renderItem?: (children: React.ReactElement) => React.ReactElement | null;
  cta?: React.ReactNode;
};

function InfoItem(props: InfoItemProps) {
  return (
    <Stack>
      <Text tone="neutral">{props.title}</Text>
      {props.cta ? (
        <Inline space="small">
          <Text>{props.description}</Text>
          {props.cta}
        </Inline>
      ) : (
        <Text>{props.description}</Text>
      )}
    </Stack>
  );
}

export { InfoItem };
