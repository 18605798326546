import type { PluginOptions, Uppy } from "@uppy/core";
import { BasePlugin } from "@uppy/core";
import { compressImage } from "~/config/browser-image-compression";

class UppyImageCompressor extends BasePlugin {
  constructor(uppy: Uppy, opts: PluginOptions) {
    super(uppy, opts);

    this.id = opts?.id ?? "ImageCompressor";
    this.type = "modifier";
  }

  async compress(file: File) {
    const compressed = await compressImage(file);
    return compressed;
  }

  prepareUpload = (fileIDs: string[]) => {
    const promises = fileIDs.map((fileID) => {
      const file = this.uppy.getFile(fileID);

      this.uppy.emit("preprocess-progress", file, {
        message: "compressing image",
        mode: "indeterminate",
      });

      if (!file.type?.startsWith("image/")) {
        return;
      }

      return this.compress(file.data as File).then((compressedFile) => {
        this.uppy.setFileState(fileID, { data: compressedFile });
      });
    });

    const emitPreprocessCompleteForAll = () => {
      fileIDs.forEach((fileID) => {
        const file = this.uppy.getFile(fileID);
        this.uppy.emit("preprocess-complete", file);
      });
    };

    // Why emit `preprocess-complete` for all files at once, instead of
    // above when each is processed?
    // Because it leads to StatusBar showing a weird “upload 6 files” button,
    // while waiting for all the files to complete pre-processing.
    return Promise.all(promises).then(emitPreprocessCompleteForAll);
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export { UppyImageCompressor };
