import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import type { FileMeta } from "~/config/uppy";
import { uppy } from "~/config/uppy";
import { Avatar } from "~/design-system/Avatar";
import { Button } from "~/design-system/Button";
import { Field } from "~/design-system/Field";
import { Inline } from "~/design-system/Inline";
import { createComponentHook } from "~/types";
import { usePrepareProjectUserAvatarForUploadMutation } from "../EditUserForm.graphql";

type AvatarFieldProps = {
  userId: string;
  avatarUrl?: string | null;
  userName: string;
  projectId: string;
  editionAllowed: boolean;
};

const useAvatarField = createComponentHook((props: AvatarFieldProps) => {
  const { t } = useTranslation();

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [, prepareProjectUserAvatarForUpload] =
    usePrepareProjectUserAvatarForUploadMutation();

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    async function uploadFile() {
      const [file] = Array.from(e.target.files ?? []);

      if (!file) {
        return;
      }

      const fileMeta = await prepareProjectUserAvatarForUpload({
        input: {
          name: file.name,
          project_id: props.projectId,
          user_id: props.userId,
        },
      });

      if (
        fileMeta.data?.prepareProjectUserAvatarForUpload?.__typename ===
        "PrepareProjectUserAvatarForUploadErrors"
      ) {
        toast.error(
          t(
            "screens.ProjectMembersScreen.EditUserForm.AvatarField.uploadError",
            "The upload didn't succeed"
          )
        );
        return;
      }
      if (
        fileMeta.data?.prepareProjectUserAvatarForUpload?.__typename !==
        "PrepareProjectUserAvatarForUploadSuccess"
      ) {
        return;
      }
      const { key, url } = fileMeta.data.prepareProjectUserAvatarForUpload;
      uppy.addFile<FileMeta>({
        data: file,
        meta: {
          internalFileType: "userAvatar",
          key,
          onFinalizeEnd: (_meta, _data, error) => {
            // Reset the fileInput value to "empty" once the upload is done
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            if (error) {
              toast.error(
                t(
                  "screens.ProjectMembersScreen.EditUserForm.AvatarField.finalizeFilesUploadError",
                  "An error occured while uploading your avatar"
                )
              );
            } else {
              toast.success(
                t(
                  "screens.ProjectMembersScreen.EditUserForm.AvatarField.finalizeFilesUploadSuccess",
                  "User avatar has been successfully uploaded"
                )
              );
            }
          },
          url,
        },
        name: file.name,
        size: file.size,
        type: file.type,
      });
    }

    void uploadFile();
  };

  return {
    actions: {
      handleButtonClick,
      handleFileInputChange,
    },
    state: {
      fileInputRef,
    },
    t,
  };
});

function AvatarField(props: AvatarFieldProps) {
  const { actions, state, t } = useAvatarField(props);

  const input = (
    <input
      ref={state.fileInputRef}
      type="file"
      onChange={actions.handleFileInputChange}
      accept="image/x-png,image/gif,image/jpeg"
      hidden
    />
  );

  return (
    <Field
      label={t(
        "screens.ProjectMembersScreen.EditUserForm.AvatarField.avatar",
        "Avatar"
      )}
      name="photo"
      dataIntercomTarget="project-member-edit-avatar"
    >
      <Inline space="large">
        {props.avatarUrl ? (
          <Avatar src={props.avatarUrl} alt={props.userName} />
        ) : (
          <Avatar>{props.userName}</Avatar>
        )}
        {props.editionAllowed && !props.avatarUrl && (
          <Button
            type="button"
            variant="ghost"
            size="small"
            onClick={actions.handleButtonClick}
            dataIntercomTarget="project-members-edit-user-add-avatar"
          >
            {t(
              "screens.ProjectMembersScreen.EditUserForm.AvatarField.addAvatar",
              "Change avatar"
            )}
            {input}
          </Button>
        )}
      </Inline>
    </Field>
  );
}

export { AvatarField };
