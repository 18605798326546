// import type { validationSchema } from "@clovis/server/src/app/domain/project/update-users-company-name/update-users-company-name.validation";
// import uniqBy from "lodash/uniqBy";
import * as React from "react";
import type { UseFormGetValues, UseFormSetValue } from "react-hook-form";
// import type z from "zod";
import type { CompanyInfosApiSearchResult } from "~/config/papers/types";
import type { BasicMultiSelectFieldInputProps } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { createComponentHook } from "~/types";

const useCompanyNameAutocompleteSelectFieldInput = createComponentHook(
  (
    props: CompanyNameAutocompleteSelectFieldInputProps<CompanyInfosApiSearchResult>
  ) => {
    const [companyOptions, setCompanyOptions] = React.useState(props.options);

    // We plug this to react-select component to monitor the input field change
    const addNewOption = (newValue: string) => {
      const newValueObject = {
        label: newValue,
        type: BasicMultiSelectFieldInputOptionType.ORG,
        value: { name: newValue } as CompanyInfosApiSearchResult,
      };

      const newOptions = [...companyOptions, newValueObject];

      props.setValue("companyData", { name: newValue });

      setCompanyOptions(newOptions);
    };

    return {
      actions: {
        addNewOption,
      },
      state: {
        companyOptions,
      },
    };
  }
);

type CompanyNameAutocompleteSelectFieldInputProps<U> =
  BasicMultiSelectFieldInputProps<U> & {
    setValue: UseFormSetValue<any>;
    getValues: UseFormGetValues<any>;
    isDisabled?: boolean;
  };

const CompanyNameAutocompleteSelectFieldInput = React.forwardRef<
  HTMLInputElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CompanyNameAutocompleteSelectFieldInputProps<CompanyInfosApiSearchResult>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(function CompanyNameAutocompleteSelectFieldInput(props, _) {
  const { actions, state } = useCompanyNameAutocompleteSelectFieldInput(props);
  const { options, ...rest } = props;

  return (
    <BasicMultiSelectFieldInput
      options={state.companyOptions}
      compareValue={(left: { name: string }, right: { name: string }) => {
        return left.name?.toLowerCase() === right.name?.toLowerCase();
      }}
      isClearable={true}
      multiple={false}
      onCreateOption={async (value) => {
        actions.addNewOption(value);
      }}
      {...rest}
    />
  );
});

export { CompanyNameAutocompleteSelectFieldInput };
export type { CompanyNameAutocompleteSelectFieldInputProps };
