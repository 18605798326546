import * as React from "react";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { NotificationBadge } from "~/design-system/NotificationBadge";

export const DisplayNotification = (props: {
  strongNotification?: number;
  weakNotif: boolean;
  css?: CSS;
}) => {
  let strongNotificationToShow: number | string | undefined =
    props.strongNotification;
  if (props.strongNotification && props.strongNotification > 99)
    strongNotificationToShow = "99+";

  return props.strongNotification ? (
    <NotificationBadge css={props.css} variant="strong">
      {strongNotificationToShow}
    </NotificationBadge>
  ) : props.weakNotif ? (
    <Box css={{ ...props.css, paddingRight: "0.35rem" }}>
      <NotificationBadge variant="weak"></NotificationBadge>
    </Box>
  ) : (
    <></>
  );
};
