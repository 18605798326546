import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Nav, NavList, NavListItem } from "~/design-system/Navigation";
import { Text } from "~/design-system/Text";
import { createComponentHook } from "~/types";
import type { ProjectListByGroup } from "../ProjectListByGroup";
import { PROJECT_ITEM_HEIGHT, ProjectItem } from "./ProjectItem/ProjectItem";

type ProjectListContentByGroupProps = {
  projectListByGroup: ProjectListByGroup;
  activeProjectId: string | undefined;
  goBack: () => void;
};

const useProjectListContentByGroup = createComponentHook(
  (props: ProjectListContentByGroupProps) => {
    const parentRef = React.useRef(null);
    const rowVirtualizer = useVirtual({
      estimateSize: React.useCallback(() => PROJECT_ITEM_HEIGHT, []),
      parentRef,
      size: props.projectListByGroup.projects.length,
    });

    return { state: { parentRef, rowVirtualizer } };
  }
);

function ProjectListContentByGroup(props: ProjectListContentByGroupProps) {
  const { state } = useProjectListContentByGroup(props);
  const { t } = useTranslation();
  return (
    <>
      <Box
        onClick={props.goBack}
        css={{
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
          padding: "$xsmall",
        }}
      >
        <Inline space="xsmall">
          <Icon
            color="white"
            css={{ opacity: "0.5" }}
            size="xsmall"
            label="label icon"
          >
            <ArrowCircleLeftIcon />
          </Icon>
          <Text css={{ color: "white" }} size="standard">
            {t("NavigarionDrawer.ProjectListMenu.GoBack", "Go back to groups")}
          </Text>
        </Inline>
      </Box>
      <Nav
        ref={state.parentRef}
        css={{
          flexGrow: 1,
          overflowY: "auto",
        }}
        label="Projects List by group"
      >
        <NavList
          css={{
            color: "white",
            height: `${state.rowVirtualizer.totalSize}px`,
            position: "relative",
            width: "100%",
          }}
        >
          {state.rowVirtualizer.virtualItems.map((virtualRow) => {
            const row = props.projectListByGroup.projects[virtualRow.index];
            return (
              <NavListItem
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                css={{
                  height: `${virtualRow.size}px`,
                  left: 0,
                  position: "absolute",
                  top: 0,
                  transform: `translateY(${virtualRow.start}px)`,
                  width: "100%",
                }}
              >
                <ProjectItem
                  data={row}
                  active={row.id === props.activeProjectId}
                />
              </NavListItem>
            );
          })}
        </NavList>
      </Nav>
    </>
  );
}

export { ProjectListContentByGroup };
