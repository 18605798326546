import create from "zustand";

type MultiSelectStore<Item extends object> = {
  selectedItems: Item[];
  setSelectedItems: (newSelectedItems: Item[]) => void;
  clearSelection: boolean;
  setClearSelection: (clearSelection: boolean) => void;
};

const initialState = {
  clearSelection: false,
  selectedItems: [],
};

function createMultiSelectStore<T extends object>() {
  return create<MultiSelectStore<T>>((set) => ({
    ...initialState,
    setClearSelection: (newClearSelection) =>
      set(() => ({
        clearSelection: newClearSelection,
      })),
    setSelectedItems: (newSelectedItems) =>
      set(() => ({
        selectedItems: newSelectedItems,
      })),
  }));
}

export { createMultiSelectStore, initialState };
export type { MultiSelectStore };
