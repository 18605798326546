import * as React from "react";
import type { BoxProps } from "../Box";
import { Box } from "../Box";

type CardProps = BoxProps & {
  children: React.ReactNode;
};

function Card(props: CardProps) {
  const { children, ...rest } = props;

  return (
    <Box background="card" borderRadius="standard" boxShadow="medium" {...rest}>
      {children}
    </Box>
  );
}

export type { CardProps };
export { Card };
