/* This file centralises all the permissions of the clovis app, to help to manage it easily, 
   between the front and the backend
 */
import { OrgRoleId } from "@clovis/server/src/app/domain/org/role";
import { ProjectRoleId } from "@clovis/server/src/app/domain/project/role";
import {
  Permission as OrgPermissions,
  PermissionList as OrgPermissionsList,
} from "./org-permissions";
import {
  Permission as ProjectPermissions,
  PermissionList as ProjectPermissionsList,
} from "./project-permissions";

type NonEmptyArray<T> = [T, ...T[]];
type OrgPermissionsType = (typeof OrgPermissionsList)[number];
type ProjectPermissionsType = (typeof ProjectPermissionsList)[number];

/* The section part is just used for front end presentation, and permission organisation,
   it doesn't have any functionnal utility on the backend */
const enum Section {
  board = "board",
  chat = "chat",
  document = "document",
  file = "file",
  filesFolders = "filesFolders",
  folder = "folder",
  info = "info",
  org = "org",
  orgProject = "orgProject",
  project = "project",
  task = "task",
  members = "members",
  participants = "participants",
  filters = "filters",
}

type SectionType =
  | "board"
  | "chat"
  | "document"
  | "file"
  | "filesFolders"
  | "filters"
  | "folder"
  | "info"
  | "members"
  | "org"
  | "participants"
  | "project"
  | "task";

type PermissionTranssactionType<T> = {
  permission: T;
  owner: boolean;
  administrator: boolean;
  standard: boolean;
  limited: boolean;
  readOnly: boolean;
  section: SectionType;
  disabled: boolean;
};

const PermissionSchema: Record<
  OrgPermissionsType | ProjectPermissionsType,
  {
    /** The list of roles concerned allowed to perform the action, example: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard]  */
    roles: (OrgRoleId | ProjectRoleId)[];
    /** The "section" key helps to categorise all these rights in the front end UI that centralizes them: packages/client/src/screens/App/screens/AnonRightsScreen */
    section: SectionType;
    /** (definition to adjust if necessary): The "show" option is to know if the action (button, option, etc...) will be shown on the frontend, even if the user doesn't have the permissin to use it, https://uxpsychology.substack.com/p/hidden-vs-disabled-states*/
    show?: boolean;
  }
> & {
  OrgRules: () => Record<OrgRoleId, Record<string, boolean>>;
  ProjectRules: () => Record<ProjectRoleId, Record<string, boolean>>;
  getOrgRoles: (permission: OrgPermissionsType) => NonEmptyArray<OrgRoleId>;
  getProjectRoles: (
    permission: ProjectPermissionsType
  ) => NonEmptyArray<ProjectRoleId>;
} = {
  [OrgPermissions.RemoveFromOrganization]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "members",
    show: true,
  },
  [OrgPermissions.UpdateOrgRole]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "members",
    show: true,
  },
  [OrgPermissions.UpdateOrgMember]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "members",
    show: true,
  },
  [OrgPermissions.UpdateOrgProfile]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "info",
    show: true,
  },
  [OrgPermissions.GenerateOrgApiToken]: {
    roles: [OrgRoleId.Owner],
    section: "info",
    show: true,
  },
  [OrgPermissions.OrgInvitations]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "members",
    show: true,
  },
  [OrgPermissions.CreateProjectCategory]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "project",
    show: true,
  },
  [OrgPermissions.CreateProjectGroup]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "project",
    show: true,
  },
  [OrgPermissions.UpdateProjectGroup]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "project",
    show: true,
  },
  [OrgPermissions.DeleteProjectGroup]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "project",
    show: true,
  },
  [OrgPermissions.UpdateProjectCategory]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "project",
    show: true,
  },
  [OrgPermissions.DeleteProjectCategory]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "project",
    show: true,
  },
  [OrgPermissions.JoinOtherOrgMemberProject]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator],
    section: "project",
    show: true,
  },
  [OrgPermissions.CreateOrgProject]: {
    roles: [OrgRoleId.Owner, OrgRoleId.Administrator, OrgRoleId.Standard],
    section: "project",
    show: true,
  },
  [ProjectPermissions.UpdateUserTeams]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.SendUserEmailInvitation]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.DeleteProject]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "info",
    show: true,
  },
  [ProjectPermissions.ProjectInvitations]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.UpdateProjectArchive]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "info",
    show: true,
  },
  [ProjectPermissions.UpdateProjectProfile]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "info",
    show: true,
  },
  [ProjectPermissions.UpdateProjectRole]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.UpdateProjectTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.UpdateProjectTeams]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.CreateProjectLabel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "info",
    show: true,
  },
  [ProjectPermissions.DeleteProjectLabel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "info",
    show: true,
  },
  [ProjectPermissions.UpdateProjectLabel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "info",
    show: true,
  },
  [ProjectPermissions.AddNewFileVersion]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.CreateEmptyFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.DeleteFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.DuplicateFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.MoveFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.RenameFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.RequestApprovalExport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.RequestApprovalReport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.RequestUsersApprovalsFiles]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.RestoreFileVersion]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UpdateAssignationsFiles]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UpdateDueDateFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UpdateFilesApprovalMode]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UpdateLabelsFiles]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UpdatePermissionsFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.UploadFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.CreateTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.ImportTasks]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.RequestTasksExport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.RequestTasksReport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateArchiveTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateAssignationsTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateLabelsTasks]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateTaskLabels]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateSubtask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited, // It may evolve, for now we prefer that a user invited on a project can also validate / unvalidate subtasks
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateValidationTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited, // It may evolve, for now we prefer that a user invited on a project can also validate / unvalidate tasks
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.CreateFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.DeleteFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.DownloadFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.RenameFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.getSubFolderCount]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.MoveFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.UpdateAssignationsFolders]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.UpdateLabelsFolders]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.UpdatePermissionsFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.RequestFileTreeExport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "folder",
    show: true,
  },
  [ProjectPermissions.OpenFileFolderLabelsDialog]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "filesFolders",
    show: false,
  },
  [ProjectPermissions.AddMembersToProjectChannel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.CreateChannelForProject]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.CreateProjectDirectMessage]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.OrgProjectAuthorize]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "project",
    show: false,
  },
  [ProjectPermissions.CreateChannelForTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.CreateChannelForFileApprovals]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.RemoveMembersFromProjectChannel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.RenameProjectChannel]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "chat",
    show: true,
  },
  [ProjectPermissions.GetPresignedFileUrl]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Readonly,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.SignFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "file",
    show: true,
  },
  [ProjectPermissions.AddUserViewToFolder]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
      ProjectRoleId.Readonly,
      ProjectRoleId.Disabled,
    ],
    section: "folder",
    show: false,
  },
  [ProjectPermissions.PrepareFileStructureForUpload]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "filesFolders",
    show: true,
  },
  [OrgPermissions.PrepareOrgAssetForUpload]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "info",
    show: false,
  },
  [OrgPermissions.CreateOrgFilters]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "filters",
    show: false,
  },
  [ProjectPermissions.CreateProjectFilters]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "filters",
    show: false,
  },
  [OrgPermissions.PrepareProjectSpreadsheetForUpload]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "project",
    show: false,
  },
  [OrgPermissions.UpdateOrgUserInfos]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "members",
    show: true,
  },
  [OrgPermissions.UpdateOrgProjectSummary]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "info",
    show: false,
  },
  [ProjectPermissions.AddUserViewToProject]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "project",
    show: false,
  },
  [ProjectPermissions.DuplicateProject]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "project",
    show: true,
  },
  [ProjectPermissions.PrepareProjectAssetForUpload]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "project",
    show: false,
  },
  [ProjectPermissions.PrepareProjectGroupAssetForUpload]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "project",
    show: false,
  },
  [ProjectPermissions.PrepareTaskSpreadsheetForUpload]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UpdateProjectUserInfos]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.DeleteTask]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.DeleteTaskAttachment]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.UploadTaskAttachment]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.PersignedTaskAttachmentUrl]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
      ProjectRoleId.Readonly,
    ],
    section: "task",
    show: false,
  },
  [ProjectPermissions.RequestTaskExport]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.RequestTaskReport]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "task",
    show: false,
  },
  [ProjectPermissions.UpdateTaskFileVersion]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "task",
    show: true,
  },
  [ProjectPermissions.AddUserInTeam]: {
    roles: [ProjectRoleId.Owner, ProjectRoleId.Administrator],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.CreateTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.DeleteTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.RemoveUserInTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.UpdateTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.UpdateUserInTeam]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
    ],
    section: "participants",
    show: true,
  },
  [ProjectPermissions.AnnotateFile]: {
    roles: [
      ProjectRoleId.Owner,
      ProjectRoleId.Administrator,
      ProjectRoleId.Standard,
      ProjectRoleId.Limited,
    ],
    section: "file",
    show: true,
  },

  OrgRules: () => {
    type initialOrgPermissionsAssertionType = Record<
      OrgPermissionsType,
      boolean
    >;

    const initialOrgPermissionsAssertion = OrgPermissionsList.reduce(
      (
        accumulate: initialOrgPermissionsAssertionType,
        current: OrgPermissionsType
      ) => {
        accumulate[current] = false;
        return accumulate;
      },
      {} as initialOrgPermissionsAssertionType
    );

    const baseRules = {
      [OrgRoleId.Owner]: { ...initialOrgPermissionsAssertion },
      [OrgRoleId.Administrator]: { ...initialOrgPermissionsAssertion },
      [OrgRoleId.Standard]: { ...initialOrgPermissionsAssertion },
      [OrgRoleId.Limited]: { ...initialOrgPermissionsAssertion },
      [OrgRoleId.Readonly]: { ...initialOrgPermissionsAssertion },
      [OrgRoleId.Disabled]: { ...initialOrgPermissionsAssertion },
    };

    OrgPermissionsList.forEach((permission) => {
      if (!Object.keys(PermissionSchema).includes(permission)) {
        return;
      }

      // this assertion is because ts doesn't recognize the last guard clause
      const permissionSchema = PermissionSchema as unknown as Record<
        OrgPermissionsType,
        { roles: OrgRoleId[] }
      >;

      permissionSchema[permission].roles.forEach((role) => {
        baseRules[role][permission] = true;
      });
    });

    return baseRules;
  },
  ProjectRules: () => {
    type initialProjectPermissionsAssertionType = Record<
      ProjectPermissionsType,
      boolean
    >;

    const initialProjectPermissionsAssertion = ProjectPermissionsList.reduce(
      (
        accumulate: initialProjectPermissionsAssertionType,
        current: ProjectPermissionsType
      ) => {
        accumulate[current] = false;
        return accumulate;
      },
      {} as initialProjectPermissionsAssertionType
    );

    const baseRules = {
      [ProjectRoleId.Owner]: { ...initialProjectPermissionsAssertion },
      [ProjectRoleId.Administrator]: { ...initialProjectPermissionsAssertion },
      [ProjectRoleId.Standard]: { ...initialProjectPermissionsAssertion },
      [ProjectRoleId.Limited]: { ...initialProjectPermissionsAssertion },
      [ProjectRoleId.Readonly]: { ...initialProjectPermissionsAssertion },
      [ProjectRoleId.Disabled]: { ...initialProjectPermissionsAssertion },
    };

    ProjectPermissionsList.forEach((permission) => {
      if (!Object.keys(PermissionSchema).includes(permission)) {
        return;
      }

      // this assertion is because ts doesn't recognize the last guard clause
      const permissionSchema = PermissionSchema as unknown as Record<
        ProjectPermissionsType,
        { roles: ProjectRoleId[] }
      >;

      permissionSchema[permission].roles.forEach((role) => {
        baseRules[role][permission] = true;
      });
    });

    return baseRules;
  },

  getOrgRoles: (permission: OrgPermissionsType) => {
    return PermissionSchema[permission].roles as NonEmptyArray<OrgRoleId>;
  },

  getProjectRoles: (permission: ProjectPermissionsType) => {
    return PermissionSchema[permission].roles as NonEmptyArray<ProjectRoleId>;
  },
};

const getProjectData =
  (): PermissionTranssactionType<ProjectPermissionsType>[] => {
    const permissionsList = ProjectPermissionsList;
    const result = permissionsList.map((key) => {
      const permissionSchema = (
        PermissionSchema as unknown as Omit<
          typeof PermissionSchema,
          "OrgRules" | "ProjectRules"
        >
      )[key];

      return {
        administrator: permissionSchema.roles.includes(
          ProjectRoleId.Administrator
        ),
        disabled: permissionSchema.roles.includes(ProjectRoleId.Disabled),
        limited: permissionSchema.roles.includes(ProjectRoleId.Limited),
        owner: permissionSchema.roles.includes(ProjectRoleId.Owner),
        permission: key,
        readOnly: permissionSchema.roles.includes(ProjectRoleId.Readonly),
        section: permissionSchema.section,
        standard: permissionSchema.roles.includes(ProjectRoleId.Standard),
      };
    });

    return result;
  };

const getOrgData = (): PermissionTranssactionType<OrgPermissionsType>[] => {
  const permissionsList = OrgPermissionsList;

  const result = permissionsList.map((key) => {
    const permissionSchema = (
      PermissionSchema as unknown as Omit<
        typeof PermissionSchema,
        "OrgRules" | "ProjectRules"
      >
    )[key];

    return {
      administrator: permissionSchema.roles.includes(OrgRoleId.Administrator),
      disabled: permissionSchema.roles.includes(OrgRoleId.Disabled),
      limited: permissionSchema.roles.includes(OrgRoleId.Limited),
      owner: permissionSchema.roles.includes(OrgRoleId.Owner),
      permission: key,
      readOnly: permissionSchema.roles.includes(OrgRoleId.Readonly),
      section: permissionSchema.section,
      standard: permissionSchema.roles.includes(OrgRoleId.Standard),
    };
  });

  return result;
};

type PermissionDataType = PermissionTranssactionType<
  OrgPermissionsType | ProjectPermissionsType
>;
export type { PermissionDataType, PermissionTranssactionType, SectionType };
export { getOrgData, getProjectData, PermissionSchema, Section };
