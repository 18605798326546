import * as React from "react";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import { OrgAbacProvider } from "~/screens/App/screens/Org/Org.authorize";
import { GroupsList } from "./GroupsList/GroupsList";
import { Header } from "./Header";

type OrgProjectGroupsDrawerContentProps = {
  orgId: string;
};

function OrgProjectGroupsDrawerContent(
  props: OrgProjectGroupsDrawerContentProps
) {
  return (
    <DrawerContent position="right">
      <OrgAbacProvider>
        <Box width="screen" maxWidth="xsmall">
          <Header />
          <GroupsList orgId={props.orgId} />
        </Box>
      </OrgAbacProvider>
    </DrawerContent>
  );
}

export type { OrgProjectGroupsDrawerContentProps };
export { OrgProjectGroupsDrawerContent };
