import * as React from "react";
import create from "zustand";
import createContext from "zustand/context";
import { useBadge } from "~/config/capacitor/badge/use-badge";
import { captureMessage } from "~/config/sentry";
import { createComponentHook } from "~/types";
import { UserNotificationsFavicon } from "./notifications-favicon";
import type { UserNotifcationsQuery } from "./UserNotifications.graphql";
import {
  useReadNotificationsMutation,
  useUnreadNotificationsMutation,
  useUserNotifcationsQuery,
} from "./UserNotifications.graphql";
import type { UserBadgeNotificationsState } from "./utils/types";

type UserNotificationsProviderProps = {
  userId: string;
  children: React.ReactNode;
};

const { Provider, useStore: useUserNotificationsContext } =
  createContext<UserBadgeNotificationsState>();

const userNotificationsStore = create<UserBadgeNotificationsState>(
  (set, get) => ({
    getNotifications: (predicate) => {
      if (predicate) {
        return get().notifications.filter(predicate);
      }
      return get().notifications;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifications: [],
    setNotifications: (
      notifications: UserBadgeNotificationsState["notifications"]
    ) => {
      set((state) => ({
        ...state,
        notifications,
      }));
    },
  })
);

const useUserNotificationsHook = createComponentHook(() => {
  const [, markNotificationsAsRead] = useReadNotificationsMutation();
  const [, markNotificationsAsUnread] = useUnreadNotificationsMutation();

  const handleMarkNotificationsAsRead = React.useCallback(
    async (notificationIds: string[]) => {
      if (notificationIds.length > 0) {
        await markNotificationsAsRead(
          { notificationIds },
          { additionalTypenames: ["user_notifications"] }
        );
      }
    },
    [markNotificationsAsRead]
  );

  const handleMarkNotificationsAsUnread = React.useCallback(
    async (notificationIds: string[]) => {
      if (notificationIds.length > 0) {
        await markNotificationsAsUnread(
          { notificationIds },
          { additionalTypenames: ["user_notifications"] }
        );
      }
    },
    [markNotificationsAsUnread]
  );

  return {
    actions: { handleMarkNotificationsAsRead, handleMarkNotificationsAsUnread },
  };
});

const useUserNotificationsProvider = createComponentHook(
  (props: UserNotificationsProviderProps) => {
    const [fetchedNotifications, setFetchedNotifications] = React.useState<
      UserNotifcationsQuery["unreadNotifications"]
    >([]);
    const strigifiedNotifications = JSON.stringify(fetchedNotifications);
    const [{ data, error, fetching }, refetchNotifications] =
      useUserNotifcationsQuery({ variables: { userId: props.userId } });
    const badgeHook = useBadge();

    const setAppBadgeCount = React.useCallback(
      async (count: number) => {
        try {
          await badgeHook.setCount(count);
        } catch (e) {
          captureMessage("Failed to set badge count");
        }
      },
      [badgeHook]
    );

    React.useEffect(() => {
      const refetchInterval = setInterval(() => {
        refetchNotifications();
        // Refetch notifications every 10s (we juse polling instead of websockets because websockets is poorly supported by graphql / hasura / and many servers)
      }, 10000);
      return () => {
        clearInterval(refetchInterval);
      };
    }, [fetching]);

    // Handle notifications fetch query result
    React.useEffect(() => {
      if (fetching === false) {
        if (error) {
          captureMessage(error.message);
        } else {
          if (data?.unreadNotifications) {
            setFetchedNotifications(data.unreadNotifications);
          }
        }
      }
    }, [data, error, fetching]);

    // Dispatch newly fetched notifications to the store
    React.useEffect(() => {
      if (fetchedNotifications) {
        const notificationsStore = userNotificationsStore.getState();
        notificationsStore.setNotifications(
          fetchedNotifications as UserBadgeNotificationsState["notifications"]
        );
      }
      // Set the total number of notifications on the app mobile icon badge
      void setAppBadgeCount(
        fetchedNotifications?.filter((n) => n.is_strong).length ?? 0
      );
    }, [strigifiedNotifications]);

    return {};
  }
);

function UserNotificationsProvider(props: UserNotificationsProviderProps) {
  useUserNotificationsProvider(props);

  return (
    // cast our state as we checked that user isn't null
    <Provider createStore={() => userNotificationsStore}>
      <UserNotificationsFavicon />
      {props.children}
    </Provider>
  );
}

export {
  UserNotificationsProvider,
  useUserNotificationsContext,
  useUserNotificationsHook,
};
