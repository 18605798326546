import { keyframes } from "@stitches/react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const load = keyframes({
  "0%": {
    left: "-100%",
  },
  "100%": {
    left: "100%",
  },
});

const MainBar = styled("div", {
  "&::after": {
    animation: `${load} 1s infinite cubic-bezier(0, 0, 0.2, 1)`,
    boxShadow: "-40px 0 80px 9px rgba(254,254,254)",
    content: " ",
    height: "100%",
    left: "-100%",
    position: "absolute",
    top: 0,
    ...tw`rounded-3xl`,
  },
  ...tw`
  h-10 
  bg-info-500
  rounded-3xl	
  absolute`,
});

export { MainBar };
