import * as React from "react";
import { Box } from "~/design-system/Box";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";

type InfoItemWithBadgesProps = {
  title: string;
  badges: React.ReactNode[];
  cta?: React.ReactNode;
};

function InfoItemWithBadges(props: InfoItemWithBadgesProps) {
  return (
    <Stack>
      <Text tone="neutral">{props.title}</Text>
      <Box paddingTop="xxsmall">
        <Inline space="small">
          {props.badges.map((badge) => badge)}
          {props.cta}
        </Inline>
      </Box>
    </Stack>
  );
}
export { InfoItemWithBadges };
