import * as React from "react";
import { AllowedTo } from "react-abac";
import { Box } from "~/design-system/Box";
import { Stack } from "~/design-system/Stack";
import { Permission } from "~/screens/App/screens/Org/Org.authorize";
import { createComponentHook } from "~/types";
import { CreateGroupForm } from "./components/CreateGroupForm/CreateGroupForm";
import { GroupsListItem } from "./components/GroupListItem";
import { useGroupsList_DataQuery } from "./GroupsList.graphql";

const useGroupsList = createComponentHook((props: GroupsListProps) => {
  const queryContext = React.useMemo(
    () => ({ additionalTypenames: ["orgs"] }),
    []
  );
  const [{ data }] = useGroupsList_DataQuery({
    context: queryContext,
    variables: { orgId: props.orgId },
  });

  return {
    state: {
      project_groups: data?.orgs_by_pk?.project_groups,
    },
  };
});

type GroupsListProps = {
  orgId: string;
};

function GroupsList(props: GroupsListProps) {
  const { state } = useGroupsList(props);

  return (
    <Box padding="gutter">
      <Stack space="small">
        {state.project_groups?.map((group) => (
          <GroupsListItem key={group.id} orgId={props.orgId} group={group} />
        ))}
        <AllowedTo perform={Permission.CreateProjectGroup}>
          <CreateGroupForm orgId={props.orgId} />
        </AllowedTo>
      </Stack>
    </Box>
  );
}

export { GroupsList };
