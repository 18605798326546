import type {
  IProjectNotification,
  IProjectNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import {
  IProjectNotificationTypePrefix,
  ProjectNotificationNamespace,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";

interface IFolder {
  id: string;
}

interface IFile {
  id: string;
  version?: {
    id: string;
  };
}

interface IFolderNotificationPayload extends IProjectNotificationPayload {
  folder: IFolder;
}

interface IFileNotificationPayload extends IProjectNotificationPayload {
  file: IFile;
}

enum DocumentNotificationNamespace {
  Folder = "FOLDER",
  File = "FILE",
}

const IDocumentNotificationTypePrefix =
  `${IProjectNotificationTypePrefix}/${ProjectNotificationNamespace.Document}` as const;
const IFolderNotificationTypePrefix =
  `${IDocumentNotificationTypePrefix}/${DocumentNotificationNamespace.Folder}` as const;
const IFileNotificationTypePrefix =
  `${IDocumentNotificationTypePrefix}/${DocumentNotificationNamespace.File}` as const;

// All Chat notifications must extend from this interface rien qu
interface IFolderNotification extends IProjectNotification {
  type: `${typeof IFolderNotificationTypePrefix}/${string}`;
  readonly payload: IFolderNotificationPayload;
}

interface IFileNotification extends IProjectNotification {
  type: `${typeof IFileNotificationTypePrefix}/${string}`;
  readonly payload: IFileNotificationPayload;
}

export {
  DocumentNotificationNamespace,
  IDocumentNotificationTypePrefix,
  IFileNotificationTypePrefix,
  IFolderNotificationTypePrefix,
};

export type {
  IFileNotification,
  IFileNotificationPayload,
  IFolderNotification,
  IFolderNotificationPayload,
};
