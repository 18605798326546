import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Icon } from "~/design-system/Icon";
import { createComponentHook } from "~/types";

const useToolbar = createComponentHook(() => {
  const { t } = useTranslation();

  return { t };
});

export function Toolbar(props: { handleClose: () => void }) {
  const { t } = useToolbar();

  return (
    <Box display="flex" justifyContent="spaceBetween" width="full">
      <Heading level="4">
        {t("components.OrgProfileDrawer.Toolbar.profile", "Organization")}
      </Heading>
      <DrawerClose onClick={props.handleClose}>
        <Icon color="neutral" size="medium" label="dismiss">
          <XIcon />
        </Icon>
      </DrawerClose>
    </Box>
  );
}
