import * as React from "react";
import { useUserNotificationsContext } from "~/config/user/user-notifications/UserNotifications";
import { isProjectNotification } from "~/config/user/user-notifications/utils/filters";
import type { UserBadgeNotification } from "~/config/user/user-notifications/utils/types";
import { DisplayNotification } from "~/screens/App/components/DisplayNotification";
import { createComponentHook } from "~/types";
import { getDisplayNotificationProps } from "./helpers";

function filterProjectGroupBadge(
  notification: UserBadgeNotification,
  projectId: string
) {
  return (
    isProjectNotification(notification) &&
    notification.payload.project.id === projectId
  );
}

/**
 * Custom hook for managing the project group badge component.
 *
 * @param {ProjectGroupBadgeProps} props - The props for the project group badge component.
 * @returns {Object} - The state object containing the strongNotificationsCount and weakNotif values.
 */

const useProjectGroupBadge = createComponentHook(
  (props: ProjectGroupBadgeProps) => {
    const notifications = props.projectsId
      .map((projectId) => {
        return useUserNotificationsContext((state) =>
          state.notifications.filter((n) =>
            filterProjectGroupBadge(n, projectId)
          )
        );
      })
      .flat();

    const { strongNotificationsCount, weakNotif } =
      getDisplayNotificationProps(notifications);

    return {
      state: {
        strongNotificationsCount,
        weakNotif,
      },
    };
  }
);

type ProjectGroupBadgeProps = { projectsId: string[] };

/**
 * ProjectGroupBadge component.
 *
 * @param {projectsId} props - List of projects Id that we need to know the notifications.
 * @returns {React.ReactElement} - with a badge with the numbers of notifications.
 */

const ProjectGroupBadge = (props: ProjectGroupBadgeProps) => {
  const { state } = useProjectGroupBadge(props);
  return (
    <DisplayNotification
      weakNotif={state.weakNotif}
      strongNotification={state.strongNotificationsCount}
    />
  );
};

export { filterProjectGroupBadge, ProjectGroupBadge, useProjectGroupBadge };
