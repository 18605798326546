import { Color } from "@clovis/server/src/app/config/colors/colors";
import React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import type { BadgeProps } from "~/design-system/Badge";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Badge } from "../Badge/Badge";

type BadgesClusterProps<GenericProps = BadgeProps> = {
  children: React.ReactElement<BadgeProps>[];
  vSpacing?: "large" | "medium" | "none" | "small";
  hSpacing?: "large" | "medium" | "small";
  size?: BadgeProps["size"];
  max?: number;
  css?: CSS;
  plusBadgeOnClick?:
    | ((e: React.MouseEvent<Element, MouseEvent>) => void)
    | undefined;
  customPlusBadge?: React.ReactElement;
  plusBadgeGenericProps?: GenericProps;
  plusBadgeEnablePropagation?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const BadgesClusterWrapper = styled("div", {
  ...tw`flex flex-wrap`,

  defaultVariants: {
    hSpacing: "small",
    vGap: "small",
    vSpacing: "none",
  },
  variants: {
    hSpacing: {
      large: tw`space-x-4`,
      medium: tw`space-x-3`,
      small: tw`space-x-2`,
    },
    vGap: {
      small: tw`gap-y-2`,
    },
    vSpacing: {
      large: tw`my-4`,
      medium: tw`my-3`,
      none: tw`my-0`,
      small: tw`my-2`,
    },
  },
});

const BadgesClusterComponent = <GenericProps extends object>(
  props: BadgesClusterProps<GenericProps>,
  ref?: React.Ref<HTMLDivElement>
) => {
  let badges: React.ReactElement<any>[];
  const childrenLength = props.children.length;
  if (props.max) {
    if (props.max === childrenLength || props.max >= childrenLength) {
      badges = props.children;
    } else {
      badges = props.children.slice(0, props.max);
    }
  } else {
    badges = props.children;
  }

  const badgesRight = childrenLength - badges.length;
  const {
    customPlusBadge,
    plusBadgeEnablePropagation,
    plusBadgeGenericProps,
    plusBadgeOnClick,
    ...wrapperProps
  } = props;

  return (
    <BadgesClusterWrapper css={{ ...props.css }} ref={ref} {...wrapperProps}>
      {badges}
      {badgesRight > 0 &&
        (!customPlusBadge ? (
          <Button
            variant="text"
            css={{
              backgroundColor: "transparent",
              border: "none",
              display: "inline-flex",
              margin: "0px",
              maxWidth: "100%",
              minHeight: "fit-content",
              padding: "0px",
            }}
            onClick={(event) => {
              plusBadgeEnablePropagation ? null : event.stopPropagation();
              plusBadgeOnClick ? plusBadgeOnClick(event) : null;
            }}
            dataIntercomTarget="badges-cluster"
          >
            <Badge
              size={props.size ?? "medium"}
              color={Color.Gray}
              {...plusBadgeGenericProps}
            >{`+ ${badgesRight}`}</Badge>
          </Button>
        ) : (
          React.cloneElement(
            customPlusBadge,
            plusBadgeGenericProps,
            <Box display="flex">{`+ ${badgesRight}`}</Box>
          )
        ))}
    </BadgesClusterWrapper>
  );
};

const BadgesCluster = React.forwardRef<HTMLDivElement, BadgesClusterProps>(
  BadgesClusterComponent
) as <GenericProps extends object>(
  props: BadgesClusterProps<GenericProps> & {
    ref?: React.Ref<HTMLDivElement>;
  }
) => React.ReactElement<BadgesClusterProps<GenericProps>>;

export type { BadgesClusterProps };
export { BadgesCluster };
