import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Box } from "~/design-system/Box";
import { useBreakpoint } from "~/design-system/hooks";
import { createComponentHook } from "~/types";
import { Breadcrumb } from "./components/Breadcrumb/Breadcrumb";
import { FilesFoldersNavigatorList } from "./components/FilesFoldersNavigatorList/FilesFoldersNavigatorList";
import type { GetFilesFoldersNavigatorQuery } from "./FilesFoldersNavigator.graphql";
import {
  useGetFilesFoldersNavigatorQuery,
  useGetProjectFileRootQuery,
} from "./FilesFoldersNavigator.graphql";
import { useFileFoldersNavigatorContext } from "./FilesFoldersNavigatorContext";

type File = {
  file: NonNullable<
    GetFilesFoldersNavigatorQuery["folders_by_pk"]
  >["files"][number];
  type: "file";
  disabled?: boolean;
};

type Folder = {
  folder: NonNullable<
    GetFilesFoldersNavigatorQuery["folders_by_pk"]
  >["folders"][number];
  type: "folder";
};

type FileFolder = File | Folder;

const useFilesFoldersNavigator = createComponentHook(
  (props: FilesFoldersNavigatorProps) => {
    const { t } = useTranslation();
    const { breakpoint } = useBreakpoint();
    const { projectId } = useParams();

    const { folderId, folderName, setFolderName } =
      useFileFoldersNavigatorContext((state) => ({
        folderId: state.folderId,
        folderName: state.folderName,
        setFolderName: state.setFolderName,
      }));

    const [{ data: projectRootData }] = projectId
      ? useGetProjectFileRootQuery({
          variables: {
            project_id: projectId,
          },
        })
      : [{ data: null }];

    const [{ data }] = useGetFilesFoldersNavigatorQuery({
      variables: { folder_id: folderId },
    });

    const breadCrumbData =
      data?.folders_by_pk?.pwd?.map((p) => ({
        id: p.id,
        name: p.name,
        // TODO: retrieve those value from api instead of this placeholder
        strongNotification: 0,
        weakNotif: false,
      })) ?? [];
    const files = data?.folders_by_pk?.files ?? [];
    const folders = data?.folders_by_pk?.folders ?? [];
    const filteredFolders = props.foldersFilter?.(folders) ?? folders;

    React.useEffect(() => {
      const folderInfoSelect = breadCrumbData.filter((f) => f.id === folderId);
      if (folderInfoSelect.length > 0 && folderId) {
        const newName =
          projectRootData?.projects_by_pk?.root_folder_id === folderId
            ? "root"
            : folderInfoSelect[0].name;
        if (newName !== folderName) {
          setFolderName(newName);
        }
      }
    }, [folderId, breadCrumbData]);

    return {
      state: {
        breadCrumbData,
        breakpoint,
        files,
        folders: filteredFolders,
      },
      t,
    };
  }
);

function FilesFoldersNavigatorContent(props: FilesFoldersNavigatorProps) {
  const { state } = useFilesFoldersNavigator(props);

  return (
    <>
      <Breadcrumb folders={state.breadCrumbData} />
      <Box css={{ height: "100%", overflow: "auto" }}>
        <FilesFoldersNavigatorList
          filesLocalizable={props.filesLocalizable}
          filesDisabled={props.filesDisabled}
          files={state.files}
          folders={state.folders}
          hiddenFiles={props.hiddenFiles}
        />
      </Box>
    </>
  );
}

interface FilesFoldersNavigatorProps {
  height?: string;
  foldersFilter?: (folders: Array<Folder["folder"]>) => Array<Folder["folder"]>;
  filesDisabled?: boolean;
  filesLocalizable?: boolean;
  hiddenFiles?: boolean;
}

function FilesFoldersNavigatorComponent(props: FilesFoldersNavigatorProps) {
  return (
    // Replace a Stack but with a defined height for virtualized list in pop-up
    <Box
      css={{
        display: "grid",
        gridRowGap: "$gutter",
        gridTemplateRows: "$buttonHeight auto",
        height: props.height,
      }}
    >
      <FilesFoldersNavigatorContent {...props} />
    </Box>
  );
}

function FilesFoldersNavigator(props: FilesFoldersNavigatorProps) {
  const { foldersFilter, height } = props;
  return (
    <FilesFoldersNavigatorComponent
      height={height ?? "100%"}
      foldersFilter={foldersFilter}
      filesDisabled={props.filesDisabled}
      hiddenFiles={props.hiddenFiles}
      filesLocalizable={props.filesLocalizable}
    />
  );
}

export type { File, FileFolder, FilesFoldersNavigatorProps, Folder };
export { FilesFoldersNavigator, useFilesFoldersNavigator };
