import { z } from "zod";
import { validEmail } from "../../../config/zod/custom-validator";

export const validationSchema = z.object({
  categories: z.array(z.string().uuid()).nullable().optional(),
  client_email: validEmail().nonempty().nullable().optional(),
  client_fullname: z.string().nonempty().nullable().optional(),
  client_phone_number: z.string().nonempty().nullable().optional(),
  end_date: z.date().nullable().optional(),
  end_hour: z.string().nullable().optional(),
  groups: z.array(z.string().uuid()).nullable().optional(),
  hours_done: z.number().nonnegative().nullable().optional(),
  hours_expected: z.number().nonnegative().nullable().optional(),
  id: z.string().uuid(),
  price_estimate: z.string().nonempty().nullable().optional(),
  reference: z.string().nonempty().nullable().optional(),
  start_date: z.date().nullable().optional(),
  start_hour: z.string().nullable().optional(),
});
