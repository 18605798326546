import * as React from "react";
import { useNavigate } from "react-router";
import type { DialogProps } from "~/design-system/Dialog";
import { Dialog } from "~/design-system/Dialog";
import { CreateDemoProjectContent } from "./components/CreateDemoProjectContent";

type CreateDemoProjectDialogProps = {
  open?: DialogProps["open"];
  setOpen?: DialogProps["setOpen"];
  children?: React.ReactNode;
  onSuccess?: (projectId: string) => void;
};

const useCreateDemoProjectDialog = (props: CreateDemoProjectDialogProps) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const defaultOnSuccess = (projectId: string) => {
    navigate(`/project/${projectId}`);
  };

  return {
    actions: {
      defaultOnSuccess,
      setOpen: props.setOpen ?? setOpen,
    },
    state: { open: props.open ?? open },
  };
};

function CreateDemoProjectDialog(props: CreateDemoProjectDialogProps) {
  const { actions, state } = useCreateDemoProjectDialog(props);
  return (
    <Dialog open={state.open} setOpen={actions.setOpen}>
      {props.children}
      <CreateDemoProjectContent
        onSuccess={props.onSuccess ?? actions.defaultOnSuccess}
      />
    </Dialog>
  );
}

export { CreateDemoProjectDialog };
