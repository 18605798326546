import type * as Types from '../../../../../../../../schema.graphql';

import { gql } from 'urql';
import { CategoriesList_ItemFragmentDoc } from './components/UpdateCategoryForm/UpdateCategoryForm.graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CategoriesList_DataQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type CategoriesList_DataQuery = { orgs_by_pk?: { project_categories: Array<{ id: string, name: string, color: string }> } | null | undefined };


export const CategoriesList_DataDocument = gql`
    query CategoriesList_data($orgId: uuid!) {
  orgs_by_pk(id: $orgId) {
    project_categories {
      ...CategoriesList_item
    }
  }
}
    ${CategoriesList_ItemFragmentDoc}`;

export function useCategoriesList_DataQuery(options: Omit<Urql.UseQueryArgs<CategoriesList_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CategoriesList_DataQuery>({ query: CategoriesList_DataDocument, ...options });
};