import { SelectorIcon } from "@heroicons/react/outline";
import React from "react";
import type { DropdownIndicatorProps } from "react-select";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";

/** DropdownIndicator: custom top down arrows on the right of the select  */
export function DropdownIndicator({
  innerProps,
}: DropdownIndicatorProps<BasicMultiSelectFieldInputOption<any>>) {
  return (
    <div {...innerProps}>
      <Box paddingX={"small"}>
        <Icon label="select" size="small">
          <SelectorIcon />
        </Icon>
      </Box>
    </div>
  );
}
