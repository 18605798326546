import * as React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Box } from "../../Box";
import { Text } from "../../Text";

type CheckboxGroupLabelProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  variant: "list" | "simple";
};

const CheckboxGroupLegend = styled("legend", {
  ...tw`text-base font-medium text-gray-900`,
});

export const CheckboxGroupLabel = (props: CheckboxGroupLabelProps) => {
  if (!props.label && !props.description) {
    return null;
  }

  return (
    <Box
      paddingTop="small"
      display="flex"
      flexDirection="column"
      css={{
        ...(props.variant === "list" && tw`sr-only`),
      }}
    >
      <CheckboxGroupLegend>{props.label}</CheckboxGroupLegend>
      <Text size="small" tone="neutral">
        {props.description}
      </Text>
    </Box>
  );
};
