import { StreamChat } from "stream-chat";
import { env } from "~/config/env";

const streamChatClient = StreamChat.getInstance(env.VITE_STREAM_CHAT_API_KEY, {
  /* Set the client server location to EU West region for speed */
  baseURL: "https://chat-proxy-dublin.stream-io-api.com",

  /* Basic timeout is 3s, which can be too fast for low connexions */
  timeout: 30000,
});

type StreamChatClient = typeof streamChatClient;

export type { StreamChatClient };
export { streamChatClient };
