enum Permission {
  /* user */
  UpdateProjectUserInfos = "UpdateProjectUserInfos",
  UpdateUserTeams = "UpdateUserTeams",
  SendUserEmailInvitation = "SendUserEmailInvitation",
  /* project */
  DuplicateProject = "DuplicateProject",
  DeleteProject = "DeleteProject",
  ProjectInvitations = "ProjectInvitations",
  UpdateProjectArchive = "UpdateProjectArchive",
  UpdateProjectProfile = "UpdateProjectProfile",
  OrgProjectAuthorize = "OrgProjectAuthorize",
  UpdateOrgProjectSummary = "UpdateOrgProjectSummary",
  AddUserViewToProject = "AddUserViewToProject",
  PrepareProjectAssetForUpload = "PrepareProjectAssetForUpload",
  PrepareProjectGroupAssetForUpload = "PrepareProjectGroupAssetForUpload",
  /* project role */
  UpdateProjectRole = "UpdateProjectRole",
  /* project team */
  AddUserInTeam = "AddUserInTeam",
  RemoveUserInTeam = "RemoveUserInTeam",
  UpdateUserInTeam = "UpdateUserInTeam",
  UpdateProjectTeam = "UpdateProjectTeam",
  UpdateProjectTeams = "UpdateProjectTeams",
  CreateTeam = "CreateTeam",
  DeleteTeam = "DeleteTeam",
  UpdateTeam = "UpdateTeam",
  /* project label */
  CreateProjectLabel = "CreateProjectLabel",
  DeleteProjectLabel = "DeleteProjectLabel",
  UpdateProjectLabel = "UpdateProjectLabel",
  /* file */
  AddNewFileVersion = "AddNewFileVersion",
  CreateEmptyFile = "CreateEmptyFile",
  DeleteFile = "DeleteFile",
  DuplicateFile = "DuplicateFile",
  MoveFile = "MoveFile",
  RenameFile = "RenameFile",
  RequestApprovalExport = "RequestApprovalExport",
  RequestApprovalReport = "RequestApprovalReport",
  RequestUsersApprovalsFiles = "RequestUsersApprovalsFiles",
  RestoreFileVersion = "RestoreFileVersion",
  UpdateAssignationsFiles = "UpdateAssignationsFiles",
  UpdateDueDateFile = "UpdateDueDateFile",
  UpdateFilesApprovalMode = "UpdateFilesApprovalMode",
  UpdateLabelsFiles = "UpdateLabelsFiles",
  UpdatePermissionsFile = "UpdatePermissionsFile",
  UploadFile = "UploadFile",
  GetPresignedFileUrl = "GetPresignedFileUrl",
  SignFile = "SignFile",
  AnnotateFile = "AnnotateFile",
  /* task */
  ImportTasks = "ImportTasks",
  RequestTasksExport = "RequestTasksExport",
  RequestTasksReport = "RequestTasksReport",
  UpdateArchiveTask = "UpdateArchiveTask",
  UpdateAssignationsTask = "UpdateAssignationsTask",
  UpdateLabelsTasks = "UpdateLabelsTasks",
  CreateTask = "CreateTask",
  UpdateTask = "UpdateTask",
  DeleteTask = "DeleteTask",
  UpdateTaskLabels = "UpdateTaskLabels",
  UpdateSubtask = "UpdateSubtask",
  UpdateValidationTask = "UpdateValidationTask",
  PrepareTaskSpreadsheetForUpload = "PrepareTaskSpreadsheetForUpload",
  DeleteTaskAttachment = "DeleteTaskAttachment",
  UploadTaskAttachment = "UploadTaskAttachment",
  PersignedTaskAttachmentUrl = "PersignedTaskAttachmentUrl",
  RequestTaskExport = "RequestTaskExport",
  RequestTaskReport = "RequestTaskReport",
  UpdateTaskFileVersion = "UpdateTaskFileVersion",
  /* folder */
  CreateFolder = "CreateFolder",
  DeleteFolder = "DeleteFolder",
  DownloadFolder = "DownloadFolder",
  RenameFolder = "RenameFolder",
  MoveFolder = "MoveFolder",
  getSubFolderCount = "getSubFolderCount",
  UpdateAssignationsFolders = "UpdateAssignationsFolders",
  UpdateLabelsFolders = "UpdateLabelsFolders",
  UpdatePermissionsFolder = "UpdatePermissionsFolder",
  AddUserViewToFolder = "AddUserViewToFolder",
  /* file & folder */
  PrepareFileStructureForUpload = "PrepareFileStructureForUpload",
  OpenFileFolderLabelsDialog = "OpenFileFolderLabelsDialog",
  /* chat */
  AddMembersToProjectChannel = "AddMembersToProjectChannel",
  CreateChannelForProject = "CreateChannelForProject",
  CreateProjectDirectMessage = "CreateProjectDirectMessage",
  CreateChannelForTask = "CreateChannelForTask",
  CreateChannelForFileApprovals = "CreateChannelForFileApprovals",
  RemoveMembersFromProjectChannel = "RemoveMembersFromProjectChannel",
  RenameProjectChannel = "RenameProjectChannel",
  CreateProjectFilters = "CreateProjectFilters",
  RequestFileTreeExport = "RequestFileTreeExport",
}

const PermissionList = [
  "UpdateProjectUserInfos",
  "UpdateUserTeams",
  "DeleteProject",
  "ProjectInvitations",
  "UpdateProjectArchive",
  "UpdateProjectProfile",
  "UpdateProjectRole",
  "UpdateProjectTeam",
  "UpdateProjectTeams",
  "CreateProjectLabel",
  "DeleteProjectLabel",
  "UpdateProjectLabel",
  "AddNewFileVersion",
  "CreateEmptyFile",
  "DeleteFile",
  "DuplicateFile",
  "MoveFile",
  "RenameFile",
  "RequestApprovalExport",
  "RequestApprovalReport",
  "RequestUsersApprovalsFiles",
  "RestoreFileVersion",
  "UpdateAssignationsFiles",
  "UpdateDueDateFile",
  "UpdateFilesApprovalMode",
  "UpdateLabelsFiles",
  "UpdatePermissionsFile",
  "UploadFile",
  "CreateTask",
  "ImportTasks",
  "RequestTasksExport",
  "RequestTasksReport",
  "UpdateArchiveTask",
  "UpdateAssignationsTask",
  "UpdateLabelsTasks",
  "UpdateTask",
  "UpdateTaskLabels",
  "UpdateSubtask",
  "UpdateValidationTask",
  "CreateFolder",
  "CreateProjectFilters",
  "DeleteFolder",
  "getSubFolderCount",
  "DownloadFolder",
  "RenameFolder",
  "MoveFolder",
  "UpdateAssignationsFolders",
  "UpdateLabelsFolders",
  "UpdatePermissionsFolder",
  "OpenFileFolderLabelsDialog",
  "AddMembersToProjectChannel",
  "CreateChannelForProject",
  "CreateProjectDirectMessage",
  "OrgProjectAuthorize",
  "UpdateOrgProjectSummary",
  "CreateChannelForTask",
  "CreateChannelForFileApprovals",
  "RemoveMembersFromProjectChannel",
  "RenameProjectChannel",
  "GetPresignedFileUrl",
  "SignFile",
  "AddUserViewToFolder",
  "PrepareFileStructureForUpload",
  "AddUserViewToProject",
  "DuplicateProject",
  "PrepareProjectAssetForUpload",
  "PrepareProjectGroupAssetForUpload",
  "PrepareTaskSpreadsheetForUpload",
  "DeleteTask",
  "DeleteTaskAttachment",
  "UploadTaskAttachment",
  "PersignedTaskAttachmentUrl",
  "RequestTaskExport",
  "RequestTaskReport",
  "UpdateTaskFileVersion",
  "AddUserInTeam",
  "CreateTeam",
  "DeleteTeam",
  "RemoveUserInTeam",
  "UpdateTeam",
  "UpdateUserInTeam",
  "AnnotateFile",
  "SendUserEmailInvitation",
  "RequestFileTreeExport",
] as const;

export { Permission, PermissionList };
