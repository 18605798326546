import { Language } from "@clovis/server/src/app/config/i18next/languages/allowed-languages";

type Symbols = {
  B: string;
  EB: string;
  GB: string;
  KB: string;
  MB: string;
  PB: string;
  TB: string;
  YB: string;
  ZB: string;
};

const languageToSymbols = new Map<Language, Symbols>([
  [
    Language.FR,
    {
      B: "o",
      EB: "Eo",
      GB: "Go",
      KB: "Ko",
      MB: "Mo",
      PB: "Po",
      TB: "To",
      YB: "Yo",
      ZB: "Zo",
    },
  ],
]);

export { languageToSymbols };
