import svgToDataUri from "mini-svg-data-uri";
import tw from "twin.macro";
import { styled } from "~/config/stitches";

const CheckedStyledCheckbox = styled("div", {
  ...tw`bg-sky-500`,

  "&": {
    alignItems: "center",
    appearance: "none",
    backgroundImage: `url("${svgToDataUri(
      `<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>`
    )}")`,
    borderRadius: "0.25rem",
    boxShadow: "inset 0 0 0 1px gainsboro",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    padding: 0,
    width: "100%",
  },

  "&[data-disabled]": {
    ...tw`bg-neutral-300`,
  },

  defaultVariants: {
    color: "blue",
  },

  variants: {
    color: {
      blue: {},
      white: {
        boxShadow: "inset 0 0 0 1px white",
        ...tw`bg-transparent`,
      },
    },

    rounded: {
      false: {},
      true: {
        "&": {
          borderRadius: "50%",
          boxShadow: "none",
        },
      },
    },
  },
});

export { CheckedStyledCheckbox };
