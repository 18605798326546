import { InformationCircleIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/design-system/Button";
import { Icon } from "~/design-system/Icon";
import { Inline } from "~/design-system/Inline";
import { Text } from "~/design-system/Text";

function ActionsButtons(props: {
  handleCancel?: () => unknown;
  loading?: boolean;
  handleWarningVerification?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => unknown | undefined;
  handleSubmit?: () => void;
  preActionButton?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Inline
      css={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {props.loading && (
        <Text
          italic
          tone={"info"}
          css={{ alignItems: "center", display: "flex", marginRight: "2rem" }}
          size={"small"}
        >
          <Icon label="info" css={{ marginRight: "0.25rem" }}>
            <InformationCircleIcon />
          </Icon>
          {t(
            "screens.ProjectProfileScreen.ProjectInvitationForm.justWaitFewSeconds",
            "Just wait few seconds, we are validating these informations"
          )}
        </Text>
      )}
      {props.preActionButton && props.preActionButton}
      <Button
        type="button"
        variant="solid"
        css={{
          marginRight: "$gutter",
        }}
        onClick={() => {
          props.handleCancel?.();
        }}
        dataIntercomTarget="cancel"
      >
        {t(
          "screens.ProjectProfileScreen.ProjectInvitationForm.cancelButton",
          "Cancel"
        )}
      </Button>
      {props.handleWarningVerification ? (
        <Button
          loading={props.loading}
          variant={props.loading ? "soft" : "solid"}
          tone={props.loading ? "neutral" : "brandAccent"}
          dataIntercomTarget="Dialog-Submit-Button-Verification"
          onClick={(event) => {
            event.stopPropagation();
            props.handleWarningVerification?.(event);
          }}
        >
          {t(
            "screens.ProjectProfileScreen.ProjectInvitationForm.submitButtonVerification",
            "Submit"
          )}
        </Button>
      ) : (
        <Button
          loading={props.loading}
          variant={props.loading ? "soft" : "solid"}
          type="submit"
          tone={props.loading ? "neutral" : "brandAccent"}
          dataIntercomTarget="submit"
          onClick={() => {
            props.handleSubmit?.();
          }}
        >
          {t(
            "screens.ProjectProfileScreen.ProjectInvitationForm.submitButton",
            "Submit"
          )}
        </Button>
      )}
    </Inline>
  );
}

export { ActionsButtons };
