import * as React from "react";
import { useParams } from "react-router";
import { Box } from "~/design-system/Box";
import { SpinLoader } from "~/design-system/Spinner/SpinLoader";
import { createComponentHook } from "~/types";
import { OrgProfile } from "./OrgProfile";
import {
  useOrgProfileContainerQuery,
  useProjectInfosQuery,
} from "./OrgProfileContainer.graphql";

type OrgProfileContainerProps = {
  orgId: string;
  handleClose: () => void;
};

const useOrgProfileContainer = createComponentHook(
  (props: OrgProfileContainerProps) => {
    const { projectId } = useParams() as { projectId: string };

    const [{ data, error, fetching }] = useOrgProfileContainerQuery({
      variables: { orgId: props.orgId },
    });
    const [{ data: projectInfos }] = useProjectInfosQuery({
      pause: projectId === undefined,
      variables: { orgId: props.orgId, projectId },
    });

    return {
      state: {
        error,
        fetching,
        org: data?.orgs_by_pk,
        orgMembersInProject: projectInfos?.projects_by_pk?.members,
        projectName: projectInfos?.projects_by_pk?.name,
      },
    };
  }
);

function OrgProfileContainer(props: OrgProfileContainerProps) {
  const { state } = useOrgProfileContainer(props);
  if (state.fetching === true) {
    // TODO: add proper loading state to the app

    return (
      <Box
        width="full"
        height="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="standard"
      >
        <SpinLoader />
      </Box>
    );
  }
  if (state.org) {
    return (
      <OrgProfile
        org={state.org}
        orgMembersInProject={state.orgMembersInProject}
        projectName={state.projectName}
        handleClose={props.handleClose}
      />
    );
  }
  // if not fetching && no org, an error occured
  return <span>That should not happen {JSON.stringify(state.error)}</span>;
}

export { OrgProfileContainer };
