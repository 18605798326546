import type * as Types from '../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SaveFirebaseTokenMutationVariables = Types.Exact<{
  input: Types.SaveFirebaseTokenInput;
}>;


export type SaveFirebaseTokenMutation = { saveFirebaseToken?: { __typename: 'SaveFirebaseTokenErrors', errors: Array<{ __typename: 'InputValidationError' }> } | { __typename: 'SaveFirebaseTokenSuccess', user_device_id: string } | null | undefined };


export const SaveFirebaseTokenDocument = gql`
    mutation saveFirebaseToken($input: SaveFirebaseTokenInput!) {
  saveFirebaseToken(input: $input) {
    __typename
    ... on SaveFirebaseTokenSuccess {
      user_device_id
    }
    ... on SaveFirebaseTokenErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useSaveFirebaseTokenMutation() {
  return Urql.useMutation<SaveFirebaseTokenMutation, SaveFirebaseTokenMutationVariables>(SaveFirebaseTokenDocument);
};