import { OrgRoleId as Role } from "@clovis/server/src/app/domain/org/role";
import {
  OrgPermission as Permission,
  PermissionSchema,
} from "@clovis/server/src/app/domain/utils/permissions";
import React from "react";
import type { Rules } from "react-abac";
import { AbacProvider } from "react-abac";
import type { UserContextType } from "~/config/user/UserProvider";
import { useUser } from "~/config/user/UserProvider";
import {
  isAllowedToUpdateMember,
  isAllowedToUpdateRole,
} from "~/config/users-rights-permissions/org";

const rules: Rules<Role, Permission, UserContextType["user"]> = {
  ...PermissionSchema.OrgRules(),
};

// extra owner's permissions
rules[Role.Owner] = {
  ...rules[Role.Owner],
  UpdateOrgMember: (
    { memberId, memberRoleName }: { memberRoleName: Role; memberId: string },
    user
  ) =>
    isAllowedToUpdateMember(Role.Owner, memberRoleName) ||
    memberId === user?.id,
};

// extra administrator's permissions
rules[Role.Administrator] = {
  ...rules[Role.Administrator],
  UpdateOrgMember: (
    { memberId, memberRoleName }: { memberRoleName: Role; memberId: string },
    user
  ) =>
    isAllowedToUpdateMember(Role.Administrator, memberRoleName) ||
    memberId === user?.id,
  UpdateOrgRole: (memberRoleName: Role) =>
    isAllowedToUpdateRole(Role.Administrator, memberRoleName),
};

function OrgAbacProvider(props: { children: React.ReactNode }) {
  const { user } = useUser();
  return (
    <AbacProvider
      key={user.active_org?.org.id}
      roles={[user.active_org?.role.name as Role]}
      rules={rules}
      user={user}
    >
      {props.children}
    </AbacProvider>
  );
}

export { OrgAbacProvider, Permission, Role, rules };
