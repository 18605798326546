import * as React from "react";
import { uppy, useUppy } from "~/config/uppy";
import type { UppyStoreState } from "~/config/uppy/store";
import { createComponentHook } from "~/types";
import { FilesUploaderDrawer } from "./components/FilesUploaderDrawer/FilesUploaderDrawer";
import { FilesUploaderProgressBar } from "./components/FilesUploaderProgressBar";

const useFilesUploader = createComponentHook(() => {
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

  React.useEffect(() => {
    const handleComplete = () => {
      uppy.reset();
      setDrawerIsOpen(false);
    };

    uppy.on("complete", handleComplete);

    useUppy.subscribe(
      (prevState: UppyStoreState, nextState: UppyStoreState) => {
        if (nextState.totalProgress === 100) {
          setDrawerIsOpen(false);
        }
      }
    );

    return () => {
      uppy.off("complete", handleComplete);
    };
  }, []);

  const uppyState = useUppy();

  const handleProgressBarClick = () => {
    setDrawerIsOpen(true);
  };

  return {
    actions: {
      handleProgressBarClick,
      setDrawerIsOpen,
    },
    state: {
      drawerIsOpen,
      files: Object.values(uppyState.files),
      totalProgress: uppyState.totalProgress,
    },
  };
});

function FilesUploader() {
  const { actions, state } = useFilesUploader();

  /* Use this code to test / display the progress bar under dev without downloading files */
  // const devTestFiles = [
  //   {
  //     length: 100,
  //     name: "test.docx",
  //     progress: 10,
  //   },
  // ];
  // const devTestTotalProgress = 45;

  // comment this part also to test the display of the progress bar without files uploaded
  if (state.files.length === 0) {
    return null;
  }

  return (
    <FilesUploaderDrawer
      open={state.drawerIsOpen}
      setOpen={actions.setDrawerIsOpen}
      files={state.files}
    >
      {!state.drawerIsOpen && (
        <FilesUploaderProgressBar
          files={state.files}
          totalProgress={state.totalProgress}
          // files={devTestFiles}
          // totalProgress={devTestTotalProgress}
          onClick={actions.handleProgressBarClick}
        />
      )}
    </FilesUploaderDrawer>
  );
}

export { FilesUploader };
