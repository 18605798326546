import * as React from "react";
import { Box } from "~/design-system/Box";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer, DrawerContent } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import { createComponentHook } from "~/types";
import type { CreateOrgFormProps } from "./components/CreateOrgForm/CreateOrgForm";
import { CreateOrgForm } from "./components/CreateOrgForm/CreateOrgForm";
import { DrawerHeader } from "./components/DrawerHeader";

type CreateOrgDrawerProps = {
  children: React.ReactNode;
  onSuccess?: CreateOrgFormProps["onSuccess"];
  controlledDrawer?: Pick<ControlledDrawerProps, "open" | "setOpen">;
};

const useCreateOrgDrawer = createComponentHook(
  (props: CreateOrgDrawerProps) => {
    const [open, setOpen] = React.useState(false);

    const closeDrawer = (orgId: string) => {
      setOpen(false);
      props.onSuccess?.(orgId);
    };

    return {
      actions: {
        onSuccess: closeDrawer,
        setOpen,
      },
      state: {
        open,
      },
    };
  }
);

function CreateOrgDrawer(props: CreateOrgDrawerProps) {
  const { actions, state } = useCreateOrgDrawer(props);
  if (props.controlledDrawer) {
    return (
      <Drawer
        open={props.controlledDrawer.open}
        setOpen={props.controlledDrawer.setOpen}
      >
        <DrawerContent position="right">
          <Box padding="gutter" width="screen" maxWidth="xsmall">
            <Stack space="gutter">
              <DrawerHeader />
              <CreateOrgForm onSuccess={actions.onSuccess} />
            </Stack>
          </Box>
        </DrawerContent>
        {props.children}
      </Drawer>
    );
  }
  return (
    <Drawer open={state.open} setOpen={actions.setOpen}>
      <DrawerContent position="right">
        <Box padding="gutter" width="screen" maxWidth="xsmall">
          <Stack space="gutter">
            <DrawerHeader />
            <CreateOrgForm onSuccess={actions.onSuccess} />
          </Stack>
        </Box>
      </DrawerContent>
      {props.children}
    </Drawer>
  );
}

export type { CreateOrgDrawerProps };
export { CreateOrgDrawer };
